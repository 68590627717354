.list {
    margin-bottom: 1em;
}
.item {
    border: 1px solid #cbced3;
    border-radius: 0.3125em;
    overflow: hidden;
    margin-bottom: 0.625em;
    display: none;

    :global([class^='Ranges_visible']) & {
        display: block;
    }

    :global(.dark) & {
        border: 1px solid #16171d;
    }
}

.body {
    background: #ffffff;
    padding: 0.75em 0.625em;
    display: flex;
    flex-wrap: wrap;
    gap: 0.625em;

    :global(.dark) & {
        background: #2d3039;
        color: #c7c9d1;
    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f8fa;
    padding: 0.75em 0.625em;

    :global(.dark) & {
        background: #16171d;
        color: #fff;
    }
}

.del {
    color: #666f7e;
    cursor: pointer;
}

.title {
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1;
}

.info {
    display: flex;
    align-items: center;
    gap: 0.375em;
}

.color {
    width: 0.75em;
    height: 0.75em;
    border-radius: 0.1875em;
    margin-bottom: 0.1em;
    flex: none;
}

.col {
    width: calc(50% - 0.625em);
    display: flex;
    gap: 0.375em;
    font-size: 0.95em;
    font-weight: 500;

    span {
        color: #7c8195;
    }
}

.avg {
    width: 100%;
    display: flex;
    gap: 0.375em;
}

.nohead {
    composes: list;
}

.row {
    padding-top: 0.5em;
    font-weight: 500;
    width: 100%;

    .nohead & {
        &:nth-child(2) {
            border-top: 1px solid #e8e8e8;

            :global(.dark) & {
                border-top: 1px solid #424550;
            }
        }
    }

    &:nth-child(n + 3) {
        border-top: 1px solid #e8e8e8;

        :global(.dark) & {
            border-top: 1px solid #424550;
        }
    }
}

.label {
    color: #7c8195;
    font-size: 0.875em;
    margin-bottom: 0.5em;
}

.value {
    color: #1f2026;
    font-size: 0.875em;

    :global(.dark) & {
        color: #fff;
    }
}

.reset {
    margin-top: 0.625em;
    .btn {
        width: 100%;
    }
}
