.add_card_users {
  position: absolute;
  z-index: 100;
  right: -140px;
  background: #fff;

  border-radius: 6px;
  border: 1px solid #bec9d3;
  max-width: 17.75em;

  .dark & {
    background: #16171d;
    color: #fff;
    border: 1px solid #4a4f5b;
  }

  &__modal {
    top: 3.25em;
    left: 0;
  }

  &__header {
    padding: 0.625em;
    border-bottom: 1px solid #e8e8e8;

    .dark & {
      border-bottom: 1px solid #4a4f5b;
    }
  }

  &__input {
    width: 100%;
  }

  &__body {
    padding: 0.625em;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.625em;
  }

  &__list_item {
  }

  &__list_label {
    display: flex;
    align-items: center;
    gap: 0.625em;
  }

  &__list_checkbox {
  }

  &__list_title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
