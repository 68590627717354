.root {
    
    .list {
        display: flex;
        flex-wrap: wrap;
        gap: 1.25em;
    }

    .elem {
        border: 1px solid #E8E8E8;
        width: calc(33.3% - 0.6em);
        border-radius: 0.375em;
        display: flex;
        flex-direction: column;
        font-size: 1.6em;
        overflow: hidden;

        @media (min-width: 1921px) {
            width: calc(25% - 0.6em);
        }

        @media (min-width: 2500px) {
            width: calc(20% - 0.7em);
        }

        :global(.dark) & {
            background: #363A45;
            border-color: #484B5A;
        }
    }

    .image {
        width: 100%;
        cursor: pointer;
        position: relative;
        padding-bottom: 56%;

        img {
            position: absolute;
            display: block;
            object-fit: cover;
            object-position: center top;
            width: 100%;
            height: 100%;
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.625em;
        padding: 0.86em 0.6em;
        flex-grow: 1;
        min-height: 0;
    }

    .title {
        font-size: 0.75em;
    }

    .link {
        font-size: 1em;
        flex: none;
        width: 1.5em;
        height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00B5FF;
        border: 0;
        border-radius: 0.375em;
        transition: background 0.4s;
        cursor: pointer;

        &:hover {
            background: lighten($color: #00B5FF, $amount: 10%);
        }

        &:after {
          content: '';
          border: 0.2em solid transparent;
          border-left: 0.3em solid #fff;
          margin-right: -0.3em;
        }
    }

}