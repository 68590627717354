.root {

    .footer {
        justify-content: space-between;
        button {
            flex-grow: 0;
        }
    }
 
}
