@import "../../../scss/variables/variables";

.sections {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25em;
  color: #08203A;
}

.tab {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 19em;
  background: #F5F8FA;
  border: 1px solid #CBD4DC;
  border-radius: 0.375em;
  text-align: center;
  padding: 3.3em 1em;
  transition: border-color 0.3s;

  :global(.dark) & {
    background: #363A45;
    border-color: #484B5A;
    color: #fff;
  }

  &:hover:not(.disabled) {
    border-color: $lw_blue_color;
    cursor: pointer;
  }
}

.disabled {
  composes: tab;
  opacity: 0.6;
}

.icon {
  margin-bottom: 1.25em;

  :global(.cor_icon) {
    color: #00B5FF;
    font-size: 2.25em;
  }
}

.name {
  font-size: 1em;
}

.tooltip {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.25em;
}

.notify {
  position: absolute;
  top: 0.75em;
  left: 0.75em;
}