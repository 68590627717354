@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.onlineTest {
    height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
    justify-content:space-between;

    &__flexColumn {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-items: space-between; //flex-start;
        align-items: center;
        min-width: 0;  //mandatory when use flex-grow: 1;
        min-height: 0; //mandatory when use flex-grow: 1;
        &RightCol {
            align-items: flex-start;
        }
    }

    &__stretch {
        display: flex;
        justify-content: space-between;
        align-items: top; 
        font-size: 1.125em;
        gap: 2.5em;
    }

    &__body {
        padding: 1.25em 1.4em 0;
    }

    &__LWTable {
        margin-bottom: 1em;
        min-height: 5em;
    }

    &__title {
        padding-bottom: 0.8em;
        font-weight: 600;
    }
   
    &__separator {
        border: solid 0.02em #BEC9D3;
        opacity: 0.3;
        margin-bottom: 0.6em;
        width: 120%;
        position: relative;
        left: -50px;
        right: 50px;
		top: -10px;
    }

    &__grow {
        flex-grow: 1;
    }

    &__buttons {
        float: right;
		font-size: 1.2em;
        position: relative;
        top: -2.05em;
    }

    &__bottomSpace {
        padding: 1.2em 1.2em 1.2em 0;
        border-top: solid 1px grey;
    }
}
