.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75em;

    &__col {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75em;
        flex-grow: 2;

        &:first-child {
            flex-grow: 1;
            justify-content: flex-start;
        }

        &:last-child {
            flex-grow: 1;
            justify-content: flex-end;
        }

    }

    &__btn {
        span {
            line-height: 1;
        }
    }
}

.body {
    // border: 1px solid red;
}

.addOption {
    display: inline-flex;
    align-items: center;
    gap: 0.25em;

    color: #666F7E;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;

    :global(.dark) & {
        color: #BEC9D3;
    }

    &:hover {
      color: #00b5ff;
    }

    input {
        display: none;
    }

    i {
        font-size: 1.5em;
        font-weight: bold;
    }
    
}

.trash {
    font-size: 1.25em;
    cursor: pointer;
    transition: 0.3s;
    color: #666F7E;

    &:hover {
        color: #00b5ff;
    }
}

.audio {
    margin-top: 0.75em;
}

.checkbox {
    color: #595C6C;
    cursor: pointer;

    :global(.dark) & {
        color: #d1d1d1;
    }
}

.nav {

    > * {
        flex-grow: 1;
        text-align: center;
        justify-content: center;

        &:first-child {
            justify-content: flex-start;
            text-align: left;
        }
    
        &:last-child {
            justify-content: flex-end;
            text-align: right;
        }
    }



}

.btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    outline: none;
    text-decoration: none;
    border: 0;
    padding: 0;
    background: transparent;
    font-size: 1em;
    font-weight: 400;
    color: #666F7E;
    line-height: 1;
    cursor: pointer;

    :global(.dark) & {
        color: #E8E8E8;
    }

    &:disabled {
        cursor: default;
        opacity: 0.6;
    }
}

.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.875em;


    &__item {
       
    }

    &__label {
        display: flex;
        align-items: center;
        gap: 0.625em;

        p {
            margin: 0;
            color: #7C8195;
            font-size: 0.75em;
            font-weight: 500;
            line-height: 1;

            :global(.dark) & {
                color: #ffffff;
            }

        }

        i {
            font-size: 1em;
            cursor: pointer;
            transition: 0.3s;
            color: #666F7E;
        
            &:hover {
                color: #00b5ff;
            }
        }
    }


    &__img {
        position: relative;
        width: 17.5em;
        height: 10em;
        border-radius: 0.3125em;
        overflow: hidden;
        margin-top: 0.625em;
        border: 1px solid #bec9d3;
        cursor: pointer;
        transition: 0.3s;

        :global(.dark) & {
            border: 1px solid #4a4f5b;
        }

        &:hover {
          border: 1px solid #00b5ff;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

    &__fullScreen {
        position: absolute;
        top: 2em;
        right: 2em;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 2em;
        height: 2em;

        background-color: #f5f8fa;
        border: 0.0625em solid #bec9d3;
        border-radius: 0.3125em;
        transition: 0.3s;
        font-size: 0.5em;
        color: #1e1f25;

        :global(.dark) & {
            color: #ffffff;
            background: #363a45;
            border: 0.0625em solid #4a4f5b;
        }
    }


}

.video {
    position: relative;
    width: 51.175em;
    height: 29em;
    margin-top: 1.25em;
    border-radius: 0.3125em;
    border: 0.0625em solid #bec9d3;
    overflow: hidden;

    :global(.dark) & {
        border: 0.0625em solid #4a4f5b;
    }

    video {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__trash {
        position: absolute;
        top: 1em;
        right: 1em;
    }
  
}

.question {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.5em;

    width: 100%;

    &__title {
        flex-grow: 1;
        min-width: 1px;
    }

    &__file {
        flex: none;
        width: 21em;
    }
}

.preview {
    margin-top: 1.05em;

    &__view {
        position: relative;
        width: 100%;
        height: 12em;
        border-radius: 0.3125em;
        border: 0.0625em solid #bec9d3;
        overflow: hidden;
    
        :global(.dark) & {
            border: 0.0625em solid #4a4f5b;
        }
    
        video {
            display: block;
            width: 100%;
            height: 100%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    &__audio {
        display: flex;
        align-items: center;
        gap: 0.75em;
    }

    &__trash {
        position: absolute;
        top: 0.75em;
        right: 0.75em;
    }
}

