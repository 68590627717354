.root {
    gap: 0.9375em;
    padding-top: 1em;
}

.item {
    display: flex;
    flex-direction: column;
    width: 25em;
    border-radius: 0.375em;


    background: #FFFFFF;
    border: 1px solid;
    border-color: #E8E8E8;

    :global(.dark) & {
        background: #363A45;
        border-color: #484B5A;
    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.125em 1em;

    border-top-left-radius: 0.375em;
    border-top-right-radius: 0.375em;


    background: #E8E8E8;

    :global(.dark) & {
        background: #484B5A;
    }
}



.name {
    font-size: 1.25em;
    font-weight: 500;
}

.main {
    padding: 1em;
    flex-grow: 1;
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        display: flex;
        gap: 0.5em;
        margin-top: 0.25em;

        &:first-child {
            margin-top: 0;
        }

        span {
            line-height: 1.2;

            &:first-child {
                color: #7C8195;
                min-width: 8.5em;
            }
        }

    }
}


