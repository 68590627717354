@import '../../scss/variables/variables';
@import '../../scss/mixins/mixin';

.root {
    margin-top: 0;
    padding: 0.5em 0.7em 1em 1.5em;
    min-width: 0;
    margin: 0 -1.4em;
    flex-grow: 1;
    overflow: hidden overlay;
    display: flex;
    flex-direction: column;
    gap: 1.375em;
    outline: none;
    @extend %scrollbar;

    @media (min-width: 768px) {
        margin: 0 0.5em 0 0;
    }

    @media (max-width: 767px) and (orientation: 'landscape') {
        padding: 0;
    }
}

.wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;

    &_collapsed {
        @media (max-width: 767px) {
            flex: 0;
            width: 0;
            overflow: hidden;
        }
    }
}

.head {
    padding: 0 1.3em 0.25em 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75em;
    margin-right: 1.5em;

    [class*='cl_icon_edit'] {
        color: #c7c9d1;
        margin-left: 0.625em;
        cursor: pointer;
    }
}

.title {
    font-size: 1.125em;
    font-weight: 600;

    :global(.dark) & {
        color: $color_withe;
    }
}

.settings {
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.9375em;

    label {
        font-size: 0.75em;
        color: #7c8195;
        font-weight: 500;

        :global(.dark) & {
            color: #c7c9d1;
        }
    }

    [class^='Dropdown_root'] {
        width: 9.6em;
    }

    i {
        color: #666f7e;
        cursor: pointer;
        font-size: 1.125em;

        :global(.dark) & {
            color: #c7c9d1;
        }
    }
}

.one {
    flex-direction: row;
    flex-wrap: wrap;

    [class*='Chart_root'] {
        width: 100%;
        height: calc(100vh - 10.2em);

        @media (max-width: 767px) and (orientation: 'landscape') {
            height: calc(100vh - 4.375em);
        }
    }
}

.two {
    [class*='Chart_root'] {
        height: calc(50% - 0.8em);
    }
}

.three {
    [class*='Chart_root'] {
        height: calc(33% - 0.8em);
        flex-grow: 1;
    }
}

.mono {
    [class*='Chart_wrap'] {
        flex-direction: row;
    }
}

.four {
    flex-direction: row;
    flex-wrap: wrap;

    [class*='Chart_root'] {
        width: calc(50% - 0.7em);
        height: calc(50% - 0.7em);
        flex-grow: 1;
    }
}

.analog {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1.25em;
}

.showScenario {
    font-size: 1em;
    border-radius: 0.3125em;
    border: 0.0625em solid #bec9d3;
    padding: 0;
    background: #f0f3f5;
    color: #a2a6b6;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    cursor: pointer;

    &__wrap {
        position: relative;

        &:hover {
            .showScenario__tooltip {
                display: block;
                animation-duration: 0.3s;
                animation-name: tooltipLeft;
            }
        }
    }

    &__tooltip {
        display: none;

        position: absolute;
        z-index: 10;

        font-size: 0.875em;
        width: max-content;
        text-align: left;
        padding: 0.5em 1em;
        border-radius: 0.75em;
        line-height: 1.2;

        background-color: #ffffff;
        border: 1px solid;
        color: #383e47;
        border-color: #bec9d3;
        box-shadow: (0px 9px 15px rgba(52, 61, 70, 0.09));
        font-size: 0.875em;

        :global(.dark) & {
            background: #282b33;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            color: #ffffff;
            border-color: #343640;

            &:after {
                border-color: #343640;
            }
        }

        &:after {
            content: '';
            position: absolute;

            display: block;
            width: 1em;
            height: 1em;
            border: 1px solid;
            border-left: 0;
            border-bottom: 0;

            border-color: #bec9d3;
            background-color: inherit;
        }

        top: 54%;
        right: 3.25em;
        transform: translate(0, -50%);

        &:after {
            right: -0.52em;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &:hover:not(:disabled) {
        background: #00b5ff;
        color: #fff;
    }

    &:disabled {
        display: none;
    }

    :global(.dark) & {
        background: #282b33;
        color: #fff;
        border: 0.0625em solid #343640;
    }
}

@keyframes tooltipLeft {
    0% {
        transform: translate(-0.5em, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

