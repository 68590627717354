.root {
    width: 100%;

    .label {
        display: flex;
        justify-content: space-between;
        align-items: center;

        color: #7C8195;
        font-size: 0.75em;
        font-weight: 500;
        margin-bottom: 0.5em;

        :global(.dark) & {
            color: #ffffff;
        }
    }

    .trash {
        cursor: pointer;
        margin-left: 0.75em;
        font-size: 1.125em;
    }

    .subEditor {
        [class^='se-wrapper-inner'] {
            min-height: 7em!important;
        }
    }

}