@import '../../scss/mixins/mixin';
@import '../../scss/variables/variables';
$icon_caret: '\e903';
$lw_blue_color: #00b5ff;
$lw_table_even_color: #f0f3f5;
$lw_dark_theme_color: darken($color_withe, 10%);
$lw_dark_theme_bknd_color: #282b34;
$lw_table_listbox_bknd_color: #f5f8fa;
$cm_dark_btn_unsel_bknd_color: #14161b;

.videoEditor {
    display: flex;
    flex-grow: 1;
    min-height: 0;
    gap: 0.625em;

    &__stretch {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__modal {
        [class^='Modal_dialog'] {
            display: flex;
            flex-direction: column;
        }
        [class^='Modal_body'] {
            flex-grow: 1;
            min-height: 0;
            display: flex;
        }

        &.videoEditorSelectSource {
            [class^='Modal_body'] {
                display: block;
            }
        }
    }

    &__content {
        flex-grow: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
    }
    &__head {
        &:not(:empty) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1em;
        }

        [class^='Input_root'] {
            padding: 0 1em;
        }

        [class^='Dropdown_root'] {
            border-radius: 0.235em;
        }

        [class^='Dropdown_label'] {
            padding: 0.8em 1em;
        }
    }
    &__size {
        display: flex;
        align-items: center;
        font-size: 0.875em;
        gap: 0.6em;
    }

    &__select {
        &Values5 {
            width: 5em;
        }
        //&Values8 {width: 8em;}
        //&Values24 {width: 24em;}
        //.dark & {}
    }

    &__cancelBtn {
        width: 15em;
        color: red;
    }

    &__action {
        display: flex;
        align-items: center;
        gap: 0.625em;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
            display: none;
        }
    }

    &Aside {
        width: 22%;
        flex: none;
        border-radius: 0px 6px 6px 0px;
        border: 1px solid #bec9d3;
        display: flex;
        flex-direction: column;
        padding: 1.25em 0.75em;

        .dark & {
            background: #363a45;
            border-color: #343640;
            color: #c7c9d1;
        }

        &__title {
            flex: none;
            font-weight: 600;
            font-size: 1.1em;
            padding-bottom: 1em;
        }

        &__separator {
            border-bottom: solid 1px lightgray;
            width: 100%;
        }

        &__body {
            flex-grow: 1;
            min-height: 0;
            margin: -0.6em -0.3em 0 -0.6em;
            padding: 0.6em 0.5em 0.6em 0.6em;
            @extend %scrollbar;
        }

        &__bottom {
            margin: 0.5em -0.75em -1.25em;

            &_item {
                display: flex;
                align-items: center;
                gap: 0.625em;
                padding: 0.625em 1em;
                color: #666f7e;

                box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                transition: color 0.3s;

                &:hover {
                    color: rgba(#666f7e, 0.8);
                }

                .dark & {
                    color: #c7c9d1;

                    &:hover {
                        color: rgba(#c7c9d1, 0.8);
                    }
                }
            }
        }

        .videoEditorList {
            margin-top: 0.625em;
        }

        .videoEditorAside__instruction {
            font-size: 0.875em;
            line-height: 1.2;
            padding-bottom: 0.7em;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                font-weight: 600;
                color: #00b5ff;
            }

        }
    }
    &__controls {
        display: flex;
        // gap: 1.25em;
        border-radius: 0.375em;
        overflow: hidden;
        box-shadow: 0em 0.25em 1.875em 0em rgba(0, 0, 0, 0.1);
    }
    &__control {
        display: flex;
        align-items: center;
        font-size: 0.9em;
        gap: 0.625em;
        padding: 0.4375em 0.9375em;
        background: #fff;
        color: #666f7e;
        user-select: none;
        cursor: pointer;

        .dark & {
            background: #363a45;
            color: $main_color;
        }

        &.active {
            cursor: default;
            background: darken(#fff, 5%);
            .dark & {
                background: darken(#1e1f25, 0%);
            }
        }
    }

    &__media {
        flex-grow: 1;
        min-height: 0;
        border-radius: 0.3125em;

        img,
        video,
        canvas {
            display: block;
            object-fit: contain;
            border-radius: 0.3125em;
        }
        video {
            &::-webkit-media-controls {
                // --gradient-steps: none;
            }
        }
    }

    &__selMeasure {
        padding-bottom: 1em;
    }

    &__cameraSpace {
        height: 1em;
    }

    &__cameraInput {
        width: 40em;
    }

    &__modalRow {
        display: flex;
        justify-content: center;
        gap: 1.25em;

        .InitSectionTabs__icon {
            &:before {
                font-size: 2.25em;
            }
        }
    }

    &List {
        &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5625em 0.625em;
            background: #fff;
            box-shadow: 0em 0.25em 0.7em 0em rgba(0, 0, 0, 0.1);
            border-radius: 0.375em;
            position: relative;

            .dark & {
                background: #282b33;
            }

            .cl_icon_toggler {
                transform: rotate(-90deg);
                font-size: 0.8em;
                margin-right: 0.4em;
                cursor: pointer;

                &.active {
                    transform: rotate(90deg);
                }
            }
        }

        &__count {
            width: 80%;
        }

        &__action {
            display: flex;
            align-items: center;
        }
        &__body {
            display: none;
            margin-top: -0.4em;
            padding: 1em 0.625em 0.625em;
            background: #f4fafc;
            border-radius: 0 0 0.375em 0.375em;
            box-shadow: 0em 0.25em 0.7em 0em rgba(0, 0, 0, 0.1);

            &.active {
                display: block;
            }
            .dark & {
                background: #363a45;
            }
        }
        &__item {
            margin-bottom: 0.3125em;
            user-select: none;
        }
        &__img {
            position: relative;
            margin-bottom: 0.625em;
            padding-bottom: 60%;
            cursor: pointer;

            img {
                position: absolute;
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 0.375em;
            }
        }
        &__props {
            margin-bottom: 0.4375em;
        }
        &__box {
            position: relative;
            border-top: 1px solid #bec9d3;
            padding-top: 0.5em;

            .dark & {
                border-top: 1px solid #7c8195;
            }

            &:first-child {
                border-top: 0;
            }

            .cl_icon_close {
                position: absolute;
                right: 0;
                top: 0.9em;
                font-size: 0.7em;
                cursor: pointer;
            }
        }
        &__row {
            display: flex;
            line-height: 1.4;
            white-space: normal;
            word-break: break-all;
            gap: 0.625em;
            margin-bottom: 0.5em;
        }
        &__label {
            width: 5.8em;
            font-size: 0.8em;
            color: #7c8195;
            flex: none;

            .dark & {
                // color: #fff;
            }
        }
        &__val {
            word-break: break-all;
            font-size: 0.8em;
            flex-grow: 1;
            min-width: 0;
            font-weight: 500;
        }
        &__del {
            border-top: 1px solid #bec9d3;
            display: flex;
            align-items: center;
            gap: 0.5em;
            padding: 0.4375em 0.625em;
            margin: 0 -0.625em -0.625em;
            color: #666f7e;
            cursor: pointer;

            .dark & {
                color: #fff;
                border-top: 1px solid #7c8195;
            }
        }
    }
    .form {
        display: flex;
        flex-direction: column;
        gap: 0.75em;
        width: 100%;

        &__row {
            flex-grow: 1;
        }
    }
}

//-------------------------------------------------------------------------------------------

.exportCB {
    position: relative;
    background: #f4fafc;
    user-select: none;

    .dark & {
        background: #282b33;
    }
    &:hover {
        .exportCB__drop {
            display: block;
        }
    }
    &__label {
        padding: 0.625em 1em;
        display: flex;
        align-items: center;
        gap: 0.625em;
    }
    &__drop {
        display: none;
        position: absolute;
        bottom: 100%;
        left: 0;
        box-shadow: 0em 0.25em 0.7em 0em rgba(0, 0, 0, 0.1);
        background: #f4fafc;
        width: 100%;

        .dark & {
            background: #282b33;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        gap: 0.625em;
        padding: 0.625em 1em;
        cursor: pointer;
    }
}
