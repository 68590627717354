.root {

    .head {
        display: block;

        &__search {
            margin-top: 0.75em;
            width: 50%;
        }
    }

    .main {
        position: relative;
        padding: 0 0.8125em 0 0;
        height: 30vh;
        overflow-y: auto;

        @media (min-width: 768px) {
            // overflow-y: visible;
            scrollbar-gutter: stable;
    
            &::-webkit-scrollbar {
                width: 0.3em;
                height: 0.3em;
    
                &-track {}
    
                &-button {
                    opacity: 0;
    
                    &:single-button {
                        width: 0.1em;
                        height: 0.1em;
                    }
                }
    
                &-thumb {
                    background: #bec9d3;
                    border-radius: 1em;
                    border: 0 solid transparent;
                    background-clip: border-box;
                    min-height: 5em;
    
                    :global(.dark) & {
                        background: #464f57;
                    }
    
                }
            }
        }

    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1em;
        grid-auto-rows: minmax(4.875em, auto);

        &__item {
            position: relative;
            display: flex;
            align-items: center;
            gap: 2em;
            padding: 0.9375em 0.9375em 0.9375em 1.25em;
            
            background-color: #F4FAFC;
            border-radius: 0.125em;
            
            cursor: pointer;
            transition: background-color 0.3s;
            
            :global(.dark) & {
                background-color: #363A45;
            }

            &:hover {
                background-color: darken(#F4FAFC, 3%);
                
                :global(.dark) & {
                    background: lighten(#363A45, 5%);
                }
            }

        }

        &__icon {
            font-weight: 100;
            font-size: 2.5em;
            color: #666f7e;
            :global(.dark) & {
                color: #a2a2a5;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 0.25em;
        }

        &__dots {
            font-size: 2em;
            color: #666f7e;
            :global(.dark) & {
                color: #a2a2a5;
            }
        }

        &__caption {
            font-size: 0.875em;
            font-weight: 500;
            color: #1E1F25;

            :global(.dark) & {
                color: #ffffff;
            }
        }

        &__info {
            font-size: 0.75em;
            color: #666F7E;
    
            :global(.dark) & {
                color: #A2A2A5;
            }
        }
    }
}