.login-footer {
    background: #414952;
    margin-top: -1px;

    &__top {
        border-bottom: 1px solid rgba($color_withe, 0.1);
        padding: 4.5em 0 4.75em;
    }

    &__main {
        padding: 1.875em 0 2.5em;
        border-bottom: 1px solid rgba($color_withe, 0.1);

        span {
            color: rgba($color_withe, 0.7);
            font-size: 0.875em;
            line-height: 1.2;

            a {
                color: rgba($color_withe, 0.7);
                font-size: 1em;
            }
        }
    }

    &__bottom {
        padding: 1.875em 0 2.5em;

        a,span {
            color: rgba($color_withe, 0.7);
            font-size: 0.875em;
        }
    }

    &__row {
        @extend .container_center;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        width: 12.5em;
        height: 3.5em;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__label {
        color: $color_withe;
        font-weight: 500;
        font-size: 1.125em;
        
    }

    &__list {
        display: flex;
        align-items:center;
        margin-left: 4.3745em;
    }

    &__item {
        display: flex;
        align-items:center;
        margin-right: 3.125em;
        color: rgba($color_withe, 0.8);
        text-decoration: none;

        &:last-child {
            margin-right: 0;
            span:not(.cor_icon) {
                text-decoration: underline;
            }
        }

        i {
            font-size: 1.5em;
            margin-right: 0.42em;

        }

        span:not(.cor_icon) {
            font-size: 1.125em;
        }

    }

   
   
}