@import "../variables/variables";
@import "../../Components/ui/Icon/variables.scss";

.cor-uploading {
    display: flex;
    align-items: center;
    gap: 0.75em;

    &__preview {
        width: 2.25em;
        height: 2.25em;
        border-radius: 0.1875em;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        flex-grow: 1;
    }

    &__head {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 2em;
    }

    &__title {
        font-weight: 500;
        font-size: 0.75em;
    }

    &__size {
        font-size: 0.75em;
        color: #7C8195;
        font-weight: 500;
        margin-left: 0.5em;
    }

    &__delete {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0.9em;
        height: 0.9em;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        transition: color 0.3s;
        color: #666F7E;

        .dark & {
            color: #C7C9D1;
        }

        &:after, &:before {
            border: 0;
            transform: none;
        }

        &:before {
            font-family: '#{$icomoon-font-family}';
            content: $cor_icon_close;
            font-size: 0.75em;
        }

        // &:hover {
        //     color: $color_primary;
        // }
    }

    &__progress {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 2px;
        border-radius: 0.5em;
        background-color: #BEC9D3;
        margin-top: 0.75em;


        &_current {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 0.5em;
            background-color: #00B5FF;
        }
    }

    &__status {
        font-size: 0.75em;
        font-weight: 500;
        color: #7C8195;
        margin-top: 0.375em;

        &.warning {
            color: rgba(229, 190, 51, 0.9);
        }
    
        &.error {
            color: rgba(247, 86, 86, 0.9);
        }
    }

}