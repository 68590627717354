.login-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 0.875em 0;

    background: rgba(0, 181, 255, 0.2);
    backdrop-filter: blur(48px);

    &__content {
        @extend .container_center;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__left {}

    &__center {}

    &__right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22em;


        .cor_fontResize {

            &__btn {
                .dark & {
                    color: $color_withe;
                    border: 1px solid rgb(111, 146, 185);

                    &:first-child {
                        border-right: 0;
                    }

                    &:last-child {
                        border-left: 0;
                    }
                }
            }

        }

    }

    &__logo {
        width: 8.4375em;
        height: 2.3125em;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

    &__phone {
        display: flex;
        align-items: center;

        text-decoration: none;
        color: $color_withe;

        // .dark & {
        //     color: #1E1F25;
        // }

        .cor_icon {
            margin-right: 0.625em;
        }

        i {
            font-size: 1.5em;

        }

        span:not(.cor_icon) {
            font-size: 0.875em;
            font-weight: 700;
        }

    
    }

    &__link {
        display: flex;
        align-items: center;

        text-decoration: none;
        color: $color_withe;

        .cor_icon {
            margin-right: 1em;
        }

        i {
            font-size: 1.5em;

        }

        span:not(.cor_icon) {
            font-size: 0.875em;   
            position: relative;
            
            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                border-bottom: 1px solid;
            }
        }
    }
}