@import '../../scss/variables/variables';

$lw_blue_color: #00b5ff;

.SectionTabs {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25em;
    margin-left: 1.25em;
    color: #08203a;

    &__tab {
        //
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // width: 16em;
        background: white;
        border: solid 1px $lw_blue_color;
        border-radius: 5px;
        text-align: center;
        padding: 2.3em 1em;
        transition: background 0.4s;
        cursor: pointer;

        &:hover {
            background: darken($color: #f4fafc, $amount: 1%);
        }

        .dark & {
            background: #363a45;
            border-color: #484b5a;
            color: #fff;

            &:hover {
                background: lighten($color: #363a45, $amount: 10%);
            }
        }
    }

    &__icon {
        //
        margin-bottom: 1.25em;
        color: #00b5ff;

        .cor_icon {
            font-size: 2.25em;
        }
    }

    &__name {
        font-size: 1em;
    }

    // .tooltip {
    //     position: absolute;
    //     top: 0.5em;
    //     right: 0.5em;
    //     padding: 0.25em;

    //     .cor_icon {
    //         font-size: 1.25em;
    //         color: #7C8195;
    //         transition: color 0.3s;
    //     }

    //     &:hover {
    //         .cor_icon {
    //             color: #00B5FF;
    //         }

    //         .tooltip__content {
    //             display: block;
    //             animation: tooltipFadeUpSide 0.3s;
    //         }
    //     }

    //     &__content {
    //         display: none;

    //         position: absolute;
    //         top: 6.5em;
    //         right: -1.7em;

    //         font-size: 0.875em;
    //         width: 15.5em;
    //         text-align: left;
    //         padding: 1em;
    //         border-radius: 0.75em;
    //         line-height: 1.2;

    //         background-color: #ffffff;
    //         border: 1px solid;
    //         color: #383E47;
    //         border-color: #BEC9D3;
    //         box-shadow: (0px 9px 15px rgba(52, 61, 70, 0.09));

    //         .dark & {
    //             background: $footer_dark_button_bg;
    //             box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
    //             color: $footer_dark_color;
    //             border-color: $footer_dark_dropdown_border_color;;

    //             &:after {
    //                 border-color: $footer_dark_dropdown_border_color;;
    //             }

    //         }

    //         &:after {
    //             content: '';
    //             position: absolute;
    //             top: -0.52em;
    //             right: 2.175em;
    //             display: block;
    //             width: 1em;
    //             height: 1em;
    //             border: 1px solid;
    //             border-color: #BEC9D3;
    //             background-color: inherit;
    //             border-left: 0;
    //             border-bottom: 0;
    //             transform: rotate(-45deg);
    //         }
    //     }
    //}
}
