.root {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23.625em;
    height: 34.6875em;

    border: 0.1875em solid;
    border-color: #00B5FF;
    border-radius: 2em;
    padding: 4em 3.75em 2.875em;

    background-color: #ffffff;
    :global(.dark) & {
        background-color: #095F82;
    }

    &__wrap {
        width: 75em;
        height: 50em;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        width: 10.125em;
        height: 7.375em;

        .single & {
            font-size: 1.2em;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            
            &:first-child {
                display: block; 
                :global(.dark) & {
                    display: none;
                }
            }

            &:last-child {
                display: none;
                :global(.dark) & {
                    display: block;
                }
            }
        }
    }

    &__title {
        margin-top: 1em;
        font-size: 1.375em;
        font-weight: 600;
        letter-spacing: 0.025em;
        line-height: 1.33;
        
        color: #13589A;

        :global(.dark) & {
            color: #ffffff;
        }

        text-transform: uppercase;
        text-align: center;

        .single & {
            font-size: 1.55em;
            margin-top: 0;
        }
        
    }


}

.single {
    composes: root;
    width: 19.375em;
    padding: 2em;
    justify-content: space-between;
    border-radius: 3em;
}

.list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.1875em;
    width: 10.125em;
    margin-top: 2.125em;

    .single & {
        font-size: 1.2em;
        margin-top: 0;
    }


    &__item {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 3.25em;
        border-radius: 0.625em;
        border: 0.125em solid;
        border-color: #005398;
        color: #005398;

        :global(.dark) & {
            border-color: #ffffff;
            color: #ffffff;
        }

        i {
            font-size: 2em;
        }
    }
}

.info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.625em;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.25em;


        &:after {
            content: '';
            display: block;

            width: 2.625em;
            height: 2.625em;
            border-radius: 100%;
            border: 0.0625em solid;
            border-color: #1A1A18;

            :global(.dark) & {
                border-color: #ffffff;
            }

        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 1.875em;
        height: 1.875em;
        color: #005398;

        :global(.dark) & {
            color: #ffffff;
        }

        i {
            font-size: 2em;
        }

        &_top {
            i {
                font-size: 1.75em;
            }
        }
        &_bottom {
            margin-top: -0.2em;
            i {
                font-size: 1.25em;
            }
        }

    }

    &__iconCollumn {
        composes: info__icon;
        flex-direction: column;
    }
}

.icon {
    position: absolute;

    display: flex;
    align-items: center;
    width: fit-content;


    &__title {
        font-size: 1em;
        font-weight: 600;
        letter-spacing: 0.025em;
        line-height: 1.3;
        padding-bottom: 0.5em;
        text-align: center;

        color: #005398;

        :global(.dark) & {
            color: #ffffff;
        }

        .rightBottom &,
        .rightCenter &,
        .rightTop & {
            // border: 1px solid #000;
            position: absolute;
            top: 50%;
            transform: translateY(-50%)rotate(-90deg);
            left: -7.8em;

            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 12em;
            height: 2.75em;
            padding: 0;

            
        }
        .rightBottom & {
        }
        .rightCenter & {
        }

        .rightTop & {
            top: 1em;
            transform: rotate(-90deg)
        }

        .logging & {
            position: absolute;
            top: -7.6em;
            right: -16.45em;

            &:after {
                content: '';
                position: absolute;

                bottom: -2.5em;
                left: 50%;
                transform: translateX(-50%);
                display: block;

                width: 1.3125em;
                height: 1.3125em;
                border: 0.0625em solid;
                border-color: #1A1A18;
                border-radius: 100%;

                :global(.dark) & {
                    border-color: #ffffff;
                }
            }

        }

        .single & {
           display: none;
        }
    }

    &__wrap {
        position: relative;

        display: flex;
        align-items: center;
        gap: 1.625em;

        &:before {
            content: '';
            position: absolute;
            background-color: #BEC9D3;

            :global(.dark) & {
                background-color: #838794;
            }
        }
        
        .bottom & { 
            padding-top: 2.34em;
            
            &:before {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0.1875em;
                height: 100%;
            }
        }

        .rightBottom &,
        .rightCenter & {
            padding-left: 6.56em;
            width: 23.125em;

            &:before {
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 6.56em;
                height: 0.1875em;
            }

        }

        .rightTop & {
            padding-left: 6.56em;
            width: 23.125em;

            &:before {
                top: calc(2em - 0.1875em);
            
                left: 0;
                width: 6.56em;
                height: 0.1875em;
            }
        }

        .leftBottom &,
        .leftTop & {
            flex-direction: row-reverse;
            padding-right: 6.56em;
            width: 23.125em;

            &:before {
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 6.56em;
                height: 0.1875em;
            }
        }

        .topLeft &,
        .topRight &,
        .top & {
            padding-bottom: 2.34em;
            &:before {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0.1875em;
                height: 100%;
            }
        }

        .topRightLine & {
            &:before {
                background-color: transparent;
                display: block;

                width: 4.6em;
                height: 130%;

                top: 0;
                right: calc(50% - 0.1875em);

                border: 0.1875em solid;
                border-color: #BEC9D3;
                border-radius: 2em;
                border-top: 0;
                border-left: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0;
            
                :global(.dark) & {
                    border-color: #838794;
                }
             
            }
        }
        
    }

    &__view {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 4em;
        height: 4em;
        border-radius: 100%;
        border: 0.25em solid;
        border-color: #00B5FF;
        color: #323232;
        background-color: #ffffff;

        :global(.dark) & {
            color: #ffffff;
            background-color: #095F82;
        }

        box-shadow: (0px 5.144px 38.58px rgba(0, 0, 0, 0.10));

        i {
            font-size: 2.25em;
        }

        p {
            margin: 0;
            font-size: 1.75em;
            font-weight: 600;
        }
        
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75em;

    }

    &__label {
        font-size: 1.5em;
        font-weight: 600;
        line-height: 1.2;
        color: #323232;

        :global(.dark) & {
            color: #ffffff;
        }
        
        .bottom & {
            width: 7.25em;
            &:first-child {
                text-align: right;
            }
        }

        .rightTop & {
            position: absolute;
            top: -2.4em;
            left: 4.2em;
        }
    }

    &__line {
        position: absolute;
        top: -3.9em;
        left: 15.4em;
   
        width: 12em;
        height: 5.9em;

        border: 0.1875em solid;
        border-color: #BEC9D3;
        border-radius: 2em;

        :global(.dark) & {
            border-color: #838794;
        }

        border-top: 0;
        border-left: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;

        &:before {
            content: '';
            display: block;

            position: absolute;
            bottom: 100%;
            left: 100%;

            width: 3.5em;
            height: 3em;

            border: 0.1875em solid;
            border-color: #BEC9D3;
            border-radius: 2em;

            :global(.dark) & {
                border-color: #838794;
            }

            border-bottom: 0;
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
          
        }
    }
}

.line {
    position: absolute;
    top: -2.875em;
    right: -3.8em;

    display: flex;
    justify-content: space-between;
    width: 20.625em;

    border: 0.1875em solid;
    border-color: #BEC9D3;
    border-radius: 2em;

    
    border-left: 0;
    border-bottom: 0;
    border-right: 0;
    border-bottom-right-radius: 0;

    :global(.dark) & {
        border-color: #838794;
    }

    &.line4  {
        width: 30.75em;
    }

    &__item {
        position: relative;
        border-left: 0.1875em solid;
        height: 2.5em;
        border-color: #BEC9D3;

        :global(.dark) & {
            border-color: #838794;
        }

        &_btr {
            composes: line__item;
            border-left: 0;
            height: 8.5em;

            &:before {
                content: '';
                display: block;

                position: absolute;
                top: -0.1875em;
                bottom: 0;
                right: 0;

                width: 2em;
                border: 0.1875em solid;
                border-color: #BEC9D3;

                :global(.dark) & {
                    border-color: #838794;
                }

                border-left: 0;
                border-bottom: 0;
                border-top-right-radius: 2em;
            }  
        }

        &_btl {
            composes: line__item;
            border-left: 0;
            
            .line4 & {
                
                height: 26.8em;
            }

            &:before {
                content: '';
                display: block;

                position: absolute;
                top: -0.1875em;
                bottom: 0;
                left: 0;

                width: 2em;
                border: 0.1875em solid;
                border-color: #BEC9D3;

                :global(.dark) & {
                    border-color: #838794;
                }

                border-right: 0;
                border-bottom: 0;
                border-top-left-radius: 2em;

                .line4 & { 

                    border-bottom-left-radius: 2em;
                }
            }  
        }

        &_left {
            composes: line__item;
            position: absolute;
            top: 7.5em;
            left: 1.5em;
            height: 3em;
            transform: rotate(-90deg);
        }

        &_leftBottom {
            composes: line__item;
            position: absolute;
            bottom: -1.6em;
            left: 1.7em;
            height: 3em;
            transform: rotate(-90deg);
            border-left: 0;

            &:before {
                content: '';
                display: block;

                position: absolute;
                top: -0.1875em;
                bottom: 0;
                left: 0;

                width: 2em;
                border: 0.1875em solid;
                border-color: #BEC9D3;

                :global(.dark) & {
                    border-color: #838794;
                }

                border-right: 0;
                border-bottom: 0;
                border-top-left-radius: 2em;
            }  
        }

    }

    &__title {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        .line__item_left &,
        .line__item_leftBottom & {
            transform: translateX(-50%)rotate(180deg);
            padding-top: 0;
            padding-bottom: 0.625em;
        }
        
        font-size: 1em;
        font-weight: 600;
        letter-spacing: 0.025em;
        line-height: 1.3;
        padding-top: 0.625em;
        text-align: center;
        color: #005398;

        :global(.dark) & {
            color: #ffffff;
        }
    }
}

.line4 {
    composes: line;
}

.bottom {
    composes: icon;
    flex-direction: column;
    bottom: -6.5em;
    left: 50%;
    transform: translateX(-50%);
}

.rightBottom {
    composes: icon;
    right: -23.3em;
    bottom: 4.7em;
}

.rightCenter {
    composes: icon;
    right: -23.3em;
    bottom: 12.2em;
}

.rightTop {
    composes: icon;
    right: -23.3em;
    top: 3.9em;
}

.leftBottom {
    composes: icon;
    left: -23.3em;
    bottom: 4.7em;
}

.leftTop {
    composes: icon;
    left: -23.3em;
    top: 3.9em;
}

.logging {
    composes: icon;

    bottom: -4em;
    left: -19.9em;
}

.top {
    composes: icon;
    flex-direction: column;
    top: -6.5em;
    left: 50%;
    transform: translateX(-50%);
}

.topLeft {
    composes: icon;
    flex-direction: column;
    top: -6.5em;
    left: 4.6em;
  
}

.topRight {
    composes: icon;
    flex-direction: column;
    top: -6.5em;
    right: 4.6em;
}

.topRightLine {
    composes: icon;
    flex-direction: column;
    top: -6.5em;
    right: 3em;
}