@import "../ui/Icon/variables.scss";

.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.current {
    display: flex;
    align-items: center;
    justify-content: center;

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 1em;
        padding: 0 1.75em;
        color: #666F7E;

        :global(.dark) & {
            color: #d1d1d1;
        }

        p {
            margin: 0;
            font-size: 1.125em;
            font-weight: 500;
            min-width: 6.25em;

            &:last-child {
                text-align: right;
            }
        }
    }

    p {
        text-align: center;
    }

    span {
        font-size: 0.75em;
        padding: 0.5em;
        cursor: pointer;

        .dark & {
            color: #d1d1d1;
        }

        i {
            font-weight: bold;
        }
    }

 
}

.settings {
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.625em 1.25em;
    margin: 2em -1.375em 0;

    background: #F5F8FA;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);

    > * {
        width: 33.3%;
    }

    &:before {
      content: '';
      display: block;
      width: 33.3%;
      
      
    }
    
    :global(.dark) & {
        background-color: #363a45;
    }

}

.export {
    width: 8em;
    position: relative;

    &:hover {
        .export__list {
            display: block;
        }

        .export__toggle {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
        gap: 0.75em;
        justify-content: flex-end;
    }

    &__label {
        color: #7C8195;
        font-size: 0.75em;
        font-weight: 500;

        :global(.dark) & {
            color: #ffffff;
        }
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0.375em 0.75em;
        border: 0.0625em solid #bec9d3;
        border-radius: 0.125em;

        user-select: none;
        cursor: pointer;
        color: #666f7e;
      
        
        span {
            font-size: 0.625em;
            font-weight: 500;
        }

        &:after {
            font-family: 'cor';
            content: $cor_icon_caret;
            color: inherit;
            font-size: 0.6em;
        }

        &:before {
            font-family: 'cor';
            content: $cor_icon_log-out-circle;
            color: inherit;
            font-size: 1.25em;
          
        }

        :global(.dark) & {
            color: #c7c9d1;
            border: 0.0625em solid #c7c9d1;
        }
    }

    &__list {
        display: none;
        position: absolute;
        z-index: 10;
        padding: 0.125em 0.75em;
        left: 0;
        bottom: calc(100% - 0.0625em);
        width: 100%;
        border: 1px solid #bec9d3;
        box-shadow: 0 0.5625em 0.9375em rgba(52, 61, 70, 0.09);
        border-radius: 0.125em 0.125em 0 0;
        background: #ffffff;

        :global(.dark) & {
            background-color: #363a45;
        }

        > * {
            &:nth-child(n + 2) {
                border-top: 1px solid #bec9d3;
            }
        }
    }

    &__item {
        border-radius: 0;
        width: 100%;
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        padding: 0.4375em 0;
        font-size: 1em;
        font-weight: 500;
        cursor: pointer;
        color: #7C8195;
        transition: 0.3s;

        :global(.dark) & {
            color: #c7c9d1;
        }

        &:hover {
          color: #00b5ff;
        }

        p {
            margin: 0;
            font-size: 0.625em;
        }
    }
}