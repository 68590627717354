@import "../../../scss/variables/variables";

.cor_aside {
    display: flex;
    flex-direction: column;
    max-width: calc(100vh - 3.25em);
    margin: 1.25em 1em 1.25em 0;
    transition: 0.2s;

    .dark & {
        color: #C7C9D1;
    }

    &__title {
        display: flex;
        flex-direction: row;

        &__content {
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            border-radius: 0.375em;
            margin-left: 0.75em;

            &.hidden {
                display: none;
            }

            .dark & {
                background: #282B33;
            }
        }
    }

    &__box {
        display: flex;
        background: #FFFFFF;
        box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
        border-radius: 0.375em;
        padding: 0.9375em;

        &.hidden {
            writing-mode: vertical-rl;
            text-orientation: sideways;
            font-size: 1em;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            overflow: hidden;
            padding: 1em 0 1em 0;

            &.split1 {
                border-radius: 0 0 0.375em 0.375em;
                border-top: 1px solid #dfe5ea;
            }

            &.split2 {
                box-shadow: 0em -0.25em 1.875em rgba(0, 0, 0, 0.1);
                border-radius: 0.375em 0.375em 0 0;
                margin-top: 0;
            }

            & .cor_icon {
                cursor: pointer;

                color: $navbar_icon_color;
                font-size: 1.5em;

                .dark & {
                    color: #C7C9D1;
                }
            }

            transform: rotate(180deg);
        }

        &.horizontal {
            align-items: center;
            justify-content: center;
            padding: 0.813em;
        }

        .dark & {
            background: #282B33;
        }

        &:nth-child(n+2) {
            margin-top: 20px;
        }

        &_grow {
            flex-grow: 1;
            max-width: 100%;
            min-height: 0;

            &.hidden {
                & .cor_icon {
                    transform: rotate(90deg);
                }
            }
        }

        &.hidden .changeClass {
            transform: rotate(90deg);
        }

        &.invisible {
            visibility: hidden;
        }
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.413em 1.077em;
        font-size: 0.8em;
        cursor: pointer;
        transform: rotate(0);
        background: #00B5FF;
        border: 0;
        border-radius: 0.375em;
        color: #FFFFFF;

        &:before {
            margin-left: -0.2em;
            margin-top: 0.1em;
            line-height: 0.75em;
        }

        &.active {
            transform: rotate(180deg);
        }

        .hidden & {
            width: 100%;
        }
    }
}
