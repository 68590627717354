.instructions {
    background-color: $color_withe;
    transition: background-color 0.3s;

    .dark & {
        background-color: #16171D;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5em;
        grid-row-gap: 0.75em;
        margin-top: 2.5em;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 1em;
        text-decoration: none;
        color: #425A7D;

        .dark & {
            color: $login_color_input;
        }

        span:not(.cor_icon) {
            font-size: 1.25em;
            line-height: 1.2;
            text-decoration: underline;
        }

        i {
            font-size: 1.875em;
        }

    }
}