.root {
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    font-size: 1.25em;

    transition: 0.3s;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition-property: background color border-color;
    color: #666F7E;

    &:hover:not(:disabled) {
        color: #00b5ff;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
    }
}

.bg {
    composes: root;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.875em;
    height: 1.875em;
    border-radius: 0.375em;

    background-color: rgba(#383E47, 1);
    opacity: 0.6;
    color: #ffffff;
    border: 1px solid #4a4f5b;
    font-size: 1em;

    &:hover:not(:disabled) {
        opacity: 1;
        color: #ffffff;
    }
    &[class*="small"] {
        font-size: 0.75em;
    }
}

.small {
    font-size: 1em;
}