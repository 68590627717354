@import "../../scss/mixins/mixins";

.classVoice {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &__row {
        display: flex;

        .hidden & {
            gap: 1em;

            :nth-child(2) {
                order: -1;
            }
        }

        &:nth-child(n+2) {
            margin-top: 0.6875em;
        }

        &:last-child {
            justify-content: space-between;
        }

        & .cor_icon {
            font-size: 1.5em;
            color: #666F7E;

            & i:before {
                margin-right: 0.5em;
            }

            .dark & {
                color: #C7C9D1;
            }

            .hidden & {
                & i:before {
                    margin-right: 0;
                    margin-bottom: 0.25em;
                }
            }
        }
    }

    &__range {
        padding-top: 0.2em;
        flex-grow: 1;
    }

    &__col {
        display: flex;
        align-items: center;
        color: #383E47;

        .hidden &_clock {
            height: 4em;
        }
        .hidden & {
            flex-direction: row-reverse;
        }
        .dark & {
            color: #C7C9D1;
        }
    }
}
