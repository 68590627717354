@import "../../../scss/mixins/mixins";

.cor_dashboard {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 0.875em;
    min-width: 0;
    min-height: 0;
    max-height: 100%;

    &.dark {
        color: $dark_theme_color;
        background: $dark_theme_background;
    }

    &.mt-20 {
        margin-top: 1.25em;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25em;

        &.mt-20 {
            margin-top: 1.25em;
        }

        &.no-flex {
            display: block;
        }

        &_file {
            label {
                color: #00B5FF;
                padding: 0.3em 0.5em;
                border: 1px solid #00B5FF;
                border-radius: 0.3em;
                cursor: pointer;
            }

            a {
                text-decoration: none;
            }

            i {
                color: #00B5FF;
            }
        }
    }
    
    &__breadcrumbs {
        color: #7C8195;
        margin-top: 1em;
        margin-bottom: 1.25em;
        display: flex;
        flex-wrap: wrap;
        gap: 0.3em;
        
        span {
            font-size: 0.8em;
        }

        .sep {
            &:before {
                content: '•';
            }
        }
    }

    &__title {
        font-size: 1.125em;
        font-weight: 600;
        margin-bottom: 0.4em;
        flex: none;
    }

    &__description {
        color: #858C98;
        font-size: 0.8125em;
        margin-top: 0.75em;
    }

    &__minh {
        padding-bottom: 0;
    }

    &__content {
        position: relative;
        flex-grow: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75em;

        &__minh {
            margin-bottom: 0;
        }

        .main {
            height: 100%;
            min-height: 0;

            .charts {
                min-height: 0;
                height: 100%;
            }
        }
    }

    &__content_anon {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 1em;
    }

    &__class_header {
        padding: 1.25em 0 1em;
    }

    &__class_header__section__class {
        display: flex;
        align-items: center;
        gap: 0.5em;
        // margin-right: 1.5em;
    }
    &__class_header__section__students {
        display: flex;
        align-items: center;
        gap: 0.5em;
    }
    &__class_header__info {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        gap: 0.5em;
        // justify-content: space-between;
    }


    &__class_header__title {
    }
    &__class_header__class_button {
        cursor: pointer;
    }

    &__class_header__sections {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__class_header__sections_items {
        display: flex;
    }

    &__class_header__sections_items_container {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    &__class_header__section {
        padding: 0.5em 0.8333333333333334em;
        color: #7C8195;
        background-color: #F5F8FA;
        border: 1px solid #BEC9D3;
        border-left: none;
        cursor: pointer;
        font-size: 0.75em;

        &.active {
            background-color: #00B5FF;
            color: #FFFFFF;
            border: 1px solid #00B5FF;
        }

        &:first-child {
            border-radius: 0.25em 0 0 0.25em;
            border-left: 1px solid #BEC9D3;

            &.active {
                border-left: 1px solid #00B5FF;
            }
        }

        &:last-child {
            border-radius: 0 0.25em 0.25em 0;
        }

        &:only-child {
            border-radius: 0.25em;
        }
    }

    &__class_header__section_menu {
        position: relative;
    }

    &__top {
        margin-bottom: 0.5em;
    }

    &Box {
        background: #FFFFFF;
        box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
        border-radius: 0.375em;
        position: relative;
        flex-grow: 1;
        max-height: 100%;
        min-height: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .dark & {
            background: #282B33;
        }

        &__head {
            padding: 0.8em 1.25em 0.25em 1.25em;
            display: flex;
            align-items: center;
            gap: 0.625em;

            &Pad {
                padding-top: 1.3em;
                padding-left: 1.4em;
            }

            &_sm {
                padding: 0.625em 1.25em;
            }
        }

        &__header {
            padding: 0 1em 1em 1em;
            margin-bottom: 1.5em;

            border-bottom: 1px solid #E8E8E8;
            .dark & {
                border-bottom: 1px solid #A2A2A5;
            }

            .cor-net__title {
                line-height: 1.5em;
                font-size: 1.25em;
                text-align: left;
                margin-bottom: 0;
            }
        }

        &__subTitle {
            margin: 0 0 1em;
            font-size: 1.4em;
            font-weight: bold;
            display: flex;
            flex-direction: row;
            vertical-align: bottom;

            button {
                margin-top: -0.35em;
                font-size: 0.7em;
            }

            .cor_btn {
                margin-left: 2em;
                padding: 0.5em;
            }

            .dark & {
                color: #FFFFFF;
            }

            & .filter {
                margin: 0 1em 0 2em;
                font-size: 0.5em;
            }

            & .cor_icon {
                margin-top: 0.4em;
                font-size: 0.75em;
            }
        }

        &__subTitlePad {
            max-height: 100%;
            margin: -0.5em -1.4em 0;
            padding: 0.5em 1.4em;
        }

        &__subTitleBold {
            padding: 0.7em;
            font-size: 1.4em;
            font-weight: bold;
        }

        &__subTitleNormal {
            padding: 0.7em;
            font-size: 1.4em;
            font-weight: normal;
        }

        &__chapter {
            height: 100%;
        }

        + .dashboardBox {
            margin-top: 1.25em;
        }

        &__body {
            padding: 1.25em 1.4em 0;
            flex-grow: 1;
            min-height: 0;
        }

        &__top {
            padding: 1.25em 1.25em 0.9em 1.25em;
            position: relative;
        }

        &__scrolled {
            max-height: 100%;
            padding-bottom: 4em;
            padding-right: 1.25em;
            height: 100%;
            @include scrollbar;
        }

        &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            border-top: 1px solid #E8E8E8;
            padding: 0.75em 1.25em;

            .dark & {
                background: #16171D;
                border-top: 1px solid #343640;
            }
        }

        &__item {
            display: flex;
            align-items: center;
        }

        &__label {
            margin-right: 1em;
            font-size: 0.875em;
            color: #7C8195;
            
            .dark & {
                color: #fff;
            }
        }

        &__sort {
            .cor_icon {
                font-size: 1.75em;
                color: #1E1F25;
                display: inline-block;
                transform: translateY(0.05em);

                .dark & {
                    color: #fff;
                }
            }
        }

        &__dropdown {

            &_col {
                width: 6.75em;
            }
            &_size {
                width: 7.75em;
            }

            .cor_dropdown__drop {
                ul {
                    li {
                        &:nth-child(n + 2) {
                            border-top: 0;
                          
                        }
                    }
                }
            }
        }

        &.whiteboard {
            padding: 0.3em;
            border-width: 0;
            overflow: hidden;
        }


        &__button {
            font-size: 0.9em;
            padding: 0.5em 3em;
            margin: 0 1em;
            border-radius: 0.3em;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
                color: white;
                background-color: #67d4ff;

                .dark & {
                    background-color: $lw_dark_sel_hover_btn_bknd_color;
                    color: $lw_dark_sel_hover_btn_color;
                }
            }

            &Sel {
                background-color: $lw_blue_color;
                color: white;

                .dark & {
                    background-color: $lw_dark_sel_active_btn_bknd_color;
                    color: $lw_dark_sel_active_btn_color;
                }
            }

            &Unsel {
                background-color: white;
                color: $lw_blue_color;
                border: solid 1px $lw_blue_color;

                .dark & {
                    background-color: $lw_dark_unsel_active_btn_bknd_color;
                    color: white;
                }
            }
        }
    }

    &_tld {
        button, div, span {
            all: initial;
        }
    }
}

.notification-container-empty{
    display: none;
}