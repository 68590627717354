.root {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;

    :global(.aside_collapse) & {
        display: none;
    }
}

.item {
    position: relative;
    z-index: 10;
    [class^='Dropdown_root'] {
        // position: absolute;
        // top: 100%;
        // right: 0;
        border: 0;
        background-color: transparent;
        width: auto;

        &:hover:not(:disabled) {
            border: 0;
        }

        :global(.dark) & {
            border: 0;

            &:hover:not(:disabled) {
                border: 0;
            }
        }
    }

    :global([class*='Dropdown_open']) {
        border: 0;

        [class='cl_icon_chain'],
        [class='cl_icon_sort'] {
            color: #00a7eb;
        }
    }

    [class^='Dropdown_label'] {
        background-color: transparent !important;
        padding: 0.2em;
        border: 0;

        &:after {
            display: none;
        }
    }

    [class^='Dropdown_drop'] {
        max-height: none;
        padding: 0.6em;
        width: 9.6em;
        min-width: 0;

        li {
            white-space: normal;
        }
    }

    [class='cl_icon_chain'] {
        font-size: 1.4em;
    }

    [class='cl_icon_sort'] {
        font-size: 1.6em;
    }
}

.list {
    &[class^='Dropdown_root'] {
        border: 0;
    }
    &[class^='Dropdown_drop'] {
        padding-bottom: 3em;
        right: -1em;
    }

    button {
        // position: absolute;
        width: calc(100% + 2em);
        display: block;
        // width: initial;
        max-width: none;
        text-align: center;
        justify-content: center;
        border-radius: 0;
        border-top: 0;
        margin: 0.9em -0.8em -0.8em;
        border-radius: 0 0 3px 3px;
        padding: 0.7em;

        &:before {
            display: none;
        }

        &:hover {
            color: #fff;
        }
    }
}
.sort {
    &[class^='Dropdown_root'] {
        border: 0;
    }
    &[class^='Dropdown_drop'] {
        width: 11.7em;
    }
}

.btn {
}
