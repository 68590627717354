$track-w: 100%;
$track-h: 0.25em;
$track-w-vert: 0.25em;
$track-h-vert: 100%;
$thumb-d: 1.125em;
$track-c: #f2f2f2;
$track-c-dark: #3b3e47;
$filll-c: #00b5ff;

@mixin track($fill: 0, $vert: 0) {
    box-sizing: border-box;
    border: none;
    width: $track-w;
    height: $track-h;
    border-radius: 0.125em;
    background: $track-c;

    .dark & {
        background: $track-c-dark;
    }

    @if $fill == 1 {
        background: linear-gradient($filll-c, $filll-c) 0 / var(--sx) 100% no-repeat $track-c;

        .dark & {
            background: linear-gradient($filll-c, $filll-c) 0 / var(--sx) 100% no-repeat $track-c-dark;
        }
    }
}

@mixin fill() {
    height: $track-h;
    background: $filll-c;
}

@mixin thumb() {
    box-sizing: border-box;
    border: none;
    width: $thumb-d;
    height: $thumb-d;
    border-radius: 50%;
    background: #00b5ff;
    background: rgba(0, 181, 255, 0.4)
        url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNSIgY3k9IjUiIHI9IjUiIGZpbGw9IiMwMEI1RkYiLz4KPC9zdmc+Cg==')
        center no-repeat;
    background-size: 0.625em;
    backdrop-filter: blur(0.125em);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // // outline: 0.25em solid rgba(0, 181, 255, 0.4);
    // box-shadow: 0 0 0 0.25em rgba(0, 181, 255, 0.4);

    &:after {
        content: '';
        display: block;
        width: 0.625em;
        height: 0.625em;
        background: $filll-c;
        border-radius: 50%;
    }
}

.inputRange {
    
    &__wrap {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        min-width: 0;
        min-height: 0;
        position: relative;

        input[type='range']  {
            flex-grow: 1;
            min-width: 0;
            min-height: 0;
        }
        
        &.vertical {
            // flex-direction: column;
            width: $thumb-d;
            min-height: 9em;
            align-items: center;
            justify-content: center;
        }
    }

    &[type='range'] {
        &,
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
        }
    
        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--val) - var(--min)) / var(--range));
        --sx: calc(0.5 *#{$thumb-d} + var(--ratio) * (100% - #{$thumb-d}));
        margin: 0;
        padding: 0;
        width: $track-w;
        height: $thumb-d;
        background: transparent;
        position: relative;
        z-index: 1;
        font: 1em/1 arial, sans-serif;
    
        &:before {
            content: '';
            width: $track-w;
            height: $track-h;
            border-radius: 0.125em;
            background: $track-c;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
    
            .dark & {
                background: $track-c-dark;
            }
        }
    
        &::-webkit-slider-runnable-track {
            @include track(1);
        }
    
        &::-moz-range-track {
            @include track;
        }
    
        &::-ms-track {
            @include track;
        }
    
        &::-moz-range-progress {
            @include fill;
        }
    
        &::-ms-fill-lower {
            @include fill;
        }
    
        &::-webkit-slider-thumb {
            margin-top: 0.5 * ($track-h - $thumb-d);
            @include thumb;
        }
    
        &::-moz-range-thumb {
            @include thumb;
        }
    
        &::-ms-thumb {
            margin-top: 0;
            @include thumb;
        }
    
        &::-ms-tooltip {
            display: none;
        }
    
        &[orient=vertical] {
            transform: rotate(270deg);
            width: auto;
            position: absolute;
        }
    }
}
