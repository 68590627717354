.root {
    user-select: none;
    margin-bottom: 1.25em;
    border-radius: 0.375em;
    overflow: hidden;
    box-shadow: 0 0.25em 0.9375em rgba(0, 0, 0, 0.1);

    :global(.dark) & {
        background: #282b33;
    }
}

.rootOpen {
    composes: root;
}

.head {
    background: #eef1f2;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 0.7em;
    padding: 0.75em;

    :global(.dark) & {
        background: #2d3039;
    }

    .switcher {
        font-size: 0.8em;
    }
}

.legend {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    gap: 0.3em;

    [class^='cl_icon'] {
        font-size: 0.75em;
    }

    [class='cl_icon_edit'] {
        cursor: pointer;
        color: #666f7e;

        :global(.dark) & {
            color: #c7c9d1;
        }
    }

    input[type='text'] {
        font-size: 0.7em;
        width: 100%;
        padding: 0;
        border-radius: 0;
        border: 0 !important;
        border-bottom: 1px solid #c7c9d1 !important;
        background: transparent !important;
    }
}

.count {
    font-size: 0.6em;
}

.title {
    font-size: 0.7em;
    font-weight: 700;
    word-break: break-all;
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625em;

    [class='cl_icon_trash'] {
        font-size: 0.85em;
        cursor: pointer;
        color: #666f7e;

        :global(.dark) & {
            color: #c7c9d1;
        }
    }
    [class='cl_icon_caret'] {
        cursor: pointer;
        font-size: 0.65em;
        color: #a2a6b6;
        transition: transform 0.4s;
        transform: rotate(-90deg);

        .rootOpen & {
            transform: rotate(0deg);
        }

        :global(.dark) & {
            color: #c7c9d1;
        }
    }
}

.body {
    overflow: hidden;
    height: 0;
    padding: 0 0.7em;
    transition: padding 0.4s;

    .rootOpen & {
        height: auto;
        padding: 0.7em;
    }
}

.item {
    font-size: 0.7em;
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}
