@import "../Icon/variables.scss";
.root {
    position: relative;

    &:after {
        content: $cor_icon_search;
        font-family: 'cor';
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.875em;
        font-size: 0.875em;
        transition: 0.3s;
        color: #A2A6B6;

        :global(.dark) & {
            color: #d1d1d1;
        }
    }

    input {
        padding-right: 2.5em;
    }

}
