.root {
    position: relative;
    max-height: 70vh;
    overflow: hidden;
    overflow-y: auto;
    line-height: 1.2;
    background: #f5f8fa;
    border: 1px solid #bec9d3;
    border-radius: 0.125em;
    padding: 0.8125em;
    color: #595c6c;

    width: 100%;

    :global(.dark) & {
        background: #363a45;
        border: 1px solid #4a4f5b;
        color: #fff;
    }

    > * {
        font-size: 0.75em;
    }

    @media (min-width: 768px) {
        scrollbar-gutter: stable;

        &::-webkit-scrollbar {
            width: 0.3em;
            height: 0.3em;

            &-track {
            }

            &-button {
                opacity: 0;

                &:single-button {
                    width: 0.1em;
                    height: 0.1em;
                }
            }

            &-thumb {
                background: #bec9d3;
                border-radius: 1em;
                border: 0 solid transparent;
                background-clip: border-box;
                min-height: 5em;

                :global(.dark) & {
                    background: #464f57;
                }
            }
        }
    }

}

.preview {
    [class^=cor-net__title] {
        font-size: 1.25em;
        margin-top: 0.75em;
        margin-bottom: 0.75em;
    }
    
    [class^=OnlineTestExecute_question] {
        margin-top: 0.75em;
        margin-bottom: 0.75em;
    }

    [class^=OnlineTestExecute_video] {
        font-size: 0.5em;
    }

    @media print {
        width: 280mm!important;

        html,
        body {
            width: 100%!important;
            margin: 0;
            word-wrap: break-word!important;
        }

      
    
        [class^=cor-net__title],
        [class^=OnlineTestExecute_question] {
            font-size: 18px!important;
            margin-top: 12px!important;
            margin-bottom: 12px!important;
            word-wrap: break-word!important;
        }

        [class^=OnlineTestExecute_row] {
            min-width: 1px!important;
            word-wrap: break-word!important;
        }

        [class^=targetList__status] {
            font-size: 8px;
        }
        
        [class^=cor_te__preview],
        [class^=targetList],
        [class^=pi-table],
        [class^=cor_table] {
            font-size: 12px;
        }


        @page {
            margin: 10mm 10mm 10mm 20mm;
    
            @top-center {
                content: element('');
            }
    
            @bottom-center {
                content: element(pageFooter);
            }
        }
    }
}

