@import '../../scss/variables/variables';

.root {
    position: relative;

    [class*='cl_icon_bluetooth'],
    [class*='cl_icon_usb'] {
        width: auto;
        // min-width: 1.875em;
        gap: 0.2em;

        > span {
            font-family: $main_font_family;
            font-size: 0.7em;
        }
    }

    &:hover {
        .list {
            display: block;
        }
    }
}

.list {
    font-family: $main_font_family;
    display: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    border-radius: 0.125em 0.125em 0 0;
    border: 1px solid #bec9d3;
    box-shadow: 0 0 0.9375em rgb(52 61 70 / 9%);
    padding: 0.9375em 0.6875em;
    left: -1px;
    // width: calc(100% - 2px);
    background-color: #ffffff;
    color: #7c8195;

    :global(.dark) & {
        background: #363a45;
        border: 1px solid #343640;
        left: 0;
        color: $color_withe;
    }
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 1.1em;
    cursor: pointer;
    margin-bottom: 0.6875em;

    &:last-child {
        margin-bottom: 0;
    }

    span {
        font-size: 0.75em;
        line-height: 1;
        white-space: nowrap;
        font-weight: 500;
    }

    i {
        margin-right: 0.4em;
    }

    [class*='cl_icon_turn']-on {
        margin-left: -0.2em;
    }
}

.loader {
    position: absolute;
    // font-size: 0.8em;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: #f5f8fa;
    color: #00b5ff;
    padding-left: 0.4em;
    padding-top: 0.2em;

    > * {
        transform: scale(0.8);
    }

    :global(.dark) & {
        background-color: #16171d;
    }

    :global(.active) & {
        background: #00b5ff;
    }
}
