@import "./mixins/mixins";
@import "./variables/variables";

@include font-face('Inter', '../../assets/fonts/Inter-Regular', 400, normal, ttf);
@include font-face('Inter', '../../assets/fonts/Inter-Medium', 500, normal, ttf);
@include font-face('Inter', '../../assets/fonts/Inter-SemiBold', 600, normal, ttf);
@include font-face('Inter', '../../assets/fonts/Inter-Bold', 700, normal, ttf);

@import "./core/reset";
@import "./core/template";
@import "./core/grid";
@import "./core/form";
@import "./core/table";
@import "./core/tooltip";
@import "./core/net";
@import "./core/work";
@import "./core/storage";
@import "./core/uploading";
@import "./core/timer";
@import "./core/rlab";

.squareRoot {
    position: relative;
    display: inline-block;
    padding-left: 1em;

    &:before,&:after {
        position: absolute;
    }
    &:before {
        content: '√';
        font-size: 1.5em;
        left: 0;
        bottom: -0.05em;
    }
    &:after {
        top: -0.25em;
        left: 0.75em;
        content: '';
        display: block;
        width: 75%;
        background-color: inherit;
        border-top: 0.15em solid;

    }
}

