//dark mode:
//https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/
//color picker:
//image picker: https://imagecolorpicker.com/

@import "../ui/Icon/variables.scss";
@import "../../scss/variables/variables";
// $icon_caret: '\e904';

$RCTextColor: #1E1F25;
.calcModel {  
	display: flex;
	flex-direction: column;
    justify-content:flex-start;
    flex-grow: 1;
    min-height: 0;

    &__Box {
        // background-color: $lw_table_even_color; //light light grey!!
    }

    &__flexColumn {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: center;
        min-width: 0;  //mandatory when use flex-grow: 1;
        min-height: 0; //mandatory when use flex-grow: 1;
        &RightCol {
            align-items: flex-start;
        }
        
        &Coil {
            align-items: normal;
        }

        &CoilRange {
            align-items: normal;
            flex-grow: 1;
            min-height: 0;
            margin-bottom: 1.25em;
        }

        &Graphs {
            justify-items: center; 
            width: calc(50% - 2.5em);

            svg {
                max-width: 100%;
            }
        }
        &ElDynam {
            margin-bottom: 1em;
        }
    }

    &__stretch {
        display: flex;
        justify-content: space-between;
        font-size: 1.125em;
        gap: 0.5em;

		&Left {
			justify-content:left;	
            align-items: center; 
		}
    }

    &__stretch_ScaleSvg {
        margin: 0 0 1em;
        display: flex;
        // justify-content:flex-start;
        // align-items: top; 
        flex-grow: 1;
        min-height: 0;
        min-width: 0;

        .calcModel__coil {
            height: inherit;
            min-width: 0;
            flex-grow: 1;
            width: auto;

            svg {
                max-width: 100%;
                max-height: 100%;
            }
        }
    } 

    &__topTitleBox {
        font-size: 0.7em;
        width: 100%;
        margin-bottom: 1em;

        &Dot {
            font-size: 1.6em;
            position: relative;
            top: -4px;
        }
    }

    &__stretchTop {
        display: flex;
        justify-content: space-between;
        font-size: 1.125em;
		width: 100%;
    }

    &__head {
        font-size: 1.2em;
        font-weight: 800;
        padding: 1.4em  1.4em 0.5em;
    }

    &__experimentNumber {
        font-size: 0.8em;
        margin-bottom: 0.2em;
    }

    &__centerTitle {
        text-align: center;
        font-size: 0.9em;
        font-weight: 800;
		width: 100%;
        padding-bottom: 1em;
    }    

    &__rightTitle {
        text-align: right;
        font-size: 0.9em;
        font-weight: 800;
        padding-right: 12%;
        padding-bottom: 0.5em;
    }    

    &__topEmptyBox {
        width: 15%;
    }

    &__title {
        font-size: 1em;
        font-weight: 800;
        padding: 1em 0.5em 1em 0.5em;
        width: 15em;

        &ExpModeTitle {
            padding-left: 1em;
            width: 100%;
        }
    }

    &__titleBtn {
        font-size: 1em;
        max-height: 2em;
        width: 20em;
        text-align: left;
        padding: 0.5em 0.3em;
        margin: 0.3em 0.5em;
    }
    &__printBtn {
        padding: 0.325em 0.5em;
        border: solid 1px #bec9d3;
        position: relative;
        cursor: pointer;
        top: 0.4em;
        background-color: transparent;

        color: #666F7E;
        transition: 0.3s;

        .dark & {
            color: #c7c9d1;
            border: solid 1px #4a4f5b;
        }
        &:hover {
            background: #67d3ff;
            color: #ffffff;
        }
    }

    &__label {
        font-size: 1em;
        margin-bottom: 0.8125em;
        
        .light & {
            color: #7C8195;
        }
        
        b {
            font-weight: 500;
            color: #1E1F25;

            .dark & {
                color: #fff;
            }
        }
        &Material {
            padding-bottom: 1.2em;
        }
    }

    &__range {
        font-size: 0.85em;

        &Cmf {
            margin-bottom: 1.375em;
        }

        &Col {
            width: calc(50% - 0.1em);
        }
    }


    &__nowrap {
        white-space: nowrap;

        &Txt {
            font-size: 0.75em;
            padding: 0 1em;
        }
        &Pad {
            padding: 0 2em;
        }
        &TimeOpt {
            font-size: 0.8em;
            white-space: nowrap;
            width: 6em;
            color: #7C8195;
            .dark & {
                color: $lw_dark_theme_color;
            }
        }
    }

    &__interfChkBoxes {
        font-size: 0.9em;
    }

    &__centerBox {
        margin: auto;
        width: 90%;
    }

    &__SelectMaterial {
		color: $RCTextColor;
		background-color: $lw_table_listbox_bknd_color;
		border: solid 1px gray;
		font-size: 1em;
		border-radius: 2px;
	}

    &__SelectElDynam {
		font-size: 0.9em;
		border-radius: 2px;
        line-height: 1em;
        margin: 0.1em 0;
        width: 12em;
		color: $cm_light_cb_color;
		background-color: $cm_light_cb_bknd_color;
		border: solid 1px $cm_dark_cb_border_color;
        outline: none;
        &:not(:disabled) {
            cursor: pointer;
        }
        .dark & {
            color: $cm_dark_cb_color;
            background: $cm_dark_cb_bknd_color;
            border: solid 1px $cm_dark_cb_border_color;
        }

        &Exp {
            margin-top: 0.2em;
        }
        &ExpSize {
            width: 12em;
        }
	}

    &__rainbowStripe {
        //background: linear-gradient(90deg, red, orange, yellow, green, lightblue, blue, violet);
        background: linear-gradient(90deg, rgba(255, 0, 0, 0.5), rgba(255, 165, 0, 0.5), rgba(255, 255, 0, 0.5), 
        rgba(0, 128, 0, 0.5), rgba(173, 216, 230, 0.5), rgba(0, 0, 255, 0.5), rgba(238, 130, 238, 0.5));
        margin-bottom: 0.4em;
        text-align: center;
    }

    &__rainbowStripeUp {
        //background: linear-gradient(90deg, red, orange, yellow, green, lightblue, blue, violet);
        background: linear-gradient(90deg, 
        rgba(238, 130, 238, 1),
        rgba(0, 0, 255, 1), 
        rgba(173, 216, 230, 1), 
        rgba(0, 128, 0, 1),
        rgba(255, 255, 0, 1), 
        rgba(255, 165, 0, 1), 
        rgba(255, 0, 0, 1), 
        );
        margin-bottom: 0.4em;
        text-align: center;
    }

    &__rainbowRanges {
        font-size: 0.8em;
        font-weight: 800;
        padding: 0.2em 0.8em;
    }

    &__name {
        width: 100%;

        &Font {
            font-size: 1.0em;
        }

        &Coord {
            margin: 0.5em 0;
        }

        &ElDynamChain {
            font-size: 0.7em;
            padding: 1em 0.5em 1em 0;
            width: 13em;
        }
    }

    &__inputRange {
        width: 100%;

        &Refraction {
            width: 100%;
        }
    }

    &__fullInputRange {
        &Edge {width: 1em; cursor: pointer; position: relative; top: 2px;}
        &Center {width: calc(100% - 2em); cursor: pointer;}
    }

    &__inputRangeVert { ///it should depend on height of svg -set by style!!
        margin-right: 1.0625em;
        display: flex;
        min-height: 0;
        flex: none;
    }

    &__inputRangeBottom {
        margin-left: 2.2em;
        //max-width: 100%; //Evgeniy changes - distort bottom horiz scroll
    }

    &__input {
        width: 4em;
    }
    &__inputLong {
        width: 7em;
        position: relative;
        top: -0.6em;
    }

    &__inputBox {
        margin: 0.2em 0 0.2em 1em;
        position: relative;
        top: -0.4em;
    }

    &__valueBox {
        padding-left: 1em;
        width: 20%
    }

    &__initParams {
        width: 16em;
        display: flex;
        justify-content: space-between;
        font-size: 0.8em;
        align-items: center; 
    }
    &__initParamName {
        width: 17em;
        height: 3em;

    }
    &__initParamValue {
        width: 5em;
        position: relative;
        top: -0.9em;
        font-weight: 800;
    }
    &__initParamUnits {
        width: 5em;
    }
    &__initParamRange {
        font-size: 0.7em;
    }

    &__leftCol {
        width: 27%;
        margin-right: 0.2em;
    }

    &__middleCol {
        width: 24%;
        margin-right: 0.2em;
    } 

    &__rightCol {
        width: 45%;
    }

    &__ColTitle {
        font-size: 0.8em;
    }

    &__leftColBtn {
        width: 16em;
        //height: 2em;
        margin: 0.1em 0;
    }

    &__middleColBtn {
        //width: 16em;
        margin: 1em 0;
    }

    &__bottomComment {
        width: 90%;
        margin: 1em;
        font-size: 0.9em;
    }

    &__coil {
        //border: solid 2px red;
        height: 3.4375em; 
        min-width: 100%; ////
        width: 100%;
        
        &Lense {
            text-align: center;
        }

        // &svg { 
        //     max-width: 100%; //added by Evgeniy
        // }
    }

    &__graph {
        margin-bottom: 0.5em;
        
        &Values {
            font-size: 0.7em;
            margin-bottom: 0.6em;
        }
    }

    &__result {
        font-size: 1em;
        font-weight: 800;
    }

    &__img {
        font-size: 1em;
        font-weight: 800;
        position: relative;
        top: 1em;
    }

    &__space {
        padding-bottom: 1em;
    }

    &__emptyBtn {
        width: 20em;
    }

	&__button {
		font-size: 0.9em;
		border: none;
		padding: 0.2em 1em;
        margin: 0.2em 1em;
        border-radius: 0.3125em;

        &ShortPad {
            padding: 0.2em 0.6em;
        }

		&:hover {
			cursor: pointer;
		}

        &Unsel { 
            background-color: white;
            color: $lw_blue_color;
            border: solid 1px  $lw_blue_color;
            &:hover {
                color: white;
            }
            .dark & {
                background: $cm_dark_btn_unsel_bknd_color; //#14161B;
                color: $cm_dark_btn_unsel_color; //#2E6B88;
                border-color: $cm_dark_btn_unsel_border_color; //#2E6B88;

                &:hover {
                    color: white;
                    border-color: $lw_blue_color;
                }
            }
        }
        &Sel {
            background-color: $lw_blue_color;
            color: white;
        }

        &Icon {
            padding: 0 0.5em;
            height: 1.3em;

            &Lense {
                position: relative;
                top: -0.6em;
            }
        }
        &Link {
            padding: 0 0.5em;
            height: 1.4em;
            color: $lw_blue_color; 
            text-decoration: none;
            position: relative;
            top: -5px;
        }
        &IconBtn {
            //margin-bottom: 0.4em;
            height: 1.8em;
        }

        &Disabled {
            color: lightgrey;
        }
        &BtnSize {
            width: 15em;
        }
    }

    &__clearBtn {
        width: 15em;
    }


    &__images {
		height: 12.8em;
		border: solid 1px grey;
        text-align: center;
	}

    &__rightTabs {
        float: left;
        //width: 40%;
        margin: 1em 0;
        height: 15em;
    }

    &__svgLegend {
        font-size: 0.7em;
    }
    &__svgLegendBold {
        font-size: 0.7em;
        font-weight: 800;
    }
    &__svgLenseLegend {
        font-size: 1.2em;
        background: transparent;
    }
    &__svgScale {
        font-size: 0.5em;
    }
    &__svgBoxText {
        font-size: 0.6em;
    }

    &__bottomButtons {
        padding: 0 0 1.25em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &JustLeft {
            justify-content:flex-start;
        }

        .btn {
            //width: 100%;
            //border: solid 2px red;
            //height: 3em;
        }

        .btnIcon {
            padding: 0 0.5em;
            height: 1.6em;
        }

        .btnLink {
            padding: 0 0.5em;
            height: 1.4em;
            color: $lw_blue_color; 
            text-decoration: none;
            position: relative;
            top: -3px;
        }

        .Unsel {
            background-color: white;
            color: $lw_blue_color;
            border: solid 1px  $lw_blue_color;
            padding: 0.5em 0.5em; // it overrides padding in 'btn'. If remove it, the buttons will be higher!

            &:hover {
                color: white;
            }
            .dark & {
                background-color: $cm_dark_btn_unsel_bknd_color;
                color: $cm_dark_btn_unsel_color;
                border-color: $cm_dark_btn_unsel_border_color;

                &:hover {
                    color: white;
                    border-color: $lw_blue_color;
                }
            }
        }

        .Sel {
            padding: 0.5em 0.5em;  // it overrides padding in 'btn'. If remove it, the buttons will be higher!
            background-color: $lw_blue_color;
            color: white;
        }
    }

    &__bottomArea {
        background-color: #f5f8fa;
        padding: 0.9em 2em;
        margin: 0 -1.5em;
        .dark & {
            background-color: $footer_dark_bg;
        }

    }

    &__elDynamBottomSection {
        background-color: white;
        position: relative;
        left: 1.4em;
        padding: 0.1em 1.4em 0.5em 1.4em;
    }

    &__battery {
        //width: 10px;
        height: 1em;
        padding: 0 2px;
        position: relative;
        top: 1px;
    }

    &__experienceImage {
        height: 10em;
        padding: 1em;
    }

    &__cursorPos {
        margin: 0.5em;
        font-size: 0.8em;

        &Space {
            padding: 0 1em;
        }
    }
    &__leftBottomBtn {
        font-size: 1.0em;
    }
    &__exportBtnComment {
        font-size: 0.75em;
        position: relative;
        top: 0.8em;
        // color: $lw_light_theme_color;
        color: #7C8195;
        .dark & {
            color: $lw_dark_theme_color;
        }
    }
    &__elDynamBtn {
        padding: 0 0.3em;
        margin: 0.1em 0.2em;
        font-size: 0.8em;
    }
    &__elDynamInfo {
        padding: 0.0em 0 0.7em 0;
        font-size: 1em;
    }

    &__btnIcon {
        height: 1em;
    }

    &__circle {
        background: lightblue;
        border-radius: 50%;
        margin-right: 1em;
        font-size: 0.6em;

        &Text {
            font-size: 0.8em;
        }
    }

    &_Row {
		height: 100%;
		width: 100%;
		display: flex;
        color: black;
        padding: 0.1em 0.4em 0.6em 0;
        line-height: 1.3em;
        
        &Col {
            padding-bottom: 0.3em;
        }
        &Col1 {
            width: 45%;
        }
        &Col2 {
            width: 50%;
            font-weight: 800;
        }
    }

    &__Virtual {
        //border: solid 1px green;
    }

    &__lense {   
        margin-bottom: 0.6em;

        &ColumnOpts1 {width: 25%;}        
        &ColumnOpts2 {width: 75%;}
    }

    &__eDynam {
        &ColumnOpts {width: 62%; }
        &ColumnBtns {
            width: 18%; 
            > * {
                margin-bottom: 1em;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &ColumnGraph {width: 18%; }

        &InputRange {width: 100%;}

        &OscParams {width: 30%;}
        &OscGraphs {width: 70%;}

        &GraphBox {
            width: 100%; 
            border: solid 1px $cm_dark_cb_border_color;
            .dark & {
                border: solid 1px $cm_dark_cb_border_color;
            }
        }

        &RangeMargin {
            width: 49%;
            margin-bottom: 0.5em;
        }

        &ChainBox {
            border: solid 1px grey;
            font-size: 0.8em;
            height: 100%;
            line-height: 1.1em;
            padding: 1.2em 0.5em;
            text-align: center;
        }
    }
    
    /// 
    &__mechMove {
        &ColumnOpts {width: 50%; }
        &ColumnBtns {
            width: 30%; 
            > * {
                margin-bottom: 1em;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &ColumnGraph {width: 18%; }

        &GraphBox {
            width: 100%; 
            border: solid 1px $cm_dark_cb_border_color;
            .dark & {
                border: solid 1px $cm_dark_cb_border_color;
            }
        }
    }

    &__Select {
		font-size: 1.2em;
		border-radius: 2px;
		color: $cm_light_cb_color;
		background-color: $cm_light_cb_bknd_color;
		border: solid 1px $cm_light_cb_border_color;
		cursor: pointer;

		&Values {width: 5em;}
		&Values8 {width: 8em;}
		&Values11 {width: 11em;}
		&Values18 {width: 18em;}

		.dark & {
			color: $cm_dark_cb_color;
			background-color: $cm_dark_cb_bknd_color;
			border: solid 1px $cm_dark_cb_color;
		}
	}
    
    &__NoDisplay {
        display: none;
    }
    
    &__Hidden {
        //display: none;
        visibility: hidden;
    }
    
    &__DocIcon {
        padding-right: 3px;
    }

    &__spanLink {
        &:hover {
            text-decoration: underline $lw_blue_color; 
            cursor: pointer;
        }
    }

    &__bodyBox {
        padding-bottom: 0.5em;
    }

    &__xlsLabel {
        font-size: 0.9em;
		margin: 0.6em 0 0.3em 0;
    }
    &__xlsInputBox {
        font-size: 1.1em;    
    }
    &__xlsInput {
		width: 99%;
		margin-bottom: 0.5em;
    }
    &__xlsTextArea {
		font-size: 0.9em;
		min-height: 8em;
		width: 99%;
    }

    &__docs {
        &__drop {
            width: 7em;
            height: 1.875em;
            position: relative;

            &:hover {
                .calcModel__docs__list {
                    display: block;
                }

                .calcModel__docs__toggle {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &__item {
            border-radius: 0;
            height: 1.875em;
            width: 100%;
            background: transparent; //color of floating items
            border: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            padding: 0.4375em 0;
            font-size: 1em;
            font-weight: 500;
            cursor: pointer;

            .dark & {
                color: $color_withe;
            }

            > span:not(.cor_icon) {
                font-size: 0.75em;
                font-weight: 400;
            }
        }

        &__toggle {
            user-select: none;
            border: 1px solid #bec9d3;
            cursor: pointer;
            padding: 0.5em;
            color: #666f7e;
            min-width: 6.25em;

            &:after { //arrow up/down
                font-family: 'cor';
                content: $cor_icon_caret;
                color: inherit;
                font-size: 0.5em;
                margin-left: 1em;
            }

            .dark & {
                border: 1px solid #343640;
                color: #c7c9d1;
            }
        }

        &__list {   //floating items
            display: none;
            position: absolute;
            z-index: 10;
            padding: 0.125em 0.5em;
            left: 0;
            bottom: calc(100% - 0.125em);
            width: 100%;
            border: 1px solid #bec9d3;
            box-shadow: 0 0.5625em 0.9375em rgba(52, 61, 70, 0.09);
            border-radius: 0.125em 0.125em 0 0;
            background: $light_theme_background;

            > * {
                &:nth-child(n + 2) {
                    border-top: 1px solid #bec9d3;
                }
            }

            .calcModel__docs__item {
                justify-content: flex-start;
                color: #595C6C;
                transition: color 0.3s;

                span:not(.cor_icon){
                    margin-left: 0.75em;
                    margin-bottom: -0.25em;
                }

                &:hover {
                    color: #8f93a6;
                }

                .dark & {
                    color: #c7c9d1;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .cor_icon {
                margin-left: -0.125em;
            }

            .dark & {
                border: 1px solid #4a4f5b;
                background: #363a45;
            }
        }
    }

    &__opts {
        margin-top: 1em;
    }

    &__indent {
        height: 1.5em;
    }

    &__footer {
        background: #F5F8FA;
        border-top: 0;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);

        .dark & {
            background: #16171D;
        }
    }
}

.calcModel__modal {
    &Labels {
        display: flex;
        gap: 1.25em;
        margin-bottom: 1.875em;

        &__item {
            display: flex;
            align-items: flex-start;
        }

        &_circle {
            flex: none;
            width: 0.7em;
            height: 0.7em;
            border-radius: 50%;
            margin-right: 0.4em;

            &BRV {background: #9647FB;}
            &BRH {background: #F75656;}
            &BZV {background: #FFAD32;}
            &BZH {background: #00DA98;}

            &Curve1 {background: #F75656;}
            &Curve2 {background: #00DA98;}
            &Curve3 {background: #9647FB;}
        }
    }
    .modal__action {
        // align-items: flex-start;
        .btn {
            width: 27em;
        }
    }
}

.calcModel__graph {
    &Title {
        text-align: left;
        align-self: flex-start;
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 0.5em;
    }
}