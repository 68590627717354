.root {
    > * {
        display: block;
        width: 100%!important;
        height: 100%!important;
        object-fit: cover;
    }
    
    &__wrap {
        flex-grow: 1;
        padding: 1.25em;

        canvas {
            display: block;
            width: 100%!important;
            height: 100%!important;
            object-fit: cover;
        }
    }

    [class*="EChartTooltip"] {
        width: auto!important;
        height: auto!important;
    }
}