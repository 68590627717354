@import '../../scss/variables/variables';
@import '../../scss/mixins/mixin';

.head {
    margin-bottom: 1.25em;
}

.settings {
    display: flex;
    align-items: flex-end;
    gap: 1.25em;
}

.item {
    .number {
        width: 5em;
    }
}

.body {
    max-height: 17.6875em;
    overflow-y: overlay;
    @extend %scrollbar;

    table {
        border: 1px solid #000;
        border-collapse: collapse;
        width: 100%;

        td {
            border: 1px solid #000;
            padding: 0.3em;
        }
    }
}

.img {
    img {
        width: 100%;
    }
}

@media print {
    html,
    body {
        width: 100% !important;
        margin: 0;
    }
    .width {
        width: 700px;
    }
    .body {
        border: 0 !important;
        padding: 0 !important;
        background: transparent !important;
        width: 100% !important;
        height: auto !important;
        max-height: none !important;
        overflow: inherit !important;

        table {
            width: 100% !important;
            color: #000 !important;
        }
    }
    .img {
        width: 100%;
        margin: 0 0 16px;
    }

    @page {
        margin: 1cm;

        @top-center {
            content: element('');
        }

        @bottom-center {
            content: element(pageFooter);
        }
    }
}
