@import "../../ui/Icon/Icon";
@import "../../../scss/variables/variables";

.cor_footer {
    background: $footer_bg;
    color: $footer_color;
    box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    padding: 1.25em 1.6em;
    margin-bottom: 1.25em;
    // min-height: 4.375em;
    
    .dark & {
        background: $footer_dark_bg;
        box-shadow: none;
    }
}

.cor_footerNav {

    &__content {
        position: relative;
        padding: 0 4em;
        
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.7em;
        flex-grow: 1;
    }

    &__item {
        display: flex;
        align-items: center;
        // flex-grow: 1;
        gap: 0.75em;

        &:nth-child(n+2) {
            padding-left: 1.5em;
            border-left: 1px solid $footer_column_border_color;
        }
    }

    &__label {
        font-size: 0.75em;
        font-weight: 500;
        line-height: 1.25;
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 0.625em;
    }

    &__btn {
        border: 0;
        outline: 0;
        font-size: 1em;
        line-height: 1;
        padding: 0 0.25em;
        height: 1.875em;
        min-width: 1.875em;;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $footer_button_bg;
        border-radius: 0.125em;
        transition: outline 0.2s;
        cursor: pointer;

        &:hover {
            background: $footer_button_hover_bg;
        }

        &.active {
            background: $footer_button_active_bg;
            color: $footer_color;
        }

        &_tooltip {
            position: relative;

            &:hover {
                .cor_footerNav__tooltip {
                    display: block;
                    animation: fadeUp 0.3s;
                }
            }
        }

        &_drop {
            width: 3em;
            position: relative;

            &:after {
                font-family: $icomoon-font-family;
                content: $cor_icon_caret;
                transform: rotate(0deg);
                font-size: 0.5em;
                margin-left: 1em;

                &:hover {
                    transform: rotate(180deg);
                }
            }

            &:hover {
                &:after {
                    transform: rotate(180deg);
                }

                .cor_footerNav__drop {
                    display: block;
                    animation: fadeUp 0.3s;
                }
            }
        }
    }

    &__adoc {
        color: #FFFFFF;
    }

    &__tooltip {
        display: none;
        position: absolute;
        top: auto;
        left: 50%;
        bottom: 110%;

        padding: 0.6em 1em;
        width: max-content;
        border: 1px solid $footer_dropdown_border_color;
        border-radius: 0.8em;
        color: #4A4A4D;
        background-color: $footer_dropdown_bg;
        font-size: 0.625em;
        font-weight: 500;
        box-shadow: 0px 0.5625em 0.9375em 0px rgba(52, 61, 70, 0.09);

        .dark & {
            background: $footer_dark_button_bg;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            border: 1px solid $footer_dark_dropdown_border_color;
            color: $footer_dark_color;
        }
    }

    &__drop {
        display: none;
        position: absolute;
        z-index: 10;
        bottom: 100%;
        left: 0;
        background: $footer_dropdown_bg;
        border: 1px solid $footer_dropdown_border_color;
        box-shadow: 0 0.5625em 0.9375em rgba(52, 61, 70, 0.09);
        border-radius: 0 0 0.125em 0.125em;

        .dark & {
            background: $footer_dark_button_bg;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            border: 1px solid $footer_dark_dropdown_border_color;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                padding: 0.6em 0.9em;
                white-space: nowrap;
                position: relative;
                font-size: 0.75em;
                color: $footer_dropdown_item_color;

                .dark & {
                    color: $footer_dark_color;
                }

                &:hover {
                    color: $footer_dropdown_item_hover_color;

                    > .cor_footerNav__sub {
                        display: block;
                    }
                }

                &:nth-child(n+2) {
                    border-top: 1px solid $footer_dropdown_item_border_color;
                    
                    .dark & {
                        border-top: 1px solid #343640;
                    }
                }
            }
        }

    }

    &__sub {
        display: none;
        position: absolute;
        z-index: 10;
        left: 100%;
        background: #FFFFFF;
        border: 1px solid #BEC9D3;
        box-shadow: 0 0.5625em 0.9375em rgba(52, 61, 70, 0.09);
        border-radius: 0 0 0.125em 0.125em;
        bottom: 0;
        padding: 0 0.5625em;

        .dark & {
            background: #282B33;
            box-shadow: 0px 0.25em 1.875em rgba(0, 0, 0, 0.1);
            border: 1px solid #343640;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                padding: 0.55em 0.7em;
                white-space: nowrap;
                font-size: 1em;
                color: #595C6C;

                &:hover {
                    color: #8F93A6;
                }

                &:nth-child(n+2) {
                    border-top: 1px solid #BEC9D3;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }

                input[type=file] {
                    display: none;
                }


                .checkbox {
                    cursor: pointer;
                    input[type=checkbox],
                    input[type=radio] {
                        .dark & {
                            border: 1px solid #6F92B9;
                            &:hover {
                                border: 1px solid #6F92B9;
                            }
                        }

                        &:checked {
                            &:before {
                                background: #00B5FF;
                                .dark & {
                                    background: #00B5FF;
                                }
                            }

                            &:hover {
                                &:before {
                                    background: #67D3FF;
                                }
                                .dark & {
                                    background: #67D3FF;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__back {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;

        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        color: #ffffff;
        cursor: pointer;

        display: inline-flex;
        align-items: center;
        gap: 0.5em;

        font-family: $main_font_family;
        font-size: 1em;
        font-weight: 500;
        
        .cor_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            // height: 1.875em;
            line-height: 1;
        }

        &:disabled {
            opacity: 0.4;
            cursor: default;
        }
    }

}

@keyframes fadeUp {
    0% {
        transform: translateY(0.5em);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
