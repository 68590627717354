@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?mniorj') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?mniorj') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?mniorj##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="cl_icon_"], [class*=" cl_icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cl_icon_folder {
  &:before {
    content: $cl_icon_folder; 
  }
}
.cl_icon_demo {
  &:before {
    content: $cl_icon_demo; 
  }
}
.cl_icon_not-auto-zoom {
  &:before {
    content: $cl_icon_not-auto-zoom; 
  }
}
.cl_icon_auto-zoom {
  &:before {
    content: $cl_icon_auto-zoom; 
  }
}
.cl_icon_info {
  &:before {
    content: $cl_icon_info; 
  }
}
.cl_icon_arrow-round-prev {
  &:before {
    content: $cl_icon_arrow-round-prev; 
  }
}
.cl_icon_arrow-round-next {
  &:before {
    content: $cl_icon_arrow-round-next; 
  }
}
.cl_icon_flash {
  &:before {
    content: $cl_icon_flash; 
  }
}
.cl_icon_mouse-signal {
  &:before {
    content: $cl_icon_mouse-signal; 
  }
}
.cl_icon_lightning {
  &:before {
    content: $cl_icon_lightning; 
  }
}
.cl_icon_usb_connect {
  &:before {
    content: $cl_icon_usb_connect; 
  }
}
.cl_icon_bulb {
  &:before {
    content: $cl_icon_bulb; 
  }
}
.cl_icon_blob {
  &:before {
    content: $cl_icon_blob; 
  }
}
.cl_icon_wireless {
  &:before {
    content: $cl_icon_wireless; 
  }
}
.cl_icon_screwdriver {
  &:before {
    content: $cl_icon_screwdriver; 
  }
}
.cl_icon_diskette {
  &:before {
    content: $cl_icon_diskette; 
  }
}
.cl_icon_ecology {
  &:before {
    content: $cl_icon_ecology; 
  }
}
.cl_icon_mathematics {
  &:before {
    content: $cl_icon_mathematics; 
  }
}
.cl_icon_chemistry {
  &:before {
    content: $cl_icon_chemistry; 
  }
}
.cl_icon_biology {
  &:before {
    content: $cl_icon_biology; 
  }
}
.cl_icon_phisic {
  &:before {
    content: $cl_icon_phisic; 
  }
}
.cl_icon_Icon-place {
  &:before {
    content: $cl_icon_Icon-place; 
  }
}
.cl_icon_script {
  &:before {
    content: $cl_icon_script; 
  }
}
.cl_icon_eye {
  &:before {
    content: $cl_icon_eye; 
  }
}
.cl_icon_save {
  &:before {
    content: $cl_icon_save; 
  }
}
.cl_icon_bar .path1 {
  &:before {
    content: $cl_icon_bar-path1;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_bar .path2 {
  &:before {
    content: $cl_icon_bar-path2;  
    margin-left: -1em;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_analog-color .path1 {
  &:before {
    content: $cl_icon_analog-color-path1;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_analog-color .path2 {
  &:before {
    content: $cl_icon_analog-color-path2;  
    margin-left: -1em;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_file-image {
  &:before {
    content: $cl_icon_file-image; 
  }
}
.cl_icon_file-svg {
  &:before {
    content: $cl_icon_file-svg; 
  }
}
.cl_icon_file-pdf {
  &:before {
    content: $cl_icon_file-pdf; 
  }
}
.cl_icon_json {
  &:before {
    content: $cl_icon_json; 
  }
}
.cl_icon_log {
  &:before {
    content: $cl_icon_log; 
  }
}
.cl_icon_xls {
  &:before {
    content: $cl_icon_xls; 
  }
}
.cl_icon_csv {
  &:before {
    content: $cl_icon_csv; 
  }
}
.cl_icon_txt {
  &:before {
    content: $cl_icon_txt; 
  }
}
.cl_icon_ab-testing {
  &:before {
    content: $cl_icon_ab-testing; 
  }
}
.cl_icon_lighting {
  &:before {
    content: $cl_icon_lighting; 
  }
}
.cl_icon_temperature1 {
  &:before {
    content: $cl_icon_temperature1; 
  }
}
.cl_icon_horisontal {
  &:before {
    content: $cl_icon_horisontal; 
  }
}
.cl_icon_a-pressure {
  &:before {
    content: $cl_icon_a-pressure; 
  }
}
.cl_icon_scrap {
  &:before {
    content: $cl_icon_scrap; 
  }
}
.cl_icon_analog {
  &:before {
    content: $cl_icon_analog; 
  }
}
.cl_icon_cleared .path1 {
  &:before {
    content: $cl_icon_cleared-path1;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_cleared .path2 {
  &:before {
    content: $cl_icon_cleared-path2;  
    margin-left: -1em;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_inclined .path1 {
  &:before {
    content: $cl_icon_inclined-path1;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_inclined .path2 {
  &:before {
    content: $cl_icon_inclined-path2;  
    margin-left: -1em;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_inclined .path3 {
  &:before {
    content: $cl_icon_inclined-path3;  
    margin-left: -1em;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_linear .path1 {
  &:before {
    content: $cl_icon_linear-path1;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_linear .path2 {
  &:before {
    content: $cl_icon_linear-path2;  
    margin-left: -1em;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_quadratic .path1 {
  &:before {
    content: $cl_icon_quadratic-path1;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_quadratic .path2 {
  &:before {
    content: $cl_icon_quadratic-path2;  
    margin-left: -1em;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_quadratic .path3 {
  &:before {
    content: $cl_icon_quadratic-path3;  
    margin-left: -1em;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_furie .path1 {
  &:before {
    content: $cl_icon_furie-path1;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_furie .path2 {
  &:before {
    content: $cl_icon_furie-path2;  
    margin-left: -1em;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_furie .path3 {
  &:before {
    content: $cl_icon_furie-path3;  
    margin-left: -1em;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_derivative .path1 {
  &:before {
    content: $cl_icon_derivative-path1;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_derivative .path2 {
  &:before {
    content: $cl_icon_derivative-path2;  
    margin-left: -1em;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_derivative .path3 {
  &:before {
    content: $cl_icon_derivative-path3;  
    margin-left: -1em;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_derivative .path4 {
  &:before {
    content: $cl_icon_derivative-path4;  
    margin-left: -1em;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_derivative .path5 {
  &:before {
    content: $cl_icon_derivative-path5;  
    margin-left: -1em;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_integral .path1 {
  &:before {
    content: $cl_icon_integral-path1;  
    color: rgb(0, 181, 255);
  }
}
.cl_icon_integral .path2 {
  &:before {
    content: $cl_icon_integral-path2;  
    margin-left: -1em;  
    color: rgb(199, 201, 209);
  }
}
.cl_icon_integral .path3 {
  &:before {
    content: $cl_icon_integral-path3;  
    margin-left: -1em;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_integral .path4 {
  &:before {
    content: $cl_icon_integral-path4;  
    margin-left: -1em;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_integral .path5 {
  &:before {
    content: $cl_icon_integral-path5;  
    margin-left: -1em;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_integral .path6 {
  &:before {
    content: $cl_icon_integral-path6;  
    margin-left: -1em;  
    color: rgb(247, 86, 86);
  }
}
.cl_icon_sort {
  &:before {
    content: $cl_icon_sort; 
  }
}
.cl_icon_chain {
  &:before {
    content: $cl_icon_chain; 
  }
}
.cl_icon_enter {
  &:before {
    content: $cl_icon_enter; 
  }
}
.cl_icon_comment {
  &:before {
    content: $cl_icon_comment; 
  }
}
.cl_icon_reload {
  &:before {
    content: $cl_icon_reload; 
  }
}
.cl_icon_mac {
  &:before {
    content: $cl_icon_mac; 
  }
}
.cl_icon_microscope {
  &:before {
    content: $cl_icon_microscope; 
  }
}
.cl_icon_broken {
  &:before {
    content: $cl_icon_broken; 
  }
}
.cl_icon_turn-on {
  &:before {
    content: $cl_icon_turn-on; 
  }
}
.cl_icon_sort-desc {
  &:before {
    content: $cl_icon_sort-desc; 
  }
}
.cl_icon_sort-asc {
  &:before {
    content: $cl_icon_sort-asc; 
  }
}
.cl_icon_caret {
  &:before {
    content: $cl_icon_caret; 
  }
}
.cl_icon_check {
  &:before {
    content: $cl_icon_check; 
  }
}
.cl_icon_grid {
  &:before {
    content: $cl_icon_grid; 
  }
}
.cl_icon_smartphone {
  &:before {
    content: $cl_icon_smartphone; 
  }
}
.cl_icon_file {
  &:before {
    content: $cl_icon_file; 
  }
}
.cl_icon_camera {
  &:before {
    content: $cl_icon_camera; 
  }
}
.cl_icon_toggler {
  &:before {
    content: $cl_icon_toggler; 
  }
}
.cl_icon_full-screen-off {
  &:before {
    content: $cl_icon_full-screen-off; 
  }
}
.cl_icon_full-screen {
  &:before {
    content: $cl_icon_full-screen; 
  }
}
.cl_icon_gear {
  &:before {
    content: $cl_icon_gear; 
  }
}
.cl_icon_minus {
  &:before {
    content: $cl_icon_minus; 
  }
}
.cl_icon_plus {
  &:before {
    content: $cl_icon_plus; 
  }
}
.cl_icon_pause {
  &:before {
    content: $cl_icon_pause; 
  }
}
.cl_icon_play {
  &:before {
    content: $cl_icon_play; 
  }
}
.cl_icon_stop {
  &:before {
    content: $cl_icon_stop; 
  }
}
.cl_icon_marker-plus {
  &:before {
    content: $cl_icon_marker-plus; 
  }
}
.cl_icon_marker-minus {
  &:before {
    content: $cl_icon_marker-minus; 
  }
}
.cl_icon_bluetooth {
  &:before {
    content: $cl_icon_bluetooth; 
  }
}
.cl_icon_usb {
  &:before {
    content: $cl_icon_usb; 
  }
}
.cl_icon_remote-control {
  &:before {
    content: $cl_icon_remote-control; 
  }
}
.cl_icon_speedometer {
  &:before {
    content: $cl_icon_speedometer; 
  }
}
.cl_icon_pulse {
  &:before {
    content: $cl_icon_pulse; 
  }
}
.cl_icon_dotts {
  &:before {
    content: $cl_icon_dotts; 
  }
}
.cl_icon_update {
  &:before {
    content: $cl_icon_update; 
  }
}
.cl_icon_timer {
  &:before {
    content: $cl_icon_timer; 
  }
}
.cl_icon_range-plus {
  &:before {
    content: $cl_icon_range-plus; 
  }
}
.cl_icon_range-minus {
  &:before {
    content: $cl_icon_range-minus; 
  }
}
.cl_icon_cursor-mode {
  &:before {
    content: $cl_icon_cursor-mode; 
  }
}
.cl_icon_trash {
  &:before {
    content: $cl_icon_trash; 
  }
}
.cl_icon_export {
  &:before {
    content: $cl_icon_export; 
  }
}
.cl_icon_import {
  &:before {
    content: $cl_icon_import; 
  }
}
.cl_icon_printer {
  &:before {
    content: $cl_icon_printer; 
  }
}
.cl_icon_record {
  &:before {
    content: $cl_icon_record; 
  }
}
.cl_icon_list {
  &:before {
    content: $cl_icon_list; 
  }
}
.cl_icon_sensors {
  &:before {
    content: $cl_icon_sensors; 
  }
}
.cl_icon_bullet-list {
  &:before {
    content: $cl_icon_bullet-list; 
  }
}
.cl_icon_sun {
  &:before {
    content: $cl_icon_sun; 
  }
}
.cl_icon_moon {
  &:before {
    content: $cl_icon_moon; 
  }
}
.cl_icon_question {
  &:before {
    content: $cl_icon_question; 
  }
}
.cl_icon_close {
  &:before {
    content: $cl_icon_close; 
  }
}
.cl_icon_capture {
  &:before {
    content: $cl_icon_capture; 
  }
}
.cl_icon_drag {
  &:before {
    content: $cl_icon_drag; 
  }
}
.cl_icon_table-mob {
  &:before {
    content: $cl_icon_table-mob; 
  }
}
.cl_icon_chart-mob {
  &:before {
    content: $cl_icon_chart-mob; 
  }
}
.cl_icon_change {
  &:before {
    content: $cl_icon_change; 
  }
}
.cl_icon_zoom {
  &:before {
    content: $cl_icon_zoom; 
  }
}
.cl_icon_arrow-sort {
  &:before {
    content: $cl_icon_arrow-sort; 
  }
}
.cl_icon_battery-full {
  &:before {
    content: $cl_icon_battery-full; 
  }
}
.cl_icon_battery-middle {
  &:before {
    content: $cl_icon_battery-middle; 
  }
}
.cl_icon_battery-min {
  &:before {
    content: $cl_icon_battery-min; 
  }
}
.cl_icon_battery-charge {
  &:before {
    content: $cl_icon_battery-charge; 
  }
}
.cl_icon_speed-dashboard {
  &:before {
    content: $cl_icon_speed-dashboard; 
  }
}
.cl_icon_temperature {
  &:before {
    content: $cl_icon_temperature; 
  }
}
.cl_icon_settings {
  &:before {
    content: $cl_icon_settings; 
  }
}
.cl_icon_documents-file {
  &:before {
    content: $cl_icon_documents-file; 
  }
}
.cl_icon_firmware {
  &:before {
    content: $cl_icon_firmware; 
  }
}
.cl_icon_update-app {
  &:before {
    content: $cl_icon_update-app; 
  }
}
.cl_icon_edit {
  &:before {
    content: $cl_icon_edit; 
  }
}
.cl_icon_lissague {
  &:before {
    content: $cl_icon_lissague; 
  }
}
.cl_icon_point {
  &:before {
    content: $cl_icon_point; 
  }
}
.cl_icon_bars {
  &:before {
    content: $cl_icon_bars; 
  }
}
.cl_icon_grids {
  &:before {
    content: $cl_icon_grids; 
  }
}
.cl_icon_picture {
  &:before {
    content: $cl_icon_picture; 
  }
}
.cl_icon_protractor {
  &:before {
    content: $cl_icon_protractor; 
  }
}
.cl_icon_ruler {
  &:before {
    content: $cl_icon_ruler; 
  }
}
.cl_icon_scale {
  &:before {
    content: $cl_icon_scale; 
  }
}
.cl_icon_film {
  &:before {
    content: $cl_icon_film; 
  }
}
.cl_icon_download {
  &:before {
    content: $cl_icon_download; 
  }
}
.cl_icon_sound {
  &:before {
    content: $cl_icon_sound; 
  }
}
.cl_icon_download-arrow-circle {
  &:before {
    content: $cl_icon_download-arrow-circle; 
  }
}
.cl_icon_selection-object {
  &:before {
    content: $cl_icon_selection-object; 
  }
}
.cl_icon_square {
  &:before {
    content: $cl_icon_square; 
  }
}

