@import '../../../scss/variables/variables';
@import '../..//Icon/variables';

.root {
    box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
    padding: 0.7em 1.3em;
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    color: $light_footer_color;
    position: relative;
    z-index: 5;
    background: $color_withe;

    :global(.dark) & {
        color: $dark_theme_color;
        background: $dark_footer_bg;
    }
    // justify-content: space-between;

    @media (min-width: 768px) {
        // border-top: 1px solid;
        // margin: 0 1.4em;
        padding: 0.8125em 1.25em;
        // flex-wrap: nowrap;
        // font-size: 1em;
    }

    @media (max-width: 767px) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 5;

        &.rootShow {
            // border-top: 1px solid;
            background: $dark_footer_bg;
        }
    }
}

.group {
    display: flex;
    align-items: center;

    + .group {
        @media (min-width: 768px) {
            margin-left: 0.9375em;
            padding-left: 0.9375em;
            border-left: 1px solid #e8e8e8;

            :global(.dark) & {
                border-left-color: #343640;
            }
        }

        @media (max-width: 767px) and (orientation: 'landscape') {
            margin-left: 0.9375em;
            padding-left: 0.9375em;
            border-left: 1px solid #e8e8e8;

            :global(.dark) & {
                border-left-color: #343640;
            }
        }
    }

    &_center {
        flex-grow: 1;

        @media (min-width: 768px) {
            justify-content: center;
        }

        @media (max-width: 767px) {
            width: 100%;

            .btns {
                flex-grow: 1;
            }

            .timer {
                // margin-right: 2.1em;
            }
        }
    }

    &[class*='landscape'] {
        @media (max-width: 767px) and (orientation: 'portrait') {
            display: none;
        }
    }

    &[class*='portrait'] {
        @media (max-width: 767px) and (orientation: 'landscape') {
            display: none;
        }
    }
}

.label {
    display: none;

    @media (min-width: 768px) {
        display: block;
        font-size: 0.75em;
        margin-right: 1.3em;
        line-height: 1.1;
        white-space: nowrap;
    }
}

.btns {
    display: flex;
    align-items: center;
    gap: 0.1em;

    @media (min-width: 768px) {
        gap: 0.625em;
    }

    button,
    label {
        font-family: $main_font_family;
        font-size: 1em;
        width: 1.875em;
        height: 1.875em;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f8fa;
        border: 1px solid #bec9d3;
        border-radius: 0.125em;
        color: #666f7e;
        cursor: pointer;
        transition: 0.3s;
        transition-property: color, background;

        :global(.dark) & {
            background: $dark_footer_bg;
            border-color: #343640;
            color: #c7c9d1;
        }

        &:hover:not(:disabled) {
            background: #67d3ff;
            color: $color_withe;
        }

        &:before,
        i {
            font-size: 1.125em;
        }

        &[class*='active'] {
            background: #00b5ff;
            border-color: #00b5ff;
            color: $color_withe;
        }

        &:disabled {
            cursor: default;
        }

        &.cl_icon_dotts {
            margin-right: 0.5em;
        }

        &.cl_icon_timer {
            margin-left: 0.5em;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    &_group {
        gap: 0.375em;
    }
}

.icons {
    display: flex;
    align-items: center;
    margin-right: 1.5em;
    // margin-bottom: 1em;

    @media (min-width: 768px) {
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        display: none;

        .show & {
            display: flex;
        }
    }

    button,
    i {
        &:not(.btn) {
            padding: 0;
            height: auto;
            color: inherit;
            background-color: transparent;
            border: 0;
            flex: none;
            font-size: 1.3em;
            width: 1em;
            margin-right: 1.1em;
            cursor: pointer;

            @media (min-width: 768px) {
                font-size: 1.3em;
            }

            &[class*='active'] {
                color: #3fa9f5;
            }

            &[class*='record'] {
                animation: recordBlink 1.5s infinite;
            }
        }

        &:disabled {
            cursor: default;
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 1em;
        border-top: 1px solid;
        background: $light_theme_background;

        :global(.dark) & {
            background: $dark_theme_background;
        }
    }

    [class*='cl_icon_connection'] {
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.toggle {
    margin-bottom: 0;

    .rootShow & {
        margin-bottom: 1em;
    }

    .cl_icon_arrow-duble {
        transform: rotate(-90deg);
        font-size: 0.8em;

        .rootShow & {
            transform: rotate(90deg);
        }
    }

    @media (min-width: 768px) {
        display: none;
    }
}

@keyframes recordsBtn {
    0% {
        background-color: #ffe8e8;
        border-color: #ffe8e8;
    }

    50% {
        background-color: #f5f8fa;
        border-color: #bec9d3;
    }

    100% {
        background-color: #ffe8e8;
        border-color: #ffe8e8;
    }
}
@keyframes recordsBtnDark {
    0% {
        color: #16171d;
        background-color: #ffe8e8;
        border-color: #ffe8e8;
    }

    50% {
        color: $color_withe;
        background-color: #363a45;
        border-color: #4a4f5b;
    }

    100% {
        color: #16171d;
        background-color: #ffe8e8;
        border-color: #ffe8e8;
    }
}

@keyframes recordBlink {
    0% {
        color: inherit;
    }

    30% {
        color: red;
    }

    60% {
        color: inherit;
    }

    100% {
        color: inherit;
    }
}
