.add_button {
  display: flex;
  align-items: center;
  gap: 0.3125em;
  padding: 0.625em 1.0625em 0.625em 0;
  background: none;
  color: #00b5ff;
  cursor: pointer;
  border: none;
  font-size: 0.875em;
  font-weight: 500;

  &__svg {
    fill: #00b5ff;
  }

  &__icon {
    position: relative;
    &::before,
    &::after {
      position: absolute;
      top: 0;
      content: '';
      width: 0.5625em;
      height: 0.0625em;
      background-color: #3d3d3d;
    }
    &::after {
      transform: rotate(90deg);
    }
  }

  &__form {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  &__control {
    display: flex;
    align-items: center;
    gap: 0.3125em;
    padding: 0.625em 1.0625em;
    background: none;
    color: #fff;
    cursor: pointer;
    border: none;
  }

  &__submit {
    padding: 0.5em 0.875em;
    background-color: #00b5ff;
    border-radius: 0.375em;
    // font-size: 0.75em;
  }

  &__cancel {
    position: relative;
    padding: 0.9375em;

    &::before,
    &::after {
      position: absolute;
      top: 0.2em;
      content: '';
      width: 0.125em;
      height: 1.25em;
      background-color: #00b5ff;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}