.checkInternet {
    background: #ff605b;
    border-radius: 0.5625em;
    font-size: 1em;
    line-height: 1.2;
    font-weight: 600;
    color: #ffffff;
    padding: 0.625em 1.5625em;

    .dark & {
        background: #ff605b;
        color: #1e1f25;
    }
}
