@import "../../../scss/variables/variables";

.tabs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // overflow: auto;
    align-content: flex-start;

    &__item {
        position: relative;
        background: #F5F8FA;
        border: 1px solid #BEC9D3;
        border-radius: 3px;
        padding: 0.43em 0.9em;
        min-height: 2.334em;
        font-size: 0.75em;
        color: #7C8195;
        display: flex;
        align-items: center;
        gap: 0.5em;
        user-select: none;
        cursor: pointer;

        .dark & {
            color: $dark_theme_color;
            background: #363A45;
            border: 1px solid #484B5A;
        }

        .no_border & {
            position: relative;

            background: transparent;
            border: 0;
            border-radius: 0;

            padding: 0;
            min-height: auto;
            font-weight: 500;
            font-size: 1em;
            margin-bottom: 0;

            margin-right: 1em;
            padding-bottom: 0.5em;

            &:last-child {
                margin-right: 0;
            }

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              border-bottom: 0.125em solid;
              border-color: inherit;
              opacity: 0;
            }
            


        }

        & .cor_icon {
            line-height: 1;
            font-size: 1.1em;
            cursor: pointer;
        }

        &:first-child:not(:last-child) {
            border-radius: 3px 0 0 3px;
        }

        &:nth-child(n+2) {
            border-radius: 0;
        }

        &:last-child:not(:first-child) {
            border-radius: 0 3px 3px 0;
        }

        &.active {
            background: #00B5FF;
            border: 1px solid #00B5FF;
            color: $color_withe;
            cursor: default;

            & .cor_icon {
                color: $color_withe;
            }

            .no_border & {
                background: transparent;
                border: 0;
                color: #00B5FF;

                &:after {
                    opacity: 1;
                  
                }

            }
        }
    }

    &__notify {
        position: absolute;
        top: -0.65em;
        left: calc(100% - 0.5em);
        z-index: 1;
        
        &.lg {
            left: calc(100% - 0.65em);
        }
        
        &.xl {
            left: calc(100% - 0.95em);
        }
    }
}
