$icomoon-font-family: "robiclab" !default;
$icomoon-font-path: "fonts" !default;

$cl_icon_folder: unquote('"\\e996"');
$cl_icon_demo: unquote('"\\e900"');
$cl_icon_not-auto-zoom: unquote('"\\e901"');
$cl_icon_auto-zoom: unquote('"\\e902"');
$cl_icon_info: unquote('"\\e992"');
$cl_icon_arrow-round-prev: unquote('"\\e981"');
$cl_icon_arrow-round-next: unquote('"\\e982"');
$cl_icon_flash: unquote('"\\e983"');
$cl_icon_mouse-signal: unquote('"\\e984"');
$cl_icon_lightning: unquote('"\\e985"');
$cl_icon_usb_connect: unquote('"\\e986"');
$cl_icon_bulb: unquote('"\\e987"');
$cl_icon_blob: unquote('"\\e988"');
$cl_icon_wireless: unquote('"\\e989"');
$cl_icon_screwdriver: unquote('"\\e98a"');
$cl_icon_diskette: unquote('"\\e98b"');
$cl_icon_ecology: unquote('"\\e98c"');
$cl_icon_mathematics: unquote('"\\e98d"');
$cl_icon_chemistry: unquote('"\\e98e"');
$cl_icon_biology: unquote('"\\e98f"');
$cl_icon_phisic: unquote('"\\e990"');
$cl_icon_Icon-place: unquote('"\\e991"');
$cl_icon_script: unquote('"\\e980"');
$cl_icon_eye: unquote('"\\e97f"');
$cl_icon_save: unquote('"\\e975"');
$cl_icon_bar-path1: unquote('"\\e95c"');
$cl_icon_bar-path2: unquote('"\\e95d"');
$cl_icon_analog-color-path1: unquote('"\\e97a"');
$cl_icon_analog-color-path2: unquote('"\\e97b"');
$cl_icon_file-image: unquote('"\\e97c"');
$cl_icon_file-svg: unquote('"\\e97d"');
$cl_icon_file-pdf: unquote('"\\e97e"');
$cl_icon_json: unquote('"\\e929"');
$cl_icon_log: unquote('"\\e92a"');
$cl_icon_xls: unquote('"\\e976"');
$cl_icon_csv: unquote('"\\e977"');
$cl_icon_txt: unquote('"\\e978"');
$cl_icon_ab-testing: unquote('"\\e96e"');
$cl_icon_lighting: unquote('"\\e96f"');
$cl_icon_temperature1: unquote('"\\e970"');
$cl_icon_horisontal: unquote('"\\e971"');
$cl_icon_a-pressure: unquote('"\\e972"');
$cl_icon_scrap: unquote('"\\e973"');
$cl_icon_analog: unquote('"\\e974"');
$cl_icon_cleared-path1: unquote('"\\e955"');
$cl_icon_cleared-path2: unquote('"\\e956"');
$cl_icon_inclined-path1: unquote('"\\e957"');
$cl_icon_inclined-path2: unquote('"\\e958"');
$cl_icon_inclined-path3: unquote('"\\e959"');
$cl_icon_linear-path1: unquote('"\\e95a"');
$cl_icon_linear-path2: unquote('"\\e95b"');
$cl_icon_quadratic-path1: unquote('"\\e95e"');
$cl_icon_quadratic-path2: unquote('"\\e95f"');
$cl_icon_quadratic-path3: unquote('"\\e960"');
$cl_icon_furie-path1: unquote('"\\e961"');
$cl_icon_furie-path2: unquote('"\\e962"');
$cl_icon_furie-path3: unquote('"\\e963"');
$cl_icon_derivative-path1: unquote('"\\e964"');
$cl_icon_derivative-path2: unquote('"\\e965"');
$cl_icon_derivative-path3: unquote('"\\e966"');
$cl_icon_derivative-path4: unquote('"\\e967"');
$cl_icon_derivative-path5: unquote('"\\e968"');
$cl_icon_integral-path1: unquote('"\\e969"');
$cl_icon_integral-path2: unquote('"\\e96a"');
$cl_icon_integral-path3: unquote('"\\e96b"');
$cl_icon_integral-path4: unquote('"\\e96c"');
$cl_icon_integral-path5: unquote('"\\e96d"');
$cl_icon_integral-path6: unquote('"\\e979"');
$cl_icon_sort: unquote('"\\e952"');
$cl_icon_chain: unquote('"\\e953"');
$cl_icon_enter: unquote('"\\e903"');
$cl_icon_comment: unquote('"\\e904"');
$cl_icon_reload: unquote('"\\e905"');
$cl_icon_mac: unquote('"\\e906"');
$cl_icon_microscope: unquote('"\\e907"');
$cl_icon_broken: unquote('"\\e908"');
$cl_icon_turn-on: unquote('"\\e909"');
$cl_icon_sort-desc: unquote('"\\e90a"');
$cl_icon_sort-asc: unquote('"\\e90b"');
$cl_icon_caret: unquote('"\\e90c"');
$cl_icon_check: unquote('"\\e90d"');
$cl_icon_grid: unquote('"\\e90e"');
$cl_icon_smartphone: unquote('"\\e90f"');
$cl_icon_file: unquote('"\\e910"');
$cl_icon_camera: unquote('"\\e911"');
$cl_icon_toggler: unquote('"\\e912"');
$cl_icon_full-screen-off: unquote('"\\e954"');
$cl_icon_full-screen: unquote('"\\e913"');
$cl_icon_gear: unquote('"\\e914"');
$cl_icon_minus: unquote('"\\e915"');
$cl_icon_plus: unquote('"\\e916"');
$cl_icon_pause: unquote('"\\e917"');
$cl_icon_play: unquote('"\\e918"');
$cl_icon_stop: unquote('"\\e919"');
$cl_icon_marker-plus: unquote('"\\e91a"');
$cl_icon_marker-minus: unquote('"\\e91b"');
$cl_icon_bluetooth: unquote('"\\e91c"');
$cl_icon_usb: unquote('"\\e91d"');
$cl_icon_remote-control: unquote('"\\e91e"');
$cl_icon_speedometer: unquote('"\\e91f"');
$cl_icon_pulse: unquote('"\\e920"');
$cl_icon_dotts: unquote('"\\e921"');
$cl_icon_update: unquote('"\\e922"');
$cl_icon_timer: unquote('"\\e923"');
$cl_icon_range-plus: unquote('"\\e924"');
$cl_icon_range-minus: unquote('"\\e925"');
$cl_icon_cursor-mode: unquote('"\\e926"');
$cl_icon_trash: unquote('"\\e927"');
$cl_icon_export: unquote('"\\e928"');
$cl_icon_import: unquote('"\\e92b"');
$cl_icon_printer: unquote('"\\e92c"');
$cl_icon_record: unquote('"\\e92d"');
$cl_icon_list: unquote('"\\e92e"');
$cl_icon_sensors: unquote('"\\e92f"');
$cl_icon_bullet-list: unquote('"\\e930"');
$cl_icon_sun: unquote('"\\e931"');
$cl_icon_moon: unquote('"\\e932"');
$cl_icon_question: unquote('"\\e933"');
$cl_icon_close: unquote('"\\e934"');
$cl_icon_capture: unquote('"\\e935"');
$cl_icon_drag: unquote('"\\e936"');
$cl_icon_table-mob: unquote('"\\e937"');
$cl_icon_chart-mob: unquote('"\\e938"');
$cl_icon_change: unquote('"\\e939"');
$cl_icon_zoom: unquote('"\\e93a"');
$cl_icon_arrow-sort: unquote('"\\e93b"');
$cl_icon_battery-full: unquote('"\\e93c"');
$cl_icon_battery-middle: unquote('"\\e93d"');
$cl_icon_battery-min: unquote('"\\e93e"');
$cl_icon_battery-charge: unquote('"\\e93f"');
$cl_icon_speed-dashboard: unquote('"\\e940"');
$cl_icon_temperature: unquote('"\\e941"');
$cl_icon_settings: unquote('"\\e942"');
$cl_icon_documents-file: unquote('"\\e943"');
$cl_icon_firmware: unquote('"\\e944"');
$cl_icon_update-app: unquote('"\\e945"');
$cl_icon_edit: unquote('"\\e946"');
$cl_icon_lissague: unquote('"\\e947"');
$cl_icon_point: unquote('"\\e948"');
$cl_icon_bars: unquote('"\\e949"');
$cl_icon_grids: unquote('"\\e94a"');
$cl_icon_picture: unquote('"\\e94b"');
$cl_icon_protractor: unquote('"\\e94c"');
$cl_icon_ruler: unquote('"\\e94d"');
$cl_icon_scale: unquote('"\\e94e"');
$cl_icon_film: unquote('"\\e94f"');
$cl_icon_download: unquote('"\\e950"');
$cl_icon_sound: unquote('"\\e951"');
$cl_icon_download-arrow-circle: unquote('"\\e993"');
$cl_icon_selection-object: unquote('"\\e994"');
$cl_icon_square: unquote('"\\e995"');

