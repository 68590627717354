@import '../../scss/mixins/mixin';

.root {
    position: relative;
    flex: 1 0 49%;
    min-width: 0;
    min-height: 0;
    width: calc(50% - 0.625em);
    // height: calc(33% - 0.625em);
    // min-height: 25vh;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background: #ffffff;
    // border: 1px solid #00B5FF;
    box-shadow: 0 0.25em 0.9375em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    padding: 1.15em 0.65em 1.25em 0.7em;

    :global(.dark) & {
        background: #282b33;
    }
}
.top {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5em 0;

    :global(.chart) & {
        margin-top: -0.55em;
    }
}
.title {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.7em;
}
.color {
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    border-radius: 3px;
}
.action {
    display: flex;
    align-items: center;
    gap: 0.625em;
    margin-right: -0.2em;
    color: #666f7e;
    // overflow: auto;
    // flex-grow: 1;
    max-height: 100%;

    button[class^='cl_icon_'] {
        width: 1.6em;
        height: 1.6em;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        border: 1px solid #bec9d3;
        transition: 0.3s;
        transition-property: color, background;
        background-color: transparent;
        font-size: 1.2em;
        padding: 0 0.1em;
        border-radius: 3px;

        &:before {
            font-size: 0.9em;
        }

        :global(.dark) & {
            border: 1px solid #343640;
        }

        &:first-child {
            border-radius: 3px 0 0 3px;

            :global(.chart) & {
                border-radius: 3px 3px 0 0;
            }
        }

        &:last-child {
            border-radius: 0 3px 3px 0;

            :global(.chart) & {
                border-radius: 0 0 3px 3px;
            }
        }

        &:nth-child(n + 2):not(.cl_icon_gear) {
            border-left-width: 0;
            border-radius: 0;

            :global(.chart) & {
                border-top-width: 0;
                border-left-width: 1px;
            }
        }

        &:not(:disabled) {
            cursor: pointer;

            &:hover {
                color: #00b5ff;

                :global(.dark) & {
                    color: #fff;
                }
            }
        }

        &.active {
            color: #fff;
            background: #00b5ff;
        }
    }
}

.group {
    flex-grow: 1;
    display: flex;
    max-height: 100%;
    overflow-y: auto;
    margin-right: -0.3em;

    @extend %scrollbar;

    [class^='cl_icon_'] {
    }
}

.body {
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.foot {
    flex: none;
}

.drop {
    position: relative;
    &Body {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 2;
        background: #fff;
        border: 1px solid #bec9d3;
        color: #000;
        padding: 0.4375em 0.3em;
        width: 6.4em;

        .drop:global(.open) & {
            display: block;
        }

        :global(.chart) & {
            top: auto;
            bottom: 0%;
            right: 100%;
        }

        :global(.dark) & {
            border: 1px solid #4a4f5b;
            background: #363a45;
            color: #ffffff;
        }

        input {
            width: 100%;
            font-size: 0.65em !important;
            padding: 0.4em 0.6em !important;
        }
    }
    &Row {
        &:nth-child(n + 2) {
            margin-top: 0.5em;
        }
    }
    &Label {
        font-size: 0.65em;
        margin-bottom: 0.5em;
    }
    .btn {
        width: 100%;
        font-size: 0.7em;
    }
}

.analogR,
.analogV {
    position: relative;
    background: #f5f8fa;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0.5em 0.4em 1.5em 0.4em rgba(#494949, 0.4);

    :global(.dark) & {
        box-shadow: inset 0em 0.4em 1em 0.2em rgba(#000, 0.3);
        background: #1e1f25;
    }

    canvas {
        min-width: 8em;
        min-height: 8em;

        :global(.three) & {
            min-width: 5em;
            min-height: 5em;
        }
    }
}

.analogR {
    border-radius: 50%;

    &__round,
    &__avg,
    &__val {
        position: absolute;
        width: 100%;
        text-align: center;
    }

    &__avg {
        left: 0;
        font-size: 0.6em;
        bottom: 6.2em;
    }
    &__val {
        left: 50%;
        transform: translateX(-50%);
        font-size: 1em;
        font-weight: 600;
        bottom: 1.8em;
        min-width: 5em;
        padding: 0.3em;
        overflow: hidden;
        background: #d7e1e8;
        text-overflow: ellipsis;
        border: 1px solid #8e8f92;
        border-radius: 0.25em;

        :global(.dark) & {
            background: #1a1a1a;
        }
    }

    // svg {
    //     max-width: 100%;
    //     max-height: 100%;
    //     width: auto;
    //     height: auto;
    // }

    &__round {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 20%;
        height: 20%;
        border: 0.48px solid #ffffff;
        background: #d7e1e8;
        font-weight: 600;

        span {
            font-size: 0.85em;
        }

        :global(.dark) & {
            background: #1a1a1a;
        }
    }
}

.analogV {
    border-radius: 50%;

    &__val {
        position: absolute;
        // width: 8em;
        text-align: center;
        padding: 0.3em;
        overflow: hidden;
        background: #d7e1e8;
        text-overflow: ellipsis;
        border: 1px solid #8e8f92;
        border-radius: 0.25em;
        right: 15%;
        bottom: 25%;

        :global(.dark) & {
            background: #1a1a1a;
        }
    }
}

.digigtalH {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &__gauge {
        width: 16em;
        padding: 2.3em 1.2em 1em;
        border-radius: 0.3125em;
        background: #d7e1e8;
        text-overflow: ellipsis;
        border-width: 0.25em;
        border-style: solid;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        box-shadow: inset 0.1em 0.4em 1em rgba(#494949, 0.4);

        :global(.dark) & {
            box-shadow: inset 0em 0.4em 1em 0.2em rgba(#000, 0.3);
            // background: #1a1a1a;
            background: #1e1f25;
        }
    }

    &__val {
        font-size: 1.2em;
        font-weight: 600;
        text-align: right;
        background: #c0ccd4;
        border: 1px solid #8e8f92;
        padding: 0.2em 0.3em;
        border-radius: 0.3em;
        min-width: 5.7em;
        overflow: hidden;
        max-width: 100%;
        flex: none;

        :global(.dark) & {
            background: #1a1a1a;
        }
    }
    &__avg {
        width: 100%;
        margin-top: 0.8em;
        text-align: center;
        font-size: 0.7em;
        &:before {
            content: '';
            display: block;
            // border-top: 1px solid;
            margin-bottom: 0.5em;
            opacity: 0.2;
        }
    }
}

.linearV,
.linearH {
    position: relative;
    background: #f5f8fa;
    margin: auto;
    border: 0.25em solid #000;
    border-radius: 0.5625em;
    box-shadow: inset 0.1em 0.4em 1em 0.2em rgba(#494949, 0.4);
    max-width: 100%;
    max-height: 100%;

    :global(.dark) & {
        box-shadow: inset 0em 0.4em 1em 0.2em rgba(#000, 0.3);
        background: #1e1f25;
    }

    canvas {
        max-width: 100%;
        max-height: 100%;
    }
}

.linearV {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-left: 1em;

    canvas {
        margin-right: -2em;
        max-width: 100%;
    }

    &__gauge {
        flex: none;
    }

    &__val {
        flex-grow: 1;
        margin-bottom: 0.5em;
        font-weight: 600;
        text-align: right;
        margin-right: 0.5em;
        background: #c0ccd4;
        border: 1px solid #8e8f92;
        padding: 0.2em 0.3em;
        border-radius: 0.3em;
        max-width: 60%;
        flex: none;
        overflow: hidden;

        :global(.dark) & {
            background: #1a1a1a;
        }
    }
}

.linearH {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1em 0;
    // max-height: 18em;
    max-width: 50em;

    &__gauge {
        // height: 40%;
    }

    &__val {
        margin-bottom: 0.5em;
        font-weight: 600;
        text-align: right;
        margin-right: 0.5em;
        background: #c0ccd4;
        border: 1px solid #8e8f92;
        padding: 0.2em 0.3em;
        border-radius: 0.3em;
        min-width: 7em;
        // width: calc(100% - 2.6em);
        margin-bottom: -0.5em;
        margin-right: 1em;
        max-width: 100%;
        flex: none;

        :global(.dark) & {
            background: #1a1a1a;
        }
    }
}

.lamp {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;

    svg {
        max-width: 100%;
        max-height: 100%;
    }
    &__val {
        position: absolute;
        bottom: 1.5em;
        text-align: center;
        font-weight: 600;

        :global(.dark) & {
            color: #fff;
        }
    }
}
