@import "../../../scss/mixins/mixins";
@import "../../../scss/variables/variables";

$RCTextColor: #1E1F25;

.initTest {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
    font-size: 1.1em;

    &_Row {
		height: 100%;
		width: 100%;
		display: flex;
        padding: 0.1em 0.4em 0.6em 0;
        line-height: 1.3em;
        color:  #7C8195;
        
        .dark & {
            color: #C7C9D1;
        }
    
        &Col1 {
            width: 36%;
            padding-right: 1em;
        }

        &ColInterm {
            width: 1%;
        }

        &Col2 {
            width: 62%;
            font-weight: 500;
            color: #1E1F25;
            .dark & {
                color: #FFFFFF;
            }
            &NoWeight {
                font-weight: 100;
            }
        }

        &NbCol1 { width: 44%;  }
        &NbCol2 { width: 55%;  }

        &WarnMessage {
            color: #C96203;
        }
    
    }

    &__first {
        padding: 0.1em 0.4em 0.6em 0;
        line-height: 1.3em;
        color:  #8e91a1;
        
        .dark & {
            color: $lw_dark_theme_color;
        }
    }

    &__RB {
        font-weight: 800;
        padding-right: 1em;
    }

	&__inputSpan {
        position: relative;
        top: -0.2em;
       }

	&__input {
	    border: solid 1px gray;
		font-size: 1.1em;
        font-weight: 800;
        background-color: white;
        margin-right: 1em;
        width: 5em;
        text-align: center;
    }

    &__msg {
		font-size: 0.8em;
    }

    &__Sel {
        background-color: white;
        color: $lw_blue_color;
        border: solid 1px  $lw_blue_color;
    }

    &__Unsel {
        background-color: $lw_blue_color;
        color: white;

        .dark & {
            background: $cm_dark_btn_unsel_bknd_color; //#14161B;
            color: $cm_dark_btn_unsel_color; //#2E6B88;
            border-color: $cm_dark_btn_unsel_border_color; //#2E6B88;

            &:hover {
                color: white;
                border-color: $lw_blue_color;
            }
        }
    }

    &__select {
		color: #252527; //$RCTextColor;
		background-color: #f4f8fb;
		border: solid 1px #d7dee3; 
		font-size: 1em;
		border-radius: 0.125em;
        line-height: 1em;
        width: 11em;
        cursor: pointer;

        &Width {
            width: 15em;
        }

        .dark & {
			color: $cm_dark_cb_color;
			background-color: $cm_dark_cb_bknd_color;
			border: solid 1px $cm_dark_cb_color;
        }

        &Disabled {
            background-color: #fafbfd;
            color: #9c9da2;
            cursor: not-allowed;
        }
	}

    &__comment {
        font-size: 0.9em;
        color: $lw_light_theme_color;

        .dark & {
            color: $lw_dark_theme_color;
        }
    }
}
 