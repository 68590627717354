.cor_sound {
    audio {
        display: none;
    }

    display: flex;
    align-items: center;
    // border: 1px solid #000;

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 1.5em;
        height: 1.5em;
        border-radius: 100%;

        background-color: #00B5FF;
        color: #ffffff;

        cursor: pointer;

        .cor_icon {
            font-size: 0.675em;
            .cor_icon_play-trigon {
                margin-right: -0.2em;
            }
        }

    }

    &__time {
        display: flex;
        align-items:center;
        // border: 1px solid #000;
        color: #7C8195;
        margin-left: 0.5em;
        margin-right: 0.625em;

        .dark & {
            color: #808289;
        }

        span {
            font-size: 0.625em;
        }
     
    }

    &__timeline {
        position: relative;
        overflow: hidden;
        width: 8.875em;
        height: 0.25em;
        border-radius: 0.25em;
        background-color: #EAEAEA;
        cursor: pointer;

        .dark & {
            background-color: #7C8195;
        }


    }

    &__progress {
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 20%;
        border-radius: 0.25em;

        background-color: #00B5FF;
    }
}

.cor_volume {
    
    &__label {}

    &__content {
        display: flex;
        align-items: center;
        gap: 0.625em;
        margin-top: 0.625em;
    }

    &__icon {
        font-size: 1.5em;
        color: #808289;
    }

    &__range {
        width: 16em;
    }
}