// :global(.dark) & {
//     background: #2d3039;
//     color: #fff;
// }

.wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    flex-grow: 1;
    min-height: 0;
}


.coord {
    display: flex;
    align-items: center;
    gap: 1em;

    margin: 1em 0;

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5em;
    }

    &__list {
        position: absolute;
        top: 1em;
        left: 5em;
        padding: 0.625em;
        border-radius: 0.375em;
        width: fit-content;

        background-color: #fff;
        box-shadow: 0px 4px 29px 0px rgba(0, 0, 0, 0.05);

        :global(.dark) & {
            background-color: #16171D;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 0.5em;

        margin-top: 0.5em;
        &:first-child {
            margin-top: 0;
        }


    }

}

.coordText {
    color: #383E47;
    :global(.dark) & {
        color: #fff;
    }
}

.sircle {
    flex: none;
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    border-radius: 100%;
    background-color: rgba(#00B5FF, 0.8);
}

.content {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    margin: 1em 0;
}

.ranges {
    width: 42.5em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.875em;
    grid-row-gap: 1em;
}

.range {
}

.label {
    font-size: 1em;
    margin-bottom: 0.5em;
    color: #7C8195;

    span {
        display: inline-block;
        width: 0.875em;
    }
    
    b {
        font-weight: 500;
        color: #1E1F25;
        margin-left: 1em;

        :global(.dark) & {
            color: #fff;
        }
    }

}

.chooses {
    width: 26em;
    position: relative;
}

.choose {
    margin-top: 1em;
    &:first-child {
        margin-top: 0;
        width: 60%;
    }

}

.chooseInfo {
    position: absolute;
    top: 1em;
    right: 0em;
}

.equations {
    width: 20em;
}

.equation {
    margin-top: 1em;
    &:first-child {
        margin-top: 0;
    }

    span {
        color: #2B2B2B;
        line-height: 1.4;

        :global(.dark) & {
            color: #fff;
        }

    }

}

.action {
    display: flex;
    align-items: center;
    gap: 1.25em;
}


