@import '../../scss/variables/variables';

.root {
    position: absolute;
    bottom: 5em;
    right: 1.3em;
    width: 2.5em;
    height: 2.5em;
    background: #00b5ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color_withe;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    transition-property: background, opacity, bottom, right;
    z-index: 5;
    opacity: 0.5;
    // z-index: -1;

    @media (min-width: 768px) {
        // right: 1.3em;
        // bottom: 5.3em;
        bottom: 3.4em;
        right: 0.5em;

        // [class^='Dashboard_root'] & {
        // }
    }

    &:hover {
        background: #67d3ff;
        opacity: 1;
    }

    i {
        font-size: 1.2em;
    }
}

.dashboard {
    composes: root;
    z-index: 10;
    bottom: 5.6em;
    right: 1.1em;
}

.collapsed {
    composes: root;
    opacity: 1;
    z-index: 10;
    bottom: 1.2em;
    right: -0.2em;
}
