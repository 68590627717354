.root {

    [class^=cor-net__col] {
        button {
            width: 100%;
        }
    }

    // start head
    .head {
        display: block;
    }

    .top {
        display: flex;
        align-items: center;
    }

    .status {
        display: flex;
        align-items: center;
        margin-left: 1.25em;
        color: #C96203;
        margin-top: 0.5em;
        margin-bottom: 0.5em;

        [class^=cor_icon] {
            font-size: 1.25em;
            font-weight: bold;
            margin-right: 0.5em;
        }
    }

    .checking {
        display: flex;
        margin-left: 0.25em;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;

            li {
                font-weight: 600;
                margin-left: 0.25em;
                
                &:after {
                    content: ',';
                }

                &:last-child {
                    &:after {
                        content: '.';
                    }
                }

            }
        }
    }
    
    .info {
        margin-top: 1em;
    }
    
    .row {
        display: flex;
        align-items: center;
        gap: 2.75em;

        margin-bottom: 0.625em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .col {
        font-size: 0.875em;

        &:first-child {
            width: 20em; //16.25em;
            color: #7C8195;
        }

        &:last-child {
            font-weight: 500;
        }
    }
    // end head

    // start main
    .main {
        position: relative;
        background: #f5f8fa;
        border: 1px solid #bec9d3;
        border-radius: 0.125em;
        padding: 0.8125em;
        color: #595c6c;

        max-height: 40vh;
        overflow-y: auto;

        :global(.dark) & {
            background: #363a45;
            border: 1px solid #4a4f5b;
            color: #ceced6;
        }

        @media (min-width: 768px) {
            // overflow-y: visible;
            scrollbar-gutter: stable;
    
            &::-webkit-scrollbar {
                width: 0.3em;
                height: 0.3em;
    
                &-track {}
    
                &-button {
                    opacity: 0;
    
                    &:single-button {
                        width: 0.1em;
                        height: 0.1em;
                    }
                }
    
                &-thumb {
                    background: #bec9d3;
                    border-radius: 1em;
                    border: 0 solid transparent;
                    background-clip: border-box;
                    min-height: 5em;
    
                    :global(.dark) & {
                        background: #464f57;
                    }
    
                }
            }
        }

    }

    .label {
        font-size: 0.875em;
        font-weight: 600;
        margin-bottom: 1em;

        margin-top: 1.25em;

        &:first-child {
            margin-top: 0;
        }
    }

    .answer {
        word-wrap: break-word;

        &__list {
            counter-reset: line var(--line-num, 0);

            > p {
                margin: 0 0 1.125em 0;
                &:last-child {
                    margin: 0;
                }
            }
        }

        &__number {
            span {
                &:before {
                  content: counter(line) ' ';
                }

            }
        }

        &__item {
            counter-increment: line;

            border-radius: 0.375em;
            padding: 0.975em;
            background-color: #ffffff;
            margin-bottom: 1.125em;
            border: 1px solid #E8E8E8;

            :global(.dark) & {
                background-color: #282B33;
                border: 1px solid #343640;
            }
            
            &:last-child {
                margin-bottom: 0;
            }

            &_warning {
                position: relative;
                border: 1px solid #C96203;

                .testResults__status {
                    margin-left: 0;
                    position: absolute;
                    top: 0.975em;
                    right: 0.975em;
                }

                .answer__row {
                    &:last-child {
                        position: relative;
                        margin-top: 2em;

                        &:before {
                            content: '';
                            position: absolute;
                            top: -1em;
                            left: 50%;
                            transform: translateX(-50%);

                            display: block;
                            width: calc(100% + 1.875em);
                            height: 1px;
                            background-color: #C96203;

                            // border: 1px solid #C96203;

                        }
                    }
                }
            }
        }

        &__content {
            margin-top: 0.75em;
        }

        &__row {
            display: flex;
            gap: 1.25em;
            margin-bottom: 0.5em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__row_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1em;

            margin-bottom: 0.5em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__col {
            border: 1px solid #BEC9D3;
            width: 100%;
            height: 12em;
            background-color: #F0F3F5;
            border-radius: 0.375em;
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            :global(.dark) & {
                background-color: #282B33;
                border: 1px solid #343640;
            }
        }

        &__label {
            flex: none;
            color: #7C8195;
            font-size: 0.875em;
            width: 10em;
            line-height: 1.4;
            
        }

        &__text {
            font-size: 0.875em;
            font-weight: 500;
            line-height: 1.4;

            
            span {
                margin-left: 0.25em;
                
                &[class*=error] {
                    color: #F75656;
                }
    
                &[class*=success] {
                    color: #33C200;
                }

                &:first-child {
                    margin-left: 0;
                }
            }

            [class*=cor_icon] {
                color: #7C8195;
                cursor: pointer;
            }

        }

        &__textarea {
            textarea {
                width: 100%;
                resize: none;
            }
        }
        
        &__options {
            display: flex;
            flex-direction: column;
        }

        &__option {
            display: flex;
            align-items: center;
            cursor: pointer;

            input[type='radio'] {
                border: 1px solid #BEC9D3;
            }

            &:first-child {
                span {
                    color: #33C200;
                }

                input[type='radio'] {
                    &:hover {
                        border: 1px solid rgba(#33C200, 0.5);
                    }

                    &:before {
                        background: #33C200;
                    }
                }
            }

            &:last-child {
                margin-top: 0.5em;

                span {
                    color: #F75656;
                }

                input[type='radio'] {
                    &:hover {
                        border: 1px solid rgba(#F75656, 0.5);
                    }

                    &:before {
                        background: #F75656;
                    }
                }
            }

            span {
                font-size: 0.875em;
                font-weight: 500;
                margin-left: 0.375em;
            }
        
        }

        &__audio {
            display: flex;
            align-items: center;
            
            &_name {
                margin-left: 1em;
                font-weight: 500;
            }
        }

        &__textarea {
            width: 100%;
        }

        &__sequence {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 0.625em;
            min-width: 1px;

            span {
                padding: 0.584em 0.7856em;
                font-size: 0.75em;
                font-weight: 500;
                line-height: 1;
                min-width: 1px;

                border-radius: 0.25em;
                border: 1px solid #BEC9D3;
                background: #F5F8FA;
                color: #7C8195;

                :global(.dark) & {
                    background-color: #363A45;
                    border: 1px solid #343640;
                    color: #ffffff;
                }

            }
        }
    }
    // end main

    .footer {
        display: flex;
        justify-content: space-between;

        button {
            flex-grow: 0;
        }

        &__warning {
            padding: 0.2em 1.4286em;
        }

        &__dropdown {
            width: 11.25em;
        }
    }

}