@import '../../../scss/mixins/mixin';

.modal {
    max-height: 80vh;
    overflow-y: auto;
    @extend %scrollbar;

    h3 {
    }

    ul {
        padding: 0 0 0 1.2em;

        li {
            margin-bottom: 0.5em;
        }
    }

    figure {
        max-width: 100%;
        position: relative;
        padding: 0;
        margin: 0;
    }

    [class~='se-component'] {
        display: flex;
        padding: 1px;
        margin: 0 0 10px;
    }

    [class~='__se__float-left'] {
        float: left;
        margin-right: 4px;
    }

    [class~='__se__float-right'] {
        float: right;
        margin-left: 4px;
    }

    [class~='__se__float-center'] {
        float: initial;
    }

    [class~='__se__float-none'] {
        float: none;
    }

    [class~='__se__float-left'] figure,
    [class~='__se__float-right'] figure,
    [class~='__se__float-center'] figure {
        margin: auto !important;
    }

    [class~='se-video-container'] {
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
        }
    }
}
