.upload_cover__button {
  display: flex;
  align-items: center;
  font-size: 0.875em;
  padding: 0.3125em 0.625em;
  gap: 0.5em;
  cursor: pointer !important;
  position: relative;
  &:hover {
    background: #e6eaef;
    .dark & {
      background: #1f2026;
    }
  }
  
  input[type=file] {
    cursor: pointer !important;
  }
  
  button {
    cursor: pointer !important;
  }
}