@import "../../scss/mixins/mixins";

.classEventList {
    display: flex;
    flex-direction: column;
    width: 14em;

    &__head {
        display: flex;
        box-shadow: 0 2em 3em rgba(0, 0, 0, 0.1);
        padding: 0 0.9375em 0.5em 0.9375em;
        margin: 0 -0.9375em;
        flex: none;
        font-size: 1.2em;

        .tabs,
        .tabs__item {
            flex-grow: 1;
            justify-content: center;
        }
    }

    &__scroll {
        flex-grow: 1;
        min-height: 0;
        margin-right: -1em;
        padding-right: 1.3em;
        overflow: hidden;

        @include scrollbar;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 0.625em;
    }

    &__item {
        background: #F4FAFC;
        border-radius: 0.125em;
        padding: 0.625em;
        
        .dark & {
            background: #363A45;
        }

        &.author {
            text-align: right;
            background: #cef;
            border-radius: 0.125em;
            padding: 0.625em;

            .dark & {
                background: #1F2026;
            }
        }

    }
    
    &__name {
        font-size: 0.75em;
        line-height: 1.2;
        margin-bottom: 0.6em;
        color: #666F7E;

        .dark &, .teacher & {
            color: darken($color_withe, 10%);
        }
    }
    
    &__text {
        font-size: 0.9em;
        line-height: 1.25;
        margin-bottom: 0.65em;
        color: #383E47;

        .dark &, .teacher & {
            color: $color_withe;
        }
    }
    
    &__time {
        font-size: 0.6em;
        text-align: right;
        color: #7C8195;

        .dark &, .teacher & {
            color: darken($color_withe, 15%);
        }
    }
}
