@import '../../scss/variables/variables';
@import '..//Icon/variables';

.oscilloSetts {
    font-size: 1em;
    border-radius: 0.375em;
    box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.1);
    margin-bottom: 1.25em;
    user-select: none;

    &__title {
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 0.625em;
    }

    .dark & {
        background: #282b33;
    }

    &__head {
        border-radius: 0.375em;
        background: #eef1f2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // text-align: center;
        // margin-bottom: 0.5em;

        @media (min-width: 768px) {
            font-size: 0.75em;
            font-weight: 700;
            padding: 1em 1.2em;
        }

        &:after {
            font-family: 'robiclab';
            content: $cl_icon_caret;
            font-weight: 400;
            font-size: 0.9em;
            color: #a2a6b6;
        }

        .dark & {
            background: #2d3039;
        }

        &.active {
            &:after {
                transform: rotate(-90deg);
            }
        }
    }

    &__body {
        padding: 0.9375em 0.9375em 0.625em;
    }

    &__row {
        // display: flex;
        // align-items: center;
        padding-bottom: 0.8125em;
        font-size: 0.9em;

        &:not(:last-child) {
            margin-bottom: 0.8125em;
            border-bottom: 1px solid #eef1f2;

            .dark & {
                border-bottom: 1px solid #3d4351;
            }
        }
    }

    &__label {
        position: relative;
        // width: 6.6em;

        label {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        input[type='checkbox'].swicher {
            flex: none;
            background: #bec9d3 !important;

            .dark & {
                background: rgba(#ffffff, 0.2) !important;
            }

            &:disabled {
                background: #00b5ff !important;
            }
        }

        span {
            display: block;
            font-size: 0.75em;
            padding-right: 1em;
        }
    }

    &__unit {
        font-size: 0.75em;
        line-height: 1.2;
        margin-bottom: 0.5em;
    }

    &__range {
        // width: 9em;
        flex: none;

        .range {
            margin: 0 0 0 -0.3em;

            .btn {
            }
        }

        button {
            background-color: transparent !important;
            padding: 0 !important;
            border: 0 !important;
            color: $light_theme_color !important;
            margin: 0;

            .dark & {
                color: $dark_theme_color !important;
            }
        }
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.75em;
        font-weight: 600;
        padding: 0.3125em 0;
        margin-bottom: 0.5em;

        &:after {
            font-family: 'robiclab';
            content: $cl_icon_caret;
            font-weight: 400;
            font-size: 0.7em;
            color: #a2a6b6;
            transform: rotate(180deg);
        }

        &.active {
            &:after {
                transform: rotate(0deg);
            }
        }
    }

    &__list {
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: #eef1f2;
        padding-top: 0.5em;
        margin-bottom: 0.7em;

        .dark & {
            border-color: #3d4351;
        }
    }
}

.mode {
    // display: flex;
    // flex-wrap: wrap;
    // margin-left: -1.5em;

    &__status {
        margin-bottom: 1.2em;
        font-size: 0.8125em;

        span {
            color: #00da98;

            .dark & {
            }
        }
    }

    &__item {
        margin-bottom: 1.5em;
    }

    &__name {
        margin-bottom: 0.6em;
        font-size: 0.75em;

        .dark & {
            color: #c7c9d1;
        }
    }

    [class^='Dropdown_root'] {
        font-size: 0.9em;

        [class^='Dropdown_label'] {
            &:after {
                font-size: 0.5em;
            }

            span {
                // font-size: 0.75em;
            }
        }

        .dark & {
            background: #363a45;

            [class^='Dropdown_label'] {
                color: $color_withe;
            }
        }

        [class^='Dropdown_drop'] {
            ul {
                li {
                    font-size: 0.75em;
                }
            }
        }
    }
}

.oscilloModes {
    // margin-top: 1.5em;
}
