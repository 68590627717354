@import '../Icon/Icon.scss';

.inputFile {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:before {
        font-family: 'cor';
        content: $cor_icon_clip;
        font-size: 1.2em;
    }
    input {
        display: none;
    }
}
