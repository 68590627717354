@import "../../scss/variables/variables";

$tableIconSize: 1.3em;
$RCSubTitleColor: #7C8195;

.lwtSelect {
	width: 100%;

    &__stretch {
        display: flex;
        justify-content: space-between;
        align-items: top; 
        font-size: 1.125em;

		&Right {
			justify-content: end;
		}
    }

	&__boxes {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		&SelectAlign {
			justify-content: space-between;
			align-items: center;
			width: 97%;
		}
	
		&_Box {
			color: $cm_light_cb_color;
			font-weight: 500;
			font-size: 1.0em;
			line-height: 1.1em;
			margin-top: 5px;
			margin-right: 15px;

			&NoMargin {
				margin: 0;
			}

			.dark & {
				color: $cm_dark_cb_color;
			}

			&G01 {width: 30%;}
			&G02 {width: 25%;} 
			&G03 {width: 45%; 
				font-size: 0.9em; 
				color: grey;
			}

			//text and date/time box
			&Gr01 {width: 10%; 
				text-align: center; 
				position: relative; 
				top: 0.5em;
			}
			&Gr02 {width: 40%; 
				line-height: 1.6em; 
				padding: 0 0.5em; 
				//border: solid 1px lighten($color: grey, $amount: 20%); 
				border-radius: 0.25em;
			}
		}
		&_Title {
			padding-bottom: 5px;
		}
		&_Rect {
			//border: solid 1px #BEC903;
			//width: 330px;
		}

		&_Select {
			font-size: 0.9em;
			width: 400px;
			border-radius: 2px;
			color: $cm_light_cb_color;
			background-color: $cm_light_cb_bknd_color;
			border: solid 1px $cm_light_cb_border_color;
			cursor: pointer;

			.dark & {
				color: $cm_dark_cb_color;
				background-color: $cm_dark_cb_bknd_color;
				border: solid 1px $cm_dark_cb_color;
			}
			
			&Small {
				width: 180px;	
			}
			&Big {
				width: 600px;	
			}
		}
	}

	&__input {
		width: 100%;
		line-height: 1.5em;
		margin-bottom: 0.5em;
		border: solid 1px  gray;
		font-size: 1em;
		background-color: white;
		cursor: pointer;
	}

	&__dateValue {
		width: 50%; //40em;
		border: solid 1px green;
	}

	&__ActionIcon {
		padding: 0.1em 0.3em;
		font-size: $tableIconSize;
		color: lighten($color: grey, $amount: 20%); // #666F7E;

		&:hover {
			cursor: pointer;
		}
	}

	&__Separator {
		width: 110%;
		border-top: solid 1px lighten($color: grey, $amount: 40%);
		margin-top: 0.5em;
		position: relative;
        left: -2em;
	}

}
