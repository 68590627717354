@import "../../../scss/variables/variables";
@import "../Icon/variables.scss";


$btn_color: #000000;
$btn_background: #ffffff;
$btn_color_contrast: #ffffff;
$btn_primary: #00B5FF;
$btn_danger: #F75656;
$btn_muted: #BEC9D3;
$btn_secondary: #e2f5ff;

$btn_primary_color_hover: #67d3ff;
$btn_danger_color_hover: #FF7B7B;

.cor_btn {
    color: $btn_color;
    font-family: $main_font_family;
    font-size: 0.9375em;
    font-weight: 500;
    padding: 1em;
    border: 1px solid $btn_muted;
    outline: none;
    text-decoration: none;
    display: inline-block;
    background-color: $btn_background;
    text-align: center;
    max-width: 100%;
    cursor: pointer;
    border-radius: 0.375em;
    transition: 0.3s;
    transition-property: background-color;
    user-select: none;

    &:hover:not(:disabled) {}

    &:active:not(:disabled) {}

    &_border {
        transition: 0.3s;
        &:hover:not(:disabled) {}

        &:active:not(:disabled) {}
    }

    &_primary {
        background: $btn_primary;
        border-color: $btn_primary;
        color: $btn_color_contrast;

        &:hover:not(:disabled) {
            background-color: $btn_primary_color_hover;
            border: 1px solid $btn_primary_color_hover;
        }

        &.cor_btn_border {
            background-color: transparent;
            color: $btn_primary;

            &:hover:not(:disabled) {
                background-color: transparent;
                border: 1px solid $btn_primary_color_hover;
                color: $btn_primary_color_hover;
            }
        }
    }

    &_secondary {

        background: $btn_secondary;
        border-color: $btn_secondary;
        color: $btn_primary;
        transition: 0.3s;

        &:hover:not(:disabled) {
            background-color: darken($btn_secondary, 2%);
            border: 1px solid darken($btn_secondary, 2%);
        }
    }

    &_danger {
        border-color: $btn_danger;
        background: $btn_danger;
        color: $btn_color_contrast;

        &:hover:not(:disabled) {
            background-color: $btn_danger_color_hover;
            border: 1px solid $btn_danger_color_hover;
        }

        &.cor_btn_border {
            background-color: transparent;
            color: $btn_color;

            &:hover:not(:disabled) {
                background-color: transparent;
                border: 1px solid $btn_danger_color_hover;
            }
        }
    }

    &_small {
        padding: 0.3em 0.6em;
        border-radius: 0.1875em;
    }

    &_extraSmall {
        padding: 0.646em 1em;
        border-radius: 0.416667em;
        font-size: 0.75em;
        min-width: 12em;
    }

    &_medium {
        padding: 1.063em 1em;
        border-radius: 0.416667em;
        font-size: 0.75em;
        min-width: 13.667em;

        &.has_input {
            padding: 0;
            label {
                display: block;
                min-width:  13.667em;
                padding: 1.063em 1em;
                cursor: pointer;
            }
            input {
                display: none;
            }
        }
    }

    &_edit,
    &_minus,
    &_plus {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.87em;
        width: 1.87em;
        background: #F5F8FA;

        &:before {
            content: '+';
            color: $btn_muted;
            padding: 0 0 0.2em 0;
            font-size: 1.5em;
            font-weight: 400;
            line-height: 0.2;
        }
    }

    &_minus {
        &:before {
            content: '\2013';
        }
    }

    &_icon_left {
        position: relative;
        padding-left: 2.5em;

        .cor_icon {
            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.5em;
                left: 0.5em;

            }
        }
    }

    &_back {
        display: inline-flex;
        align-items: center;
        gap: 0.5em;
        
        font-size: 1em;

        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        color: #666F7E;
        font-weight: 400;
        transition: 0.3s;

        .dark & {
            color : #d1d1d1;
        }

        &:before,&:after {}

        &:before {
            content: $cor_icon_arrow-prev;
            font-family: 'cor';
            font-size: 0.625em;
        }
        &:after {
            content: 'Назад';
            font-size: 0.857em;
        }

        &:hover {
            color: #00B5FF;
        }

    }

    &_add {
        display: inline-flex;
        align-items: center;
        gap: 0.5em;
        
        font-size: 1em;
        line-height: 1;

        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        color: #00B5FF;
        font-weight: 400;
        cursor: pointer;
        transition: 0.3s;

        span:not(.cor_icon) {
            text-decoration: underline;
        }

        input {
            display: none;
        }

        .cor_icon_minus {
            font-size: 1.75em;
            font-weight: bold;
        }

    }

    &.active {}

    &:disabled {
        opacity: 0.4;
        cursor: default;
    }
}
