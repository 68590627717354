.sticker {
  display: flex;
  align-items: center;
  // gap: 2px;
  gap: 0.625em;
  width: fit-content;
  border-radius: 18px;
  // padding: 2px 6px;
  padding: 0.2em 0.6em;
  cursor: pointer;
  // font-size: 10px;
  font-size: 0.625em;
  color: #595c6c;
  font-weight: 400;

  &__title {
    white-space: nowrap;
  }
}

.messages {
  background: rgba(78, 228, 84, 0.12);
  color: #41cb47;
}

.important {
  background: rgba(243, 81, 81, 0.12);
  color: #f35151;
}

.urgently {
  background: rgba(243, 198, 81, 0.12);
  color: #f3c651;
}

.normal {
  background: rgba(78, 228, 84, 0.12);
  color: #41cb47;
}

.notImportant {
  background: rgba(190, 201, 211, 0.15);
  color: #a7b0b8;
}

.homework {
  background: rgba(206, 90, 146, 0.12);
  color: #ce5a92;
}

.labWork {
  background: rgba(239, 136, 78, 0.1);
  color: #ef884e;
}

.stickers {
  position: absolute;
  z-index: 100;
  left: 0;
  // top: 1.5em;
  display: flex;
  flex-direction: column;
  // gap: 6px;
  // gap: 0.375em;
  // padding: 10px;
  padding: 0.3125em 0;
  // border-radius: 8px;
  border-radius: 0.5em;
  border: 1px solid #bec9d3;
  background: #fdfdfd;
  box-shadow: 0px 9px 15px 0px rgba(52, 61, 70, 0.09);

  .dark & {
    background: #16171d;
    color: #fff;
    border: 1px solid #4a4f5b;
  }
  &__modal {
    top: 1.5em;
    left: 0;
    z-index: 10000;
  }

  &__item {
    position: relative;
    padding: 0.1875em 0.625em;

    &:hover {
      background: #e6eaef;

      .dark & {
        background: #1f2026;
      }
    }
  }
  &__submenu {
    position: absolute;
    top: 0;
    left: 100%;

    display: flex;
    flex-direction: column;
    z-index: 10;
    // gap: 6px;
    gap: 0.375em;
    // padding: 10px;
    padding: 0.375em;
    // border-radius: 8px;
    border-radius: 0 0.5em 0.5em 0.5em;
    border: 1px solid #bec9d3;
    background: #fdfdfd;
    box-shadow: 0px 9px 15px 0px rgba(52, 61, 70, 0.09);

    .dark & {
      background: #16171d;
      color: #fff;
      border: 1px solid #4a4f5b;
    }
  }
}
