@import "../../scss/mixins/mixins";
@import "../ui/Icon/Icon";
@import "../../scss/variables/variables";

.pi {  

    &__main {
        display: flex;

        .cor-net__label {
            margin-bottom: 0.75em;
        }

        .cor-net__title {
            font-weight: 500;
            font-size: 1.125em;
        }

        // TAB VIEW 
        &.view {
            .sun-editor-editable {
                font-size: 1em;
                padding: 0;
                background: transparent;

                .dark & {
                    color: #d1d1d1;
                }
            }


        }
    }

    &__body {
        flex-grow: 1;
        min-width: 1px;

       
        > .cor-net__section {
            padding: 1.25em;
            border: 1px solid #CBD4DC;
            border-radius: 0.375em;
            background: lighten(#F5F8FA, 2%);  
            transition: 0.3s;
        
            
            .dark & {
                border: 1px solid #484B5A;
                background: darken(#363A45, 10%);
            }

            &:hover:not(.view &) {
                border: 1px solid #00B5FF;
            }

        }
        
    }

    &__iconPanel {
        margin: 0 0 0 1.875em;
        padding: 0.9375em 0.625em;
        list-style: none;

        flex: none;
        position: sticky;
        top: 0;
        right: 0;
        z-index: 20;

        height: 100%;
        border-radius: 0.25em;
        border: 0.0625em solid #BEC9D3;
        background: #F5F8FA;

        .dark & {
            background: rgb(54, 58, 69);
            border: 1px solid #484B5A;
        }

        li {
            margin-bottom: 0.625em;
            &:last-child {
                margin-bottom: 0;
            }

            color: #9FA1AA;
            transition: 0.3s;

            &:hover,&.active {
                color: #00B5FF;
            }
        }

        .cor_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.25em;
            height: 1.25em;
            cursor: pointer;
            

            i {
                font-size: 1.25em;
            }
        }
    }

    &__content {
        font-size: 1em;
        line-height: 1.4;
        color: #595C6C;
        font-weight: 500;

        .dark & {
            color: #d1d1d1;
        }

        > * {
            &:first-child {
                margin-top: 0;
            }
    
            &:last-child {
                margin-bottom: 0;
            }
        }

        ol, ul {
            padding-left: 1.75em;
        }

    }

    &__tableImage {
        width: 20px;
        height: 20px;
        border: solid 1px grey;
    }

    &__stretch {
        display: flex;
        justify-content: space-between;
        font-size: 1.125em;
        gap: 0.5em;
    }

    &__svg {
        max-width: 100%;
        max-height: 100%;
        width: 1000%; 
    }

    &__units1 {width: 80%;}
    &__units2 {width: 20%;}

    &__links1 {
        width: 50%; 
    }
    &__links2 {
        width: 50%; 
    }

    &__svgText {
        font-size: 1em;
    }
    &__svgTextScale {
        font-size: 0.8em;
    }
    &__goalInput {
        width: 30em;
    }

    &__workName {
        font-size: 1.4em;
    }

	&__objImagePreview {
		width: 180px;
		height: 100px;
	}

	&__drawToolFull {
		width: 100%;
	}

    &__showCompose {
        opacity: 0;
    }
    &__changeCompose {
        opacity: 1;        
    }

    &__linkGraph {
        color: black;
    }

    &__newTable {
        &Header {
            max-width: 100%; //40em;
        }
        &AddCell {
            color: lightblue;
            text-decoration: underline;
        }
    }

    &__chgCompose {
        min-height: 1.5em;
    }

    &__defEditCell {
        min-width: 3.5em;
        min-height: 1.5em;
        border: solid 1px grey;

        label {
            display: block;
            width: 2em;
            height: 2em;
            cursor: pointer;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                
            }
        }
    }

    // modal
    &__objectList {
        .labWorkTable__actionsSpan {
            justify-content: center;
            .cor-net__checkbox {
                font-size: 1.25em;
            }
        }
    }

    &__linkUnits {
        .labWorkTable__actionsSpan {
            justify-content: center;
            .cor-net__checkbox {
                font-size: 1.25em;
            }
        }
    }
}

.targetList {
    width: 100%;

    &__item {
        display: flex;
        align-items: flex-start;
        gap: 1.25em;
        padding: 0.625em;
    }

    &__action {
        display: flex;
        align-items: center;
        gap: 0.625em;

        .cor_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.25em;
            height: 1.5em;

            color: #858C98;
            transition: 0.3s;
            padding: 0.25em;
            cursor: pointer;

            .dark & {
                color: #d1d1d1;
            }

            &:hover {
              color: #00b5ff;
            }
        }

        label {
            cursor: pointer;
        }

        &_opton {
            flex-direction: column;
            align-items: flex-start;
            width: 10em;
            flex: none;

    
            label {
                display: flex;
                align-items: center;
                gap: 0.625em;
            }
        }

       
    }

    &__content {
        flex-grow: 1;

        display: flex;
        align-items: flex-start;
        gap: 0.5em;
        color: #595C6C;
        font-size: 1em;
        font-weight: 500;
        margin: 0;
        line-height: 1.4;

        span {
            &:first-child {
                flex: none;
                &:after {
                  content: ' .';
                }
            }
        }

        .dark & {
            color: #d1d1d1;
        }

    }

    &__enter {
        flex-grow: 1;

        textarea {
			resize: vertical;

            .pi__body & {
                min-height: 7em;
            }
		}
    }

    &__toggle {
        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        font-size: 0.75em;
        font-weight: 500;
        text-decoration-line: underline;
        cursor: pointer;
        color: #00b5ff;
        transition: 0.3s;
    }

    &__status {
        flex: none;
        display: inline-block;
        line-height: 1;
        text-align: center;
        padding: 0.75em 1.125em;
        border-radius: 0.375em;

        color: #ffffff;
        background-color: #E55E3A;

        &.success {
            background-color: #22D27E;
        }
    }
}

.piAdd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;

    width: 100%;
    padding: 1em;
    border-radius: 0.25em;
    border: 1px solid #BEC9D3;
    background-color: #ffffff;
    cursor: pointer;

    .dark & {
        border: 1px solid #4a4f5b;
        background-color: #363a45;
    }

    &__label {
        display: flex;
        align-items: center;
        gap: 0.5em;
        

        .cor_icon {
            color: #00B5FF;
            font-size: 0.75em;
        }
    }

    &__text {
        color: #595C6C;
        font-size: 1.125em;
        font-weight: 500;

        .dark & {
            color: #d1d1d1;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        gap: 0.5em;

        .cor_icon {
            color: #d1d1d1;
            padding: 0.25em;
            transition: 0.3s;

            &:hover {
                color: #00B5FF;
            }
        }
    }
}

.pi-options {
    position: relative;

    &:hover {
        
        .pi-options__content {
            display: flex;
            animation-duration: 0.3s;
            animation-name: optionsLeft;
        }

        .pi-options__toggle {
            color: #00B5FF;
        }
    }

    .cor_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.25em;
        height: 1.5em;

        color: #858C98;
        transition: 0.3s;
        padding: 0.25em;
        cursor: pointer;

        .dark & {
            color: #d1d1d1;
        }

        &:hover {
          color: #00b5ff;
        }
    }

    &__content {
        display: none;
        position: absolute;

        top: 50%;
        right: 1.75em;
        transform: translate(0, -50%);

        align-items: center;
        gap: 0.5em;

        padding: 0.5em 1em;
        border-radius: 0.75em;

        background-color: #ffffff;
        color: #383E47;
        border: 1px solid #BEC9D3;
        box-shadow: (0px 9px 15px rgba(52, 61, 70, 0.09));

        &:after {
            content: '';
            position: absolute;

            right: -0.52em;
            top: 50%;
            transform: translateY(-50%)rotate(45deg);
            display: block;
            width: 1em;
            height: 1em;
            border: 1px solid #BEC9D3;
            border-bottom-width: 0;
            border-left-width: 0;
            background-color: inherit;

            
        }

        &:before {
          content: '';
          display: block;

          position: absolute;
          top: 0;
          bottom: 0;

          left: 100%;

          width: 0.75em;
        }

        .dark & {
            background: $footer_dark_button_bg;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            color: $footer_dark_color;
            border-color: $footer_dark_dropdown_border_color;

            &:after {
                border-color: $footer_dark_dropdown_border_color;
            }
        }
       
    }
}

@keyframes optionsLeft {
    0% {
        transform: translate(-0.5em, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

.pi-plots {
    .uplot {
        .u-cursor-x,
        .u-cursor-y{
            &:before {
                content: '';
                padding: 0;
            }
        }
    }
}

.selectedSections {
    display: flex;
    align-items: center;
    gap: 1em;

    cursor: pointer;

    padding: 0.5em;
    margin-bottom: 0.75em;

    // &:last-child {
    //     margin-bottom: 0;
    // }

    border-radius: 0.25em;
    border: 1px solid #BEC9D3;
    background-color: #F5F8FA;
    transition: 0.3s;
    
    .dark & {
        background-color: #363a45;
        border: 1px solid #4a4f5b;
    }

    &__title {
        flex-grow: 1;

        font-size: 0.875em;
        font-weight: 500;
        color: #08203A;
        line-height: 1.2;

        .dark & {
            color: #ffffff;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        gap: 0.5em;

        .cor_icon {
            color: #d1d1d1;
            padding: 0.25em;
            transition: 0.3s;

            &:hover {
                color: #00B5FF;
            }
        }
    }
}

.pi-actions {
    display: flex;
    align-items: center;
    gap: 0.75em;

    width: 100%;
    min-height: 3em;
    // border-top: 1px solid #E8E8E8;

    // .dark & {
    //     border-top: 1px solid #A2A2A5;
    // }

    &__input {
        flex-grow: 1;
    }

    &__title {
        font-size: 1em;
        font-weight: 500;
        color: #595C6C;

        flex-grow: 1;

        .dark & {
            color: #ffffff;
        }
    }

    &__options {
        display: flex;
        align-items: center;
        gap: 0.25em;
    }

    .cor_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.25em;
        height: 1.25em;

        color: #858C98;
        transition: 0.3s;
        padding: 0.25em;
        cursor: pointer;

        .dark & {
            color: #d1d1d1;
        }

        &:hover {
          color: #00b5ff;
        }
    }
}

.objectGrid {
    margin-top: 1.25em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2em;

    &__item {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        border-radius: 0.125em;

        &_img {
            height: 14em;
        }
    }

    &__a {
        color: inherit;
        text-decoration: none;
    }

    &__add {
        margin-top: 2em;
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__file {
        position: relative;

        display: flex;
        flex-direction: column;
        gap: 0.75em;
        width: 100%;
        height: 100%;
        padding: 1.5em 1.5em 2em 5em;
        background-color: #F4FAFC;
        min-height: 6.25em;


        .dark & {
            background-color: #363A45;
        }

        .info & {
            color: #A2A6B6;
        }
    }

    &__caption {
        line-height: 1.2;
        color: #1E1F25;

        .dark & {
            color: #ffffff;
        }
    }

    &__date {
        font-size: 0.875em;
        color: #A2A6B6;

        .dark & {
            color: #A2A6B6;
        }
    }

    &__size {
        font-size: 0.875em;
        color: #A2A6B6;

        .dark & {
            color: #A2A6B6;
        }
    }

    &__icon {
        position: absolute;
        top: 1.5em;
        left: 1.125em;

        .cor_icon {
            font-size: 3em;
            font-weight: 400;
            color: #666F7E;

            .dark & {
                color: #A2A6B6;
            }
        }
    }

    &__trash {
        position: absolute;
        top: 0.75em;
        right: 0.75em;
        z-index: 1;
    }
}


.pi-table {
    th {
        &:first-child {
            width: 3%;
        }
    }

    tr {
        &.empty {
            height: 3.5em;
        }
    }

    &_edit {
        &__num {width: 5%;}

        &__title {width: 30%;}
        &__type {width: 25%;}
        &__unit {width: 35%;}

        &__titleF {width: 25%;}
        &__typeF {width: 15%;}
        &__unitF {width: 20%;}
        &__formula {width: 30%;}

        // &__action {width: 5%;}

        th, td {
            &:last-child:not([class]) {
                width: 7%;
                text-align: right;

                .cor_icon {
                    margin-left: auto;
                }
            }
        }
    }

    &__editButtons {
        display: flex;
        justify-content: flex-end;
    }

    &__cell {
        width: 100%;
        input[type='text'],
        input[type='number']
        {
            &:focus {
                border: 1px solid #00B5FF;
            }
        }
    }

    &__img {
        position: relative;
        // border: 1px solid #a41919;
        width: 4em;
        height: 2.25em;
        // font-size: 1em;

        &_trash {
            position: absolute;
            top: -0.75em;
            right: -0.75em;
            font-size: 0.6em;
            border-radius: 0.375em;

            background: #fff;
            border: 1px solid #bec9d3;

            .dark & {
                background: #16171D;
                border: 1px solid #4a4f5b;
            }
        }

        &_wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 100%;
            height: 100%;
            cursor: pointer;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            canvas {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            input[type='file'] {
                display: none;
            }
        }



        .cor_tooltip {
            .cor_icon {
                font-size: 1.5em;
            }
            .cor_tooltip__content {
                bottom: 3.5em;
            }
            // font-size: 2em;
        }
    }

    .cor_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        cursor: pointer;
        transition: 0.3s;

        i {
            font-size: 1.25em;
        }

        &:hover {
          color: #00B5FF;
        }
    } 
}

.pi-modalCreatingMeasure {
    .cor-net__row {
        gap: 0.5em;
    }
}

.pi__modal_help {
    cursor: pointer;
    font-size: 1.125em;
    margin-left: 0.75em;
    color: #00B5FF;
}