.vrlab {
    display: block;
    width: 100%;
    height: 100%;

    & canvas {
        width: 100% !important;
        height: 100% !important;
    }
}
