@import "../../scss/mixins/mixins";

.cor_profile {
  &__wrap {
    flex-grow: 1;
  }
  display: grid;
  grid-template:
            "p n"
            "p l"
            "p i"
            "p c"
            "p e"
            "z z"
            / 0fr 1fr;
  column-gap: 1em;
  text-align: left;
  color: #383E47;

  .dark & {
    color: #FFFFFF;
  }

  &__name {
    font-size: 1.4em;
    font-weight: 800;
    grid-area: n;
  }

  &__login {
    grid-area: l;
  }

  &__info {
    grid-area: i;
  }

  &__class {
    grid-area: c;
  }

  &__email {
    grid-area: e;

    & a {
      color: $main_color2;
      .dark & {
        color: $lw_blue_color;
      }
    }
  }

  &__edit {
    grid-area: z;
    // margin-top: 0.6em;
    // padding-top: 0.4em;
    // border-top: 3px solid #E6E6E6;
    font-size: 1.25em;
    font-weight: 600;
    color: #666F7E;

    .dark & {
      color: #FFFFFF;
    }

    .cor_icon {
      cursor: pointer;
      :before {
        padding-right: 0.3em;
      }
    }
  }

  &__picture {
    grid-area: p;
    width: 12em;
    height: 12em;
    display: flex;
    align-items: center;
    justify-content: center;

    & .cor_icon {
      font-size: 5em;
      color: #666F7E;

      .dark & {
        color: #ffffff;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 1em;
    }
  }
}

.cor_profile_edit {
  display: grid;
  grid-template:
            "n n n2 n3"
            "l l e g"
            "p a a a"
            "z z z z"
            / 1fr 1fr 2fr 2fr;
  column-gap: 1em;
  text-align: left;
  color: #383E47;

  .dark & {
    color: #FFFFFF;
  }

  & .input {
    display: grid;
    vertical-align: bottom;

    label {
      color: #7C8195;
      padding: 0.7em 0  0.35em 0;

      .dark & {
        color: #FFFFFF;
      }
    }

    input.form_error {
      border-color: $color_danger;
    }

    div.form_error {
      text-align: right;
      padding: 1.35em 0  0.35em 0;
      font-size: 0.7em;
      color: $color_danger;
    }

    :first-child {
      grid-column: 1;
    }

    :nth-child(2) {
      grid-column: 2;
    }

    :nth-child(3) {
      grid-column: 1/3;
    }
  }

  &__name {
    grid-area: n;
  }

  &__name2 {
    grid-area: n2;
  }

  &__name3 {
    grid-area: n3;
  }

  &__login {
    grid-area: l;
  }

  &__info {
    grid-area: i;
  }

  &__class {
    grid-area: c;
  }

  &__city {
    grid-area: g;
  }

  &__email {
    grid-area: e;

    & a {
      color: $main_color2;
    }
  }

  &__save {
    // grid-area: z;
    // margin-top: 0.6em;
    // padding-top: 0.4em;
    // border-top: 3px solid #E6E6E6;
    font-size: 1.25em;
    font-weight: 600;
    color: #666F7E;

    .dark & {
      color: #FFFFFF;
    }

    .cor_icon {
      cursor: pointer;
      padding-right: 2em;

      :before {
        padding-right: 0.3em;
      }
    }
  }

  &__picture {
    grid-area: p;
    margin-top: 1em;
    width: 300px;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 1em;
    }

    i {
      padding: 0.2em 0;
      font-size: 2em;
    }
  }

  &__avatar {
    width: 300px !important;
    height: 300px !important;
    margin-top: 1em;
    overflow: hidden;
    grid-area: a;

    .dark & label {
      color: #FFFFFF !important;
    }
  }
}
