@import "../../rlab/src/scss/mixins/mixin";
@import "../../rlab/src/scss/variables/variables";

.emptyScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 4em;

  &__img {
    width: 18em;
    max-width: 100%;

    @media (min-width: 768px) {
      width: 23.9375em;
    }

    @media (max-width: 767px) and (orientation: 'landscape') {
      margin-top: 2em;
      width: 14em;
    }
  }

  &__text {
    font-size: 1em;
    font-weight: 600;
    line-height: 1.2;
    max-width: 22.13em;

    @media (min-width: 768px) {
      font-size: 1.5em;
    }
  }
}

.scrolledBox {
  background: #f5f8fa;
  border: 1px solid #bec9d3;
  border-radius: 0.125em;
  padding: 0.8125em;
  color: #595c6c;

  .dark & {
    background: #363a45;
    border: 1px solid #4a4f5b;
    color: $color_withe;
  }

  > * {
    font-size: 0.75em;
  }
}

.watch-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.devicepicker {
  list-style: none;
  padding: 0;
  margin: 0;

  max-height: 60vh;
  overflow-y: auto;
  @extend %scrollbar;

  li {
    cursor: pointer;
    font-size: 1.1em;
    padding: 0.5em;

    &:hover {
      background: #3fa9f5;
    }
  }
}

.notification {
  margin-top: 0;
  padding: 0.625em 1.3125em 0.625em 3.625em;
  border-radius: 0.125em;
  box-shadow: none;
  backdrop-filter: blur(0.125em);
  opacity: 1;
  font-size: 1em;

  &:nth-child(n + 2) {
    margin-top: 0.6875em;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 0.2em;
    margin-top: -0.875em;
    display: block;
    font-family: 'Notification';
    width: 1.75em;
    height: 1.75em;
    font-size: 1.75em;
    text-align: center;
    line-height: 1.75em;
  }

  &-container {
    width: 20em;
    padding: 0.6875em 0.625em;
    max-height: calc(100% - 1.875em);
  }

  &-success {
    background: rgba(3, 201, 141, 0.9);
  }

  &-info {
    background: rgba(#2f96b4, 0.9);
  }

  &-warning {
    background: rgba(229, 190, 51, 0.9);
  }

  &-error {
    background: rgba(247, 86, 86, 0.9);
  }

  .title {
    font-size: 1em;
  }

  .message {
    font-size: 0.875em;
  }
}

.legend {
  display: block;
  font-size: 0.75em;
  font-weight: 500;
  margin-bottom: 0.5em;
  color: #7c8195;

  .dark & {
    color: #c7c9d1;
  }
}

// Electron modal

#devicepicker {
  [class*='Modal_root'] {
    display: none;
  }

  &.open {
    [class*='Modal_root'] {
      display: flex;
    }

    ul {
      padding: 0;
      font-size: 0.85em;
    }
  }

  .modal__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1em;
    top: 1em;
    width: 1em;
    height: 1em;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      border-top: 1px solid;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
