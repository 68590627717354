@import '../../scss/variables/variables';
@import '../../scss/mixins/mixin';

.root {
    max-height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
    color: $light_border_color;
    flex-grow: 1;
    padding-bottom: 0.925em;
    padding: 2px;
    // @extend %scrollbar;
    // overflow: hidden;

    :global(.dark) & {
        color: $dark_theme_color;
    }

    [class='ReactVirtualized__Grid__innerScrollContainer'] {
        // max-height: 100%;
        color: #1e1f25;
        font-size: 0.63em;

        :global(.dark) & {
            color: $color_withe;
            background: #363a45;
        }
    }

    [class='ReactVirtualized__Table'] {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        max-height: 100%;
        font-size: 0.65em;
        display: flex;
        flex-direction: column;
        // overflow: hidden overlay !important;
        // @extend %scrollbar;
    }

    [class*='ReactVirtualized__Table__Grid'] {
        // max-width: calc(100% + 14px);
        max-width: 100%;
        max-height: 100%;
        // width: 100% !important;
        padding-right: 0.5em;
        margin-right: -0.5em;
        font-size: 1.539em;

        flex-grow: 1;
        min-height: 0;
        overflow: hidden overlay !important;
        @extend %scrollbar;
    }

    [class='ReactVirtualized__Table__headerRow'] {
        // display: none;
        display: none;
        height: 2.0625em !important;
    }

    // [class='ReactVirtualized__Table__headerColumn'] {
    //     font-weight: 400;
    //     background: #d7e1e8;
    //     color: #444754;
    //     padding: 1em 0.3em;

    //     :global(.dark) & {
    //         background: #16171d;
    //         color: #c7c9d1;
    //     }

    //     &:first-child {
    //         text-align: center;
    //         width: 4em;
    //         flex: none;
    //     }

    //     &:last-child {
    //         width: 8.3em;
    //         padding: 0;
    //         position: relative;
    //         text-align: center;
    //     }
    // }

    [class='ReactVirtualized__Table__row'] {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100% !important;
        // height: 2.0625em !important;

        &:nth-child(even) {
            background: #f0f3f5;

            :global(.dark) & {
                background: #282b33;
                // background: rgba(#fff, 0.2);
            }
        }
    }

    [class='ReactVirtualized__Table__rowColumn'] {
        // display: table-cell;
        flex-basis: auto !important;
        flex-shrink: 0 !important;
        flex-grow: 1 !important;
        padding: 1em 0.2em;
        width: auto !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:first-child {
            flex: none !important;
            text-align: center;
            width: 4.6em !important;
        }

        &:last-child {
            width: 8.3em !important;
            flex: none !important;
            padding: 0;
            position: relative;
            text-align: center;
            align-self: stretch;
        }
    }
}

.tabs {
    margin: 0.5em 0em 0.8em 0;
    display: flex;
    white-space: nowrap;
    // overflow: hidden;
    // overflow-x: overlay;
    flex: none;
    position: relative;
    // overflow-y: hidden;

    // @extend %scrollbar;

    @media (min-width: 768px) {
        margin-right: 0.7em;
    }

    [class^='Dropdown_root'] {
        position: static;

        [class^='Dropdown_label'] {
            text-align: center;
        }

        &:first-child:not(:last-child) {
            border-radius: 0.1875em 0 0 0.1875em;
            [class^='Dropdown_label'] {
                border-radius: 0.1875em 0 0 0.1875em;
            }
        }

        &:nth-child(n + 2):not(:first-child):not(:last-child) {
            border-left: 0;
            border-radius: 0;
            [class^='Dropdown_label'] {
                border-left: 0;
                border-radius: 0;
            }
        }

        &[class*='active'] {
            border-color: #3fa9f5;
            [class^='Dropdown_label'] {
                color: #fff;
                background: #3fa9f5;
            }

            &:hover {
                border-color: #3fa9f5;
            }
        }

        &:last-child:not(:first-child) {
            border-radius: 0 0.1875em 0.1875em 0;
            border-left: 0;
        }

        [class^='Dropdown_drop'] {
            top: 100%;
            width: 100%;
            right: 0.7em;
            min-width: auto;
        }
    }

    &Item {
        margin-right: 0.7em;
        cursor: pointer;
    }
}

.current {
    display: flex;
    align-items: center;
}

.color {
    width: 1.2em;
    height: 1.2em;
    border-radius: 0.1875em;
    margin-right: 0.5625em;
    flex: none;
}

.name {
    font-size: 0.9em;
    line-height: 1.2;
    font-weight: 600;
    color: $light_theme_color;

    :global(.dark) & {
        color: $color_withe;
    }
}

.wrap {
    margin-top: 0.7em;
    min-height: 0;
    max-height: 100%;
    // margin-right: -0.6em;
    flex-grow: 1;
    // padding-right: 0.5em;
    // overflow-y: auto;
    // overflow-x: hidden;
    // @extend %scrollbar;
    display: flex;
    flex-direction: column;
}

.rez {
    color: $light_theme_color;
    border-radius: 0.125em;
    margin-right: 0.7em;
    // justify-content: space-between;
    background: #d7e1e8;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :global(.dark) & {
        background: #16171d;
        color: #c7c9d1;
    }

    span {
        font-size: 0.75em;
        font-weight: 600;
    }

    &Item {
        padding: 0.5625em 1.6em 0.5625em 0.875em;
        display: flex;
        gap: 1.875em;
    }

    [class*='cl_icon_dotts'] {
        cursor: pointer;
        position: absolute;
        right: 0.6em;
        bottom: 0.4em;
        transform: rotate(0);

        &:before {
            // font-size: 0.6em;
        }
    }
    &[class*='open'] {
        [class*='cl_icon_caret'] {
            transform: rotate(180deg);
            bottom: 0.4em;
        }
    }
}

.markers {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 1.5em;
}

.marker {
    width: 0.5em;
    margin-left: 0.9em;
    flex: none;
}

.dtable {
    display: flex;
    flex-direction: column;
    min-height: 0;
    max-height: 100%;
    flex-grow: 1;

    @media (min-width: 768px) {
        padding-right: 0.7em;
    }

    &Row {
        display: flex;
        align-items: center;
    }

    &Col {
        padding: 0.9em 0.7em;

        &:first-child {
            padding-left: 1.1em;
        }

        &:last-child {
            padding-right: 1em;
        }
    }

    &Sort {
        cursor: pointer;

        &:hover {
            color: lighten(#444754, 15%);

            :global(.dark) & {
                color: darken(#c7c9d1, 10%);
            }
        }

        &[class*='sorted'] {
            color: #00b5ff;

            &:hover {
                color: darken(#00b5ff, 10%);
            }
        }
    }

    &Head {
        background: #d7e1e8;
        color: #444754;
        border-radius: 0.3em;
        font-size: 0.6em;

        .dtableCol {
            padding: 1.1em 0.8em;
            display: flex;
            align-items: center;

            i {
                cursor: pointer;
                font-size: 0.7em;
            }

            &:nth-child(2),
            &:nth-child(3) {
                flex-grow: 1;
            }

            &:nth-child(n + 4) {
                padding: 0.3em;
            }

            &:first-child {
                padding-left: 1.4em;
                // padding-left: 0.6em;
            }

            &:last-child {
                padding-right: 1.5em;
            }
        }

        :global(.dark) & {
            background: #16171d;
            color: #c7c9d1;
        }
    }

    &Body {
        flex-grow: 1;
        min-height: 0;
        color: #1e1f25;
        margin-right: -1em;
        padding-right: 0em;
        max-height: 100%;
        overflow: overlay;

        @extend %scrollbar;
    }
}
