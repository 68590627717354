$icomoon-font-family: "cor" !default;
$icomoon-font-path: "../../../assets/fonts" !default;

$cor_icon_alarm: unquote('"\\e900"');
$cor_icon_arrow-next: unquote('"\\e901"');
$cor_icon_arrow-prev: unquote('"\\e902"');
$cor_icon_arrow-round-next: unquote('"\\e903"');
$cor_icon_arrow-round-prev: unquote('"\\e904"');
$cor_icon_attachment: unquote('"\\e905"');
$cor_icon_back: unquote('"\\e906"');
$cor_icon_back-round: unquote('"\\e907"');
$cor_icon_battery: unquote('"\\e908"');
$cor_icon_beaker: unquote('"\\e909"');
$cor_icon_bell: unquote('"\\e90a"');
$cor_icon_biology: unquote('"\\e90b"');
$cor_icon_blob: unquote('"\\e90c"');
$cor_icon_block: unquote('"\\e90d"');
$cor_icon_block-ban: unquote('"\\e90e"');
$cor_icon_board: unquote('"\\e90f"');
$cor_icon_browser: unquote('"\\e910"');
$cor_icon_brush: unquote('"\\e911"');
$cor_icon_bullet: unquote('"\\e912"');
$cor_icon_calculator: unquote('"\\e913"');
$cor_icon_calendar: unquote('"\\e914"');
$cor_icon_caret: unquote('"\\e915"');
$cor_icon_caret-double-down: unquote('"\\e916"');
$cor_icon_chainsaw: unquote('"\\e917"');
$cor_icon_chainsaw_no_border: unquote('"\\e918"');
$cor_icon_change: unquote('"\\e919"');
$cor_icon_change-replace: unquote('"\\e91a"');
$cor_icon_charging: unquote('"\\e91b"');
$cor_icon_chat: unquote('"\\e91c"');
$cor_icon_check: unquote('"\\e91d"');
$cor_icon_checkmark-list: unquote('"\\e91e"');
$cor_icon_chemistry: unquote('"\\e91f"');
$cor_icon_clear_all: unquote('"\\e920"');
$cor_icon_clip: unquote('"\\e921"');
$cor_icon_clock-back: unquote('"\\e922"');
$cor_icon_clock-back2: unquote('"\\e923"');
$cor_icon_close: unquote('"\\e924"');
$cor_icon_compass: unquote('"\\e925"');
$cor_icon_connect: unquote('"\\e926"');
$cor_icon_connect-bluetooth: unquote('"\\e927"');
$cor_icon_connect-usb: unquote('"\\e928"');
$cor_icon_copy-paste: unquote('"\\e929"');
$cor_icon_delete: unquote('"\\e92a"');
$cor_icon_delivery: unquote('"\\e92b"');
$cor_icon_dock: unquote('"\\e92c"');
$cor_icon_dots: unquote('"\\e92d"');
$cor_icon_dots-menu: unquote('"\\e92e"');
$cor_icon_dotts: unquote('"\\e92f"');
$cor_icon_download: unquote('"\\e930"');
$cor_icon_drag: unquote('"\\e931"');
$cor_icon_ecology: unquote('"\\e932"');
$cor_icon_edit: unquote('"\\e933"');
$cor_icon_electricity: unquote('"\\e934"');
$cor_icon_ellipse: unquote('"\\e935"');
$cor_icon_email: unquote('"\\e936"');
$cor_icon_eraser: unquote('"\\e937"');
$cor_icon_error: unquote('"\\e938"');
$cor_icon_eye: unquote('"\\e939"');
$cor_icon_eye-hide: unquote('"\\e93a"');
$cor_icon_eye-no-eyelashes: unquote('"\\e93b"');
$cor_icon_figure: unquote('"\\e93c"');
$cor_icon_figures: unquote('"\\e93d"');
$cor_icon_file: unquote('"\\e93e"');
$cor_icon_file-copy: unquote('"\\e93f"');
$cor_icon_file-excel: unquote('"\\e940"');
$cor_icon_file-folder: unquote('"\\e941"');
$cor_icon_file-pp: unquote('"\\e942"');
$cor_icon_file-refresh: unquote('"\\e943"');
$cor_icon_file-word: unquote('"\\e944"');
$cor_icon_file-xls: unquote('"\\e945"');
$cor_icon_film: unquote('"\\e946"');
$cor_icon_filter: unquote('"\\e947"');
$cor_icon_flash: unquote('"\\e948"');
$cor_icon_flask: unquote('"\\e949"');
$cor_icon_folder: unquote('"\\e94a"');
$cor_icon_full-screen: unquote('"\\e94b"');
$cor_icon_gear: unquote('"\\e94c"');
$cor_icon_geography: unquote('"\\e94d"');
$cor_icon_glass: unquote('"\\e94e"');
$cor_icon_graph: unquote('"\\e94f"');
$cor_icon_graph_sm: unquote('"\\e950"');
$cor_icon_grid: unquote('"\\e951"');
$cor_icon_grid_lg: unquote('"\\e952"');
$cor_icon_grid_md: unquote('"\\e953"');
$cor_icon_grid_sm: unquote('"\\e954"');
$cor_icon_grid_xl: unquote('"\\e955"');
$cor_icon_hand: unquote('"\\e956"');
$cor_icon_info: unquote('"\\e957"');
$cor_icon_integral: unquote('"\\e958"');
$cor_icon_lamp: unquote('"\\e959"');
$cor_icon_lattice: unquote('"\\e95a"');
$cor_icon_lens-left: unquote('"\\e95b"');
$cor_icon_lens-right: unquote('"\\e95c"');
$cor_icon_license: unquote('"\\e95d"');
$cor_icon_lightning: unquote('"\\e95e"');
$cor_icon_lock: unquote('"\\e95f"');
$cor_icon_log-out: unquote('"\\e960"');
$cor_icon_log-out-circle: unquote('"\\e961"');
$cor_icon_mac: unquote('"\\e962"');
$cor_icon_mathematics: unquote('"\\e963"');
$cor_icon_menu: unquote('"\\e964"');
$cor_icon_messages: unquote('"\\e965"');
$cor_icon_microphone: unquote('"\\e966"');
$cor_icon_microphone-mute: unquote('"\\e967"');
$cor_icon_microscope: unquote('"\\e968"');
$cor_icon_minimize: unquote('"\\e969"');
$cor_icon_minus: unquote('"\\e96a"');
$cor_icon_monitor: unquote('"\\e96b"');
$cor_icon_monitor-off: unquote('"\\e96c"');
$cor_icon_moon: unquote('"\\e96d"');
$cor_icon_mouse-signal: unquote('"\\e96e"');
$cor_icon_mute: unquote('"\\e96f"');
$cor_icon_natural: unquote('"\\e970"');
$cor_icon_next: unquote('"\\e971"');
$cor_icon_next-round: unquote('"\\e972"');
$cor_icon_notes: unquote('"\\e973"');
$cor_icon_pause: unquote('"\\e974"');
$cor_icon_pen-ruler: unquote('"\\e975"');
$cor_icon_pen-tool: unquote('"\\e976"');
$cor_icon_pencil: unquote('"\\e977"');
$cor_icon_phisic: unquote('"\\e978"');
$cor_icon_phone: unquote('"\\e979"');
$cor_icon_photocamera: unquote('"\\e97a"');
$cor_icon_picture: unquote('"\\e97b"');
$cor_icon_play: unquote('"\\e97c"');
$cor_icon_play-round: unquote('"\\e97d"');
$cor_icon_play-setting: unquote('"\\e97e"');
$cor_icon_play-trigon: unquote('"\\e97f"');
$cor_icon_plus: unquote('"\\e980"');
$cor_icon_plus_bold: unquote('"\\e981"');
$cor_icon_prev: unquote('"\\e982"');
$cor_icon_print: unquote('"\\e983"');
$cor_icon_protractor: unquote('"\\e984"');
$cor_icon_question: unquote('"\\e985"');
$cor_icon_question-circle: unquote('"\\e986"');
$cor_icon_rectangle: unquote('"\\e987"');
$cor_icon_reload: unquote('"\\e988"');
$cor_icon_robot: unquote('"\\e989"');
$cor_icon_rocket: unquote('"\\e98a"');
$cor_icon_ruler: unquote('"\\e98b"');
$cor_icon_save: unquote('"\\e98c"');
$cor_icon_school: unquote('"\\e98d"');
$cor_icon_screwdriver: unquote('"\\e98e"');
$cor_icon_script: unquote('"\\e98f"');
$cor_icon_script-review: unquote('"\\e990"');
$cor_icon_script-templates: unquote('"\\e991"');
$cor_icon_search: unquote('"\\e992"');
$cor_icon_search-earth: unquote('"\\e993"');
$cor_icon_send: unquote('"\\e994"');
$cor_icon_share: unquote('"\\e995"');
$cor_icon_share-slim: unquote('"\\e996"');
$cor_icon_share_pen: unquote('"\\e997"');
$cor_icon_show-less: unquote('"\\e998"');
$cor_icon_show-more: unquote('"\\e999"');
$cor_icon_silence: unquote('"\\e99a"');
$cor_icon_sinusoid: unquote('"\\e99b"');
$cor_icon_smileys: unquote('"\\e99c"');
$cor_icon_snowflake: unquote('"\\e99d"');
$cor_icon_song: unquote('"\\e99e"');
$cor_icon_sort: unquote('"\\e99f"');
$cor_icon_sort-asc: unquote('"\\e9a0"');
$cor_icon_sort-desc: unquote('"\\e9a1"');
$cor_icon_sort-text: unquote('"\\e9a2"');
$cor_icon_sort-text-desc: unquote('"\\e9a3"');
$cor_icon_speedo: unquote('"\\e9a4"');
$cor_icon_speedo-2: unquote('"\\e9a5"');
$cor_icon_square: unquote('"\\e9a6"');
$cor_icon_stop: unquote('"\\e9a7"');
$cor_icon_student: unquote('"\\e9a8"');
$cor_icon_student-round: unquote('"\\e9a9"');
$cor_icon_success: unquote('"\\e9aa"');
$cor_icon_sun: unquote('"\\e9ab"');
$cor_icon_table: unquote('"\\e9ac"');
$cor_icon_table_edit: unquote('"\\e9ad"');
$cor_icon_task: unquote('"\\e9ae"');
$cor_icon_tassel: unquote('"\\e9af"');
$cor_icon_teacher: unquote('"\\e9b0"');
$cor_icon_text: unquote('"\\e9b1"');
$cor_icon_thermometer: unquote('"\\e9b2"');
$cor_icon_tile: unquote('"\\e9b3"');
$cor_icon_transmission: unquote('"\\e9b4"');
$cor_icon_trash: unquote('"\\e9b5"');
$cor_icon_triangle: unquote('"\\e9b6"');
$cor_icon_union: unquote('"\\e9b7"');
$cor_icon_usb: unquote('"\\e9b8"');
$cor_icon_user: unquote('"\\e9b9"');
$cor_icon_user-cards: unquote('"\\e9ba"');
$cor_icon_user-check: unquote('"\\e9bb"');
$cor_icon_user-group: unquote('"\\e9bc"');
$cor_icon_user-place: unquote('"\\e9bd"');
$cor_icon_user-select: unquote('"\\e9be"');
$cor_icon_users-profile: unquote('"\\e9bf"');
$cor_icon_video: unquote('"\\e9c0"');
$cor_icon_video-off: unquote('"\\e9c1"');
$cor_icon_voltage: unquote('"\\e9c2"');
$cor_icon_volume: unquote('"\\e9c3"');
$cor_icon_warning: unquote('"\\e9c4"');
$cor_icon_wifi: unquote('"\\e9c5"');
$cor_icon_window: unquote('"\\e9c6"');
$cor_icon_wireless: unquote('"\\e9c7"');

