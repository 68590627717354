.cor_fullscreen {
    color: #fff;
    width: 1.75em;
    height: 1.75em;
    border: 0.0625em solid #6F92B9;
    border-radius: 0.125em;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    outline: 1px solid transparent;
    transition: outline 0.2s;
    cursor: pointer;

    .dark & {
        border: 1px solid #343640;
    }

    &:hover {
        outline: 1px solid #ffffff;
        outline-offset: -1px;
    }

    i {
        font-size: 1em;
    }

    &.active {
        background: #00B5FF;
    }

}
