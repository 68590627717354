.panel {
    background: #ffffff;
    box-shadow: 0px 0.25em 1.875em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    margin-left: 0.5em;
    width: 3.75em;
    overflow: hidden;
    flex: none;
    transition: width 0.4s;

    :global(.dark) & {
        background: #282b33;
        box-shadow: none;
    }
}

.visible {
    composes: panel;
    width: 16em;

    :global(.dark) & {
        background: transparent;
    }
}
.hide {
    display: none;
}

.head {
    display: flex;
    align-items: center;
    gap: 0.625em;
    // justify-content: space-between;
    flex: none;
    padding: 0.75em 0.9375em;
    flex-direction: column;

    .visible & {
        flex-direction: row;
        border-bottom: 1px solid #e8e8e8;

        :global(.dark) & {
            border-bottom: 1px solid transparent;
            padding: 0 0.9375em 0.2em;
        }
    }
}
.title {
    font-weight: 600;
    font-size: 1.125em;
    writing-mode: vertical-rl;
    // transform: rotate(180deg);

    .visible & {
        writing-mode: inherit;
        transform: rotate(0deg);
    }

    :global(.dark) & {
        color: #fff;
    }
}
.toggle {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.625em;
    height: 1.625em;
    border-radius: 50%;
    color: #bec9d3;
    background: #f5f8fa;
    border: 1px solid;
    transform: rotate(0deg);
    cursor: pointer;

    :global(.dark) & {
        background: transparent;
        border: 1px solid #343640;
        color: #c7c9d1;
    }

    .show & {
        transform: rotate(180deg);
    }
}

.icon {
    font-size: 0.7em;
    &:before {
        display: block;
    }
}

.body {
    padding: 0.625em;
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
}
