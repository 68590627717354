.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    min-height: 0;
}

.img {
    width: 23.9375em;
    height: auto;
}

.text {
    width: 25em;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.4;
    color: #7c8195;
    text-align: center;

    :global(.dark) & {
        color: #ffffff;
        opacity: 0.7;
    }
}
