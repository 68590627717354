.root {
    gap: 0.9375em;
    margin-top: 1em;
}

.item {
    display: flex;
    flex-direction: column;
    width: 26em;
    border-radius: 0.375em;


    background: #FFFFFF;
    border: 1px solid;
    border-color: #E8E8E8;

    :global(.dark) & {
        background: #363A45;
        border-color: #484B5A;
    }
}

.item_blocked {
    composes: item;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.375em;

      opacity: 0.6;
      background: #fff;
      z-index: 1;
      
        :global(.dark) & {
            background: #363A45;
        }
    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75em 1em;

    border-top-left-radius: 0.375em;
    border-top-right-radius: 0.375em;
    margin: -1px -1px 0;

    background: #EFF6F9;

    :global(.dark) & {
        background: #16171D;
    }
}

.name {
    display: inline-block;
    width: 80%;
    font-size: 1em;
    line-height: 1.1875;

}

.link {
    composes: name;
    cursor: pointer;
}

.action {
    position: relative;
    margin-left: 2em;
   

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        margin-top: 0.25em;

        &:first-child {
            margin-top: 0;
        }

        i,input {
            font-size: 1.125em;
        }
    }

    &__snowflake {
        position: absolute!important;
        top: 50%;
        transform: translateY(-50%);
        left: -130%;
        z-index: 5;
    }

    i {
        color: #666F7E;
        cursor: pointer;
        transition: color 0.3s;
    
        :global(.dark) & {
            color: #d1d1d1;
        }
    
        &:hover {
          color: #00B5FF;
        }

    }
}

.main {
    padding: 1em;
    flex-grow: 1;
}

.content {
    display: flex;
    gap: 1em;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.role {
    font-size: 0.875em;
    font-weight: 600;
}

.picture {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 2.75em;
    height: 2.75em;
    border-radius: 100%;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    span {
        font-size: 2.375em;
    }
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        display: flex;
        gap: 0.5em;
        margin-top: 0.375em;

        &:first-child {
            margin-top: 0;
        }

        span {
            line-height: 1.2;
            font-size: 0.875em;

            &:first-child {
                color: #7C8195;
                min-width: 4.5em;

                :global(.dark) & {
                    color: #A2A2A5;
                }
            }
        }

    }
}


.bottom {
    padding: 1em;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-top: 1px solid;
    border-color: #E8E8E8;

    :global(.dark) & {
        border-color: #484B5A;
    }


    &__link {
        position: relative;
        z-index: 5;
        font-size: 0.875em;
        color: #666F7E;
        cursor: pointer;
        transition: color 0.3s;
    
        :global(.dark) & {
            color: #d1d1d1;
        }
    
        &:hover {
          color: #00B5FF;
        }
      
    }
}

.forever {
    composes: bottom__link;

    &:hover {
        color: #f75656;
    }

}

.modal {
    &__text {
        line-height: 1.2;
    }

    &__column {
        flex-direction: column;
        button {
            width: 100%;
        }
    }
}