@import "../../../scss/mixins/mixins";


.cor_content {
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    max-height: 100%;
    min-height: 0;
    border-radius: 0.375em;

    margin-top: 1.25em;
    margin-bottom: 0.75em;

    background: #FFFFFF;
    box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);

    .whiteboard {
        position: relative;
        overflow: hidden;

        flex-grow: 1;
        max-height: 100%;
        min-height: 0;
        
        padding: 0.3em;
        border-width: 0;
        border-radius: 0.375em;

        box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
    }

    .tldrawboard {
        border: none;
        padding: 20px;
        flex-grow: 1;
    }

    .dark & {
        background: #282B33;
    }

    &.hide_bg {
        background: transparent;
        box-shadow: none;
    }

    &__wrap {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin: 0 1.875em;
        min-width: 0;
        min-height: 0;
        max-height: 100%;

        word-wrap: break-word;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.75em;

        margin-top: 1.25em;

        &.no-flex {
            display: block;
        }

        &.fd-column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &.сalculation_models_head {
            > * {
                width: 33.3%;
                text-align: center;

                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    &__title {
        font-size: 1.125em;
        font-weight: 600;
    }

    &__desc {
        color: #858C98;
        font-size: 0.8125em;

        &_wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.75em;
        }

    }

    &__status {
        display: flex;
        align-items: center;
        color: #C96203;

        .cor_icon {
            font-size: 1.25em;
            font-weight: bold;
            margin-right: 0.5em;
        }
    }

    &__header {
        padding: 0.75em 1.25em;
        gap: 0.75em;
        // margin-bottom: 1.5em;

        border-bottom: 1px solid #E8E8E8;
        
        .dark & {
            border-bottom: 1px solid #A2A2A5;
        }

        .tabs__item {
            margin-bottom: 0;
        }

        .lr-p0 & {
            padding-left: 0;
            padding-right: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &_title {
            font-weight: 600;
            color: #1E1F25;

            .dark & {
                color: $lw_dark_theme_color;
            }
        }

        &_description {
            font-weight: 600;
            color: lighten(#1E1F25, 30%);

            .dark & {
                color: darken($lw_dark_theme_color, 20%);
            }
        }

        .filter {
            user-select: none;
        }
    }

    &__subheader {
        padding: 0.75em 1.25em;

        .lr-p0 & {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__section {
        // padding: 1.25em;
        border-radius: 0.375em;

        margin-top: 1.25em;
        margin-bottom: 0.75em;
    
        background: #FFFFFF;
        box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);

        .dark & {
            background: #282B33;
        }

        &.hide_bg {
            background: transparent;
            box-shadow: none;
        }

        &.flex {
            display: flex;
        }

        &.mb_0 {
            margin-bottom: 0;
        }
    
    }

    &__row {
        display: flex;
        gap: 1.25em;
        flex-grow: 1;
        max-height: 100%;
        min-height: 0;
    }

    &__col {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-width: 0;
        min-height: 0;
        max-height: 100%;

        &.w73 {
            width: 73%;
        }
        &.org {
            width: 27%;
        }
    }

    &__body {
        padding: 1.25em 1.25em 0;
        flex-grow: 1;
        min-height: 0;

        display: flex;
        flex-direction: column;

        &.p_0 {
            padding: 0;
        }

        &.pb_1 {
            padding-bottom: 1.25em;
        }

        .lr-p0 & {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__scrolled {
        max-height: 100%;
        padding-bottom: 1.4em;
        padding-right: 1.25em;
        height: 100%;
        @include scrollbar;
    }

    &__overflow {
        max-height: 100%;
        height: 100%;
        // overflow: hidden;
        border: 1px solid #000;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: #FFFFFF;
        border-top: 1px solid #E8E8E8;
        padding: 0.75em 1.25em;

        .dark & {
            // background: #16171D;
            border-top: 1px solid #A2A2A5;
        }

        &.gap {
            gap: 0.625em;
        }
        
        &.jc-start {
            justify-content: flex-start;
        }

        &.jc-end {
            justify-content: flex-end;
        }

        &.jc-center {
            justify-content: center;
        }


        .lr-p0 & {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // &__wrap {}

    // &__wrap {}

    // &__wrap {}
}
