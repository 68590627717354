$grid_gap: 0.75em;

@mixin netCol($size: 1, $gap: $grid_gap) {
    width: calc(100% / #{$size} - #{$gap} + #{$gap} / #{$size});
}

.cor-net {
    // border: 1px solid #000;

    // &.has_pi_panel {
    //     position: relative;
    //     padding-right: 4em;
    //     // width: calc(100% - 2em);
    // }

    &.grow {
        flex-grow: 1;
        min-width: 1px;
    }

    &__title {
        color: #1E1F25;
        font-size: 1.0625em;
        font-weight: 600;
        margin-bottom: 0.88234em;

        .dark & {
            color: #BEC9D3;
        }

        &.lg {
            font-size: 1.5em;
        }

        &.mb_0 {
            margin-bottom: 0;
        }
    }

    &__subtitle {
        color: #1E1F25;
        font-size: 0.875em;
        font-weight: 600;

        .dark & {
            color: #BEC9D3;
        }
    }

    &__section {
        margin-top: 1.25em;

        &.mt_2 {
            margin-top: 2em;
        }

        .mt_lg & {
            margin-top: 1.5em;
        }

        &:first-child {
            margin-top: 0;
        }

        &.flex {
            display: flex;
            gap: 1em;
        }

        &.border {
            padding: 1.25em;
            border: 1px solid #CBD4DC;
            border-radius: 0.375em;
            background: lighten(#F5F8FA, 2%);  
            transition: 0.3s;
        
            .dark & {
                border: 1px solid #484B5A;
                background: darken(#363A45, 10%);
            }

            &:hover:not(.view &) {
                border: 1px solid #00B5FF;
            }
        }
    }


    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: $grid_gap;
        margin-top: 0.75em;

        flex: auto;

        .mt_lg & {
            margin-top: 1.25em;
        }

        &.ai-start {
            align-items: flex-start;
        }

        &:first-child {
            margin-top: 0;
        }

        &.mt_0 {
            margin-top: 0;
        }

        &.disabled {
            position: relative; 
            opacity: 0.6;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                z-index: 1;
            }
        }

        &.row-content {
            font-size: 1em;
            line-height: 1.1;
            color: #595C6C;
            font-weight: 500;

            .dark & {
                color: #d1d1d1;
            }

        }

    }

    &__col {
        .btn,
        .cor_btn,
        .react-datepicker-wrapper,
        iframe,
        button,
        input:not([type]),
        input[type='date'],
        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='search'],
        input[type='tel'],
        input[type='text'],
        input[type='url'],
        textarea {
            width: 100%;
        }

        .react-datepicker__navigation {
            width: 32px;
        }

        textarea {
            resize: vertical;
            max-width: 100%;
            min-height: 3.75em;

            &[class*='nb__taResizeDisable'] {
                resize: none;
            }
        }

        iframe {
            max-width: 100%!important;
        }

        .cor_tooltip {
            width: fit-content;
        }

        &.col-1 {
            @include netCol(1);
        }
        &.col-2 {
            @include netCol(2);
        }
        &.col-3 {
            @include netCol(3);
        }
        &.col-4 {
            @include netCol(4);
        }
        &.col-5 {
            @include netCol(5);
        }
        &.col-6 {
            @include netCol(6);
        }
        &.col-7 {
            @include netCol(7);
        }
        &.col-8 {
            @include netCol(8);
        }
        &.col-9 {
            @include netCol(9);
        }
        &.col-10 {
            @include netCol(10);
        }
        &.col-11 {
            @include netCol(11);
        }
        &.col-12 {
            @include netCol(12);
        }
        &.col-grow {
            width: auto;
            flex-grow: 1;
            min-width: 1px;
        }

        &.col-flex {
            display: flex;
            align-items: center;
            gap: 0.625em; 

            .cor-net__label {
                margin-bottom: 0;
                min-width: fit-content;
            }

            > :not(.cor-net__label,.cor_icon,.cor_tooltip)  {
                width: 100%;
            }
        }

        &.col-icon {
            position: relative;

            input {
                padding-right: 2.5em;
            }

            .cor_icon {
                position: absolute;
                top: 0.475em;
                right: 0.875em;
                color: #595c6c;
        
                .dark & {
                    color: #ceced6;
                }
            }
        }

        .tabs__item {
            margin-bottom: 0;
            flex-grow: 1;
            justify-content: center;
            padding: 0.627em 1em;
        }

        .cor_btn {
            font-size: 0.75em;
            padding: 0.542em;
        }

        &.mark {
            color: #7C8195;
            font-weight: 500;
            .dark & {
                color: #C7C9D1;
            }
        }
        &.info {
            color: #595C6C;
            font-weight: 500;
            .dark & {
                color: #ffffff;
            }
        }

        .cor-net__row {
            margin-top: 0;
        }
    }

    .modal {
        .cor_btn {
            font-size: 0.9375em;
            // padding: inherit;
        }
    }

    &__label {
        color: #7C8195;
        font-size: 0.75em;
        font-weight: 500;
        margin-bottom: 0.5em;

        .dark & {
            color: #ffffff;
        }

        &.hide {
            opacity: 0;
            height: 1em;
        }

        &.mb_0 {
            margin-bottom: 0;
        }

        &.flex {
            display: flex;
            align-items: center;
            gap: 1em;
        }
        
        &.jc-between {
            justify-content: space-between;
        }

        .form_error {
            color: #ff1a1a;
        }


    }

    &__lenght, &__length {
        position: relative;

        input,textarea {
            padding-right: 4.5em;
        }

        span {
            position: absolute;
            top: 0.8em;
            right: 0.5em;
            font-size: 0.75em;
            color: #7c8195;
            .dark & {
                color: lighten(#7c8195, 10%);
            }
        }
    }

    // &__checkbox {
    //     display: flex;
    //     align-items: center;

    //     label {
    //         color: #08203A;
    //         font-size: 0.75em;
    //         font-weight: 500;
    //         margin-left: 0.5em;
    //         cursor: pointer;

    //         .dark & {
    //             color: #fff;
    //         }
    //     }
    // }

    &__checkbox {
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: 0.875em;
        color: #595C6C;
        cursor: pointer;
        width: fit-content;

        .dark & {
            color: #d1d1d1;
        }
    }

    &__reload {
        display: flex;
        align-items: center;
        gap: 0.25em;
        color: #00B5FF;
        font-weight: 500;
        cursor: pointer;

        span:not(.cor_icon) {
            text-decoration: underline;
            font-size: 0.875em;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0.25em;
        // padding: 0.5em 0;
    }

    &__options {
        margin-left: 0.25em;
        .cor_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.25em;
            height: 1.25em;
    
            color: #858C98;
            transition: 0.3s;
            padding: 0.25em;
            cursor: pointer;
    
            .dark & {
                color: #d1d1d1;
            }
    
            &:hover {
              color: #00b5ff;
            }
        }
    }

    &__uploadbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 8.75em;

        padding: 1em ;

        border-radius: 0.2344em;
        border: 1px solid;
        border-color: #BEC9D3;
        background: #F5F8FA;

        .dark & {
            background: #363a45;
            border-color: #4a4f5b;
        }



        .cor_icon {
            font-size: 3.75em;
            color: #BEC9D3;

            // .dark & {
            //     color: rgba(#ffffff, 0.6);
            // }
        }

        span:not(.cor_icon) {
            font-size: 0.75em;
            font-weight: 500;
            margin-top: 1em;

            color: #7C8195;
            
            .dark & {
                color: #ffffff;
            }
        }


    }


    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        font-size: 1em;
        color: #595C6C;
        font-weight: 500;
        line-height: 1.4;

        .dark & {
            color: #d1d1d1;
        }

        li {
            display: flex;

            gap: 1em;

            margin-top: 1.15em;

            &:first-child {
                margin-top: 0;
            }

            .cor_icon {
                line-height: 1.1;
                transition: 0.3s;
                font-size: 1.125em;
                cursor: pointer;

                &:hover {
                  color: #00B5FF;
                }
                
            }
        }

        span {
            flex: none;
        }

        b {
            font-weight: 500;
            color: #1E1F25;

            .dark & {
                color: rgba(#d1d1d1, 0.8);
            }
        }

        a {
            display: inline-flex;
            gap: 0.5em;
            text-decoration: none;
            font-weight: 500;
            color: #00B5FF;
        }


    }

    &__add {
        margin-top: 2em;
    }

    &__hover {
        cursor: pointer;
        transition: color 0.3s;
        &:hover {
          color:#00B5FF;
        }
    }

}
