.root {
}

.list {
    list-style: none;
    padding: 0;
    margin: 1.6em 0;
    line-height: 1.3;
    cursor: pointer;
}

.item {
    margin-bottom: 0.3em;
    cursor: default;
    // font-size: 1em;

    [class*='Checkbox_root'] {
        display: inline-flex;
        cursor: pointer;
    }
}

.footer {
    flex-wrap: wrap;

    [class*='Button_root'] {
        width: auto;
    }
}

.learn {
    [class*='Checkbox_root'] {
        > span {
            // font-size: 0.9em;
        }
    }
}

.video {
    font-size: 0.8em;
    line-height: 1.3;
}
