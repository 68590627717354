$icomoon-font-family: 'robiclab-neuro' !default;
$icomoon-font-path: 'fonts' !default;

$icon_double-chart: '\e900';
$icon_turn-on: '\e901';
$icon_sort-desc: '\e902';
$icon_sort-asc: '\e903';
$icon_caret: '\e904';
$icon_check: '\e905';
$icon_grid: '\e906';
$icon_smartphone: '\e907';
$icon_file: '\e908';
$icon_camera: '\e909';
$icon_toggler: '\e90a';
$icon_full-screen: '\e90b';
$icon_gear: '\e90c';
$icon_minus: '\e90d';
$icon_plus: '\e90e';
$icon_pause: '\e90f';
$icon_play: '\e910';
$icon_stop: '\e911';
$icon_marker-plus: '\e912';
$icon_marker-minus: '\e913';
$icon_bluetooth: '\e914';
$icon_usb: '\e915';
$icon_remote-control: '\e916';
$icon_speedometer: '\e917';
$icon_pulse: '\e918';
$icon_dotts: '\e919';
$icon_update: '\e91a';
$icon_timer: '\e91b';
$icon_range-plus: '\e91c';
$icon_range-minus: '\e91d';
$icon_cursor-mode: '\e91e';
$icon_trash: '\e91f';
$icon_export: '\e920';
$icon_import: '\e921';
$icon_printer: '\e922';
$icon_record: '\e923';
$icon_xls: '\e924';
$icon_csv: '\e925';
$icon_log: '\e926';
$icon_sensors: '\e927';
$icon_bullet-list: '\e928';
$icon_sun: '\e929';
$icon_moon: '\e92a';
$icon_question: '\e92b';
$icon_close: '\e92c';
$icon_capture: '\e92d';
$icon_drag: '\e92e';
$icon_table-mob: '\e92f';
$icon_chart-mob: '\e930';
$icon_change: '\e931';
$icon_zoom: '\e932';
$icon_arrow-sort: '\e933';
$icon_battery-full: '\e934';
$icon_battery-middle: '\e935';
$icon_battery-min: '\e936';
$icon_battery-charge: '\e937';
$icon_speed-dashboard: '\e938';
$icon_temperature: '\e939';
$icon_settings: '\e93a';
