.new_modals {
  position: relative;

  &__title {
    margin: 0 0 0.5em;
    font-size: 1.125em;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.25em;
  }

  &__section {
  }

  &__section_table {
    display: flex;
    flex-direction: column;
  }
  &__section_table_header {
    display: grid;
    align-items: center;
    grid-template-columns: 40% 30% 30%;
    background-color: #d7e1e8;
    color: #444754;
    padding: 0.625em 1.25em;
    border-radius: 0.2em;

    .dark & {
      background-color: #1e1f25;

      color: #fff;
    }
  }
  &__section_table_column {
    display: flex;
    align-items: center;
    font-size: 0.625em;
  }
  &__section_table_column_img {
    width: 1.8em;
    height: 1.8em;
    margin-right: 0.5em;
    border-radius: 50%;
    background-color: #d7e1e8;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__section_table_column_name {
    max-width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__section_table_items {
  }
  &__section_table_item {
    padding: 0.625em 0.375em;
    border-radius: 0.2em;

    display: grid;
    align-items: center;
    grid-template-columns: 40% 30% 30%;

    &:nth-child(even) {
      background-color: #f0f3f5;

      .dark & {
        background-color: #212228;
        color: #fff;
      }
    }
  }
  &__section_inputs {
    display: flex;
    flex-direction: column;
    gap: 0.625em;
  }

  &__section_title {
    font-size: 0.75em;
    color: #7c8195;
    margin-bottom: 0.5em;
  }

  &__section_input {
    width: 100%;
    background-color: #f5f8fa;
    border: 1px solid #bec9d3;
    font-family: "Inter";
    color: #595c6c;
    padding: 0.542em 1em;
    outline: none;
    font-size: 0.75em;
    font-weight: 400;
    border-radius: 0.3125em;
    transition: 0.3s;

    &.users {
      width: fit-content;
    }

    &.disabled {
      opacity: 0.6;
    }
  }

  &__section_colors {
    display: flex;
    gap: 0.5em;
  }
  &__section_button {
    font-size: 0.75em;
    color: #00b5ff;
    cursor: pointer;
    position: relative;
    padding-left: 1.5em;
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 0.83333333em;
      height: 0.1666em;
      background-color: #00b5ff;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 0.83333333em;
      height: 0.1666em;
      transform: rotate(90deg);
      background-color: #00b5ff;
    }

    &.cancel {
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(135deg);
      }
    }
  }

  &__section_menu {
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0.5em;
    border: 1px solid #bec9d3;
    background: #fdfdfd;
    box-shadow: 0px 9px 15px 0px rgba(52, 61, 70, 0.09);
    list-style: none;
    margin: 0;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  &__section_menu_item {
    color: #595c6c;
    // font-size: 0.75em;
    font-weight: 400;
    cursor: pointer;
    padding: 0.3125em;

    &:hover {
      background-color: #f5f8fa;
    }
  }

  &__section_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625em;

    &.users {
      justify-content: start;
    }
  }

  &__button {
    padding: 0.75em 1em;
    border: 1px solid #00b5ff;
    border-radius: 0.5em;
    font-size: 0.75em;
    cursor: pointer;
    width: 100%;
    text-align: center;

    &.users {
      width: 40%;
    }

    &.add {
      color: #fff;
      background: #00b5ff;
    }

    &.cancel {
      color: #00b5ff;
    }
  }
}
