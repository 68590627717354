@import '../../scss/mixins/mixin';

.chartComment {
    position: relative;
    &__drop {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 2px;
        background: #f5f8fa;
        border: 1px solid #d7e1e8;
        width: 13em;
        z-index: 10;

        .dark & {
            background: #363a45;
            border: 1px solid #4a4f5b;
        }
    }
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.375em 0.5625em;

        .cl_icon_comment {
            border: 0 !important;
            width: auto;
            height: auto;
            padding: 0;
            margin-left: -0.2em;

            .dark & {
                color: #c7c9d1;
            }
        }

        span {
            font-size: 0.75em;
        }
        .cl_icon_close {
            border: 0 !important;
            width: auto;
            height: auto;
            padding: 0;
            font-size: 0.7em;
        }
    }
    &__title {
        display: flex;
        align-items: center;
    }
    &__body {
        border-top: 1px solid #d7e1e8;
        border-bottom: 1px solid #d7e1e8;
        padding: 0.375em 0.5625em;
        max-height: 20em;
        overflow-y: overlay;
        @extend %scrollbar;

        .dark & {
            border-top: 1px solid #4a4f5b;
            border-bottom: 1px solid #4a4f5b;
        }
    }
    &__item {
        padding: 0.15em 0em 0.15em 0.15em;
        margin-right: -0.2em;
        display: flex;

        &:nth-child(n + 2) {
            border-top: 1px solid rgba(#000, 0.05);
            margin-top: 0.3em;
            padding-top: 0.6em;

            .dark & {
                border-top: 1px solid rgba(#fff, 0.05);
            }
        }
    }
    &__content {
        flex-grow: 1;
        min-width: 0;
    }
    &__edit {
        flex: none;
        margin-right: -0.3em;

        [class^='cl_icon_'] {
            border: 0 !important;
            width: auto;
            height: auto;
            padding: 0;
            font-size: 1em;
            margin-bottom: 0.3em;
        }
    }
    &__top {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        margin-bottom: 0.3em;
    }
    &__date {
        font-size: 0.625em;
        color: #8c8f9b;
    }
    &__text {
        font-size: 0.75em;
        word-break: break-all;
        padding-right: 1em;
        line-height: 1.4;
        // display: flex;

        textarea {
            flex-grow: 1;
            width: 100%;
            height: 100%;
            padding: 0;
            // border: 0;
            font-size: 1em;
        }
    }
    &__field {
        flex-grow: 1;
        min-width: 0;
        margin-right: -1px;

        input {
            border: 0 !important;
            border-radius: 0 !important;
            padding-left: 0.7em !important;
            padding-right: 0.1em !important;
            width: 100%;
        }
    }
    &__file {
        cursor: pointer;
        .cl_icon_picture {
            border: 0 !important;
        }
        input {
            display: none;
        }
    }
    &__foot {
        display: flex;

        .cl_icon_enter {
            background: #00b5ff;
            color: #fff;
            height: auto;
            border: 0 !important;
            border-radius: 0 !important;
        }
    }

    &__img {
        position: relative;
        img {
            display: block;
            object-fit: contain;
            margin-bottom: 0.3em;
            width: 100%;
            height: 100%;
        }
        .cl_icon_close {
            cursor: pointer;
            border: 0 !important;
            font-size: 0.7em;
            color: #000;
            position: absolute;
            right: 0.7em;
            top: 0.7em;
        }
    }
    &__loaded {
        display: flex;
        align-items: center;
        gap: 0.3125em;
        padding: 0.375em 0.5625em;
        background: rgba(#4a4f5b, 0.5);

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.1;
            font-size: 0.8em;
            min-width: 0;
        }

        .cl_icon_close {
            border: 0 !important;
            font-size: 0.6em;
        }
    }
    &__cover {
        width: 1.75em;
        height: 1.125em;
        border-radius: 2px;

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__fullcover {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow: auto;
        margin: 0;

        img {
            display: block;
            margin: auto;
            // object-fit: contain;
            // object-position: center;
            // width: 100%;
            // height: 100%;
            // max-width: 90vw;
            // max-height: 900vh;
            flex: none;
            min-height: 0;
            min-width: 0;
        }
    }
}
