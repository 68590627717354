.root {
 
    [class*="Modal_dialog"] {
        max-width: 58em;
    }
    
    .head {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .filter {
        position: relative;
        width: 50%;
    
        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.75em;
        }
    
        input {
            padding-right: 3em;
        }
    }
}




.scrolled {
    height: 50vh;
    overflow-y: auto;
    padding-right: 1em;

    @media (min-width: 768px) {
        scrollbar-gutter: stable;

        &::-webkit-scrollbar {
            width: 0.3em;
            height: 0.3em;

            &-track {
            }

            &-button {
                opacity: 0;

                &:single-button {
                    width: 0.1em;
                    height: 0.1em;
                }
            }

            &-thumb {
                background: #bec9d3;
                border-radius: 1em;
                border: 0 solid transparent;
                background-clip: border-box;
                min-height: 5em;

                :global(.dark) & {
                    background: #464f57;
                }

                .dark & {
                    background: #464f57;
                }
            }
        }
    }

}