@import "../../scss/mixins/mixins";
@import "../ui/Icon/Icon";
@import "../../scss/variables/variables";

.nb {  
    &__wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__head {
        margin-top: 0;
    }

    // start nb__header
    &__header {
        .cor-warning {
            margin-top: 1em;
        }
    }

    &__flexColumn {
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: center;
	}

    &__title {

        button {
            display: inline-flex;
            align-items: center;
            gap: 0.5em;

            padding: 0;
            border: 0;

            background: transparent;
            color: #110f12;
            
            font-size: 1em;
            font-weight: 600;

            line-height: 1;
            cursor: pointer;
            
            :global(.dark) & {
                color: #d1d1d1;
            }

            [class^='cor_icon'] {
                display: inline-block;
                transform: translateY(-0.05em);
                transition: 0.3s;
            }
            
            &:hover {
                [class^='cor_icon'] {
                    color: #00B5FF;
                }
            }
        }
 

        p {
            display: inline-block;
            line-height: 1;
            font-size: 0.666667em;
            padding: 0.4587em 0.475em;
            border-radius: 0.3333334em;
            font-weight: 400;
            margin: 0;

            color: #5D5E66;
            background-color: #DEE3E8;

            b {
                color: #1E1F25;
                font-weight: 500;
            }
        }
    }

    &__description {
        color: #858C98;
        font-size: 0.8125em;
        margin-top: 0.75em;
    }

    &__filter {
        border-bottom: 1px solid #CECECE;
        padding: 0.9375em 2.75em 0.9375em 1.4em;
        
        :global(.dark) & {
            border-bottom: 1px solid #343640;
        }
    }

    // end nb__header

    // start nb__body
    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        width: 100%;
        height: 100%;
        margin-top: 1.5em;

        // &.mt-0 {
        //     margin-top: 0;
        // }
    }

 

  
    &__taResizeDisable {
        resize: none;  
    }

    &__action {
        display: flex;
        align-items: center;
        gap: 1.25em;
        margin-top: 1.875em;

        &_btnIcon {
            display: flex;
            align-items: center;
            gap: 0.375em;
    
            span {
                line-height: 1;
                padding: 0.1045em 0;
            }
        }
    }
    // end nb__body
    
    // start nb__aside
    &__aside {
        margin: 1.25em 1em 1.25em 0;
        background-color: #ffffff;
        border-radius: 0.375em;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
        width: 15.125em;

        :global(.dark) & {
            background-color: #282B33;

        }

        .nb__asideBox {
            padding: 0.625em 0.9375em;
            
            &:first-child {
                padding: 0.9375em;
                border-bottom: 1px solid #BEC9D3;
            }

            .tabs__item {
                margin-bottom: 0;
                width: 50%;
                justify-content: center;
            }

            &_grow {
                flex-grow: 1;
                height: 100%;
                overflow: hidden;
            }
        }
    }
    // end nb__aside

    // start nb__footer
    &__footer {
        margin-top: 1.875em;
        padding: 1.875em 0;

        border-top: 1px solid #BEC9D3;

        &_row {
            display: flex;
            justify-content: space-between;
        }

        &_col {
            display: flex;
            gap: 0.625em;
        }
    }

    &__warning {
        font-size: 0.875em;
        margin-top: 1em;
        font-weight: 600;
        color: red;

        &Space {
            margin-bottom: 1em;
        }
    }
    // end nb__footer

    &__tabs {
        margin-left: 0;
        margin-bottom: 1.875em;
    }

    &__table {
        padding-bottom: 1.875em;
    }

    &__testNoAnswer {
        color: #C96203;
    }

    &__example {
        line-height: 1.4;
        
        span {
            margin-left: 0.25em;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    &__showRootBox {
        span {
            margin-left: 0.25em;
            &:first-child {
                margin-left: 0;
            }
        }

    }

    &__commentBox {
        padding-top: 1em;
        padding-bottom: 0.5em;
    }

    &__commentText {
        width: 50em;
        resize: vertical;
        min-height: 10em;
    }

    &__execAside {
        padding-bottom: 1em;
    }
    &__execResultIcon {
        font-size: 5em;

        &Good {color: green;}
        &Bad {color: red;}
        &Unknown {color: brown;}

        &Title {
            font-size: 1.4em;
        }
        &Score {
            font-size: 2em;    
        }
        &Info {
            font-size: 0.8em;
        }
    }
    &__execResultEstim {
        font-size: 0.8em;
        color: $lw_blue_color;
        text-decoration: underline;
    }
    &__sqrt{
        border-top: 1px solid black;
    }
} 

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 100%;
    width: 100%;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    &_description {
        margin-top: 0.75em;
    }

    &_title {
        font-size: 1.75em;
        font-weight: 500;
        line-height: 1.2;
    }

    &_description {
        font-size: 0.875em;
        line-height: 1.2;
    }

    &_button {
        margin-top: 1.25em;
        width: 12.5em;
    }
}

//end of nb ====================================================================================
.structureList {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0.5em 1em;


    &__scroll {
        height: 100%;
        min-height: 0;
        margin-right: -1em;
        padding-right: 1em;
        @include scrollbar;
    }
   
    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__firstPage {
        margin-top: 0.5em;
        margin-bottom: 0.2em;
        padding-bottom: 0.6em; //0.625em;
        border-bottom: 1px solid #BEC9D3;
    }

    &__item {
        margin-bottom: 0.625em;

        // &:last-child {
        //     margin-bottom: 0;
        //     padding-top: 0.625em;
        //     border-top: 1px solid #BEC9D3;
        // }

        &.disabled { //is it ok???????
            position: relative;
            cursor: default;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            
            .structureList__content {
                opacity: 0.5;
            }
        }

        .doing {
            position: relative;
        
            &__toggle {
                position: relative;
                cursor: pointer;
                color: #858C98;
                transition: 0.3s;
        
                &:before {
                    content: "";
                    display: block; 
                    width: 200%;
                    height: 200%;
        
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        
            &__list {
                display: none;
                position: absolute;
                top: 0.5em;
                right: 100%;
        
                margin: 0;
                padding: 0;
                list-style: none;
        
                padding: 0.625em 0.5em;
        
                background-color: #ffffff;
                box-shadow: 0px 9px 15px 0px rgba(52, 61, 70, 0.09);
        
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -0.5em;
                    display: block;
                    width: 0; 
                    height: 0;
                    border-top: 0.5em solid #ffffff;
                    border-right: 0.5em solid transparent;
                }
        
                :global(.dark) & {
                    background-color: #16171D;
                    &:after {
                        border-top: 0.5em solid #16171D;;
                    }
                }
            }
        
            &__item {
                margin-bottom: 0.75em;
                cursor: pointer;
                &:last-child {
                    margin-bottom: 0;
                }
        
                span:not(.cor_icon) {
                    font-size: 0.75em;
                    color: #383E47;
                    font-weight: 500;
                    transition: 0.3s;
        
                    :global(.dark) & {
                        color: #ffffff;
                    }
                }
        
                .cor_icon { 
                    color: #858C98;
                    margin-right: 0.3125em;
        
                    :global(.dark) & {
                        color: #00B5FF;
                    }
                }
        
                &:hover {
                    span:not(.cor_icon) {
                        color: #00B5FF;
                    }
                }
            }
        
            &:hover {
                .doing__toggle {
                    color: #00B5FF;
                }
        
                .doing__list {
                    display: block;
                    animation: tooltipFadeUpSide 0.3s;
                }
            }
        }
    }

    &__title {
        font-size: 0.75em;
        font-weight: 500;
        color: #08203A;

        :global(.dark) & {
            color: #ffffff;
        }
    }

    &__page {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        min-height: 6.5625em;
        
        margin-top: 0.3125em;
        padding: 1.5em 0.5em 1em;

        border-radius: 0.25em;
        border: 1px solid #BEC9D3;
        background-color: #F5F8FA;
        transition: 0.3s;
        
        :global(.dark) & {
            background-color: #363a45;
            border: 1px solid #4a4f5b;
        }

        .active & {
            border: 2px solid #00B5FF;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #7C8195;
        font-size: 0.6875em;
        line-height: 1.213;
        transition: 0.3s;
        text-align: center;

        span {
            display: inline-block;
            margin-top: 0.5em;

            &:first-child {
                margin-top: 0;
            }
        }

        :global(.dark) & {
            color: #ffffff;
        }
    }

    &__action {
        position: absolute;
        top: 0.375em;
        right: 0.375em;
        // border: 1px solid #000;

        display: flex;
        align-items: center;
        gap: 0.125em;
    }

    &__add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 8.75em;
        padding: 1em;

        font-size: 0.75em;
        font-weight: 500;

        border-radius: 0.25em;
        background: rgba(210, 239, 255, 0.65);
        color: #00B5FF;
        cursor: pointer;

        :global(.dark) & {
            background-color: #363a45;
        }

        .cor_icon {
            font-size: 1.25em;
            font-weight: bold;
            margin-right: 0.25em;
        }

        &.disabled { //is it ok???????
            position: relative;
            cursor: default;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            
            .structureList__content {
                opacity: 0.5;
            }
        }
    }
}
//====================================================================================
.actionList {
    &__item {
        margin: 0 -0.9375em;
        font-size: 1em;
        border-bottom: 1px solid #BEC9D3;
        cursor: pointer;

     

        &:first-child {
            .actionList__content {
                padding-top: 0.3125em;
            }
        }

        &:hover:not(.disabled) {}

        &.disabled {
            position: relative;
            cursor: default;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            
            .actionList__content {
                opacity: 0.5;
            }
        }
    }

    &__content {
        display: flex;
        align-items: flex-end;
        gap: 0.3125em;

        padding: 0.9375em;

        p {
            color: #383E47;
            margin: 0;
            font-size: 0.8125em;

            :global(.dark) & {
                color: #ffffff;
            }
        }

        .cor_icon {
            color: #858C98;
            :global(.dark) & {
                color: #ffffff;
            }
        }
    }
}



.drag {
    color: #858C98;
    transition: 0.3s;
    cursor: grabbing;

    &:hover {
        color: #00B5FF;
    }

}

.selectedStudents {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;

    background-color: #f5f8fa;
    border: 1px solid #bec9d3;
    border-radius: 0.3125em;
    padding: 0.625em 0.75em;

    :global(.dark) & {
        background-color: #363a45;
        border: 1px solid #4a4f5b;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        gap: 0.375em;
        line-height: 1;
        padding: 0.5em 0.75em;
        background-color: #E1E6EB;
        border-radius: 2.375em;
        color: #595C6C;

        :global(.dark) & {
           background-color: #16171D;
           color: #fff;
        }
    }

    &__name {
        flex: none;
        font-weight: 500;
        font-size: 0.75em;
    }

    &__trash {
        font-size: 0.875em;
    }


}

.printBtns {
    display: flex;
    gap: 1em;
    justify-content: flex-end;
}