@import "../../scss/mixins/mixins";

.changeClass {
    margin: 0 0 0.7em 0;
    padding: 0;

    .tabs__item {
        font-size: 0.7em;
    }
}
