@import '../../scss/variables/variables';

.root {
    position: relative;

    &:hover {
        .list {
            display: block;
        }
    }
}
.list:not(:empty) {
    display: none;
    position: absolute;
    bottom: 100%;
    border-radius: 0.125em 0.125em 0 0;
    border: 1px solid #bec9d3;
    box-shadow: 0 0 0.9375em rgba(52, 61, 70, 0.09);
    padding: 0.4em;
    left: -0.1em;
    width: 1.875em;
    background-color: $light_theme_background;

    :global(.dark) & {
        background: #363a45;
        border: 1px solid #343640;
    }
}

.item {
    &:nth-child(n + 2) {
        margin-top: 0.5em;
    }
}

.color {
    border: 1px solid transparent;
    width: 0.9375em;
    height: 0.9375em;
    border-radius: 0.1875em;

    &:hover {
        border: 1px solid #67d3ff;
    }
}
