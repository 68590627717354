@import "../../../scss/mixins/mixins";
@import "../../../scss/variables/variables";

.InitSectionTabs {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25em;
    // margin-left: 12.25em;
    color: #08203A;

    &__tab {
        position: relative;
        display: flex;
	    flex-direction: column;
        justify-content: center;
        align-items: center; 
        width: 19em;
        background: #F5F8FA;
        border: solid 1px;
        border-color: #CBD4DC;
        border-radius: 0.375em;
        text-align: center;
        padding: 3.3em 1em;
        transition: border-color 0.3s;
        

        .dark & {
            background: #363A45;
            border-color: #484B5A;
            color: #fff;
        }

        &:hover:not(.disabled) {
            border-color: $lw_blue_color;
            cursor: pointer;
        }
    }

    &__iconBox {
        margin-bottom: 1.25em;

        .cor_icon {
            font-size: 2.25em;
        }
    }

    &__icon {
        color:  #00B5FF;
    }

    &__name {
        font-size: 1em;
    }

    &__tooltip {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        padding: 0.25em;
    }
}
