@import "../../scss/variables/variables";
@import "../../scss/mixins/mixins";


.cor_practicum {
    position: relative;
    display: flex;
    gap: 0.625em;
    max-height: 100%;

    &__col {
        width: 50%;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        &:last-child {
            border-left: 1px solid #E8E8E8;
            padding-left: 0.625em;
        }
    }

    &__scroll {
        max-height: 100%;
        padding-bottom: 1.4em;
        padding-right: 1.25em;
        height: 99%;
        @include scrollbar;
    }
}

.tutorialSelect {
    &__stretch {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        //border: solid 1px red;
        font-size: 1.125em;
    }

    &__grow {
        flex-grow: 1;
    }

    &__separator {
        border: solid 0.02em #BEC9D3;
        opacity: 0.3;
        margin: 1.2em 0 0.6em 0;
        width: 120%;
        position: relative;
        left: -50px;
        right: 50px;
    }

    &__space {
        margin: 1.2em 0 0.6em 0;
    }

    &__buttons {
        float: right;
		font-size: 1.2em;
        position: relative;
        top: -0.2em;
    }

	&__button {
		font-size: 0.7em;
		border: none;
		padding: 0.4em 3em;
		margin-left: 1.5em;
        margin-right: 0.2em;
        border-radius: 0.3125em;

		&:hover {
			cursor: pointer;
		}

        &Sel {
            background-color: white;
            color: $lw_blue_color;
            border: solid 1px  $lw_blue_color;
        }

        &Unsel {
            background-color: $lw_blue_color;
            color: white;
        }
	}

    &__blueTextBtn {
        color: $lw_blue_color;        
        font-size: 0.9em;
        font-weight: 600;
        cursor: pointer;
    }
}
