.root {
    display: flex;
    align-items: center;
    padding-right: 1.3em;

    @media (min-width: 768px) {
        // margin-right: 0.85em;
        padding-right: 0;
    }

    @media (max-width: 767px) and (orientation: 'landscape') {
        padding-right: 0;
    }

    input:not([type]),
    input[type='date'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'] {
        padding: 0 0 0 0.5em;
        border: 0;
        font-size: 0.875em;
        width: 6.5em;
        // width: auto;
        text-align: right;
        color: #7c8195;
        background-color: transparent;

        @media (min-width: 768px) {
            font-size: 0.8em;
        }

        @media (max-width: 767px) and (orientation: 'landscape') {
            font-size: 0.8em;
        }

        :global(.dark) & {
            border: 0;
            background-color: transparent;
        }
    }

    [class^='Dropdown_root'] {
        margin-top: 0.1em;
        border: 0;
        background-color: transparent;

        :global(.dark) & {
            border: 0;
            background-color: transparent;
        }

        &:hover:not(:disabled) {
            border: 0;
            background-color: transparent;

            :global(.dark) & {
                border: 0;
                background-color: transparent;
            }
        }

        [class^='Dropdown_label'] {
            color: #a2a6b6;
            &:after {
                margin-left: 0em;
                // transition: transform 0.2s;
            }
        }
    }
    &[class*='Dropdown_open'] {
        [class^='Dropdown_label']:after {
            transform: rotate(180deg);
            margin-top: -0.1em;
        }
    }
}
