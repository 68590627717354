.corModules {
    background-color: #F8FBFF;
    transition: background-color 0.3s;

    .dark & {
        background-color: #282B33;
    }

    &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        margin-top: 3.125em;
    }

    &__left {
        width: 28.75em;

        .corModules__tabs {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
    
        }

        div.tabs__item {
            font-size: 1.25em;
            padding: 1.4em 1.1em;
            margin-bottom: 0.75em;
            


            border: 0.1em solid transparent;
            border-radius: 0.25em;   

            background-color: $color_withe;
            color: #808E95;
            box-shadow: 0px 7px 17px -12px rgba(28, 57, 69, 0.17);

            .dark & {
                background-color: #16171D;
                border: 0.1em solid #808E95;
            }
            
            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                background: #F8FBFF;
                border: 0.1em solid #00AAF3;
                color: #00AAF3;
                cursor: default;
                border-radius: 0.25em;  

                .dark & {
                    background-color: #282B33;
                }
            }
        }
    }

    &__right {
        width: 57.8125em;
        flex: none;
    }

    &__tabs {
        
    }

    &__label {
        font-size: 0.875em;
        color: #6C6C6C;
        margin-top: 2em;
        line-height: 1.5;

        .dark & {
            color: #d1d1d1;
        }
    }

    &__img {
        width: 100%;
        height: 27.75em;
        border-radius: 0.3125em;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        color: #22262C;
        font-size: 1.5em;
        margin-top: 1.417em;

        .dark & {
            color: $color_withe;
        }
    }

    &__content {
        color: #22262C;
        .dark & {
            color: $color_withe;
        }
        p, ul {
            font-size: 1.125em;
            margin: 0.833334em 0;
            line-height: 1.875;
        }

        ul {
            color: #6A7987;
            padding: 0;
            padding-left: 1em;

            column-width: 24em;
            column-gap: 2em;

            .dark & {
                color: #d1d1d1;
            }
           
        }
    }


}