@import "../../scss/mixins/mixins";
@import '../../Components/ui/Icon/style';

.videoGrid {

    &.expanded {
        align-items: stretch;
        height: 100%;
    }

    &_sm:not(.expanded) {
        font-size: 0.75em;
    }

    &_md:not(.expanded) {
        font-size: 1.25em;
    }

    &_lg:not(.expanded) {
        font-size: 1.5em;
    }

    &__col {
        position: relative;
        overflow: hidden;
        background: #F0F3F5;
        border: 1px solid #E8E8E8;
        border-radius: 0.375em;
        display: flex;
        flex-direction: column;
        transition: flex-grow 0.2s;
        width: 20em;

        &.expanded {
            flex-grow: 1;
            min-height: 100%;
            margin-right: -1.25em;
        }

        &.hidden {
            display: none;
        }


        &.talk {
            border-color: $color_info;
        }

        .dark & {
            border: 1px solid #1F2026;
            background: #1F2026;

            &.talk {
                border-color: $color_info;
            }
        }
    }

    &__prev {
        display: block;
        position: relative;
        height: 14.9em;
        width: 100%;
        background-color: #F0F3F5;
        overflow: hidden;
        border-radius: 0.375em 0.375em 0 0;

        .expanded & {
            display: flex;
            position: relative;
            height: 100%
        }

        .dark & {
            background: #1F2026;
        }
    }

    &__picture {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        & .cor_icon {
            font-size: 8em;
            color: #666F7E;

            .dark & {
                color: #ffffff;
            }
        }

        & img {
            display: block;
            object-fit: contain;
            padding: 5em;
            border-radius: 50%;
        }
    }

    &__role, &__expand {
        position: absolute;
        z-index: 9;
        background-color: #fff;
        border: 1px solid #e8e8e8;
        border-radius: 0.4em;
        padding: 0.4em;
        cursor: pointer;

        .dark & {
            border-color: #5c5e67;
            background: #1F2026;
        }

        & .cor_icon {
            font-size: 1.4em;
            color: #666F7E;

            .dark & {
                color: #ffffff;
            }
        }
    }

    &__role {
        left: 0.6em;
        top: 0.6em;
    }

    &__expand {
        right: 0.6em;
        top: 0.6em;
    }

    &__screen {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2;

        &__title {
           display: none;
        }

        :fullscreen &__title {
            color: white;
            display: block;
            position: absolute;
            padding: 1em;
        }

        video {
            display: block;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__icons {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.9375em;

        [class*="icon_"] {
            font-size: 3.375em;
            color: #666F7E;
            cursor: pointer;
        }
    }

    &__name {
        font-size: 1em;
        padding: 0.5em 0.625em;
        color: #383E47;
        background: #ffffff;
        min-height: 2.1875em;
        border-radius: 0 0 0.375em 0.375em;

        .dark & {
            background: #16171D;
            color: #FFFFFF;
        }

        span {
            font-size: 0.875em;
            &.link {
                cursor: pointer;
                &_desc {
                    color: #666f7e;
                    .dark & {
                        color: #A2A2A5;
                    }
                }
            }

            &.controls {
                // position: relative;
                float: right;
                font-size: 1em;
            
                &__item {
                    margin: 0 0 0 0.5em;

                    &:first-child {
                        margin: 0;
                    }

                    &_volume {
                        position: relative;

                        &:hover .volume {
                            z-index: 3;
                        }
                    }
                }

                & .cor_icon {
                    cursor: pointer;
                    font-size: 1.2em;

                    & .on {
                        color: $color_info;
                    }

                    & .off {
                        color: $color_danger;
                    }
                }

                & .volume {
                    position: absolute;
                    padding: 0 0.4em;
                    background: #ffffff;
                    border: 1px solid #E8E8E8;
                    border-radius: 8px;
                    z-index: -1;
                    right: -0.475em;
                    bottom: -0.55em;
                    display: flex;
                    flex-direction: column;
                    // width: 100%;

                    & .cor_icon {
                        font-size: 1.2em;
                        margin: 0.4em 0;
                        padding: 0;
                    }

                    .dark & {
                        border: 1px solid #4A4F5B;
                        background: #1f2026;
                    }
                }
            }
        }

        &.teacher {
            background: #ffffff;

            .dark & {
                background: #16171D;
                color: #FFFFFF;
            }
        }

        &.isYou {
 
            background: $color_info;
            color: #fff;

            span.link_desc {
                color: #fff;
            }

            .dark & {
                background: $color_info;
                color: #fff;
            }

            span.controls .cor_icon .on {
                color: #fff;
            }
        }
    }


    &.videoGrid_tile:not(.expanded) {
        gap: 1.25em;
        .videoGrid__col {
            flex-direction: row;
            width: 30em;
        }

        .videoGrid__prev {
            height: 8.95em;
            width: 40%;
            border-radius: 0;
        }

        .videoGrid__expand {
            font-size: 0.675em;
        }

        .videoGrid__picture {
            .cor_icon {
                font-size: 3.125em;
            }

            & img {
                padding: 3.5em;
            }
        }

        .videoGrid__screen {}

        .videoGrid__name {
            position: relative;
            flex: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 60%;
            padding: 1.25em 1em 1.25em 1.25em;
            border-radius: 0;

            span.controls {
                width: fit-content;
            }

            span.controls .cor_icon {
                &:first-child {
                    margin-left: 0;
                }
            }

            span.controls .volume {
                flex-direction: row;
                align-items: center;
                right: auto;
                left: -0.45em;
                bottom: -0.55em;
                width: 12em;

                .inputRange__wrap {
                    width: 4em;
                    min-height: auto;
                    margin: 0 0.25em;
                }
            }

            &:after {
                font-family: 'cor';
                content: $cor_icon_student;
                font-size: 1.5em;

                position: absolute;
                bottom: 0.625em;
                right: 0.625em;
                color: #666F7E;

                .dark & {
                    color: #ffffff;
                }
            }

            &.teacher {
                &:after {
                    content: $cor_icon_teacher;
                }
            }

            &.isYou {
                &:after {
                    color: #ffffff;
                }
            }
        }
    }

    .inputRange[type=range][orient=vertical] {
        width: 9em!important;
    }
}

.videoGridFooter {

    &__item {
        display: flex;
        align-items: center;
    }

    &__label {
        margin-right: 1em;
        font-size: 0.875em;
        color: #7C8195;
        
        .dark & {
            color: #fff;
        }
    }

    &__sort {
        .cor_icon {
            font-size: 1.75em;
            color: #1E1F25;
            display: inline-block;
            transform: translateY(0.05em);

            .dark & {
                color: #fff;
            }
        }
    }

    &__dropdown {
        width: 7.75em;
    }
}
