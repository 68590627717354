@import '../../rlab-neuro/src/scss/mixins/mixins.scss';
@import '../ui/Icon/Icon.scss';

.chart {
  position: relative;
  flex: none;
  min-width: 0;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 0.25em 0.9375em rgba(0, 0, 0, 0.1);
  border-radius: 0.375em;
  margin: 0;
  padding: 1em 0.7em;
  min-height: 30em;

  :global(.dark) & {
    background: #282b33;
  }
}

.head {
  padding: 0 1em 0 0.5em;
  margin-bottom: 1.125em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: none;
}

.left {
  display: flex;
  align-items: center;
  gap: 1.8125em;
  overflow: auto hidden;
  min-width: 0;
  margin-right: 1.4em;

  @extend %scrollbar;
}

.legend {
  display: flex;
  align-items: center;
  background: $light_theme_background;

  :global(.dark) & {
    background: #282b33;
  }

  &_multy {
    display: block;

    > div {
      display: flex;
      align-items: center;
    }
  }

  span {
    font-weight: 600;
    font-size: 0.875em;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625em;
  cursor: pointer;

  button, label {
    width: 1.65em;
    height: 1.65em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    color: #666f7e;
    background-color: transparent;
    border: 1px solid #bec9d3;
    border-radius: 2px;
    transition: 0.3s;
    transition-property: color, background;
    cursor: pointer;


    :global(.dark) & {
      border: 1px solid #343640;
    }

    &:hover:not(:disabled) {
      color: #bec9d3;

      .dark & {
        color: #ffffff;
      }
    }

    &.active {
      border: 1px solid #00b5ff;
      background: #00b5ff;
      color: #ffffff;

      &:hover {
        color: #ffffff;
      }
    }

    &:disabled {
      cursor: default;
    }
  }
}

.color {
  flex: none;
  width: 1.65em;
  height: 1.65em;
  border-radius: 0.1875em;
  margin-right: 0.4em;
}

.wrap {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
}

.name {
  font-size: 0.66em;
  font-weight: 500;
  writing-mode: vertical-rl;
  transform-origin: center;
  transform: rotate(-180deg);
  text-align: center;
  user-select: none;
  padding: 2em 0.5em 0 0;
}

.big {
  display: flex;
  flex-grow: 1;
  min-height: 14em;
  min-width: 14em;
}

.foot {
  padding: 0.1em 0.1em 0.1em 3.7em;
  width: 13em;
  flex: none;
  display: flex;
  align-items: center;
  gap: 1.1875em;
  position: relative;
  z-index: 1;

  label {
    display: flex;
    align-items: center;
    font-size: 0.8em;
  }

  span {
    font-size: 0.8em;
    padding-right: 0.4em;
  }
}

.file {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    font-family: 'cor';
    content: $cor_icon_file-excel;
    font-size: 1.2em;
  }
  input {
    display: none;
  }
}

.axeSwitcher {
  width: 6.25em;
  display: flex;
  align-items: center;
  gap: 1.25em;
}

.xLabel {
  white-space: nowrap;
  font-size: 0.625em;
}
