@import '../../scss/variables/variables';

.modalSettings {
    &__body {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.25em;
    }

    &__col {
        width: 50%;
        padding-left: 1.25em;
        margin-bottom: 0.5625em;

        &_full {
            width: 100%;
        }
    }

    &__label {
        margin-bottom: 0.5em;
        font-size: 0.75em;
        font-weight: 500;
        color: #7c8195;

        .dark & {
            color: #c7c9d1;
        }

        &_val {
            color: #1e1f25;
            margin-left: 0.7em;

            .dark & {
                color: $color_withe;
            }
        }
    }

    &__range {
        min-height: 1.875em;
        display: flex;
        align-items: center;
    }

    .colors {
        [class^='Dropdown_label'] > span,
        li {
            display: flex;
            align-items: center;
            flex: none;
        }

        &_val {
            display: block;
            flex: none;
            border: 1px solid;
            width: 0.87em;
            margin-right: 0.45em;
        }

        svg {
            flex-grow: 1;
            width: 17em;
        }
    }

    [class^='Dropdown_root'] {
        [class^='Dropdown_label'] span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .dark & {
            background: #363a45;

            &:hover {
                background: darken(#363a45, 3%);
            }
        }
    }

    input {
        width: 100%;
    }
}

[class^='Dropdown_root'] .react-colorful {
    width: 100%;
    height: auto;
    // flex-direction: row;
    margin-bottom: 0.7em;
    &__saturation {
        width: 100%;
        // width: calc(100% - 3em);
        height: 14.375em;
        border-radius: 0;
        margin: 0;
        // margin-right: 0.7em;
        margin-bottom: 0.7em;
        // flex: none;
    }
    &__interactive {
    }
    &__pointer {
        // display: none;
        width: 0.7em;
        height: 0.7em;
        border: 1px solid $color_withe;
    }
    &__saturation-pointer {
    }
    &__pointer-fill {
    }
    &__hue {
        border-radius: 0;
        height: 1.2em;
        // width: 1.2em;
        // height: auto;
        // flex: none;
        // background: linear-gradient(0deg, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red);
    }
    &__last-control {
        .react-colorful__interactive {
            // width: 100%;
            // height: 100%;
            // right: auto;
            // bottom: auto;
            // transform: rotate(90deg);
        }
    }
    &__hue-pointer {
    }
}
