.root {
    display: inline-flex;
    align-items: center;
    gap: 0.375em;
    
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;

    color: #666F7E;
    font-weight: 400;
    transition: 0.3s;
    font-size: 0.875em;
    cursor: pointer;

    :global(.dark) & {
        color : #d1d1d1;
    }

    p {
        margin: 0;
        transform: translateY(0.06em);
        
    }

    span {
        font-size: 0.9em;
    }

    &:hover:not(&:disabled) {
        color: #00B5FF;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default!important;
    }
}

.mt {
    margin-top: 1em;
}

.mb {
    margin-bottom: 1em;
}