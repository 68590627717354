.sensor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.25em;
    position: relative;
    overflow: hidden;
    padding: 0.8125em;
    background: #ffffff;
    box-shadow: 0 0.125em 0.875em rgba(0, 0, 0, 0.08);
    border-radius: 0.375em;

    :global(.dark) & {
        background: #2d3039;
        color: #fff;
    }

    :global(.aside_hidden) & {
        display: none;
    }
}

.disabled {
    composes: sensor;
    background: #eef1f2;

    :global(.dark) & {
        background: #363a45;
    }
}

.color {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.25em;
}

.name {
    font-size: 0.875em;
    font-weight: 700;
    flex-grow: 1;
}

.val {
    font-size: 0.875em;
    font-weight: 500;
    color: #828282;

    :global(.dark) & {
        color: #c7c9d1;
    }
}
