/* .menu {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #bec9d3;
  background: #fdfdfd;
  box-shadow: 0px 9px 15px 0px rgba(52, 61, 70, 0.09);
  // font-size: 12px;
  font-weight: 500;
  color: #595c6c;
  min-width: 216px;

  position: absolute;
    z-index: -1;
    top: 24px;
    right: -188px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;

    &.open {
      opacity: 1;
      visibility: visible;
      z-index: 100;
    }

  &__item {
    position: relative;
    z-index: 20;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: #e6eaef;
    }

    &:first-child {
      margin-top: 5px;
    }
    &:last-child {
      padding: 10px;
      border-top: 1px solid #e8e8e8;
    }
  }

  &__title {
    text-wrap: nowrap;
  }
}

.submenu.closed {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

}
.disabled {
  opacity: 0.6;
}

.submenu {
  position: absolute;
  top: 0;
  right: -217px;
  z-index: 1120;
  border-radius: 0px 8px 8px 8px;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease;

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 5px 10px;
    transition: all 0.2s ease;


    &:first-child {
      margin-top: 5px;
    }
    &:last-child {
      padding: 5px 10px;
      margin-bottom: 5px;
      border-top: none;
    }
  }

  &__title {
    // flex: 1;
  }
}
 */

.menu {
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  border: 0.0625em solid #bec9d3;
  background: #fdfdfd;
  box-shadow: 0px 0.5625em 0.9375em 0px rgba(52, 61, 70, 0.09);
  // font-size: 0.75em;
  font-weight: 500;
  color: #595c6c;
  min-width: 13.5em;
  max-width: 13.75em;

  position: absolute;
  z-index: -1;
  top: 2.1em;
  right: -8.75em;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;

  .dark & {
    background: #16171d;
    color: #fff;
    border: 1px solid #4a4f5b;
  }

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }

  &__item {
    font-size: 0.875em;
    position: relative;
    z-index: 20;
    padding: 0.3125em 0.625em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
    transition: all 0.2s ease;
  border-radius: 0.5em;


    &:hover {
      background: #e6eaef;
      .dark & {
        background: #1f2026;
      }
    }

    &:first-child {
      margin-top: 0.3125em;
    }
    &:last-child {
      padding: 0.625em;
      border-top: 0.0625em solid #e8e8e8;
      .dark & {
        border-top: 1px solid #4a4f5b;
      }
    }

    &:nth-last-child(2):hover {
      background: none;
      cursor: default;
    }
  }

  &__title {
    text-wrap: nowrap;
    width: 100%;
  }
}

.submenu.closed {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.disabled {
  opacity: 0.6;
}

.popup_menu__item {
  border-radius: 0.5em;

  font-size: 0.875em;
  position: relative;
  z-index: 20;
  padding: 0.3125em 0.625em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #e6eaef;
    .dark & {
      background: #1f2026;
    }
  }

  &:first-child {
    padding: 0.625em;

  }
  &:last-child {
    padding: 0.625em;
    
  }
}

.submenu {
  position: absolute;

  top: 0;
  right: -13.5625em;
  z-index: 1120;
  border-radius: 0px 0.5em 0.5em 0.5em;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease;

  &__item {
    font-size: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
    padding: 0.3125em 0.625em;
    transition: all 0.2s ease;

    &:first-child {
      margin-top: 0.3125em;
    }
    &:last-child {
      padding: 0.3125em 0.625em;
      margin-bottom: 0.3125em;
      border-top: none;
    }

    &:nth-last-child(2):hover {
      background: #e6eaef;
      cursor: pointer;
    }
  }

  &__title {
    // flex: 1;
  }
}
