.add_input {
  padding: 0.625em;
  margin-bottom: 0.5em;
  border-radius: 0.375em;
  border: 0.0625em solid #e8e8e8;
  background: #fff;

  &:focus {
    outline: none;
  }
}
