.wrap {
    margin-top: 1.5em;
}

.root {
    counter-reset: line var(--line-num, 0);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:before {
        content: '';
        position: absolute;
        top: 1.1875em;
        left: 0;

        display: block;
        width: 100%;
        height: 0.1875em;
        background-color: #CBD4DC;
    }

    .item {
        counter-increment: line;

        &__counter {
            &:before {
                content: counter(line) ' ';
            }
        }

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 20%;

        &:first-child {
            align-items: flex-start;
        }

        &:last-child {
            align-items: flex-end;
        }
    }

    .number {
        position: relative;
    
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5em;
        height: 2.5em;
        border-radius: 100%;

        border: 1px solid;
        border-color: #CBD4DC;
        background-color: #ffffff;
        box-shadow: (0px 4px 30px rgba(0, 0, 0, 0.10));
        cursor: pointer;
        transition: 0.3s;

        span {
            line-height: 1;
            font-size: 0.8125em;
            color: #08203A;
            font-weight: 500;
            transition: 0.3s;
        }

        &:hover  {
            border-color: #00B5FF;
            background-color: #ffffff;

            span {
                color: #00B5FF;
            }
        }

    }

    .label {
        margin-top: 0.625em;

        color: #858C98;
        font-weight: 600;

        span {
            font-size: 0.75em;
        }

        .dark & {
            color: #ffffff;
        }
    }


    .current {
        .number {
            border-color: #00B5FF;
            background-color: #00B5FF;
            box-shadow: none;
            
            span {
                color: #ffffff;
            }
        }

        .label {
            color: #00B5FF;
        }
    }

    .done {
        .number {
            border-color: #D0D0D0;
            background-color: #D0D0D0;
            box-shadow: none;

            span {
                color: #ffffff;
            }

            &:hover {
                border-color: #00B5FF;
                background-color: #ffffff;
                
                span {
                    color: #00B5FF;
                }
            }
        }

        .label {
            color: #BCBCBC;
        }
    }
}