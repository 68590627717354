@import '../../../scss/mixins/mixins';

.aside {
    flex: none;
    width: 15.35em;
    transition: width 0.4s;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 -0.2em -0.6em;
    padding: 0 0.2em 0.8em 0.6em;
    scrollbar-gutter: stable;
    @extend %scrollbar;

    @media (min-width: 768px) {
        overflow: hidden overlay;
    }

    // .sensors {
    //     flex-grow: 0;
    //     min-height: 0;
    // }
    // margin: 0 1.25em;
}

.asideHidden {
    composes: aside;
    width: 3.75em;
    margin: 0;
    padding: 0.75em 2em;
    height: calc(100% - 1em);
    background: #ffffff;
    box-shadow: 0px 0.25em 1.875em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;

    :global(.dark) & {
        background: #282b33;
    }

    [class^='Sensors_sensors'],
    [class^='AsideFilter_filter'] {
        display: none;
    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;
    gap: 0.5em;

    .asideHidden & {
        flex-direction: column-reverse;
    }
}

.title {
    font-size: 1.125em;
    font-weight: 600;

    :global(.dark) & {
        color: #fff;
    }

    .asideHidden & {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }
}

.toggle {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.625em;
    height: 1.625em;
    // background: #f5f8fa;
    border: 1px solid #bec9d3;
    border-radius: 1.875em;
    transition: transform 0.4s;
    cursor: pointer;
    color: #a2a6b6;

    :global(.dark) & {
        color: #fff;
        background: transparent;
        border: 1px solid #343640;
        color: #c7c9d1;
    }

    i {
        font-size: 0.7em;
        line-height: 0;
    }
}

.toggleInactive {
    composes: toggle;
    width: 2em;
    height: 2em;
    background: #00b5ff;
    border-color: #00b5ff;
    color: #fff;

    :global(.dark) & {
        color: #fff;
        background: #00b5ff;
        border-color: #00b5ff;
        color: #fff;
    }

    i {
        font-size: 1em;
    }
    // transform: rotate(180deg);
}
