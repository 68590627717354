.root {
}

$colPadding: 0.9em 1.2em;
.table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.8em;

    thead {
        th {
            text-align: left;
            background: #d7e1e8;
            font-size: 1em;
            font-weight: 500;
            border: 0;
            padding: $colPadding;

            :global(.dark) & {
                background: #2b2f37;
                font-weight: 400;
            }

            [class^='Dropdown_label'] {
                color: #1e1f25;

                :global(.dark) & {
                    color: #c7c9d1;
                }
            }

            &:nth-child(n + 2) {
                border-left: 1px solid rgba(#000, 0.1);

                :global(.dark) & {
                    border-left: 1px solid #525460;
                }
            }

            &:first-child {
                width: 15em;
            }
        }
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background: #fff;

                :global(.dark) & {
                    background: #363a45;
                }
            }
            &:nth-child(even) {
                background: #f0f3f5;

                :global(.dark) & {
                    background: #2b2f37;
                }
            }
        }
        td {
            padding: $colPadding;
            border: 0;

            &:nth-child(n + 2) {
                border-left: 1px solid rgba(#000, 0.1);

                :global(.dark) & {
                    border-left: 1px solid #525460;
                }
            }
        }
    }

    [class^='Dropdown_root'] {
        font-size: 1.25em;
        display: inline-block;
        border: 0 !important;
        background: transparent !important;

        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 0.7em;
            left: 0;
            width: 100%;
            top: 100%;
        }

        &:hover:not(:disabled) {
            border: 0;
            background: transparent;
        }
    }
    [class^='Dropdown_label'] {
        padding: 0;
    }
    [class^='Dropdown_drop'] {
        margin-top: 0.7em;
    }
}
