.root {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625em;
    margin-bottom: 2em;

    [class*='Input_btns'] {
        button {
            color: #a2a6b6;
        }
    }
}

.item {
    width: 4.375em;
}

.label {
    display: block;
    font-size: 0.75em;
    font-weight: 500;
    margin-bottom: 0.5em;
    color: #7c8195;

    :global(.dark) & {
        color: #c7c9d1;
    }
}
