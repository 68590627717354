@import "../../scss/mixins/mixins";

.corGrid {
    align-items: flex-start;
    margin-bottom: 2em;

    &__col {
        flex: 1 0 20em;
        max-width: 28em;

        &.lg {
            flex-basis: 36em;
            max-width: 36em;
        }

        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 0.375em;
        display: flex;
        flex-direction: column;
        font-size: 0.8em;

        &.newMember {
            margin-top: -1px;
            border-width: 2px;
            border-style: dashed;
        }


        .dark & {
            background: #363A45;
            border-color: #484B5A;
            color: #FFFFFF;
        }
    }

    &__screen {
        flex-grow: 1;
    }

    &__icon {
        margin-left: 0.6em;
        margin-top: -0.125em;
        width: 1.25em;
        height: 1.25em;
        border: 0.0625em solid #666;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.325em;
        transition: outline 0.2s;
        cursor: pointer;
    }

    &__name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.8em 1.2em;
        background: #EFF6F9;
        color: #383E47;
        min-height: 3em;
        border-radius: 0.375em 0.375em 0 0;

        .dark & {
            background: #16171D;
            color: #FFFFFF;
        }

        &.blocked span {
            opacity: 0.3;
        }

        &.active {
            background: #00B5FF;
            color: $color_withe;
        }

        & .cor_icon {
            font-size: 1.5em;
            &_folder, &_file {
                margin-right: 0.5em;
            }
        }

        > span {
            font-size: 1.2em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &_upload {
            & input[type=file] {
                display: none;
            }
        }

        .link {
            cursor: pointer;
        }
    }

    &__action {
        margin-left: 2em;
        display: flex;
        align-items: center;
        gap: 0.5em;

        .cor_icon {
            font-size: 1.3em;
            cursor: pointer;

            .blocked & {
                opacity: unset;
                color: #00B5FF;
            }

            .dark & {
                color: #A2A2A5;
            }

            .active & {
                color: $color_withe;
            }
        }
    }

    &__body {
        padding: 1em 1.3em;
        border-bottom: 0 solid #E8E8E8;

        .newMember & {
            opacity: 0.3;
        }

        .dark & {
            border-bottom: 0 solid #484B5A;
        }
    }

    &__role {
        font-weight: 600;
        margin-bottom: 0.7em;
    }

    &__picture {
        float: left;
        height: 5em;
        margin: 0 0.7em 0 0;

        img {
            border-radius: 100%;
            max-width: 5em;
            max-height: 5em;
        }

        .cor_icon {
            font-size: 5em;
        }
    }

    &__desc {
        line-height: 1.2em;
        margin-bottom: 0.5em;
        display: flex;

        &:last-child {
            margin-bottom: 0;
        }

        span {
            color: #7C8195;
            min-width: 4.5em;
            padding-right: 0.5em;

            .dark & {
                color: #A2A2A5;
            }
        }

        &.md span {
            min-width: 5.2em;
        }

        &.lg span {
            min-width: 7em;
        }

        &.xl span {
            min-width: 9em;
        }

        div {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__users {
        padding: 1em 0.5em 1em 1.3em;
        display: flex;

        .newMember & {
            opacity: 1;
        }

        .link {
            font-size: 1em;
            text-decoration: underline;
            color: $color_info;
            cursor: pointer;
        }

        & .blocked {
            width: 50%;
            color: $color_info;
        }

        .cor_icon {
            opacity: 1;
            font-size: 1.1em;
            cursor: pointer;

            .dark & {
                color: #FFFFFF;
            }
        }
    }

    &__user {
        font-weight: normal;
        padding: 0.2em 0;
        font-size: 1em;
        display: flex;
        flex-direction: row;
    }

    &__checks {
        padding-top: 1em;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8em;

        & .cor_checkbox {
            white-space: nowrap;
            flex: 1 1 10em;
            max-width: 20em;
        }
    }

    &__firmwares {
        padding: 1em 0.75em 1em 1em;

        &_item {
            font-weight: normal;
            padding: 0.3em 0;
            font-size: 1.1em;
            display: flex;
            gap: 0.8em;

            &_name {
                width: 35%;
                &.changed {
                    border-color: $color_warning;
                }
            }

            &_hash {
                overflow: hidden;
                white-space:nowrap;
                text-overflow: ellipsis;
                font-size: 0.8em;
                margin-top: 0.9em;
                width: 50%;
            }

            & .cor_icon {
                margin-top: 0.5em;
            }

            & label {
                font-size: 1em;
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;
            }

            & input[type=file] {
                display: none;
            }
        }
    }

    &__preview {
        width: 10em;
        & img{
            display: block;
            object-fit: contain;
        }
    }

    &__link a {
        color: #383E47;
        .dark & {
            color: #FFFFFF;
        }
    }
}
