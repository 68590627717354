@import "../../../scss/variables/variables";

.table {
	&__table {
		input[type=text] {
			font-size: 14px; 
			text-align: center;
			padding: 0;
			margin: 0;
			width: 30px;
		}
	}

	&__Row0 {
		font-size: 1.1em;

		.dark & {
			color: $cm_dark_cb_color;
		}
	}

	&__Row {
		font-size: 1em;

		&Score {
			td span {
				.dark & {
					color: #1E1F25;
				}
			}
		}
	}

	&__colorLightDefault {color: #1E1F25;}
	&__colorDarkDefault {color: #eceff3;}

	&__ComboBox {
		color: $cm_light_cb_color;
		background-color: $cm_light_cb_bknd_color;
		border: solid 1px #BEC9D3;
		font-size: 1em;
		width: 100%;
		border-radius: 0.125em;
		cursor: pointer;

		
		.dark & {
			color: $cm_dark_cb_color;
			background-color: $cm_dark_cb_bknd_color;
			border: solid 1px $cm_dark_cb_color;
		}
	}

	&__SortCell {
		position: relative;
		display: inline-flex;
		align-items: center;
		gap: 0.15em;
		cursor: pointer;

		> span {
			width: fit-content;
		}
	}

	&__SortIcon {
		transform: translateY(0.075em);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2.125em;
		
		.sortText {
			font-size: 2em;
		}

		.sortNumber {
			font-size: 1.5em;
		}

		.cor_icon i {
			color: #A2A6B6;
			transition: 0.3s;

			&.active {
				color: #444754;

				.dark & {
					color: #ffffff;
				}
			}
		}

		
		&:hover {
			cursor: pointer;
		}
	}

	&__sortBoldIcon {
		font-weight: 900;
	}

	&__svgScale {
        font-size: 0.6em;
		color: $cm_light_cb_color;
		background-color: $cm_light_cb_bknd_color;
		.dark & {
			color: $cm_dark_cb_color;
			background-color: $cm_dark_cb_bknd_color;
		}
    }

	&__PersonBtn {
		background-color: transparent;
		border: none;
	}

	&__actionsSpan {
		display: flex;
		align-items: center;
	}

	&__ActionBtn {
		background-color: transparent;
		width: 50px;
		margin: 0;
		padding: 0;
		border: none;
	}

	&__PersonIcon {
		font-size: 1.5em;
		position: relative;
		top: 2px;
		color: $lw_blue_color; //#b5b3ba;
	}

	&__PersonCol {
		color: $lw_blue_color; //#b5b3ba;

		&:hover {
			color: lighten($color: $lw_blue_color, $amount: 10%);  //#b5b3ba;
			text-decoration: underline;
			cursor: pointer;
		}

		.dark & {
			&:hover {
				color: lighten($color: $lw_blue_color, $amount: 10%);
				text-decoration: underline;
				cursor: pointer;
			}
		}

		&.align-center {
			display: flex;
			gap: 0.5em;
			align-items: center;
		}

	}

	&__LinkCol {
		color: black;
		text-decoration: underline;

		&:hover {
			cursor: pointer;
		}

		.dark & {
			color: white;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&__PersonFontSize {
		font-size: 0.9em;
	}

	&__stretchStart {
        display: flex;
        justify-content: flex-start; //space-between;
        align-items: center; 
        font-size: 1.125em;
        gap: 0.2em;
    }

	$tableIconSize: 1.3em;

	&__pointer {
		&:hover {
			cursor: pointer;
		}
	}

	&__ActionIcon {
		//margin-right: 16px; //use if button is used
		padding: 0.1em 0.3em;
		font-size: $tableIconSize;
		color: #666F7E;

		&:hover {
			cursor: pointer;
		}

		&Decoration {
			text-decoration: underline;
		}

		&Blue {
			color: $lw_blue_color;
			cursor: pointer;
		}

		&Grey {
			color: grey;
			cursor: pointer;

			.dark & {
				color: lightgrey;
			}
		}
	}
}
