@import '../../scss/variables/variables';

.graduation {
    margin: 1.25em 0;

    &__title {
        font-size: 0.875em;
        font-weight: 600;
        margin: 0 0 1em;

        .dark & {
            color: $color_withe;
        }
    }

    &__label {
        font-size: 0.8em;
        line-height: 1.25;
        color: #7c8195;
        margin: 0.5em 0;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex: none;

        > span {
            flex-grow: 1;
            min-width: 0;
        }

        .dark & {
            color: #c7c9d1;
        }
    }

    &__body {
        margin: 0.8em 0 0;

        @media (min-width: 768px) {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            flex-wrap: wrap;
            margin: 0.8em 0 0 -1em;
        }
    }

    // &__col {
    //     margin-bottom: 0.8em;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;

    //     > span:not([class]) {
    //         @extend .graduation__label;
    //         margin-bottom: 0;

    //         &:first-child {
    //             margin-right: 1.2em;
    //         }

    //         &:nth-child(2) {
    //             color: #1e1f25;

    //             .dark & {
    //                 color: $color_withe;
    //             }
    //         }
    //     }

    //     // .rdn {
    //     //     width: 4.375em;
    //     // }
    //     [class^='Dropdown_root'] {
    //         // font-size: 0.9em;
    //     }

    //     @media (min-width: 768px) {
    //         // width: 40%;
    //         padding-left: 1em;
    //         margin-right: 2.49em;
    //     }
    // }

    &__inline {
        display: flex;
        align-items: center;
        gap: 0.625em;

        > span {
            font-size: 0.8em;
            font-weight: 500;

            .dark & {
                color: #fff;
            }
        }

        small {
            color: #7c8195;
            font-size: 90%;

            .dark & {
                color: #c7c9d1;
            }
        }

        i {
            cursor: pointer;
        }
    }

    &__rezult {
        // min-height: 11.875em;
        // padding-bottom: 1em;
        margin-bottom: 0.875em;
    }

    &__num {
        font-size: 0.75em;
    }

    &__row {
        display: flex;
        align-items: center;
        // margin-bottom: 0.7em;
        gap: 1.5em;
        border-radius: 0.125em;
        padding: 0.25em 0.9375em;
        background: #f0f3f5;
        font-size: 0.9em;

        .dark & {
            background: #2b2f37;
        }

        &_head {
            background: #d7e1e8;

            .dark & {
                background: #16171d;
            }
        }

        &:nth-child(2n) {
            background: #fafdff;

            .dark & {
                background: #363a45;
            }
        }

        > span {
            text-align: left;
            &:first-child {
                width: 2.5em;
                flex: none;
            }

            &:nth-child(2).graduation__label {
                // width: 6em;
            }

            &:nth-child(n + 2) {
                flex-grow: 1;
                // width: 27%;
            }

            &:nth-child(2) {
            }

            &:last-child {
                flex: none;
                width: 6em;
                // text-align: right;

                .btn {
                    text-transform: none;
                    width: 6em;
                }
            }
        }

        .field {
            width: 100%;
            background: rgba(#f5f8fa, 0.3);

            .dark & {
                background: rgba(65, 70, 82, 0.3);
            }
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 0.625em;

        i {
            cursor: pointer;
        }
    }

    &Chart {
        margin-top: 1em;
        height: 12em;
        display: flex;
        flex-direction: column;

        &__wrap {
            flex-grow: 1;
            min-height: 0;
        }
    }

    &__sort {
        margin-left: 0.4em;
        display: inline-flex;

        .up {
            transform: rotate(180deg);
        }
    }

    &__modal {
        [class^='Dropdown_label'] {
            .dark & {
                background: #363a45;
            }
        }

        .form__row + .form__row {
            margin-top: 1em;
        }
        .modal__action {
            .btn {
                flex-grow: 0;
                width: 12em;

                i {
                    margin-right: 0.6em;
                }
            }
            .btn_link {
                width: auto;
            }
        }
    }
}

.pin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__field {
        max-width: 10em;
        text-align: center;
        margin-bottom: 1em;
    }
}
