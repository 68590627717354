.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    color: #22262c;

    .dark & {
       color: #d1d1d1;
    }

    .login-title {
        font-size: 2.25em;

    }

    
   
    .login-form__row {
		input[type=password],
		input[type=login] {
			width: 100%;
		}
    }

    .login-form__privacy {
        display: flex;
        align-items: center;
        gap: 0.75em;
 
        input[type=checkbox] {
            font-size: 1.5em;
        }

        p {
            margin: 0;
            font-size: 0.75em;
            line-height: 1.2;
            
        }

        span {
            font-weight: 500;
            cursor: pointer;
            text-decoration: underline;
        }
    }

  

    input[type=password],
    input[type=login] {

        background-color: $color_withe;
        font-family: $main_font_family;
        color: $login_color_input;
        border: 1px solid #bec9d3;

        padding: 1.2035em 1.1em;
        outline: none;
        font-size: 1em;
        font-weight: 400;
        border-radius: 0.375em;
        transition: 0.3s;
        line-height: 1;

        // Placeholder for input +++++++++++++++
        @include placeholder {
            color: $login_color_input;
        }

        .dark & {
            color: #ffffff;
            background: #363a45;
            border: 1px solid #4a4f5b;
        }

        &:hover {
            // background-color: $input_hover_bg_color;
        }

        &:focus {
            // background-color: $input_focus_bg_color;
        }

        &.invalid {
            // background-color: $input_error_bg_color;
        }

   
    }

    button {
        font-size: 1em;
        font-weight: 700;
        padding: 1.3125em 4.7em;
        line-height: 1;

        box-shadow: 0 6px 35px 0 rgba(0, 181, 255, 0.25);

    }
}

