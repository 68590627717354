.cor_themeSwitcher {
    display: flex;
    align-items: center;
    gap: 0.75em;
    margin-right: 0.75em;

    i {
        font-size: 1.5em;
        line-height: 1;
        color: #ffffff;
        cursor: pointer;

        &.active {
            color: #00B5FF;
            cursor: default;
        }
    }
}
