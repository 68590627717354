@import "../Icon/variables.scss";
.root {
    display: flex;
    align-items: center;
    gap: 1em;

    &__item {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    &__label {
        color: #7C8195;
        font-size: 0.75em;
        font-weight: 500;
    }

    &__input {
        position: relative;

        &:after {
            content: $cor_icon_calendar;
            font-family: 'cor';
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.875em;
            font-size: 0.875em;
            transition: 0.3s;
            color: #A2A6B6;
        
            :global(.dark) & {
                color: #d1d1d1;
            }
        }
        
        input {
            padding-right: 2.5em;
        }
    }
}

