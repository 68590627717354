.cor_checkbox {
	display: flex;
	align-items: center;

	input[type=checkbox] {
		width: 1.3em;
		height: 1.3em;
		flex: none;
		margin-right: 0.5em;
		border: 1px solid #BEC9D3;
		border-radius: 2px;
		cursor: pointer;

		&:checked, &:indeterminate {
			background: #00B5FF;
			border: 1px solid #00B5FF;

			&:before {
				font-size: 0.8em;
			}
		}

		&:disabled {
			opacity: 0.8;
			cursor: default;

			&:before {
				opacity: 1;
			}
		}
	}
}


@import '../variables/variables';
@import '../../Components/ui/Icon/variables.scss';

.form {
    &__row {
        margin-bottom: 15px;

        .btn,
        input:not([type]),
        input[type='date'],
        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='search'],
        input[type='tel'],
        input[type='text'],
        input[type='url'],
        textarea {
            width: 100%;
        }

        textarea {
            max-width: 100%;
            max-width: 100%;
            min-height: 150px;
        }
    }

    &__label {
        display: block;
        font-size: 0.75em;
        font-weight: 500;
        margin-bottom: 0.5em;
        color: #7c8195;

        .dark & {
            color: #c7c9d1;
        }
    }

    &__inline {
        display: flex;
        align-items: center;
        gap: 0.375em;

        span {
            font-size: 0.75em;
            color: #595c6c;
            font-weight: 500;
        }
    }
}
// Input text and textarea ================================================
input:not([type]),
input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
    background-color: #f5f8fa;
    border: 1px solid #bec9d3;
    font-family: $main_font_family;
    color: #1e1f25;
    padding: 0.542em 1em;
    outline: none;
    font-size: 0.75em;
    font-weight: 400;
    border-radius: 0.3125em;
    transition: 0.3s;

    .dark & {
        color: $color_withe;
        background: #363a45;
        border: 1px solid #4a4f5b;
    }

    // &:hover {
    // 	border: 1px solid $input_hover_border_color;
    // 	background-color: $input_hover_bg_color;
    // }
    //
    // &:focus {
    // 	border: 1px solid $input_focus_border_color;
    // 	background-color: $input_focus_bg_color;
    // }

    &.invalid {
        background-color: #f5f8fa;
        border: 1px solid red;
    }
    
    &.form_error {
        border: 1px solid $color_danger;
    }

    // Placeholder for input +++++++++++++++
    @include placeholder {
        color: #7c8195;
        .dark & {
            color: lighten(#7c8195, 10%);
        }
    }
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    appearance: none;
}

/* Для Chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    height: auto;
    appearance: none;
    margin: 0;
}
/* Для Firefox */
input[type='number'] {
    appearance: textfield;
}

// Input checkbox and radio ===============================================
input[type='checkbox']:not([class]),
input[type='radio']:not([class]) {
    appearance: none;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: none;
    height: 0.94em;
    width: 0.94em;
    color: $color_withe;
    // box-shadow: inset 5px 5px 2px rgba(0,0,0,0.1);
    background: transparent;
    border: 1px solid #6f92b9;
    overflow: hidden;
    vertical-align: middle;
    outline: 0;
    text-align: center;
    position: relative;
    border-radius: 0.125em;
    transition: 0.3s;
    transition-property: color, border, background;
    font-size: 1em;

    &:hover {
        border: 1px solid #67d3ff;
    }

    &:not(:disabled),
    &:not(:disabled) {
        cursor: pointer;
    }

    &:before,
    &:before {
        display: block;
        margin-top: -0.2em;
    }

    &:disabled,
    &:disabled {
        color: #ddd;
    }
}

input[type='radio'] {
    &:checked {
        background-color: transparent;

        .dark & {
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }
        }

        &:before {
            content: '';
            width: 0.45em;
            height: 0.45em;
            display: block;
            border-radius: 1px;
            background: #00b5ff;
            margin-top: 0;
        }
    }

    &:disabled:checked:before {
        opacity: 0.7;
    }
}

input[type='checkbox']:not([class]) {
	
    .dark & {
        border-color: #4a4f5b;
    }

	&:before {
        content: '';
        display: block;
        opacity: 0;
    }

    &:checked,
    &:indeterminate {
        background: #00b5ff;
        border-color: #00b5ff;

        &:hover {
            background: #67d3ff;
        }

        .dark & {
            border-color: #00b5ff;
      

            &:hover {
                border-color: #67d3ff;
            }
        }

        &:before {
			content: '';
            display: block;
            width: 0.5em;
            height: 0.3em;
            color: $color_withe;
            border-radius: 0;
            border-left: 0.125em solid;
            border-bottom: 0.125em solid;
            transition-property: color, border, background;
            opacity: 1;
            margin-top: -0.125em;
            transform: rotate(-45deg);
        }
    }

    &:indeterminate:before {
        content: '';
    }

    &:disabled:checked:before,
    &:disabled:indeterminate:before {
        opacity: 0.7;
    }
}

.checkbox {
    display: flex;
    align-items: center;

    input[type='checkbox'],
    input[type='radio'] {
        margin-right: 0.4em;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }

        .dark & {
            background-color: transparent;
            border: 1px solid #6f92b9;

            &:hover {
                border: 1px solid #fff;
                background-color: transparent;
            }
        }

        .dark & {
        }

        &:checked {
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                &:before {
                    background: #67d3ff;
                }

                .dark & {
                    border: 1px solid #6f92b9;

                    &:before {
                        background: #cdd9e8;
                    }
                }
            }

            &:before {
                content: '';
                margin: auto;
                flex: none;
                line-height: 0;
                font-size: 1em;
                width: 0.4em;
                height: 0.4em;
                display: block;
                border-radius: 1px;
                // margin-bottom: -0.2em;
                background: #00b5ff;

                .dark & {
                    background: #ffffff;
                }
            }
        }
    }
}

input[type='checkbox']:is(.checkbox_square) {
    appearance: none;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: none;
    height: 0.94em;
    width: 0.94em;
    color: $color_withe;
    background: transparent;
    border: 0.0625em solid #6f92b9;
    overflow: hidden;
    vertical-align: middle;
    outline: 0;
    text-align: center;
    position: relative;
    border-radius: 0.125em;
    transition: 0.3s;
    transition-property: color, border, background;
    font-size: 1em;

    &:hover {
        border: 0.0625em solid #67d3ff;
        background-color: transparent;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &:before {
        content: '';
        display: block;
        width: 0.625em;
        height: 0.625em;
        border-radius: 0.0625em;
        background-color: #00b5ff;
        opacity: 0;
        font-size: 1em;
        transition: 0.3s;
        margin-top: 0;
    }

    &:disabled,
    &:disabled {
        color: #ddd;
    }

    &:checked {
        background-color: transparent;
    }

    &:checked:before,
    &:indeterminate:before {
        content: '';
        opacity: 1;
    }

    &:indeterminate:before {
        content: '';
    }

    &:disabled:checked:before,
    &:disabled:indeterminate:before {
        color: #aaa;
    }
}

select {
    background: #868f96;
    color: $color_withe;
    font-size: 0.8em;
    border-radius: 0.25em;
    padding: 0.6em;
    border: 1px solid $color_withe;
}

input[type='checkbox'].toggler {
    appearance: none;
    width: 1.93em;
    height: 1em;
    border-radius: 0.5em;
    background: #00b5ff;
    padding: 0;
    border: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:before {
        display: none;
    }

    &:after {
        content: '';
        flex: none;
        position: relative;
        display: block;
        width: 0.7em;
        height: 0.7em;
        border-radius: 50%;
        background-color: $color_withe;
        margin: 0;
        padding: 0;
        border: 0;
        transition: left 0.2s;
        transform: rotate(0deg);
        left: 0.26em;
    }

    &:checked,
    &:indeterminate {
        background: #00b5ff;

        &:hover {
            background: #00b5ff;
        }
        // justify-content: flex-end;

        &:after {
            content: '';
            margin: 0;
            border: 0;
            left: 0.95em;
            font-size: 1em;
        }
    }
}

.number {
    display: flex;
    position: relative;
    width: 4.375em;

    input {
        width: 100%;
    }

    &__btn {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 1.6em;
    }

    .minus,
    .plus {
        // border: 1px solid;
        color: #a2a6b6;
        // border-radius: 0.2em;
        line-height: 0.7;
        // margin: 0.2em;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        padding-bottom: 0.5em;

        &:before {
            // font-family: 'robiclab';
            // content: $cl_icon_caret;
            font-size: 0.4em;
        }
    }

    .plus {
        transform: rotate(180deg);
    }

    .minus {
        margin-top: 0;
    }
}
