.root {

    .body {
    }

    .row {
        display: flex;
        // align-items: center;
        gap: 1em;
        line-height: 1.1;
        margin-top: 0.75em;
      
        span:not([class]) {
            width: 50%;
            &:first-child {
                color: #7C8195;

                :global(.dark) & {
                    color: #C7C9D1;
                }
            }

            &:last-child {
                font-weight: 500;
                color: #1E1F25;

                :global(.dark) & {
                    color: #FFFFFF;
                }
            }
        }
    }

    .col {
        width: 50%;
    }

    .label {
        color: #7C8195;
        margin-bottom: 0.375em;

        :global(.dark) & {
            color: #C7C9D1;
        }
    }

}