.color_menu {
  padding: 0.4em;
  width: 100%;

  &__title {
    margin-bottom: 0.4285714em;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    // gap: 0.5714286em;
  }
  &__item {
    width: 1.07143em;
    height: 1.07143em;
    border-radius: 0.2143em;
    cursor: pointer;
    overflow: hidden;

    &.active {
      border: 1px solid #595c6c;
    }

    &.disabled {
      position: relative;
      border: 1px solid #595c6c;
      &::before {
        position: absolute;
        top: 50%;
        right: -0.26em;
        content: '';
        width: 1.51523083em;
        height: 1px;
        background-color: #595c6c;
        transform: rotate(-45deg);
      }
    }
  }
}
