@import "../../scss/variables/variables";
@import "../ui/Icon/variables";

.cor_main .modal, .cor_header .modal {
    display: none;
    background: rgba(#2B3C4F, 0.5);
    color: #1E1F25;

    .dark & {
        color: $dark_theme_color;
        background: rgba(#4F5E70, 0.5);
    }

    &.open {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__close {
        position: absolute;
        right: 2.15em;
        top: 1.75em;
        width: 0.9em;
        height: 0.9em;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        transition: color 0.3s;
        color: #666F7E;

        .dark & {
            color: #C7C9D1;
        }

        &:after, &:before {
            border: 0;
            transform: none;
        }

        &:before {
            font-family: '#{$icomoon-font-family}';
            content: $cor_icon_close;
        }

        &:hover {
            color: $color_primary;
        }
    }

    &__dialog {
        // margin-top: 6em;
        position: relative;
        width: 100%;
        max-width: 35em;
        padding: 1.875em;
        border-radius: 0.625em;
        animation: showModal 0.5s;
        background-color: $light_theme_background;
        // box-shadow: 0 0 1.3em rgba($color_primary, 0.8);
        // text-align: center;

        .dark & {
            // background-color: $dark_theme_background;
            background-color: #282B33;
        }

        &_browsing {
            max-width: 90vw;
            height: 90vh;
        }

        &_xxl {
            max-width: 90vw;
        }

        &_xl {
            max-width: 92em;
        }

        &_lg {
            max-width: 65em;
        }
        
        &_md {
            max-width: 43.125em;
        }

        &_sm {
            max-width: 33em;
        }

        &_xs {
            max-width: 27.5em;
        }

        &_full {
            max-width: 100%;
            height: 100%;
        }

        &.p-0 {
            padding: 0;
        }
    }

    &__message {
        font-size: 1.125em;
        line-height: 1.22;
        font-weight: 600;
        margin: 0 0 1.1em;
        padding-right: 2em;
        color: #1E1F25;

        .dark & {
            color: $color_withe;
        }
    }

    &__body {
        margin: 1.25em 0;
    }

    &__row {
        margin-bottom: 0.75em;

        &:last-child {
            margin-bottom: 0;
        }

        &.flex {
            display: flex;
            gap: 1.25em;

            > * {
                width: 50%;
            }
        }
    }

    &__label {
        color: #7C8195;
        font-size: 0.75em;
        margin-bottom: 0.5em;

        .dark & {
            color: #ffffff;
        }
    }

    &__input {
        width: 100%;
    }

    &__dropdown {
    }

    &__img {
        width: 100%;
        height: 50vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__action {
        margin-top: 1.25em;
        display: flex;
        gap: 1.25em;

        .cor_btn {
            flex-grow: 1;
            width: 15.1em;
            white-space: nowrap;
        }

        &_end {
            justify-content: flex-end;

            .cor_btn {
                flex-grow: 0;
            }
        }

        &_start {
            justify-content: flex-start;

            .cor_btn {
                flex-grow: 0;
            }
        }
    }
}

@keyframes showModal {
    0% {
        transform: translateY(2em);
    }

    100% {
        transform: translateY(0);
    }
}

.browsing {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: -1em;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 1.25em 1.875em;
    }

    &__title {
        color: #1E1F25;
        font-size: 1.125em;
        font-weight: 600;

        .dark & {
            color: #d1d1d1;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        gap: 0.5em;

        .cor_icon { 
            cursor: pointer;
        }
    }

    &__main {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        height: 28em;
        margin-top: 1.25em;
    }

    &__img {
        height: 100%;
        
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
      
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.625em;
        gap: 1.875em;

        .cor_icon {
            cursor: pointer;
            font-size: 1.5em;
        }
    }

    &__btn {
        display: flex;
        align-items: center;

        &_next {
            flex-direction: row-reverse;
        }

        gap: 0.625em;
        color: #666F7E;
        font-size: 0.875em;
        cursor: pointer;

        .dark & {
            color: #d1d1d1;
        }

        .cor_icon {
            font-size: 1.25em;
        }


    }

}

