.root {
    // border: 1px solid #000;

}

.header {

    &__nav {
        font-weight: 600;
    }

    &__time {}
}


.question {
    position: relative;
    padding-left: 1.125em;


    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.1875em;
      background-color: #00B5FF;
    }

    &__name {
        font-size: 1.625em;
        font-weight: 600;

        span {
            color: #00B5FF;
        }
    }
}

.info {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 1.125em;
    color: #7C8195;

    :global(.dark) & {
        color: #BEC9D3;
    }
}

.section {
    margin-top: 1.875em;

    &:first-child {
        margin-top: 0;
    }
}

.row {
    display: flex;
    align-items: center;
    gap: 1.25em;
    min-width: 1px;

    margin-top: 1.25em;

    &:first-child {
        margin-top: 0;
    }

    label {
        font-size: 1.25em;
        cursor: pointer;
        min-width: 1px;
        
        span {
            min-width: 1px;
        }
    }

    &_center {
        align-items: flex-start;
    }
}

.sequence {
    display: flex;
    flex-direction: column;

    gap: 1em;

    &__wrap {
        min-height: 2.0625em;
    }

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1em;
    }

    &__btn {
        font-size: 0.75em;
        font-weight: 500;
        padding: 0.75em 1em;
        outline: none;
        text-decoration: none;
        display: inline-block;
        text-align: center;
        max-width: 100%;
        cursor: pointer;
        border-radius: 0.1875em;
        transition: 0.3s;
        transition-property: background color border-color;
        color: #7C8195;
        background-color: #f5f8fa;
        border: 1px solid #BEC9D3;
    
        &:hover:not(:disabled) {
            border: 1px solid #00B5FF;
        }

        :global(.dark) & {
            border: 1px solid #343640;
            color: #d1d1d1;
            background-color: #1E1F25;
        }

    }
}

.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.875em;
 
    
    &__empty {
        min-height: 9.875em;
    }
    &__full {
        min-height: 9.875em;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        gap: 1.875em;

    }

    &__btn {
        display: block;
        width: 17.5em;
        height: 10em;
        border-radius: 0.3125em;
        padding: 0;
        overflow: hidden;
        border: 1px solid #bec9d3;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        max-width: 100%;
        transition: 0.3s;
        font-size: 1em;

        :global(.dark) & {
            border: 1px solid #4a4f5b;
        }

        &:hover {
          border: 1px solid #00b5ff;
        }
    }

    &__img {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__fullScreen {
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 5;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 2em;
        height: 2em;

        background-color: #f5f8fa;
        border: 0.0625em solid #bec9d3;
        border-radius: 0.3125em;
        transition: 0.3s;
        color: #1e1f25;

        :global(.dark) & {
            color: #ffffff;
            background: #363a45;
            border: 0.0625em solid #4a4f5b;
        }
    }

    &__checkbox {
        position: relative;

        display: block;
        width: 17.5em;
        height: 10em;
        border-radius: 0.3125em;
        padding: 0;
        overflow: hidden;
        border: 1px solid #bec9d3;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        max-width: 100%;
        transition: 0.3s;
        font-size: 1em;

        :global(.dark) & {
            border: 1px solid #4a4f5b;
        }

        &:hover {
          border: 1px solid #00b5ff;
        }

        > span {
            display: block;
            width: 100%;
            height: 100%;
        }

        label {
            display: block;
            width: 100%;
            height: 100%;
        }

        input {
            position: absolute;
            top: 0.8em;
            left: 0.8em;
            z-index: 5;
            background-color: #ffffff;
            
            &:hover,
            &:checked {
                background-color: #ffffff;
            }

            :global(.dark) & {
                background-color: #363a45;
                &:hover,
                &:checked {
                    background-color: #363a45;
                }

                &:checked {
                    &:before {
                      background-color: #00b5ff;
                    }
                }
            }
        }
    }
}

.textarea {
    position: relative;
    width: 100%;

    span {
        position: absolute;
        z-index: 1;
        top: 0.8em;
        right: 0.5em;
        font-size: 0.75em;
        color: #7c8195;

        :global(.dark) & {
            color: lighten(#7c8195, 10%);
        }
    }

    textarea {
        padding-right: 4.5em;
    }
}

.video {
    position: relative;
    width: 51.175em;
    height: 29em;
    border-radius: 0.3125em;
    border: 0.0625em solid #bec9d3;
    overflow: hidden;

    :global(.dark) & {
        border: 0.0625em solid #4a4f5b;
    }

    video {
        display: block;
        width: 100%;
        height: 100%;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


  
}

.questionFile {
    position: relative;
    width: 21em;
    height: 12em;
    border-radius: 0.3125em;
    border: 0.0625em solid #bec9d3;
    overflow: hidden;

    :global(.dark) & {
        border: 0.0625em solid #4a4f5b;
    }

    video {
        display: block;
        width: 100%;
        height: 100%;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &_audio {
        width: auto;
        height: auto;
        border: 0;
    }
}




.footer {

    > * {
        flex-grow: 1;
        text-align: center;
        justify-content: center;
        width: 25%;

        &:first-child {
            justify-content: flex-start;
            text-align: left;
        }
    
        &:last-child {
            justify-content: flex-end;
            text-align: right;
        }
    }

    .btn {
        display: inline-flex;
        align-items: center;
        gap: 0.5em;
        outline: none;
        text-decoration: none;
        border: 0;
        padding: 0;
        background: transparent;
        font-size: 1em;
        font-weight: 400;
        color: #666F7E;
        line-height: 1;
        cursor: pointer;
    
        :global(.dark) & {
            color: #E8E8E8;
        }
    
        &:disabled {
            cursor: default;
            opacity: 0;
        }
    }
}
