.root {
}

.head {
    display: flex;
    justify-content: space-between;
    gap: 3.625em;
    margin-bottom: 1.25em;

    [class^='Input_root--'] {
        width: 18.125em;
    }

    &Item {
        display: flex;
        align-items: center;
        gap: 0.625em;
        flex-grow: 1;
    }
    &Label {
        font-size: 0.75em;
    }

    [class^='Dropdown_root--'] {
        width: 7.5em;

        :global(.dark) & {
            background: #363a45;

            &:hover {
                background: #363a45;
            }
        }
    }
}

$colPadding: 0.9em 1.2em;
.table {
    table {
        border-collapse: collapse;
        width: 100%;
        font-size: 0.8em;

        thead {
            th {
                text-align: left;
                background: #d7e1e8;
                color: #444754;
                font-size: 1em;
                font-weight: 500;
                border: 0;
                padding: $colPadding;
                position: relative;

                i {
                    position: absolute;
                    right: 1.2em;
                    cursor: pointer;
                }

                :global(.dark) & {
                    background: #16171d;
                    font-weight: 400;
                    color: #c7c9d1;
                }

                [class^='Dropdown_label'] {
                    color: #1e1f25;

                    :global(.dark) & {
                        color: #c7c9d1;
                    }
                }
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background: #fff;

                    :global(.dark) & {
                        background: #363a45;
                    }
                }
                &:nth-child(even) {
                    background: #f0f3f5;

                    :global(.dark) & {
                        background: #2b2f37;
                    }
                }
            }
            td {
                padding: $colPadding;
                border: 0;

                :global(.dark) & {
                    color: #fff;
                }
            }
        }
    }

    &Name {
        display: flex;
        align-items: center;
        gap: 0.5em;

        i {
            color: #a5a5a5;
            font-size: 1.3em;
        }
    }

    &Action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.7em;
        
        button {
            border: 0;
            background-color: transparent;
            padding: 0;
            cursor: pointer;
            
            :global(.dark) & {
                color: #ffffff;

                &:disabled {
                    opacity: 0.3;
                }
            }

            &:disabled {
                cursor: default;
            }
        }

        [class*='trash'] {
            cursor: pointer;
        }
    }

    &Connection {
        display: flex;
        align-items: center;
        gap: 0.4375em;
        cursor: pointer;
    }

    &PM {
        border: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.2em;
        border-radius: 2px;
        line-height: 1;
        width: 1em;
        height: 1em;
        position: relative;

        &:before {
            font-weight: 600;
            font-size: 0.5em;
            position: absolute;
            letter-spacing: 0.05em;
        }
    }

    &Charge {
        display: flex;
        align-items: center;
        gap: 0.5em;

        i {
            color: #0ec60a;
            font-size: 1.1em;
            line-height: 0.7;
        }
    }
}

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5625em 1.875em;
    margin: 0 -1.875em -1.875em;
    border-radius: 0 0 0.625em 0.625em;
    background: #f5f8fa;
    border-top: 1px solid #bec9d3;

    &Label {
        font-size: 0.75em;
    }

    i,
    &Icons {
        display: flex;
        gap: 1.3125em;
    }

    :global(.dark) & {
        border-top: 1px solid #4a4f5b;
        background: #2d3039;

        i {
            border: 1px solid #4a4f5b;
            color: #c7c9d1;

            &[class*='active'] {
                background: #00b5ff;
                border-color: #00b5ff;
                color: #fff;
            }
        }
    }
}

.btn {
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;

    button {
        flex: none !important;
        width: auto !important;
        background: transparent;
        cursor: pointer;
        height: auto;
        color: #a5a5a5 !important;
        border: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:disabled {
            cursor: default;
            opacity: 0.8;
        }

        :global(.dark) & {
            color: #fff !important;
        }

        // &[class*='active'] {
        //     background: #00b5ff;
        //     border-color: #00b5ff;
        //     color: #fff !important;
        // }

        &:before {
            font-size: 0.9em;
            // position: absolute;
        }
    }
}
