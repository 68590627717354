@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.labWorksTestsModels {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	$HeaderSize: 1em;
	$RCSubTitlColor: #7C8195;
	$RCListBoxTextColor: #666F7E; //#BEC9D3;
	$RCListBoxBkndColor: #F5F8FA;

	&__stretch {
        display: flex;
        justify-content: space-between;
		align-items: center;
    }

    &__MainTitle {
        font-size: 1.0em;
        position: relative;
        top: -0.4em;
        left: 0.2em;
    }

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 3.6875em;
		padding: 0.625em 1.8125em;
		font-size: $HeaderSize; //1em;
		border-bottom: 2px solid #CECECE;

		&Title {
			font-weight: 600;
			color: #1E1F25;
		}

		&Time {
			font-size: 0.8em;
		}
	}

	&__comment {
		margin-top: 15px;
        font-size: 1em;
	}

	&__body {
		flex-grow: 1;
		min-height: 0;
		position: relative;
		background: #fcfcfc;
		background-position: center center;
		z-index: 1;
		padding: 1.0em 1.875em 0;
		overflow-y: overlay;
		display: flex;
		flex-direction: column;

		@include scrollbar;

		> div {
			// min-height: 0;
		}

	}

	&__lws {
		padding: 1em 0 0 1.5em;
	}

	&__action {
		padding: 1.25em 2.625em 1.25em 1.375em;
		border-bottom: solid 1px lighten($color: grey, $amount: 40%);

		input.lwtSelect_input {
			padding-right: 3em;
			@include placeholder {
				color: #595c6c;
				.dark & {
					color: $dark_theme_color;
				}
			}
		}
	}
}
//Great Tool: https://pinetools.com/image-color-picker
