@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?on5nlh') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?on5nlh') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?on5nlh##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon_"], [class*=" icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_double-chart {
  &:before {
    content: $icon_double-chart;     
  }
}
.icon_turn-on {
  &:before {
    content: $icon_turn-on; 
  }
}
.icon_sort-desc {
  &:before {
    content: $icon_sort-desc; 
  }
}
.icon_sort-asc {
  &:before {
    content: $icon_sort-asc; 
  }
}
.icon_caret {
  &:before {
    content: $icon_caret; 
  }
}
.icon_check {
  &:before {
    content: $icon_check; 
  }
}
.icon_grid {
  &:before {
    content: $icon_grid; 
  }
}
.icon_smartphone {
  &:before {
    content: $icon_smartphone; 
  }
}
.icon_file {
  &:before {
    content: $icon_file; 
  }
}
.icon_camera {
  &:before {
    content: $icon_camera; 
  }
}
.icon_toggler {
  &:before {
    content: $icon_toggler; 
  }
}
.icon_full-screen {
  &:before {
    content: $icon_full-screen; 
  }
}
.icon_gear {
  &:before {
    content: $icon_gear; 
  }
}
.icon_minus {
  &:before {
    content: $icon_minus; 
  }
}
.icon_plus {
  &:before {
    content: $icon_plus; 
  }
}
.icon_pause {
  &:before {
    content: $icon_pause; 
  }
}
.icon_play {
  &:before {
    content: $icon_play; 
  }
}
.icon_stop {
  &:before {
    content: $icon_stop; 
  }
}
.icon_marker-plus {
  &:before {
    content: $icon_marker-plus; 
  }
}
.icon_marker-minus {
  &:before {
    content: $icon_marker-minus; 
  }
}
.icon_bluetooth {
  &:before {
    content: $icon_bluetooth; 
  }
}
.icon_usb {
  &:before {
    content: $icon_usb; 
  }
}
.icon_remote-control {
  &:before {
    content: $icon_remote-control; 
  }
}
.icon_speedometer {
  &:before {
    content: $icon_speedometer; 
  }
}
.icon_pulse {
  &:before {
    content: $icon_pulse; 
  }
}
.icon_dotts {
  &:before {
    content: $icon_dotts; 
  }
}
.icon_update {
  &:before {
    content: $icon_update; 
  }
}
.icon_timer {
  &:before {
    content: $icon_timer; 
  }
}
.icon_range-plus {
  &:before {
    content: $icon_range-plus; 
  }
}
.icon_range-minus {
  &:before {
    content: $icon_range-minus; 
  }
}
.icon_cursor-mode {
  &:before {
    content: $icon_cursor-mode; 
  }
}
.icon_trash {
  &:before {
    content: $icon_trash; 
  }
}
.icon_export {
  &:before {
    content: $icon_export; 
  }
}
.icon_import {
  &:before {
    content: $icon_import; 
  }
}
.icon_printer {
  &:before {
    content: $icon_printer; 
  }
}
.icon_record {
  &:before {
    content: $icon_record; 
  }
}
.icon_xls {
  &:before {
    content: $icon_xls; 
  }
}
.icon_csv {
  &:before {
    content: $icon_csv; 
  }
}
.icon_log {
  &:before {
    content: $icon_log; 
  }
}
.icon_sensors {
  &:before {
    content: $icon_sensors; 
  }
}
.icon_bullet-list {
  &:before {
    content: $icon_bullet-list; 
  }
}
.icon_sun {
  &:before {
    content: $icon_sun; 
  }
}
.icon_moon {
  &:before {
    content: $icon_moon; 
  }
}
.icon_question {
  &:before {
    content: $icon_question; 
  }
}
.icon_close {
  &:before {
    content: $icon_close; 
  }
}
.icon_capture {
  &:before {
    content: $icon_capture; 
  }
}
.icon_drag {
  &:before {
    content: $icon_drag; 
  }
}
.icon_table-mob {
  &:before {
    content: $icon_table-mob; 
  }
}
.icon_chart-mob {
  &:before {
    content: $icon_chart-mob; 
  }
}
.icon_change {
  &:before {
    content: $icon_change; 
  }
}
.icon_zoom {
  &:before {
    content: $icon_zoom; 
  }
}
.icon_arrow-sort {
  &:before {
    content: $icon_arrow-sort; 
  }
}
.icon_battery-full {
  &:before {
    content: $icon_battery-full; 
  }
}
.icon_battery-middle {
  &:before {
    content: $icon_battery-middle; 
  }
}
.icon_battery-min {
  &:before {
    content: $icon_battery-min; 
  }
}
.icon_battery-charge {
  &:before {
    content: $icon_battery-charge; 
  }
}
.icon_speed-dashboard {
  &:before {
    content: $icon_speed-dashboard; 
  }
}
.icon_temperature {
  &:before {
    content: $icon_temperature; 
  }
}
.icon_settings {
  &:before {
    content: $icon_settings; 
  }
}

