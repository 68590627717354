.root {
    padding: 0 0 0 0;
    margin: 1em 0 2em;
    font-size: 0.9em;
    font-weight: 400;
    list-style: none;

    li {
        margin-bottom: 0.5em;
        display: flex;
        align-items: center;
    }

    b {
        margin-right: 0.5em;
        font-weight: 500;
    }

    a {
        color: inherit;
    }
}
