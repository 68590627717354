@import '../../scss/variables/variables';

button.active {
    background: #67d3ff !important;
    color: #fff !important;
}

.rangeDrop {
    position: relative;

    &:hover {
        .rangeDrop__list {
            display: block;
        }
    }

    &__list:not(:empty) {
        display: none;
        position: absolute;
        bottom: 100%;
        border-radius: 0.125em 0.125em 0 0;
        border: 1px solid #bec9d3;
        box-shadow: 0 0 0.9375em rgba(52, 61, 70, 0.09);
        padding: 0.4em;
        left: -0.1em;
        width: 1.875em;
        background-color: $light_theme_background;

        .dark & {
            background: #363a45;
            border: 1px solid #343640;
        }
    }

    &__item {
        &:nth-child(n + 2) {
            margin-top: 0.5em;
        }
    }

    &__color {
        border: 1px solid transparent;
        width: 0.9375em;
        height: 0.9375em;
        border-radius: 0.1875em;

        &:hover {
            border: 1px solid #67d3ff;
        }
    }
}
