@import "../../../scss/variables/variables";

.cor_tooltip {
    display: inline-block;
    position: relative;
   
    // width: fit-content;
    // flex: none;

    .cor_icon {
        font-size: 1.25em;
        color: #7C8195;
        transition: color 0.3s;
    }

    &:hover:not(.disabled &) {
        cursor: pointer;
        .cor_icon {
            color: #00B5FF;
        }
        
        .cor_tooltip__content {
            display: block;
            animation-duration: 0.3s;
            
            &.bottom {
                animation-name: tooltipBottom;
            }
            &.top {
                animation-name: tooltipTop;
            }
            &.left {
                animation-name: tooltipLeft;
            }
            &.right {
                animation-name: tooltipRight;
            }
            &.custom {
                animation-name: tooltipCustom;
            }
        }
    }

    &__content {
        display: none;
        
        position: absolute;
        z-index: 10;
    
        font-size: 0.875em;
        width: max-content;
        text-align: left;
        padding: 1em;
        border-radius: 0.75em;
        line-height: 1.2;

        background-color: #ffffff;
        border: 1px solid;
        color: #383E47;
        border-color: #BEC9D3;
        box-shadow: (0px 9px 15px rgba(52, 61, 70, 0.09));

        .dark & {
            background: $footer_dark_button_bg;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            color: $footer_dark_color;
            border-color: $footer_dark_dropdown_border_color;;

            &:after {
                border-color: $footer_dark_dropdown_border_color;;
            }
        }

        &:after {
            content: '';
            position: absolute;

            display: block;
            width: 1em;
            height: 1em;
            border: 1px solid;
            border-left: 0;
            border-bottom: 0;

            border-color: #BEC9D3;
            background-color: inherit;
        }

        &.bottom {
            top: 2.25em;
            left: 50%;
            transform: translate(-50%, 0);

            &:after {
                top: -0.52em;
                left: 50%;
                transform: translateX(-50%)rotate(-45deg);
            }
        }
        &.top {
            bottom: 2.25em;
            left: 50%;
            transform: translate(-50%, 0);

            &:after {
                bottom: -0.52em;
                left: 50%;
                transform: translateX(-50%)rotate(135deg);
            }
        }
        &.left {
            top: 50%;
            right: 2.25em;
            transform: translate(0, -50%);

            &:after {
                right: -0.52em;
                top: 50%;
                transform: translateY(-50%)rotate(45deg);
            }
        }
        &.right {
            top: 50%;
            left: 2.25em;
            transform: translate(0, -50%);

            &:after {
                left: -0.52em;
                top: 50%;
                transform: translateY(-50%)rotate(-135deg);
            }
        }
        &.custom {
            top: 2.75em;
            right: -1.7em;
            width: 15.5em;

            &:after {
                top: -0.52em;
                right: 2.175em;
                transform: rotate(-45deg);
            }
        }

    }
}

@keyframes tooltipBottom {
    0% {
        transform: translate(-50%, 0.5em);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}
@keyframes tooltipTop {
    0% {
        transform: translate(-50%, -0.5em);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}
@keyframes tooltipLeft {
    0% {
        transform: translate(-0.5em, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}
@keyframes tooltipRight {
    0% {
        transform: translate(0.5em, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}
@keyframes tooltipCustom {
    0% {
        transform: translate(0, 0.5em);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
