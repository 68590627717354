@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.instruments {
    height: 100%;
	width: 100%;
	$RCTextColor: #1E1F25;

    &__full {
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
    }    

    &__modalDlg {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__head {
        font-size: 1.2em;
        font-weight: 800;
        padding: 0.5em 0;
    }

    &__stretch {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        font-size: 1.125em;
		width: 100%;
        padding-bottom: 1.5em;

        &Left {
            justify-content: start;
        }
        &Right {
            justify-content: end;
        }
        &Center {
            justify-content: center;
        }
    }

    &__flexColumn {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: center;

        min-width: 0;  //mandatory when use flex-grow: 1;
        min-height: 0; //mandatory when use flex-grow: 1;

        &Video {
            justify-content: space-between;
            height: 100%;
            .dark & {
                color: $lw_dark_theme_color;
                background-color: $lw_dark_theme_bknd_color;
            }
        }
    }

    &__title {
        height: 2em;
        font-weight: 600;
        padding-bottom: 1em;
    }

    &__pad {
        padding: 0 1.1em;
    }

    &__head {
        font-size: 1.2em;
        font-weight: 800;
        padding: 0.5em 0;
    }

    &__preview {
        font-size: 1em;
        font-weight: 600;
        padding-bottom: 0.8em;
    }

    &__circle {
        width: 0.7em;
        height: 0.7em;
        border-radius: 50%;
        margin-right: 0.4em;

        &Color0 {
            background: white;
        }
        &Color1 {
            background: red;
        }
        &Color2 {
            background: blue;
        }
    }

    &__Row {
		width: 100%;
		display: flex;
        padding: 0.1em 0.4em 0.6em 0;
        line-height: 1.4em;
        font-size: 0.9em;
    }

    &__SelectForm {
		color: $RCTextColor;
		background-color: $lw_table_listbox_bknd_color;
		border: solid 1px gray;
		font-size: 0.9em;
		border-radius: 2px;
        width: 100%;
        outline: none;

        .dark & {
            color: #e6e6e6;
            border: solid 1px #e6e6e6;
            background-color: #282B34;
        }
	}

    &__va {
        &Hidden { 
            display: none;
        }
        &Invisible { 
            visibility: hidden;
        }

        &Buttons {
            width: 100%;
            height: 2em;
        }

        &ImageBtnBox {
            // cursor: pointer;
            // border: solid 1px rgba(175,180,187, 0.3); // #a8adb6; 
            // padding: 0.3em 0.5em;

            // &:hover {
            //     background-color: darken(white, 2%);

            //     .dark & {
            //         background-color: lighten(#1f2229, 10%);
            //         color: white;
            //     }
            // }

            // &Sel {
            //     background-color: darken(white, 15%);
            //     color: black;
            //     .dark & {
            //         background-color: lighten(#1f2229, 15%);
            //         color: white;
            //     }
            // }
        }
    }

    &__warning {
        width: 95%;
        padding: 1em 2em;
        background-color: #fff9ea;
        border-radius: 0.375em;

        .dark & {
            background-color: rgba(#363A45, 1);
        }

        &Icon {
            color: #f86d6b;
            padding-right: 0.5em;
            font-size: 40px;  //to increase size of the icon
        }

        &Text {
            padding: 0.1em 0;
        }
    }

    &__soundVolumeRange {
        width: 95%;
    }

    &__frequencyRange {
        width: 95%;
        padding: 2em 0;
    }

    &__frequencyValues {
        width: 95%;
        font-size: 1em;
        padding-bottom: 2em;

        &_item {
            color: #666F7E;
            &:first-child {
                margin-left: 1.75em;
            }

            &:last-child {
                margin-right: 1.75em;
            }

            .dark & {
                color: #d1d1d1;
            }
        }
 
    }
    &__frequencyChange {
        font-weight: 800;
        font-size: 1.5em;  //to increase size of the icon
        min-width: 5.75em;
        text-align: center;
    }

    &__frequencyArrow {
        color: #666F7E;
        font-size: 0.875em;
        font-weight: bold;
        padding: 0.5em;
        cursor: pointer;

        .dark & {
            color: #d1d1d1;
        }
    }

    &__volume {
        color: #666F7E;
        font-size: 1.4em;
        .dark & {
            color: #d1d1d1;
        }
    }

    &__btns {
        width: 100%;
        border-radius: 0.375em;

        &Group1 {
            width: 25%;
            display: flex;
            align-items: center;
        }
        
        &Group2 {
            width: 25%; 
            display: flex; 
            justify-content: left;
            align-items: center;

            &_dropdown {
                width: 5em;
            }
        }
        &Group3 {width: 25%;}
        &Group4 {width: 25%;}

        &SoundForm {
            width: 9.5em;
        }

        &WaveForm {
            font-size: 0.9em;
            padding-right: 0.5em;
            margin-bottom: 0;
        }
        &WaveFormIcons {
            position: relative;
            top: 0.5em;
        }
        &WaveFormIcon {
            //border: 0;
            outline: 0;
            font-size: 1em;
            line-height: 1;
            padding: 0 0.25em;
            height: 1.875em;
            min-width: 1.875em;
            background: $footer_button_bg;
            border-radius: 0.125em;
            transition: outline 0.2s;
            cursor: pointer;
            border: solid 1px lighten($color: grey, $amount: 30%);
            position: relative;
            top: -0.5em;

            &:hover {
                background: lighten($color: $footer_button_hover_bg, $amount: 30%); //$footer_button_hover_bg;
            }
            &.active {
                background: $footer_button_active_bg;
                color: $footer_color;
                position: relative;
                //top: 0.5em;
            }
        
            &_tooltip {
                &:hover {
                    .cor_footerNav__tooltip {
                        display: block;
                        animation: fadeUp 0.3s;
                    }
                }
            }
        }

        &SoundVolume {
            font-size: 0.9em;
        }

        .calcModel__docs__drop {
            width: 8.125em;
        }
    }
    &__Color {
        width: 100px; 
        height: 100pz;
        border: solid 1px red;
    }

    &__select {
        width: 12em;
        font-size: 0.88889em;

    }

    &__col {
        width: 50%;
        padding-left: 1em;

        &_full {
            width: 100%;
        }
    }

}

#idFulScreen:fullscreen {
    display: flex;
    align-items: center;
    justify-content: center;
}
