.deadline_button {
  position: relative;
  right: 0;
  top: 0;
  width: 9.0625em;
  padding: 0.5em 0.625em;
  background: #fff;
  background-image: url('../../../assets/icons/card-menu/14_calendar.svg');
  background-repeat: no-repeat;
  background-position: right 0.625em bottom 50%;
  background-size: 0.625em;
  border-radius: 0.125em;
  border: 0.0625em solid #bec9d3;
  cursor: pointer;
  color: #7c8195;

  .dark & {
    color: #a2a2a5;
    background: #1f2026;
    border: 1px solid #4a4f5b;
  }

  &__label {
    display: block;
    position: relative;
    font-size: 0.75em;
    // color: #595c6c;
  }
  &__icon {
    position: absolute;
    // right: 0.625em;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    // top: 0.4em;
    stroke: #7c8195;
  }

  &__input {
    position: absolute;
    left: -0.0625em;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }
}
