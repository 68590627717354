.root {
    display: flex;
    // flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    min-height: 0;

    > div {
        display: flex;
        flex-grow: 1;
        min-height: 0;
        min-width: 0;
        // height: auto !important;
        height: 100% !important;

        > ymaps {
            flex-grow: 1;
            min-height: 0;
            min-width: 0;
        }
    }

    [class*='leaflet-control-attribution'] {
        display: none;
    }

    [class*='leaflet-container'] {
        height: 100%;
        width: 100%;
    }

    ymaps[class$='balloon__content'] {
        padding: 0.9375em;
        box-shadow: 0em 0.5625em 0.9375em 0em #343d4617;

        :global(.dark) & {
            border: 1px solid #4a4f5b;
            background: transparent;
            color: #fff;
        }
    }

    ymaps[class$='balloon__layout'] {
        padding: 0;

        :global(.dark) & {
            background: transparent;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            filter: blur(7px);

            :global(.dark) & {
                background: rgba(#4a4f5b, 0.8);
            }
        }
        > ymaps {
            // filter: blur(0);
        }
    }

    ymaps[class$='balloon__tail'] {
        display: none;
    }

    // ymaps[class$='balloon__close'] {
    //     color: #fff;
    // }

    ymaps[class$='balloon__close-button'] {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.8em;
        height: 0.8em;
        margin: 0.3em;

        :global(.dark) & {
            background: none;
            color: #fff;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            border-top: 1px solid;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
