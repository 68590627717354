.cor-work {
    display: flex;
    flex-direction: column;
    height: 100%;

    .cor_dashboardBox__body & {
        margin: 0 -1.4em;
    }

    &_center {
        padding: 0 1.875em;
    }

    &__header {
        padding: 0 0 1.25em;
        border-bottom: 1px solid #CECECE;
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        width: 100%;
        height: 100%;
    }

    &__footer {
        padding: 1em 0;
        border-top: 1px solid #CECECE;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.col-w33 {
            .cor-work__col {
                width: 33.3%;
                text-align: center;
                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    &__subject {
        margin-top: 0.625em;
        font-size: 2em;
    }

    &__main {
        margin-top: 2.125em;
    }

    &__tasks {
        margin-top: 1.875em;
    }

    &__task {
        display: flex;
        align-items: center;
        gap: 0.75em;
        margin-top: 0.75em;
        
        &:first-child {
            margin-top: 0;
        }

        input {
            font-size: 1.33em;
            margin-bottom: -0.125em;
        }

        label {
            display: flex;
            align-items: center;
            gap: 1em;
        }
    }

    &__label {
        padding: 0.125em 0 0.125em 0.875em;
        border-left: 0.1875em solid #00B5FF;
        line-height: 1.3;
        color: #1E1F25;
        font-size: 1.125em;

        .dark & {
            color: #d1d1d1;
        }

        span {
            font-weight: 600;
            margin-right: 0.25em;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.875em;

    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.625em;
        font-size: 1em;

        color: rgba(#666F7E, 0.7);
        transition: 0.3s;

        cursor: pointer;

        .cor_icon {
            font-size: 1.25em;
        }

        &:hover {
            color: rgba(#666F7E, 1);
        }

        .dark & {
            color: rgba(#fff, 0.8);

            &:hover {
                color: rgba(#fff, 1);
            }
        }
    }

    &__result {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            width: 100%;
        }

        &_caption {

            &.good {color: #089B3A;}
            &.bad {color: #FF1818;}
            &.unknown {color: #C96203;}
        }

        &_icon {
            
            font-size: 8.25em;
        }

        &_title {
            font-size: 1.75em;
            font-weight: 500;
            margin-top: 0.375em;
        }

        &_rating {
            color: #1E1F25;
            font-size: 2.5em;
            font-weight: 500;
            margin-top: 0.5em;
            .dark & {
                color: #d1d1d1;
            }
        }

        &_info {
            color: #1E1F25;
            font-size: 0.875em;
            line-height: 1.4;
            margin-top: 0.75em;

            .dark & {
                color: #d1d1d1;
            }
        }

        &_methodology {
            color: #00B5FF;
            font-size: 0.875em;
            cursor: pointer;
            text-decoration: underline; 
            margin-top: 0.75em;
        }

        &_buttons {
            margin-top: 1.25em;
            display: flex;
            gap: 1.25em;

            .cor_btn {
                // flex: none;
                width: 16.667em;
            }


        }
    }
}