@import '../../scss/variables/variables.scss';
@import '../../scss/mixins/mixin.scss';

.head {
    margin-bottom: 1.5em;
}

.content {
    display: flex;
}

.list {
    overflow: hidden auto;
    max-height: 10em;
    flex-grow: 1;
    // margin-right: 2em;

    @extend %scrollbar;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            margin-bottom: 0.4em;
        }

        label {
            display: flex;
            align-items: center;

            input[type='checkbox'] {
                margin-right: 0.7em;

                &:not([checked]) {
                    // background-color: $color_withe;
                }
            }
        }
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
