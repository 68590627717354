.options__button {
  height: 20px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  &_svg {
    stroke: #666f7e;
    transition: background-color 0.2s ease, border-radius 0.2s ease;

    &.open {
      background-color: rgba(51, 196, 255, 0.15);
      border-radius: 4px;
      stroke: #00b5ff;
    }
  }
}