.root {
    display: flex;
    gap: 0.875em;

    .number {
        display: flex;
        position: relative;
        width: 4.375em;

        input {
            padding-right: 1.5em;
            text-align: center;
        }
        
        button {
            :global(.dark) & {
                color: #ffffff;
            }
        }

    }
}

.item {
    flex: none;
}

.legend {
    font-size: 0.75em;
    margin-bottom: 0.5em;
    font-weight: 500;
}
