@import '../../../scss/variables/variables';
@import '../../../scss/mixins/mixin';

.root {
    max-height: 100%;
    flex-grow: 1;
    min-width: 0;
    position: relative;
    display: flex;

    [class*='Aside_toggle'] {
        transform: rotate(180deg);

        @media (min-width: 768px) {
            transform: rotate(180deg);
        }
    }

    [class*='Aside_collapse'] {
        left: auto;
        // right: -0.8em;
        margin-right: -0.8em;
        margin-left: 0.6em;
        min-width: inherit;
        width: 0;

        @media (min-width: 768px) {
            width: 3.75em !important;
        }

        &:after,
        &:before {
            display: none;
        }

        [class*='Aside_actions'] {
            flex-direction: column;
        }
    }
}

.aside {
    flex: none;
    display: flex;
    color: $light_theme_color;

    background: $light_theme_background;

    :global(.dark) & {
        color: $dark_theme_color;
        background: $dark_theme_background;
    }

    @media (min-width: 768px) {
        position: static;
    }
    @media (max-width: 767px) {
        height: calc(100% - 9.2em);
    }
}

.wrapper {
    flex: none;
    display: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    padding-right: 0.3em;
    max-height: calc(100% - 2em);

    @media (min-width: 768px) {
        height: calc(100% - 9.2em);
        // overflow: hidden;
        // overflow-x: hidden;
    }
}

.hidden {
    composes: wrapper;
    display: block;

    @media (min-width: 768px) {
        display: none;
    }
}

.actions {
    [class^='Aside_toggle'] {
        padding: 1em;
        font-size: 0.7em;
        cursor: pointer;
        transform: rotate(0deg);

        @media (min-width: 768px) {
            transform: rotate(180deg);
        }
        &.active {
            transform: rotate(180deg);

            @media (min-width: 768px) {
                transform: rotate(0);
            }
        }
    }
}
