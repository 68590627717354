input[type=checkbox].cor_switcher {
    appearance: none;
    font-size: 1em;
    width: 1.625em;
    height: 0.875em;
    border-radius: 0.5em;
    background: #00B5FF;
    padding: 0.125em;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:before {
        content: '';
        width: 0.625em;
        height: 0.625em;
        background: #FFFFFF;
        border-radius: 50%;
        flex: none;
    }

    &:checked {
        flex-direction: row-reverse;
        background: #00B5FF;

        &:before {
            content: '';
            font-size: 1em;
        }
    }

    &.contrast {
        background: transparent;
        outline: 1px solid #00B5FF;

        &:hover {
            background: transparent;
        }

        &:before {
            background: #00B5FF;
        }
    }
}

.cor_switcher_has_labels {
    display: inline-flex;
    align-items: center;
    gap: 1em;
    cursor: pointer;

    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;

    span {
        opacity: 0.6;
        transition: 0.3s;
        &.active {
            opacity: 1;
        }
    }
}
