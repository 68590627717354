.item {
    padding-bottom: 1.4em;
    margin-bottom: 1.4em;
    border-bottom: 1px solid rgba(#000, 0.1);
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    h3 {
        margin: 0;
    }
}

.more {
    color: #67d3ff;
    cursor: pointer;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    line-height: 1.6;
}
