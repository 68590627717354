.root {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1.125em;
    padding: 0.125em 0.3125em;

    background-color: #FF0000;
    color: #ffffff;

    font-size: 1em;
    font-weight: 500;
    text-align: center;
  
    border-radius: 1em;

    .value {
        margin: 0;
        font-size: 0.6875em;
        font-weight: 800;
        line-height: 1;
    }
}

.small {
    composes: root;
    width: 1.125em;
    border-radius: 100%;
}