@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.otCreate {
	$TopHeaderSize: 1.4em;
	$TitleSize: 1.2em;
	$HeaderSize: 1em;
	$LeaveBtnSize: 1em;
	$GeneralSize: 1em;
	$StandardSize: 0.9em;
	$RCTitleColor: $lw_light_theme_color;
	$RCTextColor: $lw_light_theme_color;

	height: 100%;
	width: 100%;
	margin-top: 0.5em;
	display: flex;
	flex-direction: column;

	color: $lw_light_theme_color;
	background-color: white;
	.dark & {
		color: $lw_dark_theme_color;
		background-color: $lw_dark_theme_bknd_color;
	}

	&__topHead {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 3.6875em;
		font-size: $HeaderSize;
	}

    &__stretch {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        font-size: 1.125em;
		width: 100%;

		&Left {
			justify-content:left;	
		}
		&Top {
			align-items: start; 	
		}
    }

	&__flexColumn {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: center;
	}

	&__align {
		cursor: pointer;
		&Left {
			flex-grow: 1;
			flex-basis: 0;
			text-align: left;
			white-space: nowrap;
			font-size: 1em;
			cursor: pointer;
		}
		&Center {
			text-align: center;
			flex-grow: 1;
			flex-basis: 0;
			cursor: pointer;
		}
		&Right {
			flex-grow: 1;
			flex-basis: 0;
			text-align: right;
			font-size: 1em;
			cursor: pointer;
		}
		&BorderRadius {
    	    border-radius: 0.3125em;
			padding: 0.5em 1em;
		}
	}

	&__spanDisabled {
		pointer-events: none;
		opacity: 0.7;
		cursor:not-allowed;
	}

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 3.6875em;
		padding: 0.625em 1em; //0.625em 1.8125em;
		font-size: $HeaderSize; //1em;
		border-bottom: 2px solid #CECECE;

		&Bottom {
			border: none;
		}

		&Title {
			font-weight: 600;
			color: $RCTitleColor;
			font-size: $TopHeaderSize;
            .dark & {
                color: $lw_dark_theme_color;
            }
		}

		&Leave {
			font-size: $LeaveBtnSize;
			color: $lw_table_listbox_text_color;
			cursor: pointer;
			border: none;

			background-color: transparent;

			i {
				font-size: 0.7em;
				line-height: 0;
				margin-right: 0.5em;
			}

			&:hover {
				color: lighten($lw_table_listbox_text_color, 15%);
			}
		}
	}

	&__body {
		flex-grow: 1;
		min-height: 0;
		position: relative;
		background-position: center center;
		z-index: 1;
		overflow-y: overlay;
		display: flex;
		flex-direction: column;
		padding: 0.025em 1.4em; //0.625em 1em; //0.625em 1.8125em;

		@include scrollbar;

		> div {
			// min-height: 0;
		}
	}

	&__titleGeneral {
		font-size: $TitleSize;
		margin-top: 1.2em;
		margin-bottom: 0.2em;
		font-weight: 600;
		color: $lw_light_theme_color;

		.dark & {
			color: $lw_dark_theme_color;
		}
		&Light {
			opacity: 0.7;
		}
	}

	&__general {
		font-size: $GeneralSize;
		margin-top: 1.2em;
		margin-bottom: 0.6em;
		font-weight: 600;
		color: $lw_light_theme_color;

		.dark & {
			color: $lw_dark_theme_color;
		}
	}

	&__standard {
		font-size: $StandardSize;
		margin-top: 0.6em;
		margin-bottom: 0.3em;
		color: $lw_light_theme_color;

		.dark & {
			color: $lw_dark_theme_color;
		}

		&Pad {
			padding: 0 1em;
		}
		&PadRight {
			padding-right: 1em;
		}
		&Pad2 {
			padding-bottom: 0.6em;
		}
		&PadVert {
			line-height: 1.7em;
			//border: solid 1px red;
		}
	}

	&__HorizLine {
		border-bottom: solid 1px #CECECE;
	}

	&__titleSize {
		font-size: 1.3em;
	}

	&__questionSize  {
		font-size: 1.2em;
	}
	&__questionAnswer  {
		color: #00B5FF;
	}

	&__TestRB {
		padding: 0.3em 0;
		margin-bottom: 0.625em;
		&:last-child {
			margin-bottom: 0;
		}
		input[type=checkbox] {
			&:checked {
				border: 1px solid #00B5FF;
			}
			&:hover {
				border: 1px solid #67D3FF;
			}
		}

	}

	&__ActionIcon {
		padding-left: 0.8em;
		position: relative;
		top: -0.7em;

		&:hover {
			cursor: pointer;
		}
	}

	&__descr {
		min-height: 6em;
		font-size: 0.9em;
		width: 99%;
		cursor: pointer;
		&Width20 {
			font-size: 1em;
			width: 38em;
		}
	}

	&__Select {
		font-size: 1.2em;
		border-radius: 2px;
		color: $cm_light_cb_color;
		background-color: $cm_light_cb_bknd_color;
		border: solid 1px $cm_light_cb_border_color;
		cursor: pointer;

		&Values {width: 5em;}
		&Values8 {width: 8em;}
		&Values9 {width: 9em;}
		&Values10 {width: 10em;}
		&Values11 {width: 11em;}
		&Values12 {width: 12em;}
		&Values24 {width: 24em;}
		&Values32 {width: 32em;}

		.dark & {
			color: $cm_dark_cb_color;
			background-color: $cm_dark_cb_bknd_color;
			border: solid 1px $cm_dark_cb_color;
		}
	}

	&__selRlBlue {
		//background-color: white;
		border-left: solid 4px $lw_blue_color;
		padding: 0.2em 0em 0 0.5em;
	}

	&__selSentence {
		color: $lw_blue_color;
		padding-left: 0.5em;
	}

	&__rowSequence {
		line-height: 2em;
		margin:  2em 1em;
		//border: solid 1px green;
	}

	&__btnSequence {
		padding: 0.2em 1em;
		margin:  0 1em;
		//border: solid 1px red;

		&:hover {
			cursor: pointer;
			border: solid 1px $lw_blue_color;
		}
	}

	&__estimate {  
		font-size: 0.9em;
		padding-top: 1em;

		&Mark {
			padding: 0.5em 1em 0.5em 0;
		}
	}

	&__input {
		width: 80%;
		margin-bottom: 0.5em;
		border: solid 1px gray;
		font-size: 1em;
		cursor: pointer;

		&TimeLimitSize {
			width: 60px;
			text-align: center;
		}
	}

	&__nowrap {
		white-space: nowrap;
	}

    &__space {
        margin: 1.2em 0 0.6em 0;
    }

    &__AddOption {
        padding-top: 0.5em;
    }

	&__button {
		font-size: 0.9em;
		border: none;
		padding: 0.5em 3em;
		margin-left: 1.5em;
        margin-right: 0.2em;
        border-radius: 0.3125em;

		&Next {
			margin-left: 0;
		}

		&:hover {
			cursor: pointer;
            color: white;
            background-color: #67d4ff;

            .dark & {
                background-color: $lw_dark_sel_hover_btn_bknd_color;
                color: $lw_dark_sel_hover_btn_color;
            }
		}

		&Sel {
            background-color: $lw_blue_color;
            color: white;
            .dark & {
                background-color: $lw_dark_sel_active_btn_bknd_color;
                color: $lw_dark_sel_active_btn_color;
            }
        }

		&Unsel {
            background-color: white;
            color: $lw_blue_color;
            border: solid 1px  $lw_blue_color;
            .dark & {
                background-color: $lw_dark_unsel_active_btn_bknd_color;
                color: white; //$lw_dark_unsel_active_btn_color;
            }
        }

		&Grey {
            background-color: white;
            color: grey;
            border: solid 1px  grey;
            .dark & {
                background-color: black;
                color: grey;
            }
        }

		&Template {
			width: 20.5em;
			padding: 1em;
			margin: 1em;
		}

		&TemplateDlg {
			width: 20em;
		}

	}

	&__comments {
		&__tick {
			height: 1em;
			position: relative;
			top: -0.15em;
		}
	}

	&__bigPlus {
		font-size: 1.7em;
		position: relative;
		top: 0.15em;
	}

	&__newQuestion {
		position: relative;
		top: -0.15em;
	}

	&__horizBorder {
		//border-bottom: 1px solid #CECECE;
		padding: 1em 1em;
		width: 120%;
	}

	&__imageBody {
		width: 100%;
		height: 100%;
	}
	&__imagePreview {
		width: 360px;
		height: 200px;
	}

	&__imageSmallPreview {
		width: 240px;
		height: 150px;
	}

	&__imageInputLabel {
		padding: 0.3em 0.5em;
		cursor: pointer;
	}
	&__imageVariant {
		padding: 0 0.5em 1em 0;
	}
	&__imageTitle {
		color: grey;
		font-size: 0.8em;
		padding: 1em 0;
		.dark & {
			color: white;
		}
	}
	&__imageBottom {
		padding-bottom: 2%;
	}
	&__imagePointer {
		cursor: pointer;
	}
	&__imageNoUndescore {
		text-decoration: none;
		cursor: pointer;
		.dark & {
			color: white;
		}
	}
	&__quesitonShift {
		margin-left: 1em;
	}

	&__dlgGrp {
		&G1 { width: 20%}
		&G2 { width: 60%}

		&Answer {
			&Correct {color: green;}
			&Incorrect {color: red;}
			&NoAnswer {color: black;}
		}
	}

	&__result {
		display: flex;
		flex-direction: column;
		padding: 2em 3em;
	
		&_Row {
			display: flex;
			padding: 0.1em 0.4em 0.6em 0;
			color: $lw_light_theme_color;

			.dark & {
				color: $lw_dark_theme_color;
			}
			
			&Col1 {
				width: 30%;
			}
			&Col2 {
				//width: 50%;
				font-weight: 800;
			}
		}
	}	

	&__lostOptions {
		color: red;
		font-weight: 600;
	}

	&__videoBlock {
		padding: 1em 0;

		&Size {
			width: 10em;
			height: 10em;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&Link {
			color: white;
			.dark & {
				color: black;
			}
		}

		&Icon {
			color: #383E47;
			cursor: pointer;
			
			.dark & {
				color: #FFFFFF;
			}

			&:hover {
				border: 1px solid #67D3FF;
			}
		}
		&VideoIcon {
			cursor: pointer;
			font-size: 3em;
			color: grey;
		}
	}

	&__videoExecVideo {
		padding-bottom: 1.5em;
	}
	&__videoExecRB {
		padding: 1.8em 0 0 4em;
	}

	&__link a {
        color: #383E47;
		font-weight: 900;
        .dark & {
            color: #FFFFFF;
        }
    }

	&__gridRB {
		padding: 0 1.5em;
	}

	&__nextBtn {
		&QuestAudio {padding: 2em 4em;}
		&QuestChooseOne {padding: 2em 0em;}
	}

	&__templateName {
		width: 8em;
	}

	&__anchor {
		color: #00B5FF;
		text-decoration: underline;
	}
	&__showImage {
		padding: 1em;
	}

    &__minutes {
		padding: 0.2em 0.5em 0.6em 0;
	}
    &__timeLimit {
		margin: 0.1em 0 0.4em 0;
	}

    &__audio {
        &_item {
            display: flex;
            align-items: center;
            gap: 0.625em;
        }

        &_icon {
            font-size: 1.2em;
        }
    }	
}

//Great Tool: https://pinetools.com/image-color-picker
