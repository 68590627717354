@import "../../../scss/variables/variables";
@import "../../../scss/mixins/mixins";
@import "./core/template.scss";
@import "./core/loginHeader.scss";
@import "./core/loginFooter.scss";
@import "./core/loginForm.scss";

@import "./sections/firstScreen.scss";
@import "./sections/corModules.scss";
@import "./sections/instructions.scss";

.personalData {
    max-height: 80vh;
    overflow-y: auto;
    line-height: 1.2;

   
    // @extend %scrollbar;

    @media (min-width: 768px) {
        // overflow-y: visible;
        scrollbar-gutter: stable;

        &::-webkit-scrollbar {
            width: 0.3em;
            height: 0.3em;

            &-track {
            }

            &-button {
                opacity: 0;

                &:single-button {
                    width: 0.1em;
                    height: 0.1em;
                }
            }

            &-thumb {
                background: #bec9d3;
                border-radius: 1em;
                border: 0 solid transparent;
                background-clip: border-box;
                min-height: 5em;

                :global(.dark) & {
                    background: #464f57;
                }

                .dark & {
                    background: #464f57;
                }
            }
        }
    }
}
