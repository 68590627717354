.root {
    position: relative;
    cursor: pointer;
    font-size: 1em;

    .content {
        display: inline-flex;
        align-items: center;
        gap: 0.375em;

        color: #666F7E;
        font-weight: 500;
        
        transition: 0.3s;

        :global(.dark) & {
            color: #BEC9D3;
        }

        &:hover {
            color: #00b5ff;
        }
    }

    .icon {
        font-size: 1.125em;
    }

    .text {
        margin: 0;
    }

    .input {
        display: none;
    }

    .progress {
        position: absolute;
        bottom: -0.5em;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 2px;
        border-radius: 0.5em;
        background-color: #BEC9D3;
        cursor: default;
     
        &__current {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 0.5em;
            background-color: #00B5FF;

        }
    }

}

.wrap {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-size: 1em;
}

.disabled {
    composes: wrap;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .content {
        opacity: 0.5;
    }
}

