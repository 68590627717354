.root {
    .questionTypeList {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        label {
            &:nth-child(3) {
                margin-top: 1em;
            }
            &:nth-child(7) {
                margin-top: 1em;
            }
        }
    }
}