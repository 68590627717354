.cor_header {
    background: #215794;
    padding: 0.6em 0.9em 0.45em 0.9em;
    gap: 1.875em;

    .dark & {
        background: #16171D;
    }

    &__logo {
        margin: -3em 0;
        display: block;
        width: 9em;
        cursor: pointer;
       
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
    }

    &__not_found {
        width: 9em;
        text-align: right;
    }

    &__content {
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__ver {
        font-size: 0.875em;
        margin-left: 0.875em;
        cursor: pointer;
    }

    &__center {
        display: flex;
        flex-direction: row;

        & div{
            padding: 0 1em;
        }
    }

    &__right {
        gap: 1.2em;
        padding-right: 0.3em;
        display: flex;
        align-items: center;
    }
}
