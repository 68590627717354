// Button variables
$color_withe: #ffffff;
$btn_default_bg_color: #00b5ff;
$btn_default_bg_color_hover: #67d3ff;

.root {

}

.head {
    
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__download {
        display: inline-flex;
        align-items: center;
        gap: 0.5em;

        padding: 0.5em 1.1675em;
        border-radius: 0.42em;
        border: 1px solid $btn_default_bg_color;

        font-size: 0.75em;
        font-weight: 500;

        background-color: transparent;
        color: $btn_default_bg_color;

        outline: none;
        text-decoration: none;
        max-width: 100%;
        cursor: pointer;
        transition: 0.3s;

        &:hover:not(:disabled) {
            border: 1px solid $btn_default_bg_color_hover;
            color: $btn_default_bg_color_hover;
        }

        span {
            font-size: 1.5em;
        }
    }
}

.options {
    display: flex;
    align-items: flex-start;
    gap: 1.5em;
}

.dropdowns {
    display: flex;
    flex-direction: column;
    gap: 1em;

    min-width: 15em;
}

.powerButton {
    min-width: 20em;
}