@import '../../../scss/variables/variables';
@import '../../../scss/mixins/mixin';
@import '../..//Icon/variables';

.root {
    display: flex;
    flex-direction: column;
    height: calc(100% - 9.2em);
    // margin-right: 1em;
    // overflow: hidden;
    flex: none;
    color: $light_theme_color;
    background: $light_theme_background;
    height: 100%;

    @media (min-width: 768px) {
        position: relative;
        z-index: 1;
        width: 14.7em;
        transition: width 0.1s;
    }

    &[class*='resized'] {
        transition: 0s;
    }

    :global(.dark) & {
        // border-right: 1px solid $dark_border_color;
        color: $color_withe;
        background: $dark_theme_background;
    }

    :global([class*='Dashboard_root']) & {
        width: 100%;
        position: relative;
        user-select: none;
        // z-index: 1;
        @media (min-width: 768px) {
            // overflow: hidden;
            margin-right: -1em;
            width: 16.3em;
            min-width: 16.3em;
            max-width: 25em;
            padding-left: 0.6em;
        }

        &:after,
        &:before {
            content: '';
            opacity: 0;
            transition: opacity 0.1s;
        }

        &:before {
            position: absolute;
            width: 1px;
            background: #bec9d3;
            border-radius: 0.125em;
            z-index: 5;
            top: 0;
            left: 0.15em;
            bottom: 0.6em;
            // bottom: -2em;
            flex: none;
            // opacity: 0;
            user-select: none;

            :global(.dark) & {
                background: #4a4f5b;
            }
        }

        &:after {
            font-family: 'robiclab';
            content: $cl_icon_drag;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-size: 0.8em;
            line-height: 0;
            width: 1.6em;
            height: 1.6em;
            top: 50%;
            padding-top: 0.1em;
            left: 0.2em;
            border-radius: 50%;
            background: #ffffff;
            border: 1px solid #bec9d3;
            transform: translate(-50%);
            z-index: 6;
            color: #666f7e;

            :global(.dark) & {
                background: #363a45;
                border: 1px solid #4a4f5b;
                color: #ffffff;
            }
        }

        &[class*='resized'] {
            &:after,
            &:before {
                opacity: 1;
            }
        }
    }
}

.title {
    font-size: 1.125em;
    font-weight: 600;
    // transition: writing-mode 0.3s;
}

.sensors {
    // width: 16.2em;
    flex-grow: 1;
    overflow: hidden overlay;
    display: none;
    padding: 0 0.1em 0 0;
    margin: 0 -0.1em 0 -1em;

    &:before {
        content: '';
        width: 1em;
        flex: none;
    }

    @media (min-width: 768px) {
        display: flex;
    }

    &.hidden {
        display: flex;

        @media (min-width: 768px) {
            display: none;
        }
    }

    @extend %scrollbar;
}

.scroll {
    padding: 0 0.5em;
    margin: 0.5em 0 0;
    max-height: 100%;
    min-width: 0;
    flex-grow: 1;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.4em;
    padding-left: 0.5em;

    &.table {
        padding-left: 0;

        .toggle {
            margin-left: 0;

            // &:before {
            //     font-family: 'robiclab';
            //     content: $cl_icon_caret;
            //     display: block;
            //     transform: rotate(90deg);
            //     font-weight: 400;
            //     font-size: 0.8em;
            // }
        }
    }

    .dropTitles {
        background-color: transparent;
        border: 0 !important;
        flex: none;

        :global(.dark) & {
            background-color: transparent;
        }

        ul {
            li {
                font-size: 0.9em;
            }
        }

        [class^='Dropdown_label'] {
            padding: 0.5em 0;
            font-size: 0.9em;
            :global(.dark) & {
                // background: #363a45;
            }
        }
    }

    [class^='Dropdown_drop'] {
        // width: 10.6em;

        // ul {
        //     li {
        //         white-space: initial;
        //         line-height: 1.2;
        //         // font-size: 1em;
        //     }
        // }
    }
}

.table {
    justify-content: flex-start;

    .collapse & {
        flex-direction: column;
    }
}

.toggle {
    width: 1.625em;
    height: 1.625em;
    border-radius: 50% !important;
    margin: 0 0.7em;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
    transform: rotate(0);
    background: #f5f8fa;
    border: 1px solid #bec9d3;
    color: #a2a6b6;

    :global(.dark) & {
        background: #1e1f25;
        border: 1px solid #343640;
        color: #c7c9d1;
    }

    &:before {
        font-size: 0.8em;
        line-height: 0;
        margin-left: -0.2em;
        margin-top: 0.1em;
    }

    // transition: transform 0.4s;

    @media (min-width: 768px) {
        transform: rotate(0);
    }
}

.toggleInactive {
    composes: toggle;
    font-size: 1em;
    width: 2em;
    height: 2em;
    background: #00b5ff;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: $color_withe;
    margin: 0;
    border-color: #00b5ff;

    &:before {
        font-size: 1.1em;
        margin: 0;
    }

    :global(.dark) & {
        background: #00b5ff;
        color: $color_withe;
        box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
        border-color: #00b5ff;
    }
}

.resize {
    position: relative;
    z-index: 10;
    width: 0.5em;
    margin-right: -0.5em;
    opacity: 0;
    user-select: none;
    cursor: e-resize;

    &:hover {
        + .root {
            &:after,
            &:before {
                opacity: 1;
            }
        }
    }

    // background: rgba(1, 1, 1, 0.2);
    // border-bottom: 1px solid black;
    // border-right: 1px solid black;
    // border-top: 1px solid black;
    // cursor: col-resize;
    // height: 20px;
    // width: 10px;
}

.collapse {
    composes: root;

    @media (min-width: 768px) {
        box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
        border-radius: 0.375em;
        padding: 0.75em 0.875em;
        width: 3.75em !important;
        min-width: auto !important;
        position: relative;
        top: -0.7em;
        left: -0.4em;
        overflow: inherit;

        .actions {
            flex-direction: column-reverse;
            padding-left: 0;
        }

        .title {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            margin-top: 0.5em;
        }

        :global(.dark) & {
            background: #282b33;
        }
    }

    @media (max-width: 767px) {
        width: 0 !important;
        min-width: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
        overflow: hidden !important;
        max-width: none !important;
        width: 0 !important;
    }

    [class*='Dashboard_wrapper__'] {
        display: none;
    }

    [class*='TableData_root__'] {
        display: none;
    }

    [class*='SensorsBundles_root__'] {
        display: none;
    }
}
