.connectionButtons {
    .button {
        width: auto;
        gap: 0.3125em;

        span {
            font-family: 'Inter';
            font-size: 0.75em;
            font-weight: 600;
        }
    }
}
