.filter {
    border-radius: 0.375em;
    background: #fff;
    box-shadow: 0 0.25em 0.875em 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 1em;
    margin-top: 0.5em;

    :global(.dark) & {
        background: #282b33;
    }
}

.filterShow {
    composes: filter;

    .caret {
        transform: rotate(-180deg);
    }
}

.head {
    border-radius: 0.375em;
    background: #eef1f2;
    font-size: 0.75em;
    padding: 1em 1.2em;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    :global(.dark) & {
        background: #363a45;
        color: #fff;
    }
}

.on {
    font-size: 0.7em;
    margin-left: 0.5em;
    color: #ef884e;
}

.caret {
    color: #a2a6b6;
    cursor: pointer;
    transform: rotate(0);

    .filterShow & {
        transform: rotate(-180deg);
    }
}

.body {
    padding: 0.625em 0.875em;
    display: none;
    user-select: none;

    .filterShow & {
        display: block;
    }
}

.row {
    margin-bottom: 0.625em;

    &:last-child {
        // margin-bottom: 0;
    }

    .dropdown {
        border: 0;

        &:hover:not(:disabled) {
            border: 0;
        }

        &__label {
            font-size: 0.75em;

            :global(.dark) & {
                background: #363a45;
                color: #fff;
            }
        }

        :global(.dark) & {
            background: #363a45;
            color: #fff;
        }

        &:hover {
            :global(.dark) & {
                background: #363a45;
                color: #fff;
            }
        }
    }
}

.dropdown {
    border: 0;
}

.rowInline {
    display: flex;
    align-items: center;

    .label {
        width: 4.7em;
        margin-bottom: 0;
    }
}

.label {
    font-size: 0.75em;
    color: #7c8195;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
    flex: none;

    b {
        font-weight: 400;
        color: #000;

        :global(.dark) & {
            color: #fff;
        }
    }

    :global(.dark) & {
        color: #c7c9d1;
    }
}

.range {
    display: flex;
    align-items: center;
    gap: 0.625em;

    i {
        cursor: pointer;
        font-size: 0.5em;
        color: #7c8195;
    }
}

.foot {
    display: flex;
    gap: 0.625em;
    margin-top: 1em;
}

.btn {
    flex-grow: 1;
}
