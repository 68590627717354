@import "../../ui/Icon/variables.scss";

.root {
    display: inline-flex;
    align-items: center;
    gap: 0.75em;
    font-size: 1em;

    .label {
        font-size: 0.875em;
        font-weight: 400;
        line-height: 1;

        color: #7C8195;

        :global(.dark) & {
            color: #e6e6e6;
        }
    }

    .dropdown {
        position: relative;

        color: #666f7e;
        :global(.dark) & {
            color: #c7c9d1;
        }

        &:hover {
            .dropdown__list {
                display: block;
            }
            
            .dropdown__toggle {
                border-radius: 0 0 0.125em  0.125em;
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        &__toggle {
            display: inline-flex;
            align-items: center;
            gap: 0.5em;

            padding: 0.4375em 0.625em;
            border-radius: 0.125em;

            border: 1px solid #bec9d3;
            width: 100%;
            cursor: pointer;

            :global(.dark) & {
                border: solid 1px #4a4f5b;
            }

            p {
                margin: 0;
                font-size: 0.875em;
            }

            &:after {
                display: inline-block;
                font-family: 'cor';
                content: $cor_icon_caret;
                color: inherit;
                font-size: 0.5em;
                margin-left: 1em;
            }
        }

        &__list {
            display: none;

            position: absolute;
            z-index: 10;
            padding: 0.125em 0.5em;
            left: 0;
            bottom: calc(100% - 0.125em);
            width: 100%;
            border: 1px solid #bec9d3;
            box-shadow: 0 0.5625em 0.9375em rgba(52, 61, 70, 0.09);
            border-radius: 0.125em 0.125em 0 0;
            background: #fff;

            :global(.dark) & {
                background: #363a45;
                border: solid 1px #4a4f5b;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5em;

            border-radius: 0;
            border: 0;
            width: 100%;

            background: transparent;
            user-select: none;
            padding: 0.4375em 0;
            font-size: 1em;
            font-weight: 500;
            color: #666f7e;
            transition: 0.3s;
            cursor: pointer;

            border-top: 1px solid #bec9d3;

            &:first-child {
                border-top: 0;
            }

            :global(.dark) & {
                color: #c7c9d1;
            }

            p {
                margin: 0;
                font-size: 0.875em;
            }

            input[type='file'] {
                display: none;
            }

            &:hover:not(:disabled) {
                background: transparent;
                color: #8f93a6;
                
                :global(.dark) & {
                    color: #fff;
                }
            }
        }
    }

    .print {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 2em;
        height: 2em;
        padding: 0.25em;

        border: solid 1px #bec9d3;
        border-radius: 0.125em;

        background-color: transparent;
        color: #666F7E;

        cursor: pointer;
        transition: 0.3s;

        :global(.dark) & { 
            color: #c7c9d1;
            border: solid 1px #4a4f5b;
        }

        &:hover {
            background: #67d3ff;
            color: #ffffff;
        }
    }
}
