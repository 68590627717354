.stickers {
  &__button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.25em;
    padding: 0.125em 0.375em;
    background: none;
    font-size: 0.75em;
    font-weight: 400;
    border-radius: 18px;
    border: 1px dashed rgba(0, 181, 255, 0.3);
    cursor: pointer;
  }

  &__title {
    color: #00b5ff;
  }
}
