// Mixin =======================================================================
@import "../variables/variables";
$scrollbar_thumb_color_light: #BEC9D3;
$scrollbar_track_color_light: #BEC9D3;
$scrollbar_thumb_color_dark: transparent;
$scrollbar_track_color_dark: transparent;

@mixin scrollbar {

    // overflow: hidden overlay;
    overflow: overlay;
    
    @media (min-width: 768px) {
        
        &::-webkit-scrollbar {
            width: 1.25em;
            height: 1.25em;

            &-button {
                display: none;
            }

            &-thumb {
				background: #BEC9D3;
				border-radius: 1.25em;
				border: 0.5em solid transparent;
				background-clip: content-box;
				min-height: 3em;

				.dark & {
					border: 0.5em solid transparent;
					background: #464F57;
					background-clip: content-box;
				}
            }
        }

        &::-webkit-scrollbar-corner {
            background-color: transparent; 
        }
    }
}

// Placeholder for input
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    };

    &:-moz-placeholder {
        @content
    };

    &::-moz-placeholder {
        @content
    };

    &:-ms-input-placeholder {
        @content
    };
}

// Ширина колонок сетки
// Использование
// @include grid(2, .5em); две колонки
@mixin grid($size: 1, $gap: $grid_padding) {
    display: flex;
    flex-wrap: wrap;

    > * {
        display: flex;
        width: calc(100% / #{$size} - #{$gap} + #{$gap} / #{$size});
    }
}
// Ширина колонок сетки при смене ширины окна
// @include xs-size(2); две колонки при меньше 767 пикселей
@mixin xs-grid($size, $gap: $grid_padding) {
    @media (min-width: 768px) {
        @include grid($size, $gap);
    }
}
@mixin sm-grid($size, $gap: $grid_padding) {
    @media (min-width: 992px) {
        @include grid($size, $gap);
    }
}
@mixin md-grid($size, $gap: $grid_padding) {
    @media (min-width: 1200px) {
        @include grid($size, $gap);
    }
}

// =============================================================================
// Fonts
// =============================================================================
// @include font-face(Samplino, fonts/Samplino);
// @font-face {
// 	font-family: "Samplino";
// 	src: url("fonts/Samplino.eot?") format("eot"),
// 		 url("fonts/Samplino.woff2") format("woff2"),
// 		 url("fonts/Samplino.woff") format("woff"),
// 		 url("fonts/Samplino.ttf") format("truetype"),
// 		 url("fonts/Samplino.svg#Samplino") format("svg");
// }
// @include font-face("Samplina Neue", fonts/SamplinaNeue, bold, italic);
// @font-face {
// 	font-family: "Samplina Neue";
// 	font-style: italic;
// 	font-weight: bold;
// 	src: url("fonts/SamplinaNeue.eot?") format("eot"),
// 	     url("fonts/SamplinaNeue.woff2") format("woff2"),
// 	     url("fonts/SamplinaNeue.woff") format("woff"),
// 	     url("fonts/SamplinaNeue.ttf") format("truetype"),
// 	     url("fonts/SamplinaNeue.svg#Samplina_Neue") format("svg");
// }

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
        font-display: swap;
    }
}
