.root {
 
}

.label {
    font-size: 0.9375em;
    color: #595C6C;

    :global(.dark) & {
        color: #ceced6;
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25em;
    margin-top: 0.9375em;
}

.list_col_3 {
    composes: list;
    grid-template-columns: 1fr 1fr 1fr;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 9.75em;

    background: #F4FAFC;
    border: 1px solid #00b5ff;
    border-radius: 2px;
    text-align: center;
    padding: 1em;
    transition: background 0.3s;
    cursor: pointer;

    &:hover {
        background: darken($color: #f4fafc, $amount: 2%);
    }

    :global(.dark) & {
        background: #363a45;
        border-color: #484b5a;

        &:hover {
            background: lighten($color: #363a45, $amount: 10%);
        }
    }

}

.content {
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 1em;

    i {
        font-size: 2.25em;
        color: #00b5ff;
    }

    span {
        display: block;
        width: 10em;
        line-height: 1.2;
        text-align: center;
        color: #08203A;

        :global(.dark) & {
            color: #ceced6;
        }

    }
}