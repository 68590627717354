@import '../../scss/variables/variables';
@import '../ui/Icon/variables';

.docs {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 0.625em;
}

.label {
    font-weight: 500;
}

.drop {
    width: 6.25em;
    height: 1.875em;
    position: relative;

    &:hover {
        .list {
            display: block;
        }

        .toggle {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}

.item {
    border-radius: 0;
    height: 1.875em;
    width: 100%;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    padding: 0.4375em 0;
    font-size: 1em;
    font-weight: 500;
    width: 100%;

    :global(.dark) & {
        color: $color_withe;
        background: #363a45;
    }

    i {
        flex: none;
        width: 1.5em;
        text-align: left;
        font-weight: 400;

        &:before {
            font-size: 1.1em;
        }
    }
    .log,
    .xls {
        &:before {
            font-size: 1.4em;
            margin-left: -0.2em;
        }
    }

    > span {
        font-size: 0.75em;
        font-weight: 400;
        flex-grow: 1;
        text-align: left;
    }

    input[type='file'] {
        display: none;
    }

    &:hover:not(:disabled) {
        background: transparent;
        color: #8f93a6;
        // border-color: inherit;
        cursor: pointer;
    }
}

.toggle {
    user-select: none;
    background: #f5f8fa;
    border: 1px solid #bec9d3;
    border-radius: 0.125em;
    cursor: pointer;
    padding: 0.5em;
    color: #666f7e;
    min-width: 6.25em;

    &:hover:not(:disabled) {
        border: 1px solid #bec9d3;
        background: #f5f8fa;
        color: #666f7e;
    }

    &:after {
        font-family: 'robiclab-neuro';
        content: $icon_caret;
        color: inherit;
        font-size: 0.5em;
        margin-left: 1em;
    }

    :global(.dark) & {
        background: $dark_theme_background;
        border: 1px solid #343640;
        color: #c7c9d1;

        &:hover:not(:disabled) {
            background: $dark_theme_background;
            border: 1px solid #343640;
            color: #c7c9d1;
        }
    }

    span {
        font-size: 0.625em;
        font-weight: 600;
    }
}

.stop {
    // color: #16171D;
    animation: recordsBtn 2s infinite;

    :global(.dark) & {
        animation: recordsBtnDark 2s infinite;
    }

    &:after {
        display: none;
    }

    i {
        border-radius: 50%;
        width: 1.1em;
        height: 1.1em;
        background: #f75656;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color_withe;
        margin-right: 7px;
        line-height: 0;

        &:before {
            font-size: 0.6em;
        }
    }

    span {
        line-height: 1.2;
    }

    em {
        display: block;
        font-weight: 400;
        font-size: 0.6em;
    }
}

.list {
    display: none;
    position: absolute;
    z-index: 10;
    padding: 0.125em 0.5625em;
    left: 0;
    bottom: calc(100% - 0.125em);
    width: 100%;
    border: 1px solid #bec9d3;
    box-shadow: 0 0.5625em 0.9375em rgba(52, 61, 70, 0.09);
    border-radius: 0.125em 0.125em 0 0;
    background: $light_theme_background;

    :global(.dark) & {
        border: 1px solid #4a4f5b;
        background: #363a45;
    }

    .item {
        width: 100%;
        border: 0;
        background-color: transparent;
        padding: 0;

        :global(.dark) & {
            background-color: transparent;
            color: #fff;
        }

        &:hover:not(:disabled) {
            background-color: transparent;
            color: #8f93a6;

            :global(.dark) & {
                background-color: transparent;
                color: #8f93a6;
            }
        }

        &:nth-child(n + 2) {
            border-top: 1px solid #bec9d3;

            :global(.dark) & {
                border-top-color: rgba(#fff, 0.09);
            }
        }
    }
}
