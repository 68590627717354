@import '../../scss/mixins/mixin';

.firmware {
    .modal__message {
        span {
            color: #00b5ff;
        }
    }

    &__alert {
        background: rgba(0, 181, 255, 0.3);
        border-radius: 0.375em;
        padding: 0.7em 1.25em;
        margin-bottom: 0.625em;
        position: relative;
        overflow: hidden;
        display: inline-block;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 0.4375em;
            background: #00b5ff;
        }

        span {
            font-size: 0.75em;
            font-weight: 600;
            line-height: 1.2;
        }

        i {
            color: #00b5ff;
            font-style: normal;
        }
    }

    &__swicher {
        display: flex;
        align-items: center;
        gap: 1em;
        margin: 1em 0;

        > span {
            font-size: 0.75em;
            font-weight: 600;
        }
    }

    &__old {
        margin: 0.1em 0;
        font-size: 0.75em;
        font-weight: 500;

        .dark & {
            color: #fff;
        }
    }

    &__upload {
        span {
            color: #00b5ff;
            font-size: 0.75em;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        input {
            display: none;
        }

        &File {
            display: flex;
            align-items: center;
            gap: 0.375em;
            text-align: left;
            margin: 1.25em 0 1em;
            line-height: 1;

            span {
                font-size: 0.75em;
            }

            .dark & {
                color: #fff;
            }

            .cl_icon_documents-file {
                font-size: 1.125em;
            }

            .cl_icon_close {
                font-size: 0.75em;
                cursor: pointer;

                .dark & {
                    color: #c7c9d1;
                }
            }
        }
    }

    &__device {
        flex-grow: 1;
        min-width: 0;
        font-size: 0.9em;
    }

    &__version {
        .dropdown__label {
            font-weight: 600;
            .dark & {
                background: #363a45;
                border: 1px solid #4a4f5b;
            }
        }
    }
    &__term {
        margin: 1em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__label {
        font-size: 0.875em;
    }
    &__progress {
        width: 100%;

        &Top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.3em;

            span {
                position: relative;
                font-size: 0.75em;
                transform: translateX(-50%);
                transition: left 0.1s;

                .dark & {
                    color: #fff;
                }
            }
        }

        &Bottom {
            text-align: right;
            margin-top: 0.3em;

            span {
                font-size: 0.75em;
                font-style: normal;

                .dark & {
                    color: rgba($color: #fff, $alpha: 0.7);
                }
            }
        }

        progress {
            margin: 0;
            display: block;
            width: 100%;
            height: 0.3125em;
            appearance: none;

            &::-webkit-progress-bar {
                background: rgba(0, 181, 255, 0.4);
                border-radius: 0.75em;
            }

            &::-webkit-progress-value {
                background: #00b5ff;
                border-radius: 0.75em;
                transition: width 0.1s;
            }
        }
    }
    &__toggle {
        font-size: 0.75em;
        cursor: pointer;

        i {
            transition: transform 0.3s;
            &.inverse {
                display: block;
                transform: rotate(180deg);
            }
        }
    }

    &__info {
        padding: 0 0.875em;
        border-radius: 0;
        border: 0;
        height: 0;
        width: 100%;
        font-size: 0.875em;
        line-height: 1.4;
        transition: height 0.3s;
        overflow: hidden;
        resize: none;

        &.open {
            border: 1px solid #bec9d3;
            border-radius: 0.1875em;
            margin-bottom: 1em;
            background: #f5f8fa;
            height: 18.75em;
            padding: 0.875em;
            @extend %scrollbar;

            .dark & {
                background: #363a45;
                border: 1px solid #4a4f5b;
            }
        }
    }

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 10;

        // display: none;
    }

    &__preload,
    &__success,
    &__load {
        b {
            font-size: 0.875em;
            line-height: 1.5;
        }

        .dark & {
            color: #fff;
        }
    }

    &__rez {
        h3 {
            font-size: 1.3em;
        }
        p {
            font-size: 0.875em;
        }
    }

    @media (min-width: 768px) {
        .modal__action .btn {
            max-width: 50%;
        }
    }

    &__success {
        .modal__message span {
            color: #00b5ff;
        }
    }
}
