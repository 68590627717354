@import "../../../scss/variables/variables";

.cor_navbar {
    position: relative;
    z-index: 10;
    
    background: $navbar_light_bg;
    box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
    padding: 0.9375em 0.8125em 1.25em 0.8125em;
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    
    .dark & {
        background: $navbar_dark_bg;
        border-right: 1px solid #343640;
    }

    &_grow {
        flex-grow: 1;
        max-width: 100%;
        min-height: 0;
    }

    .profile__wrap {
        position: relative;
        width: 2.125em;
        height: 2.125em;
    }

    &__notify {
        position: absolute;
        top: -0.5em;
        right: -0.5em;
        z-index: 20;
    }

    i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.125em;
        height: 2.125em;
        color: $navbar_icon_color;
        background: $navbar_icon_bg;
        border: 1px solid $navbar_icon_border;
        border-radius: 0.125em;
        cursor: pointer;
        transition: 0.2s;
        transition-property: color, background;

        .dark & {
            color: $navbar_dark_icon_color;
            background: $navbar_dark_icon_bg;
            border: 1px solid $navbar_dark_icon_border;    
        }

        &:hover:not(.active) {
            border-color: $navbar_icon_border_hover;
            background: $navbar_icon_bg_hover;
            
            .dark & {
                border-color: $navbar_dark_icon_border_hover;
                background: $navbar_dark_icon_bg_hover;
            }
        }

        &:before {
            font-size: 1.25em;
        }

        &.active:not(.profile) {
            position: relative;
            color: $navbar_dark_icon_color_active;
            background: $navbar_icon_bg_active;
            border-color: $navbar_icon_border_active;
            cursor: default;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                height: 100%;
                border-left: 4px solid;
                border-radius: 0 0.3125em 0.3125em 0;
                left: -0.85em;
            }

            .dark & {
                color: $navbar_dark_icon_color_active;
                background: $navbar_dark_icon_bg_active;
                border-color: $navbar_dark_icon_border_active;
            }
        }

        &.profile {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;

            border-radius: 100%;
            border: 0;
            font-style: normal;
            font-size: 1.25em;
            
            .profile__img {
                position: relative;
                z-index: 11;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                i {
                    border-width: 0;
                }
            }
            
            &.active {
                i {
                    background: #F5F8FA;
                }
            }


            .profile__content {
                display: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                transform: translateX(0);
                z-index: 10;
       
                flex-direction: column;
                justify-content: center;
                width: max-content;
                
                background-color: $navbar_light_bg;
                padding: 0.125em 0.75em 0.125em 1.5em;
                border: 1px solid $navbar_icon_border;
                border-left: 0;
                border-radius: 0 0.5em 0.5em 0;
                font-weight: 500;

                .dark & {
                    background-color: $footer_dark_button_bg;
                    border: 1px solid $footer_dark_dropdown_border_color;
                }


            }

            .profile__name {
                color: #4A4A4D;
                font-size: 0.625em;
                
                .dark & {
                    color: $footer_dark_color;
                }
            }

            .profile__link {
                color: #666F7E;
                font-size: 0.5em;
                margin-top: 0.2em;
                
                .dark & {
                    color: $navbar_dark_icon_color;
                }
            }

            &:hover {
                .dark &:not(.active) {
                    i {
                        background: $footer_dark_button_bg;
                    }
                }
                .profile__content {
                    display: flex;
                    animation: profileFade 0.2s;
                }
            }
        }

        &.active {
            cursor: default;
            .profile__link {
                display: none;
            }
        }

        &.cor_icon_log-out {
            transform: scaleX(-1);

            &:before {
                font-size: 1em;
            }
        }
    }
}

@keyframes profileFade {
    0% {
       
        opacity: 0;
        transform: translateX(0.5em);
    }

    100% {
   
        transform: translateX(0);
        opacity: 1;
    }
}
