@import '../../scss/variables/variables.scss';

.root {
    // border: 1px solid;
    margin: 0 0 1.25em;
    transition: opacity 0.4s;
    background: $light_theme_background;
    box-shadow: 0 0.25em 0.9375em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    position: relative;

    @media (max-width: 767px) {
        z-index: 1;
    }

    // Dark
    color: $light_theme_color;
    user-select: none;

    :global(.dark) & {
        background: #282b33;
        color: $color_withe;
    }
}

.rootDisabled {
    composes: root;
}

.head {
    display: flex;
    align-items: center;
    background: #eef1f2;
    border-radius: 0.375em;
    padding: 0.8em 0.65em 0.8em 0.6em;
    border-left: 0.25em solid;

    :global(.dark) & {
        background: #2d3039;
        color: $color_withe;
    }

    [class^='Switcher_root'] {
        font-size: 0.8em;
    }

    .rootDisabled & {
        background: #eef1f2;

        :global(.dark) & {
            background: #2d3039;
            border-left-color: #f4308e !important;
        }
    }
}

.body {
    // margin-top: 1.5em;
    overflow: hidden;
    padding: 0.9375em;
    transition: padding 0.4s;
    height: auto;

    .rootDisabled & {
        display: none;
    }
}

.bodyClosed {
    composes: body;
    padding: 0 0.9375em;
    margin-top: 0;
    height: 0;
    height: 0;
}

.opt {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 0.6em;
}

.toggle {
    border: 0;
    background-color: transparent;
    padding: 0;
    font-size: 0.7em;
    font-weight: 400;
    color: #a2a6b6;
    transition: transform 0.2s;
    cursor: pointer;
    flex: none;
    margin: 0 0.3em;

    .rootDisabled & {
        // margin-top: -0.1em;
        transform: rotate(-90deg);
    }
}

.toggleClosed {
    composes: toggle;
    transform: rotate(-90deg);
}

.name {
    font-size: 0.75em;
    flex-grow: 1;
    padding: 0 0.7em;
    min-width: 0;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.3125em;
    word-break: break-all;
}

.info {
    flex: none;
    position: relative;
    border: 1px solid #0988bd;
    font-style: normal;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5em;
    vertical-align: top;
    margin-top: -0.1em;
    cursor: pointer;
    word-break: normal;
    // z-index: 10;

    &:before {
        content: 'i';
        color: #0988bd;
        line-height: 0.8;
        font-size: 0.7em;
    }

    &:hover {
        span {
            display: block;
        }
    }

    span {
        display: none;
        position: absolute;
        right: 0;
        top: calc(100% + 0.3em);
        padding: 1em;
        max-width: 14em;
        font-weight: 400;
        line-height: 1.3;
        font-size: 0.9em;
        z-index: 2;
        background: #fff;
        box-shadow: 0.1em 0.2em 0.4em rgba(#000, 0.05);
        border-radius: 0.375em;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -0.3em;
            right: 0.3em;
            width: 0.6em;
            height: 0.6em;
            transform: rotate(45deg);
            background: #fff;

            :global(.dark) & {
                background: #282b33;
            }
        }

        :global(.dark) & {
            background: #282b33;
        }
    }
}

.vars {
    border: 1px solid;
    font-size: 0.7em;
    padding: 0.5em;
    margin-bottom: 0.6em;
}

.var {
    &:nth-child(n + 2) {
        display: none;
    }
}

.current {
    font-size: 1em;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    background: #f5f8fa;
    border-radius: 0.125em;
    padding: 0.5em 0 0.5em 0.6875em;

    :global(.dark) & {
        background: #2d3039;
        color: $color_withe;
    }
}

span.unit {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    padding: 0.5em 0.7em;
    border-radius: 2px;
    border: 1px solid #bec9d3;

    :global(.dark) & {
        border: 1px solid #343640;
        background: #2d3039;
    }
}

.unit[class*='Dropdown_root'] {
    flex: none;
    color: #000;
    background: transparent;

    :global(.dark) & {
        background: #2d3039;
        color: $color_withe;

        &:hover:not(:disabled),
        &[class*='open'] {
            background: #2d3039;
            color: $color_withe;
        }
    }

    .rootDisabled {
        &:hover {
            outline: 0;
            border: 0;
            background-color: transparent;
        }
    }

    [class*='Dropdown_drop'] {
        font-size: 0.85em;
        max-height: 7.25em;
        padding: 0.4375em;
    }
}

.mode[class*='Dropdown_root'] {
    background: transparent;

    :global(.dark) & {
        background: #2d3039;
        color: $color_withe;

        &:hover:not(:disabled),
        &[class*='open'] {
            background: #2d3039;
            color: $color_withe;
        }
    }

    [class*='Dropdown_drop'] {
        font-size: 0.9em;
    }
}

.values {
    margin-bottom: 0.4em;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    gap: 0.4em;

    [data-learn-border] {
        display: flex;
    }
}

.progress {
    display: flex;
    align-items: center;
    gap: 0.625em;
    position: relative;
    padding-top: 1.2em;

    button {
        color: #a2a6b6;
    }
}

.freq {
    font-size: 0.75em;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.footer {
    margin-top: 0.6em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
        color: #666f7e;
        cursor: pointer;
        font-size: 1.2em;

        &:hover {
            color: #8f93a6;
        }
    }

    &_action {
        display: flex;
        align-items: center;
        gap: 0.675em;
    }
}

.reset {
    display: flex;
    align-items: center;
}

.grade {
    margin-left: 0.7em;
    font-size: 0.7em;
}
