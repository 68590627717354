@import "../../../scss/mixins/mixins";

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 17.5em;
  margin-right: 1.25em;
  text-align: left;
  border-radius: 0.375em;
  box-shadow: 0 0.125em 0.6875em 0 rgba(52, 61, 70, 0.1);
  // max-height: 100%;
  // max-height: 500px;

  .dark & {
    background: #363a45;
    border-color: #484b5a;
    color: #fff;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625em 0.9375em;
    border-radius: 0.375em 0.375em 0 0;
  }

  &__title__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    color: #1e1f25;
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    cursor: pointer;
    max-width: 12.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__title__rename_icon {
    cursor: pointer;
    margin-right: 0.5em;
  }

  &__title__input {
    border: none !important;
    background: transparent !important;
    color: #1e1f25 !important;
    font-size: 1em !important;
    font-weight: 400 !important;
    outline: none !important;
    padding: 0 !important;
  }

  &__options {
    position: relative;
  }

  &__items {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
    margin: 0;
    list-style: none;
    // @include scrollbar;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 0.75em 0.9375em 0.25em;
    // position: relative;
    max-height: fit-content;
  }

  &__body {
    @include scrollbar;
    overflow: auto !important;
    // padding: 0 !important;
  }

  &__container_empty {
    padding: 0;
  }

  &__footer {
    position: relative;
    z-index: 20;
    padding: 10px 15px;
    border-top: 0.0625em solid #e8e8e8;

    .dark & {
      border-top: 0.0625em solid #484b5a;
    }
  }

  &__archive {
    background: #f5f8fa;
    color: #595c6c;

    .dark & {
      background: #363a45;
      border-color: #484b5a;
      color: #fff;
    }
  }

  &__archive__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.833334em 1.25em;
    margin: 0;
    font-size: 0.75em;
    font-weight: 500;
    border-top: 1px solid #e8e8e8;
    cursor: pointer;

    .dark & {
      border-top: 1px solid #4a4f5b;
    }

    &.open {
      padding: 0.833334em 1.25em 0;
    }
  }

  &__archive__icon {
    transform: rotate(180deg);
  }
}
