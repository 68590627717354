@import '../../../scss/variables/variables';
@import '../../../scss/mixins/mixins';

.head {
    margin-bottom: 1.25em;
}

.settings {
    display: flex;
    align-items: flex-end;
    gap: 1.25em;

    [class*='Input_btns'] button {
        :global(.dark) & {
            color: #eee;
        }
    }
}

.label {
    font-size: 0.83em;
    margin-bottom: 0.3em;
    display: block;
}

.item {
    .number {
        width: 5em;
    }

    // .form__inline {
    //     margin-bottom: 0.5em;
    // }
}

.body {
    max-height: 17.6875em;
    overflow-y: overlay;
    @extend %scrollbar;

    @media print {
        border: 0;
        background: #fff;
        height: auto;
        overflow: hidden;
    }

    table {
        border: 1px solid #000;
        border-collapse: collapse;
        width: 100%;

        td {
            border: 1px solid #4a4f5b;
            padding: 0.3em;
        }
    }
}

.tabs {
    margin-bottom: 1em;
    display: inline-flex;
    flex-wrap: wrap;
    border-radius: 0.1875em;
    max-width: 100%;

    &Item {
        background: #f5f8fa;
        color: #7c8195;
        text-align: center;
        flex: none;
        padding: 0.375em 0.625em;
        min-width: 6em;
        cursor: pointer;
        user-select: none;
        border: 1px solid #bec9d3;

        :global(.dark) & {
            background: #282b33;
            color: #c7c9d1;
            border: 1px solid #343640;
        }

        &:nth-child(n + 2) {
            border-left: 1px solid #bec9d3;

            :global(.dark) & {
                border-left: 1px solid #343640;
            }
        }

        &.tabsItemActive {
            background: #00b5ff;
            color: #fff;
            cursor: default;
        }
    }
}

@media print {
    html,
    body {
        width: 100% !important;
        margin: 0;
    }
    .width {
        width: 700px;
    }
    .body {
        border: 0 !important;
        padding: 0 !important;
        background: transparent !important;
        width: 100% !important;
        height: auto;
        max-height: none;
        overflow: inherit;

        table {
            width: 100% !important;
            color: #000 !important;
        }
    }
    .img {
        width: 100%;
        margin: 0 0 16px;
    }

    @page {
        margin: 1cm;

        @top-center {
            content: element('');
        }

        @bottom-center {
            content: element(pageFooter);
        }
    }
}
