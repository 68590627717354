.uplot,
.u-wrap,
.u-under {
    display: flex;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;

    .u-under {
        border: 1px solid #e8e8e8;

        .dark & {
            border: 1px solid #343640;
        }
    }
}

.u-wrap {
    overflow: hidden;
}

.u-over {
    overflow: hidden;
    flex-grow: 1;
    min-width: 0;
    min-height: 0;
}

.uplot {
    $arrow_size: 0.3em;
    $arrow_color: #f75656;
    .u-cursor-x,
    .u-cursor-y {
        position: absolute;
        z-index: 2;
        border-color: $arrow_color;

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
        }
        &:before {
            content: attr(data-value);
            background: #fff;
            color: #000;
            border-radius: 2px;
            font-size: 0.6em;
            border: 1px solid $arrow_color;
            padding: 0.375em 0.625em;
            z-index: 10;

            .dark & {
                background: #282b33;
                color: #fff;
            }
        }
        &:after {
            border: $arrow_size solid transparent;
            z-index: 3;
        }
    }

    .u-cursor-x {
        z-index: 11;
        &:before {
            bottom: -$arrow_size;
        }
        &:after {
            border-top-color: $arrow_color;
            bottom: -#{$arrow_size * 2};
        }
    }
    .u-cursor-y {
        &:before {
            right: -#{$arrow_size * 4};
        }
        &:after {
            border-left-color: $arrow_color;
            right: -#{$arrow_size * 2};
            top: 50%;
        }
    }

    .u-range-x {
        position: absolute;
        height: 100%;
        cursor: grab;
        background: rgba($color: #4de4ad, $alpha: 0.1);
        z-index: 2;
    }

    .u-marker-x {
        position: absolute;
        display: inline-block;
        height: 100%;
        width: 6px;
        padding: 0 2px;
        cursor: col-resize;
        // z-index: 2;
        will-change: transform;
        z-index: 3;

        &:hover {
            // cursor: col-resize;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 2px;
            background: #bec9d3;

            .dark & {
                background: #4a4f5b;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
        }

        &-label {
            // content: attr(data-value);
            // content: '';
            user-select: none;
            will-change: auto;
            display: block;
            position: absolute;
            // bottom: 0.75em;
            bottom: 0.9em;
            transform: translateX(-50%);
            border-radius: 2px;
            border: 1px solid #bec9d3;
            background: #fff;
            box-shadow: 0em 0.5625em 0.9375em 0em rgba(52, 61, 70, 0.09);
            padding: 0.15em 0.5625em;
            color: #000;
            font-family: Inter;
            font-size: 0.625em;
            z-index: 1;

            .dark & {
                border: 1px solid #4a4f5b;
                background: #363a45;
                color: #fff;
            }
        }
        // &-label {
        //     display: block;
        //     position: absolute;
        //     bottom: 0.75em;
        //     transform: translateX(-50%);
        //     border-radius: 2px;
        //     border: 1px solid #bec9d3;
        //     background: #fff;
        //     box-shadow: 0em 0.5625em 0.9375em 0em rgba(52, 61, 70, 0.09);
        //     padding: 0.2em 0.5625em;
        //     color: #000;
        //     font-family: Inter;
        //     font-size: 0.625em;

        //     .dark & {
        //         border: 1px solid #4a4f5b;
        //         background: #363a45;
        //         color: #fff;
        //     }
        // }
    }
}
