@import '../../scss/variables/variables';
@import '../../scss/mixins/mixin';

.root {
    position: relative;
    // flex-grow: 1;
    flex: none;
    // min-height: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
    background: #ffffff;
    // border: 1px solid #00B5FF;
    box-shadow: 0 0.25em 0.9375em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    padding: 1.15em 0.65em 1.25em 0.7em;

    :global(.dark) & {
        // border: 1px solid #00B5FF;
        background: #282b33;
    }

    canvas {
        width: 100%;
        flex-grow: 1;
        min-width: 0;
        min-height: 0;
        max-width: 100%;
    }

    &:hover {
        .chart__data {
            display: block;
        }
    }
}

.head {
    padding: 0 1em 0 0.5em;
    margin-bottom: 1.125em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: none;
}

.left {
    display: flex;
    align-items: center;
    gap: 1.8125em;
    overflow: auto hidden;
    min-width: 0;
    margin-right: 1.4em;

    @extend %scrollbar;
}

.legend {
    // position: absolute;
    // top: 4.7%;
    // left: 9%;
    // padding: 0.8em;
    // border: 1px solid #A3A3A2;
    // font-size: 0.7em;
    display: flex;
    align-items: center;
    // z-index: 2;
    background: $light_theme_background;

    :global(.dark) & {
        background: #282b33;
    }

    &_multy {
        display: block;

        > div {
            display: flex;
            align-items: center;
        }
    }

    span {
        font-weight: 600;
        font-size: 0.875em;
    }
}

.scalesZoom {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 0.4em;
    padding: 0.1em;
}

.autoChart {
    padding: 0.1em;

    > label {
        display: flex;
        align-items: center;
        gap: 0.5em;

        > span {
            flex: none;
            font-size: 1.1em;
            margin-bottom: -0.1em;
        }

        input[type='checkbox'] {
            flex: none;
            margin-top: -0.1em;
            margin-left: -0.3em;
        }
    }
}

.action {
    // margin-bottom: -0.6em;
    // padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625em;
    cursor: pointer;

    [data-learn-border] {
        position: relative;
        display: flex;
    }

    i,
    button {
        min-width: 1.65em;
        height: 1.65em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.1em;
        color: #666f7e;
        background-color: transparent;
        border: 1px solid #bec9d3;
        border-radius: 2px;
        transition: 0.3s;
        transition-property: color, background;
        cursor: pointer;

        :global(.dark) & {
            border: 1px solid #343640;
        }

        &:hover:not(:disabled) {
            color: #bec9d3;
            :global(.dark) & {
                color: #ffffff;
            }
        }

        &.iconActive {
            border: 1px solid #00b5ff;
            background: #00b5ff;
            color: #ffffff;

            &:hover {
                color: #ffffff;
            }
        }

        &:disabled {
            cursor: default;
        }
    }

    [class^='Dropdown_root'] {
        ul {
            li {
                border-top: 0;
                margin-top: 0;
            }
        }
    }

    .saveList {
        li i {
            :global(.dark) & {
                color: #c7c9d1;
            }
        }
    }
}

.color {
    flex: none;
    width: 1.25em;
    height: 1.25em;
    border-radius: 0.1875em;
    margin-right: 1em;
}

.data {
    display: none !important;
    padding: 0.7em 0.8em;
    font-size: 0.7em;
    line-height: 1.3;
    border: 1px solid #a3a3a2;
    color: $light_theme_color;
    position: absolute;
    right: 2.3%;
    top: 3.3%;
    white-space: nowrap;
    background: $light_theme_background;

    :global(.dark) & {
        color: #a3a3a2;
        border: 0;
        background: $dark_theme_background;
    }

    &_line {
        display: none !important;
    }
}

.wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    // margin-bottom: -0.9em;

    &_multy {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 0;
        min-width: 0;
    }
}

.big {
    display: flex;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;

    &_center {
        align-items: center;
        justify-content: center;
    }

    .speedometer {
        position: relative;
        background: #1e1f25;
        border: 0.8em solid rgba(#9647fb, 0.5);
        padding: 0.4em;
        border-radius: 50%;

        &__avg,
        &__val {
            position: absolute;
            width: 100%;
            text-align: center;
        }

        &__avg {
            left: 0;
            font-size: 0.6em;
            bottom: 3em;
        }
        &__val {
            left: 50%;
            transform: translateX(-50%);
            font-size: 1em;
            font-weight: 600;
            bottom: 3.2em;
            width: 5em;
            padding: 0.3em;
            background: #1a1a1a;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    [class*='AnalogDevice_root'] {
        flex-grow: 1;
        box-shadow: none;
        align-self: stretch;
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: row;
    }

    [class*='AnalogDevice_title'] {
        font-size: 0;
    }

    [class*='AnalogDevice_top'] {
        order: 3;
        align-items: start;
        justify-content: start;
    }

    [class*='AnalogDevice_action'] {
        flex-direction: column;
    }

    [class*='AnalogDevice_group'] {
        flex-direction: column;
        max-height: 100%;
    }
}

.mini {
    flex-grow: 0;
    height: 30%;
    margin-top: 0.7em;
    // padding-bottom: 1.8em;
    display: none;
    display: flex;
    flex-direction: column;
    max-height: 8em;

    canvas {
        flex-grow: 1;
        min-height: 0;
        transform: translate3d(0, 0, 0);
    }

    &_title {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 0.75em;
        line-height: 1.1;
        // color: #000;
        margin-bottom: 0.6em;
        padding-left: 4.3em;

        :global(.dark) & {
            color: #ceced6;
        }
    }
}

.titles {
    padding: 3em 0 1em 0.5em;
    line-height: 1.4;
    font-weight: 500;
    writing-mode: tb-rl;
    text-align: center;
    transform: rotate(180deg);

    span {
        display: inline-block;
        font-size: 0.75em;

        &:not(:last-child):after {
            content: ', ';
        }
    }
}

.foot {
    padding: 0.1em;
    margin-left: 2.3em;
    // width: 13em;
    align-self: start;
    flex: none;
    display: flex;
    align-items: center;
    gap: 1.1875em;
    position: relative;
    z-index: 1;

    label {
        display: flex;
        align-items: center;
        font-size: 0.8em;
    }

    span {
        font-size: 0.8em;
        padding-right: 0.4em;
    }
}

.tootltip {
    top: 4.6em;
    right: 1.1em;
    position: absolute;
    border-radius: 0.125em;
    color: #1e1f25;
    border: 1px solid #bec9d3;
    background: rgba(#fff, 0.8);
    box-shadow: 0px 0.5625em 0.9375em 0px rgba(52, 61, 70, 0.09);
    padding: 0.5em 0.8125em;

    :global(.dark) & {
        border: 1px solid #4a4f5b;
        background: rgba(#363a45, 0.8);
    }

    &Row {
        display: flex;
        justify-content: space-between;
        gap: 0.625em;
        margin-bottom: 0.6em;
    }
    &Foot {
        display: flex;
        gap: 0.625em;
    }

    &Col {
        width: calc(50% - 0.625em);
        label {
            display: block;
        }
    }

    label {
        color: #7c8195;
        font-size: 0.625em;

        :global(.dark) & {
            color: #c7c9d1;
        }
    }
    span {
        font-size: 0.625em;

        :global(.dark) & {
            color: #fff;
        }
    }
}
.settings {
    // width: 10em;
    display: flex;
    align-items: center;
    gap: 1em;
    margin: 0 0.6em;

    &Label,
    &Prop {
        display: inline-block;
        font-size: 0.75em;
        vertical-align: middle;
    }

    &Prop {
        width: 3em;
    }
}

.zoom {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    font-weight: 300;
}

.group {
    display: flex;
    align-items: center;

    [class^='Dropdown_root'] {
        background-color: transparent;
        outline: none;
        outline-offset: 0;

        &:hover:not(:disabled) {
            outline: none;
            background-color: transparent;
        }

        :global(.dark) & {
            background-color: transparent;

            &:hover:not(:disabled) {
                background-color: transparent;
            }
        }

        &[class*='disabled']:hover {
            outline: none;
            outline-offset: 0;
        }

        &:nth-child(n + 2) {
            margin-left: -1px;
        }

        [class^='cl_icon_'] {
            border: 0;
            padding: 0;
            font-size: 1.45em;
            min-width: inherit;
            height: auto;
        }
        :global(.light) & {
            [class*='cl_icon_cleared'],
            [class*='cl_icon_inclined'],
            [class*='cl_icon_derivative'],
            [class*='cl_icon_integral'] {
                .path2:before {
                    color: #666f7e;
                }
            }

            [class*='cl_icon_linear'],
            [class*='cl_icon_quadratic'] {
                .path3:before {
                    color: #666f7e;
                }
            }
            [class*='cl_icon_furie'] {
                .path1:before,
                .path2:before {
                    color: #666f7e;
                }
            }
        }

        .icon {
            &_bars {
                font-size: 1.6em;
            }
            &_speed-dashboard {
                font-size: 1.8em;
            }
        }

        [class^='Dropdown_label'] {
            padding: 0.2em 0.4em;
            height: 2.23em;

            &:after {
                :global(.dark) & {
                    color: #666f7e;
                }
            }
        }

        [class^='Dropdown_drop'] {
            padding: 0.4375em 0.3em;
            text-align: center;
            ul {
                li {
                    font-size: 0.8em;
                    gap: 0.5em;
                    border-top: 0;
                }
            }
        }
    }
}

.parametric {
    margin: 0 0.6em 1em;
    position: relative;

    &__row {
        margin-bottom: 1.1875em;
    }
    &__label {
        font-size: 0.75em;
        margin-bottom: 0.5em;
        display: block;
    }
}

.editMarker {
    &Fields {
        display: flex;
        margin-left: -1em;
    }
    &Label {
        font-size: 0.8em;
        margin-bottom: 0.5em;
    }
    &Field {
        width: 50%;
        padding-left: 1em;
        flex-grow: 1;

        input {
            width: 100%;
        }
    }
}

.iconGroup {
    display: flex;

    [class^='cl_icon_'] {
        &:nth-child(n + 2) {
            border-left: 0;

            &:not(:last-child) {
                border-radius: 0;
            }
        }
    }
}
