@import "../../../scss/variables/variables";

.cor_settingsIcon {
    display: flex;
    color: #fff;
    gap: 0.625em;

    &__btn {
        width: 1.75em;
        height: 1.75em;
        border: 0.0625em solid #6F92B9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.125em;
        transition: outline 0.2s;
        cursor: pointer;
        user-select: none;
        outline: 1px solid transparent;

        .dark & {
            border: 1px solid #343640;
        }

        &:hover {
            outline: 1px solid #ffffff;
            outline-offset: -1px;
        }

        &__drop {
            position: relative;

            &:hover {
                .cor_settingsIcon__drop {
                    display: block;
                    animation: fadeUp 0.3s;
                }
            }
        }
    }

    &__drop {
        display: none;
        position: absolute;
        top: 100%;
        padding-top: 0.25em;
        z-index: 1;
        //right: 0;


        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            background: $footer_dropdown_bg;
            border: 1px solid $footer_dropdown_border_color;
            box-shadow: 0 0.5625em 0.9375em rgba(52, 61, 70, 0.09);
            border-radius: 0.5em;
            // padding: 0 0.5625em;
    
            .dark & {
                background: $footer_dark_button_bg;
                box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
                border: 1px solid $footer_dark_dropdown_border_color;
            }

            li {
                padding: 0.4375em 0;
                padding: 0.6em 1em;
                white-space: nowrap;
                text-decoration: none;
                font-size: 0.625em;
                font-weight: 500;
                color: $footer_dropdown_item_color;

                .dark & {
                    color: $footer_dark_color;
                }


                &:hover {
                    color: $footer_dropdown_item_hover_color;
                }

                &:nth-child(n+2) {
                    border-top: 1px solid #343640;
                }
            }
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }
}
