.list {
    display: flex;
    gap: 0.625em;
}
.item {
    padding: 0.8em 1em;
    font-size: 0.75em;
    font-weight: 700;
    border: 1px solid rgba(255, 255, 255, 0.75);
    border-radius: 0.125em;
    transition: background 0.4s;
    cursor: pointer;
    user-select: none;

    :global(.dark) & {
        border: 1px solid #6f92b9;
    }
}

.active {
    composes: item;
    background: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(#fff, 0.8);
    cursor: default;
}

.disabled {
    composes: item;
    opacity: 0.5;
    cursor: default;
}
