.root {

}

.personeCol {
    display: inline-flex;
    gap: 0.5em;
    align-items: center;
    cursor: pointer;


    &__icon {
        flex: none;
        font-size: 1.5em;
    }

    &__picture {
        flex: none;
        width: 1.5em;
        height: 1.5em;
        border-radius: 100%;
        overflow: hidden;
    }
    
}

.actionIcons {
    display: inline-flex;
    align-items: center;
    gap: 1em;

    &__item {
        position: relative;

        display: inline-flex;
        align-items: flex-end;
        gap: 0.375em;

        color: #7C8195;

        cursor: pointer;

        &:hover {
            .actionIcons__tooltip {
                display: block;
                animation-duration: 0.3s;
                
                &.bottom {
                    animation-name: bottom;
                }
                &.top {
                    animation-name: top;
                }
                &.left {
                    animation-name: left;
                }
                &.right {
                    animation-name: right;
                }
            }
        }
    }

    &__icon {
        font-size: 1.375em;
    }

    &__explanationText {
        text-decoration: underline;
    }

    &__tooltip {
        display: none;
    
        position: absolute;
        z-index: 10;
    
        font-size: 0.75em;
        width: max-content;
        text-align: left;
        padding: 0.5em 0.75em;
        border-radius: 0.75em;
        line-height: 1.2;

        background-color: #ffffff;
        border: 1px solid;
        color: #383E47;
        border-color: #BEC9D3;
        box-shadow: (0px 9px 15px rgba(52, 61, 70, 0.09));

        &:after {
            content: '';
            position: absolute;

            display: block;
            width: 1em;
            height: 1em;
            border: 1px solid;
            border-left: 0;
            border-bottom: 0;

            border-color: #BEC9D3;
            background-color: inherit;
        }

        :global(.dark) & {
            background: #282B33;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            color: #ffffff;
            border-color: #343640;

            &:after {
                border-color: #343640;            }
        }

        &.bottom {
            top: 2.25em;
            left: 50%;
            transform: translate(-50%, 0);

            &:after {
                top: -0.52em;
                left: 50%;
                transform: translateX(-50%)rotate(-45deg);
            }
        }

        &.top {
            bottom: 2.25em;
            left: 50%;
            transform: translate(-50%, 0);

            &:after {
                bottom: -0.52em;
                left: 50%;
                transform: translateX(-50%)rotate(135deg);
            }
        }

        &.left {
            top: 50%;
            right: 2.25em;
            transform: translate(0, -50%);

            &:after {
                right: -0.52em;
                top: 50%;
                transform: translateY(-50%)rotate(45deg);
            }
        }
        
        &.right {
            top: 50%;
            left: 2.25em;
            transform: translate(0, -50%);

            &:after {
                left: -0.52em;
                top: 50%;
                transform: translateY(-50%)rotate(-135deg);
            }
        }

    }

}

.colorInfo {
    color: #00B5FF;
}

.colorSecondary {
    color: grey;

    :global(.dark) & {
        color: lightgrey;
    }
}

.bottom {
    composes: actionIcons__tooltip;
    top: 2.25em;
    left: 50%;
    transform: translate(-50%, 0);

    &:after {
        top: -0.52em;
        left: 50%;
        transform: translateX(-50%)rotate(-45deg);
    }
}

.top {
    composes: actionIcons__tooltip;
    bottom: 2.25em;
    left: 50%;
    transform: translate(-50%, 0);

    &:after {
        bottom: -0.52em;
        left: 50%;
        transform: translateX(-50%)rotate(135deg);
    }
}

.left {
    composes: actionIcons__tooltip;
    top: 50%;
    right: 2.25em;
    transform: translate(0, -50%);

    &:after {
        right: -0.52em;
        top: 50%;
        transform: translateY(-50%)rotate(45deg);
    }
}

.right {
    composes: actionIcons__tooltip;
    top: 50%;
    left: 2.25em;
    transform: translate(0, -50%);

    &:after {
        left: -0.52em;
        top: 50%;
        transform: translateY(-50%)rotate(-135deg);
    }
}

@keyframes bottom {
    0% {
        transform: translate(-50%, 0.5em);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}

@keyframes top {
    0% {
        transform: translate(-50%, -0.5em);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}

@keyframes left {
    0% {
        transform: translate(-0.5em, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

@keyframes right {
    0% {
        transform: translate(0.5em, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}