.root {
    position: fixed;
    z-index: 10;
    border-radius: 0.5em;
    padding: 1em;
    width: 24.5625em;
    // margin: 0.5em;
    background: #fff;
    box-shadow: 0em 0.3125em 2.1875em 0em #00000040;
    user-select: none;
    cursor: move;
    // animation: boxShow 0.3s;
    // opacity: 1;
    // transition: opacity 0.2s;

    :global(.dark) & {
        background: #434854;
    }
}

.close {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0.75em;
    top: 0.75em;
    width: 0.75em;
    height: 0.75em;

    :global(.dark) & {
        color: #c7c9d1;
    }

    &:before,
    &:after {
        content: '';
        display: block;
        border-top: 1px solid;
        width: 100%;
        position: absolute;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}

.body {
    display: block;
}

.title {
    font-size: 1.125em;
    font-weight: 700;
    margin-bottom: 0.5em;
    padding-right: 2em;

    :global(.dark) & {
        color: #fff;
    }
}

.text {
    font-size: 0.875em;
    line-height: 1.3;
    font-weight: 400;
    color: #4f4f53;

    :global(.dark) & {
        color: #c7c9d1;
    }
}

.foot {
    margin-top: 0.75em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrow {
    position: absolute;
    width: 1em;
    height: 1em;
    transform: rotate(45deg);
    background: #fff;
    opacity: 0;

    :not([class]) {
    }

    &[class*='top'] {
        bottom: -0.4em;
        left: calc(50% - 0.5em);
        opacity: 1;
    }
    &[class*='bottom'] {
        top: -0.4em;
        left: calc(50% - 0.5em);
        opacity: 1;
    }
    &[class*='left'] {
        top: calc(50% - 0.5em);
        right: -0.4em;
        opacity: 1;
    }
    &[class*='right'] {
        top: calc(50% - 0.5em);
        left: -0.4em;
        opacity: 1;
    }

    :global(.dark) & {
        background: #434854;
    }
}

.steps {
    font-size: 0.6875em;
    font-weight: 500;

    :global(.dark) & {
        color: #fff;
    }

    span {
        font-weight: 400;

        :global(.dark) & {
            color: #c7c9d1;
        }
    }
}

[data-learn-border] {
    border-radius: 3px;
}

@keyframes boxShow {
    from {
        opacity: 0;
        // transform: translateY(-1.25em);
    }
    to {
        opacity: 1;
        // transform: translateY(0);
    }
}
