// tooltip (tp)
.tp {
    position: relative;
    &:hover {
        &:after {
            display: block;
            animation: tooltipFadeUp 0.3s;
        }
    }

    &:after {
        content: attr(data-tp);
        display: none;
        position: absolute;
        z-index: 20;

        left: 50%;
        top: auto;
        bottom: 120%;
        transform: translate(-50%, 0);

        padding: 0.6em 1em;
        width: max-content;
        border: 1px solid $footer_dropdown_border_color;
        border-radius: 0.8em;
        color: #4A4A4D;
        background-color: $footer_dropdown_bg;
        font-size: 0.625em;
        font-weight: 500;
        box-shadow: 0px 0.5625em 0.9375em 0px rgba(52, 61, 70, 0.09);

        .dark & {
            background: $footer_dark_button_bg;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            border: 1px solid $footer_dark_dropdown_border_color;
            color: $footer_dark_color;
        }
    }

    &_down {
        &:hover {
            &:after {
                // animation: tooltipFadeDown 0.3s;
            }
        }
    
        &:after {
            top: 120%;
            bottom: auto;
        }
    }

    &_center_right {
        &:hover {
            &:after {
                animation: tooltipFadeCenterSide 0.3s;
            }
        }

        &:after {
            top: 50%;
            transform: translate(0, -50%);
            left: 110%;
            bottom: auto;
        }
    }

    &_center_left {
        &:hover {
            &:after {
                animation: tooltipLeft 0.3s;
            }
        }

        &:after {
            top: 50%;
            transform: translate(0, -50%);
            right: 110%;
            left: auto;
            bottom: auto;
        }
    }

    &_right {
        &:hover {
            &:after {
                animation: tooltipFadeUpSide 0.3s;
            }
        }
        
        &:after {
            transform: none;
        }
    }

    &_left {
        &:hover {
            &:after {
                animation: tooltipFadeUpSide 0.3s;
            }
        }
        
        &:after {
            transform: none;
            left: auto;
            right: 50%;
        }
    }
}



@keyframes tooltipFadeUp {
    0% {
        transform: translate(-50%, 0.5em);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}

@keyframes tooltipFadeUpSide {
    0% {
        transform: translate(0, 0.5em);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes tooltipFadeCenterSide {
    0% {
        transform: translate(0.5em, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}
