@import "../../scss/variables/variables";

.connectSettings {
    &__item {
        color: #666F7E;
        display: flex;
        align-items: center;
        font-size: 1.125em;

        .dark & {
            color: #C7C9D1;
        }
    
        &.link {
            cursor: pointer;
        }

        &.active {
            color: #00B5FF;
        }

        & .cor_icon {
            font-size: 1.333334em;
            margin-right: 0.5em;
        }

        .hidden & .cor_icon {
            margin: 0;
        }

        &.active.danger{
            color: $color_danger;
        }

        &:nth-child(n+2) {
            margin-top: 0.9375em;
        }
    }
}
