.modal_task {
  position: relative;
  &__header {
    box-shadow: 0 0.25em 1.875em 0 rgba(0, 0, 0, 0.1);
    padding: 1.25em;
  }

  &__title_wrapper {
    display: flex;
    align-items: center;
    gap: 0.3125em;
    margin-bottom: 0.625em;
  }

  &__title {
    font-weight: 600;
    font-size: 0.75em;
    margin: 0;
    max-width: 12em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__title_button {
    cursor: pointer;
    stroke: #666f7e;
  }

  &__desc {
    font-weight: 500;
    font-size: 0.875em;
    display: flex;
    gap: 0.625em;
  }

  &__desc_title {
    margin: 0;
    color: #7c8195;
  }

  &__desc_text {
    margin: 0;
    color: #00b5ff;
    text-decoration: underline;
  }

  &__description_button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.3125em;
    font-size: 0.75em;
    font-weight: 500;
    color: #00b5ff;
    border: none;
    background: none;
    padding: 0;
  }

  &__description_title {
    font-weight: 400;
    font-size: 0.875em;
  }

  &__description_button_icon {
    stroke: #00b5ff;
  }

  &__body {
  }

  &__body_description {
    padding: 1.25em;
  }

  &__body_section {
    margin: 0 0 1.25em;
  }

  &__section_input {
    display: block;
    width: 100%;
    min-height: 5.625em;
    resize: none;
  }

  &__section_menu {
    position: absolute;
    top: 1.5em;
    left: 0;
    // background-color: #00b5ff;
    width: 17.75em;
    max-height: 15em;

    border-radius: 6px;
    border: 1px solid #bec9d3;
    // padding: 0.625em;
    background: #fff;
  }

  &__section_stickers {
    position: relative;
    z-index: 1000;
  }

  &__section_users {
    display: flex;
    align-items: center;
    gap: 0.3125em;
    width: fit-content;
  }

  &__section_users_menu {
    position: relative;
  }

  &__section_users_button {
    cursor: pointer;
  }

  &__section_user {
    // position: absolute;
    width: 1.25em;
    height: 1.25em;
    object-fit: cover;
    border-radius: 1em;
    border: 0.1em solid #fff;
    margin-left: -0.375em;
    cursor: pointer;
  }

  &__section_first_user {
    margin-left: 0;
  }

  &__section_users_quantity {
    font-size: 0.625em;
    color: #bec9d3;
    padding: 0.6em 0.4em 0.5em 0.3em;
    border-radius: 20px;
    border: 1px solid #bec9d3;

    background: #fff;
    margin-left: -0.375em;

    box-sizing: border-box;
    line-height: 0.5em;
    cursor: pointer;
  }

  &__section_users_stickers {
    display: flex;
    align-items: center;
  }

  &__section_date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0.625em;
    border-radius: 2px;
    border: 1px solid #bec9d3;
    width: 12.5em;
  }

  &__section_date_toggle {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 19px;
  }

  &__section_date_toggle_button {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/icons/card-menu/14_calendar.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.625em;
  }

  &__section_date_label {
    font-weight: 500;
    font-size: 0.75em;
  }

  &__section_date_input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }

  &__section_title {
    font-weight: 600;
    font-size: 0.75em;
    margin: 0 0 0.625em;
  }

  &__body_chat {
  }

  &__upload {
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }

  &__img {
    width: 13.375em;
    height: 6.6875em;
    border-radius: 0.375em;
    object-fit: cover;
    margin-bottom: 0.625em;
  }

  &__close {
    position: absolute;
    right: 2.15em;
    top: 1.75em;
    width: 0.9em;
    height: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    transition: color 0.3s;
    color: #666f7e;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 1px;
      transform: rotate(45deg);
      background-color: #666f7e;
    }

    &::after {
      content: '';
      background-color: #666f7e;
      position: absolute;
      display: block;
      width: 12px;
      height: 1px;
      transform: rotate(-45deg);
    }
  }
}

.users_modal {
  overflow-y: scroll;

  &__search {
    padding: 0.625em;

    position: relative;

    &::after {
      position: absolute;
      z-index: 3000000000;
      content: url('../../../assets/icons/search.svg');
      // content: '';
      top: 1.0625em;
      right: 1.25em;
      width: 1em;
      height: 1em;
    }
  }
  &__search_input {
    display: block;
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.625em;
    list-style: none;
    padding: 0.625em;
    margin: 0;
    border-top: 1px solid #e8e8e8;
  }

  &__list_item {
    display: flex;
    align-items: center;
  }

  &__list_checkbox {
    margin-right: 0.4375em;
    cursor: pointer;
  }

  &__list_label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__list_img {
    margin-right: 0.25em;
    width: 1.125em;
    height: 1.125em;
    border-radius: 100%;
    object-fit: cover;
  }

  &__list_name {
  }
}
