.cor-timer {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    color: #383E47;
    
    .dark & {
        color: #d1d1d1;
    }

    &__title {
        display: inline-flex;
        gap: 0.5em;
        align-items: center;
        font-size: 1em;

        b {
            font-size: 0.875em;
            font-weight: 500;
        }
    }

    &__list {
        display: inline-flex;
        align-items: center;
        width: fit-content;

    }

    &__item {
        flex: none;
        position: relative;
        display: inline-block;
        min-width: 2.5em;
        text-align: center;
        border: 1px solid #bec9d3;
        background-color: #f5f8fa;
        padding: 0.25em 0.5em 0.75em 0.5em;
        border-radius: 0.3125em;

        .dark & {
            border: 1px solid #4a4f5b;
            background-color: #363a45;
        }
    
    }

    &__number {
    }

    &__label {
        position: absolute;
        bottom: 0.5em;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.5em;
    }

    &__separator {
        margin: 0 0.125em;
        font-size: 1.5em;
        transform: translateY(-0.05em);
    }

}
