.modalBattery {
    margin-bottom: 1.3em;
}
.item {
    background: #f0f3f5;
    border: 1px solid #bec9d3;
    border-radius: 2px;
    margin-bottom: 0.625em;

    @media (min-width: 768px) {
        display: flex;
    }

    :global(.dark) & {
        border: 1px solid #4a4f5b;
        background: #363a45;
    }
}
.left {
    padding: 1em;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1;
    color: #444754;

    @media (min-width: 768px) {
        width: 50%;
        flex-grow: 1;
        padding: 1.5em 1.25em;
    }

    :global(.dark) & {
        color: #fff;
    }

    span {
        line-height: 1.4;
        font-size: 0.875em;
    }

    i {
        flex: none;
        font-size: 1.875em;
        margin-right: 0.6em;
        color: #ffad32;
    }
}
.right:not(:empty) {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    padding: 0em 1em 1em;
    border-left: 1px solid #bec9d3;

    @media (min-width: 768px) {
        flex: none;
        width: 50%;
        padding: 1.5em 1.25em;
    }

    :global(.dark) & {
        border-left: 1px solid #4a4f5b;
    }

    i {
        font-size: 1.4em;
        vertical-align: middle;
        line-height: 1;
        margin-bottom: 0.1em;
    }

    span {
        font-size: 0.75em;
        margin: 0.2em 0;
    }

    [class*='success'] {
        color: #48e000;
    }
    [class*='danger'] {
        color: #ff6c6c;
    }

    b {
        font-weight: 500;
        color: #444754;

        :global(.dark) & {
            color: #fff;
        }
    }
}
