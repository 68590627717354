.root {

}

.modalBody {
    max-height: 50vh;
    overflow-y: auto;
    padding-right: 0.8125em;

    @media (min-width: 768px) {
        // overflow-y: visible;
        scrollbar-gutter: stable;

        &::-webkit-scrollbar {
            width: 0.3em;
            height: 0.3em;

            &-track {
            }

            &-button {
                opacity: 0;

                &:single-button {
                    width: 0.1em;
                    height: 0.1em;
                }
            }

            &-thumb {
                background: #bec9d3;
                border-radius: 1em;
                border: 0 solid transparent;
                background-clip: border-box;
                min-height: 5em;

                :global(.dark) & {
                    background: #464f57;
                }
            }
        }
    }
}
