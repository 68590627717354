@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.reviewLabWork {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	$HeaderSize: 1.3em;

	&__first {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	&__StopButton {
		background-color: $lw_blue_color;
		font-size: 0.8em;
		color: white;
		border: none;
		border-radius: 0.375em;
		padding: 0.3em 1em;
		margin-right: 1em;

		.dark & {
			color: $lw_dark_theme_color;
		}
	}

	&__separator {
        border-top: solid 0.02em #BEC9D3;
        opacity: 0.5;
    }

	&__topHead {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 3.6875em;
		background-color: #efefef;
		font-size: $HeaderSize;
		font-weight: 800;
		padding-left: 0.5em;

		&TestDate {
			padding-right: 1em;
		}

		.dark & {
			background-color: $lw_dark_theme_bknd_color;
			color: $lw_dark_theme_color;
		}
	}

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 3.6875em;
		padding: 0 1.8125em 1.625em 0;
		font-size: 1em;

		&Title {
			font-weight: 600;
			padding: 1.5em 0 0 1.5em;
			color: #1E1F25;
			.dark & {
				color: $lw_dark_theme_color;
			}
		}

		&Chapter {
			font-weight: 600;
			padding: 1.5em 0 0 0;
			color: lighten(#1E1F25, 30%);
			.dark & {
				color: darken($lw_dark_theme_color, 20%);
			}
		}


		&Time {
			font-size: 0.8em;
			padding: 2em 0 0 0em;
		}
	}

	&__body {
		flex-grow: 1;
		min-height: 0;
		position: relative;
		background: #fcfcfc;
		background-position: center center;
		z-index: 1;
		padding: 1.0em 1.875em 0;
		overflow-y: overlay;
		display: flex;
		flex-direction: column;

		@include scrollbar;

		> div {
			// min-height: 0;
		}
	}

	&__alarm {
		padding: 0 0.5em;
		color: $lw_blue_color;
	}
}
//Great Tool: https://pinetools.com/image-color-picker
