@import '../../../scss/variables/variables';

.root {
}

.body {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.25em;
}

.col {
    width: 50%;
    padding-left: 1.25em;
    margin-bottom: 0.5625em;

    &_full {
        width: 100%;
    }
}

.label {
    margin-bottom: 0.5em;
    font-size: 0.75em;
    font-weight: 500;
    color: #7c8195;

    :global(.dark) & {
        color: #c7c9d1;
    }

    &_val {
        color: #1e1f25;
        margin-left: 0.7em;

        :global(.dark) & {
            color: $color_withe;
        }
    }
}

.range {
    min-height: 1.875em;
    display: flex;
    align-items: center;
}

.colors {
    [class^='Dropdown_drop'] li {
        padding: 0.43em 0;
    }
    [class^='Dropdown_label'] > span,
    li {
        display: flex;
        align-items: center;
        flex: none;

        span {
            border: 1px solid;
            width: 0.87em;
            margin-right: 0.45em;
        }
    }

    [class='react-colorful'] {
        width: 100%;
        > [class='react-colorful__saturation'] {
            border-radius: 0.25em;
        }
        > [class*='react-colorful__hue'] {
            border-radius: 0.25em;
            height: 1.25em;
            margin-top: 0.35em;
        }
    }

    [class*='react-colorful__pointer'] {
        width: 1.25em;
        height: 1.25em;
        box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.2);
        border: 1px solid #fff;
    }
}
