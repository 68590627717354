@import "../../../scss/variables/variables";
// @import "../../../scss/mixins/mixins";
@import '../Icon/variables.scss';

.cor_dropdown {
    background: #f5f8fa;
    border: 1px solid #bec9d3;
    border-radius: 0.125em;
    color: #666f7e;
    user-select: none;
    position: relative;
    font-weight: 500;
    flex-grow: 1;
    min-width: 0;
    cursor: pointer;

    &:hover:not(.disabled) {
        border: 1px solid #bec9d3;
        background: #f5f8fa;
        color: #666f7e;

        .table & {
            background: #00b5ff;
            border: 1px solid #00b5ff;
            color: $color_withe;
        }
    }

    .dark & {
        background: #363a45;
        border: 1px solid #4a4f5b;
        color: #ffffff;

        &:hover:not(:disabled) {
            background: #363a45;
            border: 1px solid #4a4f5b;
            color: #ffffff;
        }
    }

    .table & {
        background: #00b5ff;
        border: 1px solid #00b5ff;
        color: $color_withe;

        &__label {
            span {
                text-align: center;
            }
        }
    }

    &__check {
    }

    &.open {
        border-radius: 0.125em 0.125em 0 0;

        &.top & {
            border-radius: 0 0 0.125em 0.125em;
        }

        &:not(.disabled) {
            .cor_dropdown__drop {
                display: block;
            }
            .cor_dropdown__label {
                &:after {
                 
                    transform: scale(-1);
                    margin-top: 0;
                   
                    
                }
            }
        }
    }

    &.disabled {
        cursor: default;
        border: 1px solid red;
        background: #f5f8fa;
        color: #7c8195;

        .dark & {
            background: #282b33;
            border-color: #343640;
            color: #c7c9d1;

            &:hover {
                outline: 1px solid #7c8094;
                outline-offset: -1px;
                color: $color_withe;
            }
        }
    }

    &__label {
        padding: 0.667em 0.9em;
        font-size: 0.75em;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span:not(.cor_icon) {
            flex-grow: 1;
        }

        .cor_icon {
            margin-right: 0.5em;
        }

        &:after {
            font-family: 'cor';
            content: $cor_icon_caret;
            font-size: 0.7em;
            margin-left: 0.2em;
            margin-top: 0.2em;

            transform: scale(1);
            transition: 0.2s;
       
            .disabled & {
                display: none;
            }
        }
    }

    &__drop {
        display: none;
        position: absolute;
        width: calc(100% + 2px);
        top: 100%;
        left: -1px;
        color: #7c8195;
        padding: 0.4375em 0.7em;
        background: #ffffff;
        border: 1px solid #bec9d3;
        box-shadow: 0 0.5625em 0.9375em rgba(52, 61, 70, 0.09);
        border-radius: 0 0 0.125em 0.125em;
        z-index: 10;
        max-height: 13.5em;
        overflow: hidden overlay;

        @media (min-width: 768px) {
            overflow: hidden overlay;
    
            &::-webkit-scrollbar {
                width: 1.25em;
                height: 1.25em;
    
                &-button {
                    display: none;
                }
    
                &-thumb {
                    background: #BEC9D3;
                    border-radius: 1.25em;
                    border: 0.5em solid transparent;
                    background-clip: content-box;
                    min-height: 3em;
    
                    .dark & {
                        border: 0.5em solid transparent;
                        background: #464F57;
                        background-clip: content-box;
                    }
                }
            }
        }

        .notscroll & {
            overflow: inherit;
        }

        .right & {
            right: -1px;
            left: auto;
        }

        .top & {
            top: auto;
            bottom: 100%;
        }

        .dark & {
            background: #363a45;
            border: 1px solid #4a4f5b;
            color: $color_withe;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                font-size: 0.75em;
                font-weight: 500;
                display: flex;
                align-items: center;
                white-space: nowrap;

                .cor_dropdown__check & {
                    &:before {
                        content: '';
                        line-height: 0;
                        padding: 0;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        flex: none;
                        height: 1.8em;
                        width: 1.8em;
                        color: #ffffff;
                        background: transparent;
                        border: 1px solid #6f92b9;
                        overflow: hidden;
                        vertical-align: middle;
                        outline: 0;
                        text-align: center;
                        position: relative;
                        border-radius: 0.125em;
                        transition: 0.3s;
                        transition-property: color, border, background;
                        margin-right: 0.7em;
                        font-size: 0.6em;

                        .dark & {
                            border-color: #4a4f5b;
                        }
                    }

                    &.selected {
                        &:before {
                            background: #00b5ff;
                            font-family: 'cor';
                            content: $cor_icon_user-check;
                            padding-bottom: 0.3em;

                            &:hover {
                                background: #67d3ff;
                            }

                            .dark & {
                                border-color: #00b5ff;

                                &:hover {
                                    border-color: #67d3ff;
                                }
                            }
                        }
                    }
                }

           
                &:hover {
                    color: #8f93a6;
                    
                    .dark & {
                        color: #cdcdd6;
                    }
                }

                &.selected {
                    color: #00b5ff;

                    .dark & {
                        color: #00b5ff;

                    }

                }

                &:nth-child(n + 2) {
                    border-top: 1px solid #bec9d3;
                    margin-top: 0.6em;
                    padding-top: 0.6em;

                    .dark & {
                        border-top-color: #4a4f5b;
                    }
                }

                .cor_icon {
                    margin-right: 0.5em;
                }
            }
        }
    }
}
