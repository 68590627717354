@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";
$input_color: #595C6C;
$input_bg_color: #F5F8FA;
$input_border_color: #BEC9D3;

.classChatList {
    display: flex;
    flex-direction: column;
    width: 14em;

    &__head {
        display: flex;
        box-shadow: 0 2em 3em rgba(0, 0, 0, 0.1);
        padding: 0 0.9375em 0.5em 0.9375em;
        margin: 0 -0.9375em;
        flex: none;
        font-size: 1.2em;

        .tabs,
        .tabs__item {
            flex-grow: 1;
            justify-content: center;
        }
    }

    &__scroll {
        flex-grow: 1;
        min-height: 0;
        margin-right: -1em;
        padding-right: 1.3em;
        margin-left: -0.6em;
        overflow: hidden;

        @include scrollbar;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 0.625em;
        padding-bottom: 0.625em;;
    }

    &__profile {
        width: 1.3em;
        height: 1.3em;
        border-radius: 1em;
        position: absolute;
        margin: -0.625em 0 0 -2.1em ;
        border: 1px solid #BEC9D3;
        z-index: 1;
        display: flex;
        justify-content: center;

        & img {
            border-radius: 1em;
        }

        & .cor_icon {
            margin-top: 0.08em;
            font-size: 0.94em;
        }

        .teacher & {
            color: #110F12;
        }

        .dark & {
            color: #fff;
        }
    }

    &__item {
        background: #F4FAFC;
        border-radius: 0.125em;
        padding: 0.625em;
        position: relative;
        z-index: 1;
        margin-left: 1.5em;

        .dark & {
            background: #363A45;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -0.4em;
            top: 0;
            z-index: -1;
            border: 0.5em solid transparent;
            border-top-color: #F4FAFC;
            
            .dark & {
                border-top-color: #363A45;
            }
        }

        &.author {
            text-align: right;
            background: #cef;
            border-radius: 0.125em;
            padding: 0.625em;

            &:before {
                left: auto;
                right: -0.4em;
                border-top-color: #cef;
                
                .dark & {
                    border-top-color: #1F2026;
                }
            }

            .dark & {
                background: #1F2026;
            }
        }

        &.teacher {
            color: $color_withe;
            background: #00B5FF;
            border-radius: 0.125em;
            padding: 0.625em;

            &:before {
                border-top-color: #00B5FF;
            }
        }
    }

    &__name {
        font-size: 0.75em;
        line-height: 1.2;
        margin-bottom: 0.6em;
        color: #666F7E;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .dark &, .teacher & {
            color: darken($color_withe, 10%);
        }
    }

    &__text {
        font-size: 0.9em;
        line-height: 1.25;
        margin-bottom: 0.65em;
        color: #383E47;
        overflow: hidden;
        word-wrap: anywhere;

        & a {
            color: $lw_blue_color;

            .teacher & {
                color: $color_withe;
            }
        }

        .dark &, .teacher & {
            color: $color_withe;
        }
    }

    &__time {
        font-size: 0.6em;
        text-align: right;
        color: #7C8195;

        .dark &, .teacher & {
            color: darken($color_withe, 15%);
        }
    }

    &__send {
        display: flex;
        align-items: center;
        gap: 1em;
        box-shadow: 0 -2em 3em -0.2em rgba(0, 0, 0, 0.1);
        margin: 0 -0.9375em -0.9375em -0.9375em;
        padding: 0.75em 1.1em;
        border-radius: 0 0 0.375em 0.375em;
        color: #666F7E;
        background: #FFFFFF;

        .dark & {
            background: #363A45;
        }

        & .cor_icon {
            cursor: pointer;
            color: #666F7E;
            font-size: 1.5em;

            .dark & {
                color: #C7C9D1;
            }
        }
    }
    
    &__input {
        flex-grow: 1;
        min-width: 0;
        font-size: 1.2em;

        & input:not([type]), .dark & input:not([type]) {
            width: 100%;
            border: 0;
            padding: 0.25em 0;
            background-color: transparent;
            text-overflow: ellipsis;
        }
    }

}
