.root {
    position: relative;
    border-radius: 0.375em;
    background: #fff;
    box-shadow: 0em 0.25em 1.875em 0em rgba(0, 0, 0, 0.1);
    padding: 0.9375em;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    :global(.dark) & {
        background: #282b33;
    }

    canvas,
    .wrap {
        width: 100%;
        border-radius: 50%;
        border: 1px solid #00b5ff;
        padding: 0.6em;
    }
}

.val {
    background: #f5f8fa;
    padding: 0.275em 0.875em;
    width: 5em;
    font-weight: 700;
    font-size: 0.75em;
    position: absolute;
    transform: translateY(3.5em);
    text-align: center;

    :global(.dark) & {
        background: #363a45;
        color: #fff;
    }
}

.center {
    position: absolute;
    color: #000;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #00b5ff;
    width: 2.1em;
    height: 2.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: 600;
    padding-bottom: 0.1em;
    transform: translateY(-0.1em);
    // display: none;
}
