@import "../../../scss/variables/variables";

.sun-editor {
    border-radius: 0.375em;
    overflow: hidden;

    .se-btn-tray {
        padding: 4px 16px 0;
    }

    .se-resizing-bar {
        .se-char-counter-wrapper {
            font-family: "inter";
            font-size: 0.75em;
            color: #7c8195;
            padding: 0.25em 0;

            .dark & {
                color: lighten(#7c8195, 10%);
            }
        }
    }

    .se-wrapper {
        .se-wrapper-code {
            background-color: #fff;
            color: #333;
            
            .dark & {
                background-color: #282B33;
                color: #fff;
            }

            &:focus {
              border: 0;
              box-shadow: none;
            }
        }

    }

}

.dark .sun-editor {
    .sun-editor-editable, .sun-editor-common:not(.se-dialog)
    , .se-btn, .se-btn-list, .se-form-group input, .se-list-layer, button,
    .se-dialog-tabs, .se-dialog-body, .se-dialog-form, .se-dialog-header, .se-dialog-footer,
    .se-dialog label,  .se-dialog input, .se-dialog-tabs button, .se-dialog-form .se-input-form.se-input-url:disabled,
    .se-controller-resizing
    {
        background: $footer_dark_button_bg;
        border-color: $footer_dark_dropdown_border_color;
        color: $footer_dark_color;
        &:after {
            border-color: $footer_dark_dropdown_border_color;
        }

    }

    .se-btn-module-border {
        border-color: $footer_dark_dropdown_border_color;
    }

    .se-arrow.se-arrow-up:after {
        border-bottom-color: rgba(255,255,255,.25);
    }

    .se-btn:not(.on):not(.active):enabled:hover, .se-btn:not(.on):not(.active):enabled:focus,
    .se-btn:not(.on):not(.active):enabled:active, .se-btn:not(.on):not(.active):enabled.__se__active,
    .se-btn:enabled.on,
    .se-btn:enabled.on:hover, .se-btn:enabled.on:focus,
    .se-btn:enabled.on:active, .se-btn:enabled.on.__se__active,
    .se-btn:enabled.active:hover, .se-btn:enabled.active:focus,
    .se-btn-group button:hover:enabled, .se-btn-group button:focus:enabled,
    .se-btn-group button:enabled.active:hover, .se-btn-group button:enabled.active:focus
    {
        background: $footer_dark_button_bg;
        color: #00B5FF;
    }

    .sun-editor-editable hr {
        border-color: $footer_dark_color;
    }
}

.cor_te {
    &__noDisplay {
        display: none;    
    }

    &__full {
        width: 100%;
        height: 100%;
    }

    &__preview {
        font-size:1.15em;
        font-family: 'Inter', serif; 
        line-height: 1.5;
        
        .se-component {
            display: flex;
            padding: 1px;
            margin: 0 0 10px;
        }

        figure {
            position: relative;
            padding: 0;
            margin: 0;
        }

        .__se__float-left {
            float: left;
            margin-right: 4px;
        }

        .__se__float-right {
            float: right;
            margin-left: 4px;
        }

        .__se__float-center {
            float: initial;
        }

        .__se__float-none {
            float: none;
        }

        .__se__float-left figure, .__se__float-right figure, .__se__float-center figure {
            margin: auto !important;
        }

        .se-video-container {
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                border: 0;
            }
        }

        // start table style
        table {
            display: table;
            table-layout: auto !important;
            border: 1px solid #ccc;
            width: 100%;
            max-width: 100%;
            margin: 0 0 0.625em;
            background-color: transparent;
            border-spacing: 0;
            border-collapse: collapse;

            tr {
                border: 1px solid #efefef;
            }

            td, th {
                border: 1px solid #e1e1e1;
                padding: .4em;
                background-clip: padding-box;
            }
        }
        // end table style
    }
}
