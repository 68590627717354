/* Global variables */
/* ======================================================================== */
$main_font_family: 'Inter';
// $second_font_family: "Arial";
$main_color: #fff;
$main_line_height: 1;
$main_font_size: 16px;

$dark_theme_background: #1e1f25;
$dark_theme_color: #ceced6;
$dark_footer_color: #d1d1d1;
$dark_border_color: $dark_theme_color;
$dark_header_background: #16171d;
$dark_footer_bg: #16171d;

$light_theme_background: #ffffff;
$light_theme_color: #1e1f25;
$light_drop_color: #666f7e;
$light_border_color: #808b91;
$light_header_background: #215794;
$light_footer_color: #7c8195;

$container_width: 80em;

$footer_height: auto;

// Color collection for elements
$color_primary: #742abe;
$color_success: #36a566;
$color_info: #03a9f4;
$color_warning: #fca33e;
$color_danger: #ff1a1a;
$color_withe: #ffffff;

$el-colors: (
    primary: $color_primary,
    // color for .element__primary
    success: $color_success,
    // color for .element__success
    info: $color_info,
    // color for .element__info
    warning: $color_warning,
    // color for .element__warning
    danger: $color_danger,
    // color for .element__danger
);

/* Grid */
/* ======================================================================== */
$grid_padding: 20px;

/* Form */
/* ======================================================================== */
// Input variables

// Input color
$input_color: #595c6c;
$input_bg_color: #f5f8fa;
$input_border_color: #bec9d3;
$input_placeholder_color: $input_color;

// Input hover color
$input_hover_color: $main_color;
$input_hover_bg_color: $input_bg_color;
$input_hover_border_color: $input_border_color;
$input_hover_placeholder_color: $input_placeholder_color;

// Input focus color
$input_focus_color: $main_color;
$input_focus_bg_color: $input_bg_color;
$input_focus_border_color: $input_border_color;
$input_focus_placeholder_color: $input_placeholder_color;

// Input disabled color
$input_disabled_color: #fff;
$input_disabled_bg_color: #ccc;
$input_disabled_border_color: #ccc;
$input_disabled_placeholder_color: #fff;

// Input error color
$input_error_color: red;
$input_error_bg_color: $input_bg_color;
$input_error_border_color: red;
$input_error_placeholder_color: red;

// Button variables
$btn_default_bg_color: #00b5ff;
$btn_default_text_color: $color_withe;
$btn_contrast_text_color: $color_withe;
$btn_default_bg_color_hover: #67d3ff;
$btn_default_bg_color_active: darken($btn_default_bg_color, 2%);
$btn_dark_bg_color: #3aaddd;
$btn_dark_text_color: $color_withe;
$btn_dark_bg_color_hover: darken($btn_dark_bg_color, 1%);
$btn_dark_bg_color_active: darken($btn_default_bg_color, 2%);
