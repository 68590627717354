.card {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  gap: 0.625em;
  // margin-bottom: 8px;
  margin-bottom: 0.5em;
  // min-height: 30px;
  min-height: 1.875em;
  background: #fff;
  cursor: default !important;
  border-radius: 0.375em;

  &__image {
    width: 13.375em;
    height: 6.6875em;
    border-radius: 0.375em;
    object-fit: cover;
    margin-bottom: 0.625em;
  }
  // &:first-child {
  //   margin-bottom: 0;
  // }
  // &:last-child {
  //   margin-top: 0;
  // }

  &__options {
    position: relative;
  }

  &__container {
    // position: relative;
    border: 1px solid #e8e8e8;
    // border-radius: 6px;
    border-radius: 0.3125em;
    background: #fff;

    .dark & {
      color: #ffffff;
      background: #363a45;
      border: 1px solid #4a4f5b;
    }
  }

  &__border_subtask {
    border: 0.0625em solid #00b5ff;
    margin-bottom: 0.625em;

    &:last-child {
      margin: 0;
    }
  }

  &__info {
    // padding: 10px;
    padding: 0.625em;
  }

  &__head {
    display: flex;
    // align-items: flex-start;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 5px;
    margin-bottom: 0.3125em;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 9px;
    // gap: 0.5625em;
    // cursor: pointer;
    width: 100%;

    
  }

  &__title__input {
    border: none !important;
    background: transparent !important;
    // color: #1e1f25 !important;
    // font-size: 1em !important;
    // font-weight: 400 !important;
    outline: none !important;
    padding: 0 !important;
    font-size: 0.875em !important;
    font-weight: 500 !important;
    margin: 0 !important;
    display: inline-block !important;
    max-width: 13em;
    height: 1em;
  }

  &__checkbox {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.2em;
    height: 1.2em;
    // border-radius: 0.15em;
    // margin-right: 0.5em;
    // border: 0.15em solid #007a7e;
    // outline: none;
    // cursor: pointer;
    border-radius: 100%;
    border: 0.0625em solid #bec9d3;
    cursor: pointer;

    &:checked {
      background: url('./check.svg') no-repeat center !important;
    }

    &:checked:before {
      content: '' !important;
    }
  }
  &__label {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;

    &.checked {
      background-color: #000;
    }
  }

  &__label__title {
    max-width: 11em;
    margin: 0;
    font-size: 0.875em;
    font-weight: 500;
    display: block;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__label__container {
    display: flex;
    align-items: flex-start;
    gap: 0.4375em;
    width: 100%;
    cursor: pointer;
  }

  &__label__rename_icon {
    cursor: pointer;
    margin-right: 0.5em;
  }

  &__badges {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__stickers {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // gap: 5px;
    gap: 0.3125em;
    // width: 168px;
    // width: 10.5em;
  }

  &__badge_button {
    display: flex;
    align-items: center;
    // gap: 4px;
    gap: 0.25em;
    // padding: 2px 6px;
    padding: 0.125em 0.375em;
    background: none;
    font-size: 0.75em;
    font-weight: 400;
    border-radius: 18px;
    border: 0.0625em dashed rgba(0, 181, 255, 0.3);
    cursor: pointer;

    &.active {
      background: #ebf9ff;
      border: 0.0625em solid #ebf9ff;
    }
  }

  &__badge__title {
    color: #00b5ff;
  }

  &__input {
    // display: block;
    // width: 100%;
    // margin: 0 0 8px 0;
    margin: 0 0 0.5em 0;
  }

  &__input:focus {
    outline: none;
    border: 0.0625em solid #e8e8e8;
  }

  &__menu {
    position: absolute;
    top: 2.0625em;
    right: -10.5625em;
    z-index: 30;
  }

  &_users__wrapper {
    // position: relative;
    height: 1.125em;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: row-reverse;
    list-style: none;
    margin-left: 0.7em;
    padding: 0;

  }

  &_users__item {
    // position: absolute;
    margin-left: -0.7em;
    top: 0;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 0.75em;
    color: #fff;
    cursor: pointer;
    
    &:last-child {
     width: 2em;
    }
  }
}

.add__button {
  cursor: pointer;
}

.subtasks {
  background: #f5f8fa;
  padding: 0.625em;
}

.subtasks__button {
  background: #f5f8fa;
  font-size: 0.875em;
  padding: 0.625em 0.625em 0.625em 1.25em;
  display: flex;
  align-items: center;
  transition: padding 0.2s ease;
  cursor: pointer;
}
.subtasks__button__close {
  background: #eef8fc;
  // border-radius: 0 0 6px 6px;
  border-radius: 0 0 0.375em 0.375em;
  // padding: 10px;
  padding: 0.625em;
  transition: padding 0.2s ease;
}

.progress {
  width: 100%;
  height: 0.375em;
  border-radius: 0.375em;
  background: #dee6ee;
  // margin-right: 5px;
  margin-right: 0.3125em;
}

.progress__title {
  color: #666f7e;
  // font-size: 12px;
  font-weight: 500;
  // margin-right: 10px;
  margin-right: 0.625em;
}

.diagram {
  display: flex;
  align-items: flex-start;
}

.diagram__line {
  // width: 2px;
  width: 0.125em;
  background-color: #00b5ff;
}

.diagram__subtasks {
  display: flex;
  flex-direction: column;
}

.st__container {
  display: flex;
  flex-direction: column;
  // padding: 10px;
  // gap: 8px;
  gap: 0.5em;
  // background-color: #F5F8FA;
}

.subtasks__content_open {
  background-color: #f5f8fa;
  // padding: 0 10px 10px;
  padding: 0 0.625em 0.625em;
  display: flex;
  flex-direction: column;
  // gap: 10px;
  // border-radius: 0 0 6px 6px;
  border-radius: 0 0 0.375em 0.375em;

  opacity: 1;
  visibility: visible;
  max-height: 1000px;
  // padding: 10px;

  transition: max-height 0.3s ease, visibility 0.3s ease, opacity 0.2s ease,
    padding 0s ease-in;
  // transition: all 0.3s ease;
}

.subtasks__content_closed {
  opacity: 0;

  transition: max-height 0.3s ease, visibility 0.3s ease, opacity 0.2s ease,
    padding 0.3s ease-in;
  visibility: hidden;
  max-height: 0;
  padding: 0;
  // transform: translateX(-100px);
}

.subtasks_branch__button {
  position: relative;
  overflow: hidden;
  transition: all 0s ease;

  &::before {
    position: absolute;
    content: url('../../../assets/icons/Subtasks/first-item-branch.svg');
    top: 0;
    // left: 10px;
    left: 0.71em;
    height: 100%;
  }
}

.add_input__subtask {
  margin-bottom: 0;
  border: 0.0625em solid #00b5ff;
}

.card__add_button {
  flex-direction: row-reverse;
  padding-top: 0;
  // padding-left: 20px;
  padding-left: 1.25em;
}

.subtasks_branch__add_visible {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    content: url('../../../assets/icons/Subtasks/item-branch.svg');
    top: -11px;
    left: 0;
    height: 100%;
  }
}

.subtasks_branch__add_hideable {
  position: relative;
  transition: all 0s ease;
  overflow: hidden;

  &::before {
    position: absolute;
    content: url('../../../assets/icons/Subtasks/last-item-branch.svg');
    top: -16px;
    left: 0;
    height: 100%;
  }
}

.subtasks_branch__card {
  position: relative;
  // padding-bottom: 10px;
  padding-bottom: 0.625em;
  transition: all 0s ease;

  &::before {
    position: absolute;
    content: url('../../../assets/icons/Subtasks/item-branch.svg');
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
  }
}
.subtasks_branch__card:last-child {
  transition: all 0s ease;

  position: relative;
  padding-bottom: 0;
  &::before {
    overflow: hidden;
    position: absolute;
    content: url('../../../assets/icons/Subtasks/last-item-branch.svg');
    top: -6px;
    left: 0;
    height: 100%;
  }
}

.add__form_visible {
  display: flex;
  flex-direction: column;
  gap: 8px;
  // margin-left: 10px;
  margin-left: 10px;
  // margin-bottom: 10px;
  margin-bottom: 10px;
}
