@import '../../../scss/variables/variables';

.root {
    display: none;

    @media (max-width: 767px) {
        display: flex;
        border-bottom: 1px solid #d7e1e8;

        .item {
            width: 50%;
            color: #7c8195;
            font-size: 18px;
            font-weight: 500;
            padding: 22px 0;
            display: flex;
            align-items: center;
            user-select: none;

            &:first-child {
                justify-content: flex-end;
            }

            &.active {
                color: #00b5ff;
            }

            i {
                margin-right: 8px;
            }
        }

        .sep {
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            color: #8c8f9b;

            i {
                font-size: 24px;
            }
        }
    }

    @media (max-width: 767px) and (orientation: 'landscape') {
        display: none;
    }
}
