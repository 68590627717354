.dnd {
  &__container {
    display: flex;
    align-items: flex-start;
    padding: 0.25em;
    height: 100%;
  }

  &__card_container_hide {
    position: absolute;
    top: 2.625em;
    left: 0;
    z-index: -1;
    width: 100%;
    padding: 0.75em 0.9375em 2.75em;
    box-sizing: border-box;
  }

  &__card_container_hover {
    padding-bottom: 1em;
  }

  &__add_column {
    display: flex;
    flex-direction: column;
    gap: 0.625em;
    min-width: 17.5em;
    height: fit-content;
    padding: 0.625em 0.9375em;
    border-radius: 0.375em;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0.125em 0.6875em 0px rgba(52, 61, 70, 0.1);

    .dark & {
      background: #363A45;
      border-color: #484B5A;
  }
  }

  &__add_column__button {
    flex-direction: row-reverse;
    justify-content: center;
    width: 17.5em;
    height: 3.125em;
    border-radius: 0.3125em;
    border: 0.0625em solid #00b5ff;
    background: #00b5ff !important;
    color: #fff !important;
    font-size: 0.75em;
    font-weight: 500;
    cursor: pointer;
  }

  &__add_column__svg {
    fill: #fff;
  }
}