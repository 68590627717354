/*
<div className='cor-warning'>
    <Icon name="warning" />
    <p>content</p>
</div>
*/

.cor-warning {
    display: flex;
    align-items: center;
    gap: 0.75em;

    padding: 1em 1.4286em;
    border-radius: 0.375em;

    font-size: 0.875em;

    color: #383E47;
    background-color: #fff9ea;

    
    .cor_icon {
        font-size: 1.75em;
        color: #F75656;
    }
    
    p {
        line-height: 1.25;
        margin: 0;
    }

    .dark & {
        background-color: rgba(#363A45, 1);
        color: #d1d1d1;
    }

}