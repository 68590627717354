.root {
    gap: 1.25em;
    padding-top: 1em;

}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    width: calc(50% - 0.675em);

    padding: 0.75em 1.25em 0.75em 0.75em;
    border-radius: 0.5em;

    background: #F4FAFC;

    :global(.dark) & {
       background: #363A45;
    }

    > * {
        width: 33.3%;
    }
}

.caption {
    display: flex;
    align-items: center;
    gap: 0.625em;
    cursor: pointer;
}

.img {
    width: 1.875em;
    height: 1.875em;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    i {
        font-size: 1.875em;
    }
}

.title {
    font-size: 1em;
    font-weight: 500;
}

.info {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        display: flex;
        gap: 0.5em;
        margin-top: 0.125em;

        &:first-child {
            margin-top: 0;
        }

        & span {
            font-size: 0.75em;
            &:first-child {
                min-width: 5em;
            }
            &:last-child {
                color: #858C98;

                :global(.dark) & {
                   color: #fff;
                }
            }
        }
    }
}

.action {
    display: flex;
    align-items:center;
    justify-content: flex-end;
    gap: 0.9375em;

    &__item {
        font-size: 1.5em;
        cursor: pointer;
        color: #858C98;
        transition: color 0.3s;

        :global(.dark) & {
            color: #d1d1d1;
        }

        &_active {
            composes : action__item;
            color: #00B5FF;

            :global(.dark) & {
                color: #00B5FF;
            }
        }


        &:hover {
          color: #00B5FF;
        }
    }
}

.status {
    composes : action;
    font-size: 1.5em;

    &_active {
        composes : status;
        color: #36a566;
    }
    &_inactive {
        composes : status;
        color: #858C98;
    }
}
