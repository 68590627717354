@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.labWorkShow {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	$LegendSize: 0.7em;
	$HeaderSize: 1em;
	$LeaveBtnSize: 1em; //0.9em;
	$ActionBtnSize: 1em;
	$CommentSize: 1.2em; //1.0em;
	$RCGrayColor: #666F7E; 
	//// see problems with dark bknd in 1/5/8 - drawing is not visible!!
	
	.dark & {
		background: $lw_dark_theme_bknd_color;
		color: darken($color_withe, 10%);
	}

	&__first {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;

		&Legend {
			background: #DDF0F9;
			border-radius: 0.3125em;
			font-size: $LegendSize;
			font-weight: 500;
			padding: 0.7em;
			max-width: 20em;
			color: $lw_blue_color;
			text-align: center;

			.dark & {
				background: transparent;
				border: solid 2px $lw_blue_color;
			}
		}

		&Legend2 {
			background-color: #ddeff9;
			color: #26b6f0; //$lw_blue_color;
			//border: solid 2px $lw_blue_color;
			border-radius: 0.25em;
			font-size: $LegendSize;
			font-weight: 500;
			padding: 0.2em;
			max-width: 18em;
			text-align: center;
			padding: 0.5em 2em;

			.dark & {
				background-color: #1c546f;
				color: #229fd4; //$lw_blue_color;
			}
		}

		&Legend3 {
			background: transparent;
			padding-left: 0.4em;
			margin-bottom: 0.4em;
			font-size: 1.6em; //$LegendSize;
			font-weight: 800;
			border-left: solid 4px $lw_blue_color;
			text-transform: lowercase;

			&::first-letter {
				text-transform: uppercase;
			}
		}

		&Name {
			flex-grow: 1;
			min-height: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				font-weight: 600;
				font-size: 1.6em;
				max-width: 22em;
				text-align: center;
				line-height: 1.4;
				color: #212227;
				font-family: 'Inter';

				.dark & {
					color: $lw_dark_theme_color;
				}
			}

			&Goal {
				span {
					font-size: 1.3em;
					font-weight: 600;
				}
			}
		}
	}

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 3.6875em;
		padding: 0.625em 1.8125em;
		font-size: $HeaderSize;
		border-bottom: 2px solid #CECECE;

		.dark & {
			background-color: $lw_dark_theme_header_color;
		}

		&Time {
			font-size: 1em;
		}

		&Leave {
			font-size: $LeaveBtnSize;
			color: #606163;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: color 0.3s;
			margin-right: 1em;

			i {
				font-size: 0.7em;
				line-height: 0;
				margin-right: 0.5em;
			}

			&:hover {
				color: lighten($RCGrayColor, 15%); //#666F7E;
			}

			.dark & {
				color: $lw_dark_theme_color;
			
				&:hover {
					color: $lw_dark_theme_selected_color;
				}
			}
		}

		&Title {
			font-weight: 600;
			color: #272729;
			.dark & {
				color: $lw_dark_theme_color;
			}
		}

		&Count {
			font-size: $LeaveBtnSize;
			//color: #383E47;
			color: #606163;
			.dark & {
				color: $lw_dark_theme_color;
			}
		}
	}

	&__iconExit {
		position: relative;
		top: -0.5em;
	}

	&__descr {
		padding: 1.0em 1.875em 0;
	}

	&__body {
		$quarreLineColor: rgba(193, 224, 246, 0.25);
		$quarreLineSize: 1em; //0.75em;

		flex-grow: 1;
		min-height: 0;
		position: relative;
		background: #fbfcff; 
		background-image: linear-gradient($quarreLineColor, transparent 1px), linear-gradient(90deg, $quarreLineColor, transparent 1px);
		background-size: $quarreLineSize $quarreLineSize;
		background-position: center center;
		z-index: 1;
		padding: 1.0em 1.875em 0;
		overflow-y: overlay;
		display: flex;
		flex-direction: column;

		.dark & {
			background: $lw_dark_theme_bknd_color;
		}

		&Lw {
			.dark & {
				background: $lw_dark_theme_bknd_color;
				background-image: linear-gradient($lw_dark_theme_grid_color, transparent 1px), linear-gradient(90deg, $lw_dark_theme_grid_color, transparent 1px);
				background-size: $quarreLineSize $quarreLineSize;
			}
		}

		@include scrollbar;

		&TestExecute {
			background-image:none;	
		}

		> div {
			// min-height: 0;
		}

		&:before {
			// content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient($quarreLineColor, transparent 1px), linear-gradient(90deg, $quarreLineColor, transparent 1px);
			background-size: $quarreLineSize $quarreLineSize;
			// background-position: center center;
			// border: 2px solid $quarreLineColor;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.8125em;
		gap: 1.875em;
		position: relative;
		border-top: 2px solid #CECECE;

		.dark & {
			background-color: $lw_dark_theme_header_color;
		}

		// &:before {
		// 	content: '';
		// 	width: 7em;
		// }

		&Btns {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1.875em;
		}

		&Materials {
			width: 15em;
			text-align: left;
			padding-left: 10px;
		}

		&Complete {
			//min-width: 7em;
			width: 15em;
			text-align: right;			
			padding-right: 10px;
		}

		&ProgressBar {
			position: absolute;
			top: -3px;
			left: 0;
			width: 100%;

			progress {
				display: block;
				width: 100%;
				height: 3px;
				background-color: #CECECE;

				&::-webkit-progress {
					&-bar {
						background-color: transparent;
					}

					&-value {
						background: $lw_blue_color;
					}
				}
			}
		}

		button {
			cursor: pointer;
			border: 0;
			outline: 0;
			background-color: transparent;
			color: $RCGrayColor; //#666F7E;
			//display: flex;
			align-items: center;
			font-size: $ActionBtnSize; //1em;
			gap: 0.625em;
			padding: 0;
			margin: 0;
			transition: color 0.3s;
			user-select: none;

			&:disabled {
				// opacity: 0;
				display: none;
				cursor: default;
			}

			&:hover {
				color: lighten($RCGrayColor, 14%); //#666F7E
			}

			.dark & {
				color: $lw_dark_theme_color;
			
				&:hover {
					color: $lw_dark_theme_selected_color;
				}
			}

			.cor_icon {
				display: inline-block;
				transform: translateY(-0.1em);
			}
		}
	}

	&__comments {
		p {
			font-size: $CommentSize;
			color: #383E47;
			line-height: 1.2;

			.dark & {
				color: $lw_dark_theme_color;
			}
		}

		&__center {
			text-align: center;
		}

		&Up {
			font-size: 0.7em; //$CommentSize;
			position: relative;
			bottom: 7px;
		}

		&Down {
			font-size: 0.7em; //$CommentSize;
			position: relative;
			top: 4px;
		}

		&_sel {
			background-color: white;
			padding: 0.4em;
			margin: 0.4em;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;

			&Red {
				border-left: solid 6px red;
			}

			&Blue {
				border-left: solid 6px blue;
			}
		}

		&__tick {
			height: 1em;
		}

		&__equipment {
			margin: 10px;
		}

		+ div {
			margin-top: 1.75em;
		}
	}

	// &__title {
	// 	font-size: 1.625em;
	// 	font-weight: 600;
	// 	line-height: 1.3;
	// 	margin-bottom: 0.7em;
	// }

	&__cols {
		display: flex;
		flex-grow: 1;
	}

	&__col {
		width: 50%;
		flex-grow: 1;

		&:nth-child(odd) {
			padding-right: 1.875em;
		}

		&:nth-child(even) {
			padding-left: 1.875em;
		}

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}

		&:nth-child(n+2) {
			border-left: 1px solid $lw_blue_color;
			margin-top: 0.4em;//-0.4em;
			margin-bottom: 1.4em;//0.4em;
		}

		&_lottie {
			position: relative;
			top: 0;
		}
	}

	&__table {
		border-collapse: collapse;
		width: 100%;
		border: 1px solid $lw_blue_color;
		border-radius: 0.125em;
		color: #444754;
		background: #F6F8F8;

		.dark & {
			background: $lw_dark_theme_bknd_color;
			color: $lw_dark_theme_color;
		}

		&Row0 {
			border-bottom: 1px solid $lw_blue_color;

			&:last-child {
				border-bottom: 0;
			}
		}

		&Row {
			//border-bottom: 1px solid red; // #00B5FF;

			&:last-child {
				border-bottom: 0;
			}
		}

		&Col {
			text-align: left;
			font-size: $lw_table_col_size;
			padding: 0.5625em 1.25em;

			&th {
				font-weight: 600;
			}

			&:nth-child(n+2) {
				border-left: 1px solid $lw_blue_color;
			}
		}
	}

	&__alarm {
		color: $lw_blue_color;
	}

	&__timeCompleted {
		color: #98999e;
	}
}
//Great Tool: https://imagecolorpicker.com/
