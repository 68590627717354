.cor_fontResize {
    display: flex;
    color: #fff;

    .dark & {
    }
    
    &__btn {
        width: 1.75em;
        height: 1.75em;
        border: 0.0625em solid #6F92B9;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        outline: 1px solid transparent;
        border-radius: 0.125em;
        transition: outline 0.2s;
        cursor: pointer;
        user-select: none;
        
        .dark & {
            color: #A2A2A5;
            border: 1px solid #343640;
        }

        &:hover {
            outline: 1px solid #ffffff;
            outline-offset: -1px;
        }

        &:first-child {
            border-right: 0;
            border-radius: 0.125em 0 0 0.125em;
        }

        &:nth-child(2) {
            background: #00B5FF;
            border-radius: 0;

            .dark & {
                color: #ffffff;
            }
        }

        &:last-child {
            border-left: 0;
            border-radius: 0 2px 2px 0;
        }

        span {
            font-size: 0.875em;
        }
    }
}
