.login-wrapper {
    display: flex;
	flex-direction: column;
	height: auto;
	min-height: 100%;
}

.login-main {
    flex-grow: 1;
}

.container_center {
    max-width: $login_container_width;
    width: 100%;
    padding: 0 1em;
    margin-left: auto;
    margin-right: auto;
}

.section {
    padding: 6.25em 0;

    &__title {
        width: 18.5em;
        margin: 0;
        font-size: 2.25em;
        font-weight: 400;
        line-height: 1.2;
    }
}