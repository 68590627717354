.inputType {
    margin: 0;
    background: transparent;
    border: 1px solid #BEC9D3;
    border-radius: 3px;
    padding: 0.43em 0.9em;
    font: 1em/1 arial, sans-serif;

    &::-ms-tooltip {
        display: none;
    }
}
