.root {
    position: relative;
    width: auto;
    border-radius: 0.3125em;
    border: 1px solid #BEC9D3;
    background: #fff;
    box-shadow: 0px 9px 15px 0px rgba(52, 61, 70, 0.09);
   
    :global(.dark) & {
        background: #282B33;
        border: 1px solid #343640;
    }

    &.active {
        // 282B33
    }

    
}

.open {
    composes: root;
    width: 26.875em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.375em;

    cursor: pointer;

    padding: 0.5em 0.75em;

    b {
        font-size: 1em;
        font-weight: 500;

        color: #1E1F25;

        :global(.dark) & {
            color: #fff;
        }


    }

    &__preview {
        display: flex;
        align-items: center;
        gap: 0.375em;

        .open & {
            display: none;
        }

        color: #A2A6B6;
    }

    &__content {
        display: none;

        .open & {
            display: block;
        }
    }

    &__caret {
        i {
            display: inline-block;
            font-size: 0.675em;
            color: #A2A6B6;
            transform: scale(1);
            transition: 0.3s;
    
            .open & { 
                transform: scale(-1);
    
            }
        }
    }



}

.body {
    position: absolute;
    top: 100%;
    left: -1px;
    z-index: 10;
    width: calc(100% + 2px);
    padding: 0 0.75em 0.75em 0.75em;

    border: 1px solid #BEC9D3;
    border-bottom-left-radius: 0.3125em;
    border-bottom-right-radius: 0.3125em;
    
    border-top: 0;
    background: #fff;

    :global(.dark) & {
        border: 1px solid #343640;
        border-top: 0;
        background: #282B33;
    }
}



.content { 
    height: 22em;
   
    &__scrolled {
        max-height: 100%;
        padding-bottom: 0em;
        padding-right: 0.5em;
        height: 100%;
        word-wrap: break-word;

        overflow-y: auto;

        > * {
            margin: 0.75em 0;

            // &:first-child {
            //     margin-top: 0;
            // }

            &:last-child {
                margin-bottom: 0;
            }
            
        }

        @media (min-width: 768px) {
            scrollbar-gutter: stable;

            &::-webkit-scrollbar {
                width: 0.3em;
                height: 0.3em;

                &-thumb {
                    background: #bec9d3;
                    border-radius: 1em;
                    border: 0 solid transparent;
                    background-clip: border-box;
                    min-height: 5em;

                    .dark & {
                        background: #464f57;
                    }
                }
            }
        }
    }


}


.title {
    font-size: 0.875em;
    font-weight: 600; 
}

.description {
    font-size: 0.875em;
    color: #383E47;
    line-height: 1.2;

    :global(.dark) & {
        color: #fff;
    }
}

.img {
    margin: 0.75em 0;
    width: 100%;

    img {
        display: block;
        width: 100%;
        object-fit: cover;
    }
}

.sensor {
    font-size: 0.32em;
}

.doc {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.375em;
    padding-right: 2em;

    i { 
        font-size: 1.125em;
        color: #666F7E;
    }

    &__title {
        font-size: 0.75em;
    }

    &__trash {
        position: absolute;
        top: 0;
        right: 0em;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 1em;
        height: 1em;

        cursor: pointer;

        &:after,
        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 75%;
            border-top: 1px solid #858C98;

            transition: 0.3s;

        }
        &:hover {
            &:after,
            &:before {
                border-top: 1px solid #00B5FF;

            }
        }
    
        &:before {
            transform: rotate(45deg);
        }
    
        &:after {
            transform: rotate(-45deg);
    
        }
    }
}

.btn {
    display: flex;
    flex-direction: column;
    gap: 0.625em;

    button {
        width: 100%;
    }
}

.btns {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    gap: 0.625em;
   

    // padding-top: 0.75em;
    // border-top: 1px solid #BEC9D3;

    button {
        width: 100%;
    }
}


.questions {
    &__item {
        margin-top: 0.75em;
        &:first-child {
            margin-top: 0;
        }
    }

    &__title {
        position: relative;
        font-size: 0.9375em;
        font-weight: 500;
        line-height: 1.2;
        margin: 0.25em 0;
        color: #1E1F25;

        :global(.dark) & {
            color: #BEC9D3;
        }
    }

    &__input {
        margin-top: 0.75em;

        textarea {
			min-height: 8.5em;
            resize: none;
		}
    }

    &__label {
        display: block;
        font-size: 0.75em;
        font-weight: 500;
        color: #7C8195;
        margin: 0.5em 0;
    }


}

.bottom {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5em;

    margin-top: 0.75em;
    margin-right: 0.75em;
}

.nav {
    display: flex;
    align-items: center;
    

    button {
        display: inline-flex;
        align-items: center;

        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
     
        // border: 1px solid #000;
        line-height: 1;

        &:disabled {
            opacity: 0.4;
            cursor: default;
        }
    }
}

.next {
    font-size: 0.875em;
    color: #00B5FF;
    cursor: pointer;

    i {
        display: inline-block;
        transform: rotate(-180deg);
        font-size: 0.675em;
        font-weight: bold;
        margin-left: 0.5em;
    }

  

}

.pagination {
    display: flex;
    align-items: center;
    gap: 0.625em;


    margin-left: auto;

    button {
        font-size: 1.125em;
        color: #08203A;
        background: transparent;
        border: 0;
        padding: 0;

        :global(.dark) & {
            color: #666F7E;
        }

        &:hover:not(:disabled) {
          background: transparent;
          border: 0;
          color:#00B5FF;
        }
    }

    &__numbers {
        display: flex;
        align-items: center;
        gap: 0.375em;

        font-size: 0.75em;
        color: #666F7E;

        :global(.dark) & {
            color: #fff;
        }
    }

    &__current {
        color: #08203A;

        :global(.dark) & {
            color: #fff;
        }
    }
}

.current {}