@import "../../scss/variables/variables";

.cor_settings {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    column-gap: 0.7em;
    align-items: center;
    padding:0.7em;

    label {
        text-align: right;
    }

    & input.form_error, .dark & input.form_error, & textarea.form_error, .dark & textarea.form_error {
        border-color: $color_danger;
    }

    div.form_error {
        font-size: 0.7em;
        color: $color_danger;
    }

    &_video {
        height: 24em;
        & video {
            display: block;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
}

.cor_settings_vert {
    display: grid;
    grid-template:
            "a b"
            "c c";
    align-items: center;
    padding-bottom: 0.7em;

    & label, & .label {
        color: #7C8195;
        padding: 0.7em 0  0.35em 0;
    }

    & input.form_error, .dark & input.form_error, & textarea.form_error, .dark & textarea.form_error {
        border-color: $color_danger;
    }

    & textarea {
        resize: none;
    }

    div.form_error {
        padding: 0.7em 0  0 0;
        text-align: right;
        font-size: 0.7em;
        color: $color_danger;
    }

    div.link {
        text-align: right;
        font-size: 1em;
        text-decoration: underline;
        color: $color_info;
        cursor: pointer;
        user-select: none;
    }
}

.cor_settings_vert2 {
    display: grid;
    grid-template:
            "a c"
            "b d";
    align-items: center;
    column-gap: 1em;
    padding-bottom: 0.7em;

    label {
        color: #7C8195;
        padding: 0.7em 0  0.35em 0;
        white-space: nowrap;
    }

    input {
        margin: 0 0.4em 0.2em 0;
    }

    ul {
        padding: 0 1em;
        margin: 0;
        list-style: none;

        li span {
            margin: 0 1.4em 0 0.9em;
            color: #7C8195;
        }
    }
}

.cor_settings_hor1 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0.7em;
}

.cor_settings_hor2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.7em;
}

.cor_settings_hor3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.7em;

    & input {
        width: 17em;
    }
}

.cor_settings_hor4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.7em;

    & input {
        width: 17em;
    }
}

.cor_settings_hor1_2 {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.3em;
    row-gap: 0.2em;

    &_title {
        grid-column: span 2;
        color: #7C8195;
        padding: 0.7em 0 0 0;
    }

    & label {
        color: #7C8195;
        font-size: 0.8em;
        cursor: pointer;
        .dark & {
            color: $color_withe;
        }

        & input {
            margin-right: 0.3em;
        }

        & input[type=date] {
            width: 10em;
            height: 2em;
            padding: 0.5em;
        }
    }

    & span {
        color: #7C8195;
        font-size: 0.8em;
        cursor: pointer;

        .dark & {
            color: $color_withe;
        }
    }
}


.cor_settings_vert, .cor_settings_vert2{
    :first-child {
        grid-area: a
    }

    :nth-child(2) {
        grid-area: b
    }

    :nth-child(3) {
        grid-area: c
    }

    :nth-child(4) {
        grid-area: d
    }
}

.cor_settings_title {
    .warning {
        font-size: 1.2em;
        color: $color_danger;
    }
    a {
        color: $color_info;
    }
}