.password {
    position: relative;

    input {
        width: 100%;
        padding-right: 4.5em;
    }
}

.eye {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 1em;
    cursor: pointer;
    color: #7c8195;

    :global(.dark) & {
        color: lighten(#7c8195, 10%);
    }
}
