.header_menu {
  padding: 0.5625em 1.25em;
  background: #fff;
  box-shadow: 0 0.25em 1.875em 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 5.5em;
  color: #7c8195;

  .dark & {
    background: #16171d;
    color: #fff;
  }
  &__view {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  &__title {
    font-size: 0.75em;
  }

  &__view_button {
    color: #00b5ff;
    font-size: 0.75em;
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 0.75em;
  }

  &__filters_wrapper {
    display: flex;
    align-items: center;
    gap: 0.625em;
  }

  &__filters_item {
    position: relative;
    padding: 0.5em 0.625em;
    border-radius: 0.125em;
    border: 0.0625em solid #bec9d3;
    background: #fff;
    width: 9.0625em;
    cursor: pointer;

    .dark & {
      color: #a2a2a5;
      background: #1f2026;
      border: 1px solid #4a4f5b;
    }
  }

  &__filters_item_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__filters_item_button_more {
    border: 0.0625em solid #00b5ff;
    width: fit-content;
  }
  &__filters_item_button_title {
    // color: #595c6c;
    font-size: 0.75em;
  }
  &__filters_item_button_title_more {
    color: #00b5ff;
  }

  &__filters_item_list {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0.625em;
    border-radius: 0.375em;
    border: 0.0625em solid #bec9d3;
    z-index: 1000;
    min-width: 100%;
    max-width: 16.375em;
    background: #fff;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.625em;
    margin: 0;
    color: #595c6c;

    .dark & {
      background: #16171d;
      color: #fff;
      border: 1px solid #4a4f5b;
    }
  }

  &__filters_item_list_label {
    display: flex;
    align-items: center;
    gap: 0.375em;
    cursor: pointer;
  }

  &__filters_item_list_checkbox {
    cursor: pointer;
  }
  &__filters_item_list_title {
    // color: #595C6C;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__filters_button_arrow {
    transform: rotate(180deg);
  }
}
