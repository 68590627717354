.root {

    .action {
        display: flex;
        gap: 1.25em;

        button {
            flex-grow: 1;
        }
    }

    .main {
        position: relative;
        border: 1px solid #bec9d3;
        border-radius: 0.125em;
        padding: 0.8125em;
        color: #595c6c;
    
        height: 40vh;
        overflow-y: auto;
    
        :global(.dark) & {
            border: 1px solid #4a4f5b;
            color: #ceced6;
        }
    
        @media (min-width: 768px) {
            // overflow-y: visible;
            scrollbar-gutter: stable;
    
            &::-webkit-scrollbar {
                width: 0.3em;
                height: 0.3em;
    
                &-track {}
    
                &-button {
                    opacity: 0;
    
                    &:single-button {
                        width: 0.1em;
                        height: 0.1em;
                    }
                }
    
                &-thumb {
                    background: #bec9d3;
                    border-radius: 1em;
                    border: 0 solid transparent;
                    background-clip: border-box;
                    min-height: 5em;
    
                    :global(.dark) & {
                        background: #464f57;
                    }
    
                }
            }
        }
    
    }

    .list {}

    .item {
        margin-top: 0.125em;

        &:first-child {
            margin-top: 0;
        }

        &__checkbox {
            flex-direction: row-reverse;
            justify-content: space-between;
            cursor: pointer;
            padding: 0.25em 0;
        }

        &__content {
            display: inline-flex;
            gap: 0.5em;
            align-items: center;
        }

        &__icon {
            flex: none;
            font-size: 1.5em;
        }
    
        &__picture {
            flex: none;
            width: 1.5em;
            height: 1.5em;
            border-radius: 100%;
            overflow: hidden;
        }

        &__name {
            font-weight: 500;
        }
    }

    .checkbox {
    }
}