.firstScreen {
    position: relative;
    // background-color: #ddd;
    height: 100vh;

    display: flex;
    // flex-direction: column;
    // justify-content: center;
    align-items: center;


    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__wrap {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__content {
        width: 44.125em;
    }
    
    &__title {
        font-size: 2.75em;
        margin: 0;
        line-height: 1.2;
        font-weight: 600;
        color: $color_withe;
        
    }
    
    &__descriptions {
        font-size: 1.125em;
        line-height: 1.56;
        margin-top: 1.445em;
        color: $color_withe;
    }

    &__form {
        width: 27.25em;
        padding: 1.875em 1.875em 2.5em 1.875em;
        border-radius: 0.5em;

        background-color: $color_withe;
        box-shadow: 0 7px 17px -12px rgba(28, 57, 69, 0.17);
        transition: 0.3s;

        .dark & {
            background-color: #1e1f25;
        }
    }   

    &__buttons {
        margin-top: 2.5em;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.25em;

        .cor_btn {
            font-size: 1em;
        }
    }

    &__nav {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1.25em;
        margin-top: 2.5em;

        &_item {
            padding: 1.5625em 20px;
            border-radius: 0.5em;
            background-color: #ffffff;
            cursor: pointer;
            transition: 0.3s;

            .dark & {
                background-color: #363a45;
            }

            &:hover {
              .firstScreen__nav_bottom {
                p {
                    border: 0.0625em solid #00B5FF;
                    .cor_icon {
                        color: #00B5FF;

                    }
                }
                
              }
            }

        }

        &_top {

            font-size: 2.5em;
            color: #00B5FF;

        }

        &_bottom {

            display: flex;
            gap: 0.625em;
            margin-top: 0.625em;

            span {
                font-size: 1.125em;
                line-height: 1.15;
                color: #22262c;
                transition: 0.3s;
                .dark & {
                    color: #d1d1d1;
                 }
            }

            p {
                flex: none;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                transform: rotate(-90deg);


                width: 2em;
                height: 2em;
                border: 0.0625em solid #BEC9D3;
                border-radius: 100%;
                transition: 0.3s;
                
                .cor_icon {
                    color: #BEC9D3;
                    font-size: 0.75em;
                    transition: 0.3s;
                }
            }
        }

    }
}


