@import '../../scss/variables/variables';

.root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 1em;
    // padding-right: 1.8em;

    @media (min-width: 768px) {
        gap: 0.625em;
        // padding-right: 0;
        flex-grow: 0;
    }

    @media (max-width: 767px) and (orientation: 'portrait') {
        padding-left: 1em;
    }

    button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1em;

        &:not(:disabled) {
            cursor: pointer;
        }

        &[class*='cl_icon_stop'],
        &[class*='icon_stop'] {
            background-color: transparent;
            border: 0;

            :global(.dark) & {
                background-color: transparent;
                border: 0;
            }

            &:hover:not(:disabled) {
                color: #b8bccc;
                background-color: transparent;

                :global(.dark) & {
                    color: #b8bccc!important;
                }
            }

            font-size: 1.3em;

            @media (max-width: 767px) and (orientation: 'portrait') {
                &:before {
                    font-size: 1.3em;
                }
            }
        }

        &.playPause {
            width: 2.75em;
            height: 2.75em;
            background: #00b5ff;
            border-radius: 50%;
            color: $color_withe;
            line-height: 0;
            border: 0;

            :global(.dark) & {
                background: #00b5ff;
                color: $color_withe;
            }

            i {
                line-height: 0.8;
            }

            [class*='cl_icon_play'],
            [class*='icon_play'] {
                &:before {
                    font-size: 0.9em;
                    margin-left: 0.2em;
                }
            }

            [class*='cl_icon_pause'],
            [class*='icon_pause'] {
                &:before {
                    margin-left: 0;
                }
            }

            &:hover:not(:disabled) {
                background: #67d3ff;
                // cursor: default;
            }

            &[class*='active'] {
                animation: played 2s infinite;
            }
        }
    }

    [data-learn-border]:not([data-learn='7']) {
        border-radius: 50%;
    }

    [data-learn='7'] {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        width: auto;
        height: auto;
        margin: 0.5em;
        padding: 0;
        position: relative;
        width: 1em;
        height: 1em;
        border-radius: 0;
        padding-top: 0.2em;

        button {
            position: absolute;
        }
    }

    &__progressInSec {
        font-size: 0.8em;
        display: block;
        color: #7c8195;
        width: 6em;
        margin-left: 0.4em;

        :global(.dark) & {
            color: #fff;
        }
    }
}

.loader {
    border-radius: 50%;
    background-color: rgba($color: #fff, $alpha: 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

@keyframes played {
    0% {
        background: #00b5ff;
    }

    50% {
        background: darken(#67d3ff, 40%);
    }

    100% {
        background: #00b5ff;
    }
}
