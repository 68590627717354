@import '../../scss/variables/variables';
@import '../../scss/mixins/mixins';

.root {
    position: relative;
    flex: none;
    flex-grow: 1;
    height: 100%;
    max-height: 100%;
    min-height: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background: #ffffff;
    box-shadow: 0 0.25em 0.9375em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    padding: 1.15em 0.65em 1.25em 0.7em;
    user-select: none;

    :global(.dark) & {
        background: #282b33;
    }

    canvas {
        width: 100%;
        flex-grow: 1;
        min-width: 0;
        min-height: 0;
        max-width: 100%;
    }
}

.head {
    padding: 0 1em 0 0.5em;
    margin-bottom: 1.125em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: none;
}

.left {
    display: flex;
    align-items: center;
    gap: 1.8125em;
    overflow: auto hidden;
    min-width: 0;
    margin-right: 1.4em;

    @extend %scrollbar;
}

.legend {
    // position: absolute;
    // top: 4.7%;
    // left: 9%;
    // padding: 0.8em;
    // border: 1px solid #A3A3A2;
    // font-size: 0.7em;
    display: flex;
    align-items: center;
    // z-index: 2;
    background: $light_theme_background;

    :global(.dark) & {
        background: #282b33;
    }

    &Multy {
        display: block;

        > div {
            display: flex;
            align-items: center;
        }
    }

    span {
        font-weight: 600;
        font-size: 0.875em;
    }
}

.xlabel {
    font-size: 0.625em;
}

.action {
    // margin-bottom: -0.6em;
    // padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625em;
    cursor: pointer;
    height: 1.8em;

    i,
    button {
        width: 1.65em;
        height: 1.65em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.1em;
        color: #666f7e;
        background-color: transparent;
        border: 1px solid #bec9d3;
        border-radius: 2px;
        transition: 0.3s;
        transition-property: color, background;
        cursor: pointer;

        :global(.dark) & {
            border: 1px solid #343640;
        }

        &:hover:not(:disabled) {
            color: #bec9d3;
            :global(.dark) & {
                color: #ffffff;
            }
        }

        &.btnActive {
            border: 1px solid #00b5ff;
            background: #00b5ff;
            color: #ffffff;

            &:hover {
                color: #ffffff;
            }
        }

        &:disabled {
            cursor: default;
            opacity: 0.5;
        }
    }
}

.color {
    flex: none;
    width: 1.25em;
    height: 1.25em;
    border-radius: 0.1875em;
    margin-right: 1em;
}

.data {
    display: none !important;
    padding: 0.7em 0.8em;
    font-size: 0.7em;
    line-height: 1.3;
    border: 1px solid #a3a3a2;
    color: $light_theme_color;
    position: absolute;
    right: 2.3%;
    top: 3.3%;
    white-space: nowrap;
    // z-index: 2;
    background: $light_theme_background;

    :global(.dark) & {
        color: #a3a3a2;
        border: 0;
        background: $dark_theme_background;
    }

    &Line {
        display: none !important;
    }

    &:hover {
        .chart__data {
            display: block;
        }
    }
}

.wrap {
    display: flex;
    // flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    // margin-bottom: -0.9em;

    .mono & {
        flex-direction: row;
    }
}

.name {
    font-size: 0.66em;
    font-weight: 500;
    writing-mode: vertical-rl;
    transform-origin: center;
    transform: rotate(-180deg);
    text-align: center;
    user-select: none;
    padding: 2em 0.5em 0 0;
}

.big {
    display: flex;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    max-width: 100%;

    // > div {
    //     display: flex;
    //     flex-grow: 1;
    //     min-width: 0;
    //     min-height: 0;
    // }
}

.titles {
    padding: 3em 0 1em 0.5em;
    line-height: 1.4;
    font-weight: 500;
    writing-mode: tb-rl;
    text-align: center;
    transform: rotate(180deg);

    span {
        display: inline-block;
        font-size: 0.75em;

        &:not(:last-child):after {
            content: ', ';
        }
    }
}

.foot {
    padding: 0.1em 0.5em 0.1em 2.3em;
    // width: 13em;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.1875em;
    position: relative;
    z-index: 1;

    label {
        display: flex;
        align-items: center;
        font-size: 0.8em;
    }

    span {
        font-size: 0.8em;
        padding-right: 0.4em;
        color: #828282;
    }
}

.axswicher {
    width: 6.25em;
    display: flex;
    align-items: center;
    gap: 1.25em;
}

.axgrid {
    display: flex;
    justify-content: flex-end;
    width: 6.25em;
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.01);
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: '';
        color: #fff;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        border: 0.1875em solid;
        border-bottom-color: transparent;
        animation: loader 1.5s infinite linear;
    }
}

@keyframes loader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
