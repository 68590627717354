.root {
    word-wrap: break-word;
}

.header {
    padding: 0.6875em 1em;
    border-bottom: 1px solid;
    border-color: #E8E8E8;

    :global(.dark) & {
        border-color: #A2A2A5;
    }

    span {
        font-weight: 600;
        font-size: 1.125em;
    }
}

.body {
    padding: 1.125em 1em;
}

.item {
    margin-top: 0.75em;

    &:first-child {
        margin-top: 0;
    }
    
    line-height: 1.3;

    > * {
        display: block;
    }

}

.button {
    margin-top: 1.125em;
    button {
        width: 100%;
    }
}