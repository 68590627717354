.root {
    position: relative;

    > * {
        &:first-child {
            input {
                padding-right: 4.5em;

            }
        }
    }

    span {
        position: absolute;
        top: 0.8em;
        right: 0.5em;
        font-size: 0.75em;
        color: #7c8195;

        :global(.dark) & {
            color: lighten(#7c8195, 10%);
        }
    }
}
