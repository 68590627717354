.column {
  position: relative;
  overflow: hidden;
  background: #F0F3F5;
  border: 1px solid #E8E8E8;
  border-radius: 0.375em;
  display: flex;
  flex-direction: column;
  transition: flex-grow 0.2s;
  width: 20em;
  transition: border 0.3s;

  :global(.dark) & {
    background: #16171D;
    border: 1px solid #343640;
    color: #F0F3F5;
  }

  &:hover {
    border: 1px solid #00B5FF;
  }
}

.grid {
  font-size: 1.25em;
}

.preview {
  height: 9.375em;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;

    :global(.dark) & {
      background: #16171D;
      color: #FFFFFF;
    }
  }
}

.edit {
  width: 100%;
  height: 100%;
  border: 0;
}

.mode {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.content {
  min-height: 5.25em;
  padding: 0.875em;
  background: #ffffff;
  :global(.dark) & {
    background: #16171D;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-size: 0.875em;
  color: #383E47;
 
  cursor: pointer;

  :global(.dark) & {
    color: #d1d1d1;
  }
}

.preview {
  cursor: pointer;
}

.controls {
  display: flex;
  align-items: center;
  gap: 0.625em;
  color: #666F7E;
  cursor: pointer;
  :global(.dark) & {
    color: #ffffff;
  }
}

.desc {
  margin-top: 0.5em;
  word-wrap: break-word;
  span {
    font-size: 0.75em;
    font-weight: 500;
    color: #7c8195;
    :global(.dark) & {
      color: #A2A2A5;
    }

  }
}
