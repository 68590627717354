@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";

.cor_practicumLab {
    &__title {
        background: #F5F8FA;
        border-width: 1px 0;
        border-style: solid;
        border-color: #E8E8E8;
        padding: 0.4375em 1.25em;
        font-size: 1.2em; // Физика ...
        font-weight: 500;
        display: flex;
        align-items: center;
        color: #000000;

        .dark & {
            background: #282B34;
            color: darken($color: white, $amount: 3%);
            border-color: #2E6B88;
        }

        img {
            flex: none;
            margin-right: 0.3em;
            width: 0.9em;
        }
    }

    &__list {
        &Item {}
    }
    &__subjectIcon {
        color: $lw_blue_color;
    }

    &__row { 
        display: flex;
        align-items: center;
        padding: 0 1.25em; //0.5em 1.25em; //vert.padding 0.5em moved below to Name
        //gap: 1em;
        border-top: 1px solid transparent;
        border-bottom: 1px solid #E8E8E8;
        margin-top: -1px;
        min-height: 1.9em;
        cursor: pointer;

        &:hover:not(.cor_practicumLab__row_selected) {
            background-color: rgba(#F4FAFC, 0.5);
            border-top: 1px solid rgba($lw_blue_color, 0.3);
            border-bottom: 1px solid rgba($lw_blue_color, 0.3);

            .dark & { 
                background-color: lighten(#282B34, 10%);
            }
        }

        &:last-child {
            border-bottom-color: transparent;
        }

        &_selected {
            position: relative;
            color: $lw_blue_color;
            background-color: #F4FAFC;
            border-top: 1px solid $lw_blue_color;
            border-bottom: 1px solid $lw_blue_color;

            &:last-child {
                border-bottom-color: $lw_blue_color;
            }

            .dark & { 
                background: lighten(#282B34, 10%);
                color: darken($color_withe, 10%);
                border-top: solid 1px $lw_blue_color;
                border-bottom: solid 1px $lw_blue_color;
            }
        }

        &Name {
            padding: 0.5em 0;
            flex-grow: 1;
            min-width: 0;
            font-size: 1em; //1. Механика... (левая часть)
            display: block;
        }

        &Btn {
            padding: 0;
            display: block;
            width: 100%; //50%;
            text-align: left;
            border: none;
            font-family: $main_font_family;
            line-height: 1;
            font-size: 1em;
            color: $lw_blue_color;
            background: #F4FAFC;

            .dark & {
                background: lighten(#282B34, 10%);
                color: darken($color_withe, 10%);
            }
        }

        &Toggle {
            display: flex;
            align-items: center;
            gap: 0.625em;

            label {
                font-size: 0.75em; //Демон. - Тестирование
                color: #7C8195;
                font-weight: 500;
                cursor: pointer;

                &.active {
                    color: #1E1F25;

                    .dark & {
                        color: darken($color_withe, 10%);
                    }
    
                }
            }

            &Notactve {
                .dark & {
                    color: darken($color_withe, 30%);
                }
    
            }
        }
    }
}

.cor_practicumLabList {
    max-height: 100%;
    padding-bottom: 1em;

    &__title {
        font-size: 1.1em;   //1. Механика.. (правая сторона)
        font-weight: 600;
        margin-bottom: 1em;
        line-height: 1.27;
        padding-left: 0.85em;
    }

    &__item {
        color: #215794;
        padding: 0.25em 0.8125em;

        .dark & {
            color: darken($color_withe, 10%);
        }

        &_selected {
            color: $lw_blue_color;
            background-color: #F4FAFC;
            border-radius: 2px;

            .dark & {
                background-color: lighten(#282B34, 10%);
                color: darken($color_withe, 10%);
                // border: solid 1px $lw_blue_color;;
            }
        }
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1em;
        min-height: 1.3125em;
        cursor: pointer;
    }

    &__name {
        font-size: 1em; //1. Определение цены деления...
        line-height: 1.2;
        width: 90%;

        &_selected {}
    }

    &__drop {
        position: relative;

        &:hover {
          .cor_practicumLabList__dropList {
            animation: practicumLabListDrop 0.3s;
            display: block;
          }
        }
        
        .cor_practicumLabList__toggle {
            background: #D2EFFF;
            color: #00B5FF;
        }
    }

    &__dropList {
        display: none;
        position: absolute;

        top: 50%;
        transform: translateY(-50%);
        right: calc(100% + 0.5em);

        padding: 0.6em 1em;
        width: max-content;
        border: 1px solid $footer_dropdown_border_color;
        border-radius: 0.375rem;
        color: #4A4A4D;
        background-color: $footer_dropdown_bg;
        font-size: 0.625em;
        font-weight: 500;
        box-shadow: 0px 0.5625em 0.9375em 0px rgba(52, 61, 70, 0.09);

        .dark & {
            background: $footer_dark_button_bg;
            box-shadow: 0 0.25em 1.875em rgba(0, 0, 0, 0.1);
            border: 1px solid $footer_dark_dropdown_border_color;
            color: $footer_dark_color;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 100%;
          width: 2em;
        }
    }

    &__dropItem {
        display: flex;
        align-items: center;
        gap: 0.75em;

        width: max-content;

        margin-top: 0.675em;
        transition: color 0.3s;
        

        &:first-child {
            margin-top: 0;
        }

        p {
            margin: 0;
        }

        &:hover {
          color: #00B5FF;
        }
    }

    &__toggle {
        flex: none;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 1.125em;
        height: 1.125em;
        border-radius: 0.25em;

        background: #00B5FF;
        color: #ffffff;
        cursor: pointer;

        &:before {
            display: inline-block;
            font-size: 0.6em;
            transform: translateX(0.075em);
        }
    }

    .cor_icon {
        &:after {
          right: 140%;
        }
    }
}


@keyframes practicumLabListDrop {
    0% {
        transform: translate(-0.5em, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}
