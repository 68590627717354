@import "../../../scss/mixins/mixins";

.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75em;

    &_2 {
        @include grid(2);
    }

    &_3 {
        @include grid(3);
    }

    &_4 {
        @include grid(4);
    }

    &_5 {
        @include grid(5);
    }

    &_6 {
        @include grid(6);
    }

    &_7 {
        @include grid(7);
    }

    &_8 {
        @include grid(8);
    }

    &_9 {
        @include grid(9);
    }

    &_10 {
        @include grid(10);
    }

    &_11 {
        @include grid(11);
    }

    &_12 {
        @include grid(12);
    }
}
