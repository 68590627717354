@import "../../scss/mixins/mixins";
@import "../../scss/variables/variables";
@import "../../Components/ui/Icon/variables.scss";



.root {
    display: flex;
    gap: 1em;

}

.iconBt {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.875em;
    height: 1.875em;
    border-radius: 0.125em;
   

    background-color: #f5f8fa;
    border: 1px solid #bec9d3;
    color: #666F7E;
    transition: 0.3s;
    cursor: pointer;

    :global(.dark) & {
        color: #d1d1d1;
        background: #363a45;
        border: 1px solid #4a4f5b;
    }

    &:hover {
        background-color: #00B5FF;
        border: 1px solid #00B5FF;
        color: $color_withe;
    }

    &_active {
        background-color: #00B5FF;
        border: 1px solid #00B5FF;
        color: $color_withe;
    }

 
}

.iconBtActive {
    composes: iconBt;
    background-color: #00B5FF;
    border: 1px solid #00B5FF;
    color: $color_withe;

    :global(.dark) & {
        background-color: #00B5FF;
        border: 1px solid #00B5FF;
        color: $color_withe;
    }
}

.steps {

    [class^=cor-net__section] {
        padding-top: 1.25em;
        border-top: 1px solid #E8E8E8;

        :global(.dark) & {
            border-top: 1px solid #A2A2A5;
        }

        &:first-child {
            padding-top: 0;
            border-top: 0;
        }
    }
}

.leftContent {
    width: calc(50% - 0.35em);
}

.img {
    position: relative;
    width: calc(50% - 0.35em);
    height: 23.75em;
    margin-top: 6.75em;
    border: 1px solid #bec9d3;
    border-radius: 0.3125em;
    overflow: hidden;
    
    :global(.dark) & {
        border: 1px solid #4a4f5b;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    &__add {
        display: flex;
        align-items: center;
        gap: 0.25em;
        color: #00B5FF;
        font-weight: 500;
        cursor: pointer;

        span:not(.cor_icon) {
            font-size: 0.875em;
        }

        input[type=file] {
            display: none;
        }
    }

    &__trash {
        position: absolute;
        top: 1.25em;
        right: 1.25em;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 1.875em;
        height: 1.875em;
        border-radius: 0.375em;

        background-color: rgba(#383E47, 1);
        opacity: 0.6;
        color: $color_withe;
        border: 1px solid #4a4f5b;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }
}

.img_full {
    composes: img;
    width: auto;
    height: 26em;
}


.delete {
    display: flex;
    align-items: center;
    gap: 0.375em;
    color: #00B5FF;
    font-weight: 500;
    cursor: pointer;

    &:before {
      content: $cor_icon_trash;
      font-family: 'cor';
    }

}

.btnFile {

    display: inline-block;
    font-size: 0.75em;
    font-weight: 500;
    padding: 1em;
    border: 1px solid;
    border-radius: 0.3125em;
    width: 100%;
    text-align: center;
    line-height: 1.2;


    background-color: transparent;
    border-color: #00b5ff;
    color: #00b5ff;

    transition: 0.3s;
    cursor: pointer;

    :global(.cor-net__col) & {
        padding: 0.58em;
    }

    &:hover {
        border-color: #67d3ff;;
        color: #67d3ff;
      
    }
    
    input[type=file] {
        display: none;
    }
}

.downloadFile {
    display: flex;
    align-items: center;
    gap: 0.375em;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 1em;
        height: 1em;
        cursor: pointer;

        [class^=cor_icon] {
            font-size: 0.875em;
            color: #858C98;
            font-weight: bold;
            transition: 0.3s;
        }

        :global(.dark) & {
            // color: #00b5ff;
            // border: 1px solid #4a4f5b;
        }

        &:hover {
            [class^=cor_icon] {
                color: #00b5ff;
            }
        }

    }

}

.selectScheme {
    display: flex;
    gap: 1.25em;

    &__list {
        flex-grow: 1;
    }

    &__item {
        display: block;

        font-size: 0.75em;
        line-height: 1;
        padding: 1em;

        margin-top: 1em;

        &:first-child {
            margin-top: 0;
        }

        border: 1px solid;
        border-radius: 0.375em;

        border-color: #E8E8E8; 
        color: #383E47;

        cursor: pointer;
        transition: 0.3s;

        :global(.dark) & {
            color: #E8E8E8;
        }

        &:hover {
          color: #00B5FF;
          border-color: #00B5FF;
        }



    }

    &__itemActive {
        composes: selectScheme__item;
        color: #00B5FF;
        border-color: #00B5FF;
        :global(.dark) & {
            color: #00B5FF;;
        }

    }

    &__img {
        // border-radius: 0.375em;
        // overflow: hidden;
        img {
            display: block;
            width: 100%;
            // height: 100%;
            object-fit: cover;
        }
    }

    &__sensore {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.445em;

        .selectScheme__img & {
        }
        
    }
    
    &__sensore_view {
        composes: selectScheme__sensore;
        font-size: 0.5em;
    }
}

.widthSensoreModal {

    & > div {
        max-width: 72em;

    }
}

.preview {

    [class*=ScenarioRunDlg_toggle] {
        cursor: default;
    }

    &__sticky {
        position: sticky;
        top: 0;
        right: 0;
        height: 100%;

    }

    &__title {
        color: #1E1F25;
        font-size: 1.0625em;
        font-weight: 600;
        margin-bottom: 0.88234em;

        :global(.dark) & {
            color: #BEC9D3;
        }

    }

   
}


