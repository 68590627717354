.cor-storage {

    .cor_dashboardBox {
        margin-top: 1.25em;
    }

    &__header {
        padding: 0.9375em 1.25em;
        border-bottom: 1px solid #E8E8E8;

        .dark & {
            border-bottom: 1px solid #A2A2A5;
        }
    }
    
    &__main {
        padding: 1.25em 1.25em 0;
        flex-grow: 1;
        min-height: 0;


        .corGrid {
            margin-top: 2em;
        }
    }

    &__footer {
        padding: 0.75em 1.25em;
        border-top: 1px solid #E8E8E8;
        color: #666F7E;

        .dark & {
            border-top: 1px solid #A2A2A5;
        }



        display: flex;
        align-items: center;
        justify-content: space-between;

        &_item {
            font-size: 0.875em;
            width: 33.3%;
            text-align: center;
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }

        }

        .storage {
            span {
                opacity: 0.7;
            }
        }
        .action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 1.875em;

            > span {
                cursor: pointer;
               
            }

            .cor_icon {
                margin-right: 0.375em;
                font-size: 1.125em;
            }
        }

    }

    &__sorting {
        .sorting {
            display: flex;
            align-items: center;

            border-radius: 2px;
            background-color: #D7E1E8;

            .dark & {
                background-color: #1F2026;

            }

            .grid_md &,
            .grid_lg & {
                display: inline-flex;
            }

            &__item {
                display: flex;
                align-items: center;
                gap: 0.375em;

                padding: 0.5em 1.25em;
                overflow: hidden;
                cursor: pointer;
             
                span:not(.cor_icon) {
                    font-size: 0.625em;
                    color: #444754;

                    .dark & {
                        color: #A2A2A5;
                    }
                }

                .cor_icon {
                    width: 1.125em;
                    text-align: center;
                    color: #444754;

                    .dark & {
                        color: #A2A2A5;
                    }

                }
            }
        }
    }

 
    &__list {

        .grid_md & {
            margin-top: 1.25em;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 1em;
            grid-auto-rows: minmax(4.875em, auto);
        }

        .grid_lg & {
            margin-top: 1.25em;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 1em;
            grid-auto-rows: minmax(6em, auto);

        }

      
    }

    &__item {
        position: relative;

        cursor: pointer;
        transition: background-color 0.3s;

        &.back {
            .caption {
                &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }
            }
        }

        .tb & {
            display: flex;
            align-items: center;

            &:nth-child(2n):not(:hover) {
                border-radius: 2px;
                background-color: #F0F3F5;

                .dark & {
                    background-color: #363A45;
                }
            }
        }

        .grid_md & { 
            background-color: #F4FAFC;
            border-radius: 0.125em;
            
            padding: 0.9375em 0.9375em 0.9375em 3.75em;
            
            .dark & {
                background-color: #363A45;
            }
        }

        .grid_lg & { 
            background-color: #F4FAFC;
            border-radius: 0.125em;
            
            padding: 0.9375em 1.25em 1em 6.25em;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 0.125em;
            grid-row-gap: 0.5em;

            .dark & {
                background-color: #363A45;
            }
        }


        &:hover {
            background-color: darken(#F4FAFC, 3%);
            
            .dark & {
                background: lighten(#363A45, 5%);
            }
        }
    }

    &__dropdown {}

    &__toggle {
        display: none;
    }

    &__drop {
        display: flex;
        align-items: center;
        gap: 0.625em;

        .cor_icon {
            i {
                transition: 0.3s;
                cursor: pointer;
            }

            span {
                display: none;
            }

            &:hover:not(.disabled) {
                i {
                    color:#00B5FF;

                }
            }

            &.disabled {
                i {
                    cursor: not-allowed;
                    opacity: 0.5;

                }
            }
        }
    }


    &__unit {
        // border: 1px solid #000;

        // start all style
        &.caption {}

        &.date {}

        &.size {}

        &.type {}

        &.action {}


        span:not(.cor_icon) {
            font-size: 0.625em;
            color: #1E1F25;

            .dark & {
                color: #ffffff;
            }
        }
        
        .cor_icon {
            i {
                color: #666F7E;
    
                .dark & {
                    color: #A2A2A5;
                }
            }
        }
        // end all style


        // start table style
        .tb & {

            padding: 0.5em 1.25em;
            // overflow: hidden;
    
            &.caption {
                display: flex;
                align-items: center;

                .cor_icon,
                .cor-storage__preview {
                    margin-right: 0.625em;
                }
            }

            &.date {
            }
    
            &.size {
            }
    
            &.type {
            }
    
            &.action {
            }

            .cor-storage__label {
                display: none;
            }
        }
        // end table style

        // start grid_md style
        .grid_md & {
          
            .cor-storage__label {
                display: none;
            }

            &.caption {
                width: 11.5em;

                .cor_icon i {
                    font-size: 1.875em;
                }
           
                .cor_icon,
                .cor-storage__preview {
                    position: absolute;
                    top: 0.9375em;
                    left: 0.9375em;
                }

            }
    
            &.date {
                span {
                    color: #A2A6B6;
                }
            }
    
            &.size {
                span {
                    color: #A2A6B6;
                }
            
            }
    
            &.type {
               display: none;
            }
    
            &.action {
                position: absolute;
                top: 0.875em;
                right: 1em;

                .cor-storage__toggle {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 1.25em;
                    height: 1.25em;
                    border-radius: 0.1875em;

                    background: transparent;
                    color: #666F7E;

                    transition: 0.3s;

                    &:hover {
                    }
                }
                
                &:hover {
                    .cor-storage__toggle {
                        color: #00B5FF;
                        background: rgba(51, 196, 255, 0.15);

                    }
                    .cor-storage__drop {
                        display: flex;
                        animation: tooltipFadeUpSide 0.3s;

                    }

                }

                .cor-storage__drop {
                    display: none;
                    position: absolute;
                    top: 1.5em;
                    right: -0.5em;
                    z-index: 1;
                    flex-direction: column;
                    align-items: flex-start;
                    background-color: #FDFDFD;
                    border: 1px solid #BEC9D3;
                    padding: 0.625em;
                    border-radius: 0.375em;
                    box-shadow: 0px 9px 15px 0px rgba(52, 61, 70, 0.09);

                    .dark & {
                        background-color: #282B33;
                        border: 1px solid #343640;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: -1em;
                        left: 0;
                        width: 100%;
                        height: 1em;
                        display: block;
                    }


                    .cor_icon  {
                        display: flex;
                        align-items: center;
                        gap: 0.5em;

                        &:after {
                          content: none;
                        }

                        span {
                            display: inline;
                        }
                    }
                }
            }     
        }
        // end grid_md style

        // start grid_lg style
        .grid_lg & {
            .cor-storage__label {
                color: #A2A6B6;
                margin-right: 0.625em;

                .dark & {
                    color: #A2A2A5;
                }
            }

            &.caption {
                grid-column-start: 1;
                grid-column-end: 4;

                .cor_icon i {
                    font-size: 4em;
                }
           
                .cor_icon,
                .cor-storage__preview {
                    position: absolute;
                    top: 0.9375em;
                    left: 0.9375em;

                }
            }
    
            &.date {
                grid-column-start: 1;
                grid-column-end: 5;
            }
    
            &.size {
                grid-column-start: 1;
                grid-column-end: 3;
            }
    
            &.type {
                grid-column-start: 3;
                grid-column-end: 5;

                display: flex;
                justify-content: flex-end;
            }
    
            &.action {
                grid-row-start: 1;
                grid-column-start: 4;
                grid-column-end: 5;

                .cor-storage__drop {
                    justify-content: flex-end;

                }

            }     
        }
        // end grid_lg style
    }

    &__preview {
        width: 1em;
        height: 1em;
        border-radius: 0.1875em;
        overflow: hidden;

        .grid_md & {
            width: 1.875em;
            height: 1.875em;
        }

        .grid_lg & {
            width: 4em;
            height: 4em;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }

}

.cor-storage__unit,
.sorting__item {
    .tb & {
        &.caption {
            width: 36%;
        }

        &.date {
            width: 20%;
        }

        &.size {
            width: 17%;
        }

        &.type {
            width: 17%;
        }

        &.action {
            width: 10%;
        }     
    }

}

.sorting__item {
    .grid_md & {
        &.caption {
        }

        &.date {
        }

        &.size {
        }

        &.type {
            display: none;
        }

        &.action {
            display: none;
        }     
    }

    .grid_lg & {
        &.caption {
        }

        &.date {
        }

        &.size {
        }

        &.type {
            display: none;
        }

        &.action {
            display: none;
        }     
    }
}



