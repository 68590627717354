@import "../../scss/mixins/mixins";
@import "../../scss/core/table";

.cor_rlabTable {
  &__name {
    width: 25%;
  }

  &__date {
    width: 15%;
  }

  &__desc {
    width: 20%;
  }

  &__link {
    width: 17%;
  }

  &__owner {
    width: 18%;
  }

  &__edit {
    width: 5%;
  }

  td {
    text-align: left;
    font-size: 1em;
    color: #1E1F25;
    padding: 0.6em 1.25em;

    &.link, & .cor_icon {
      cursor: pointer;
      :before {
        padding: 0 0.3em;
      }
    }

    &.editablePopup {
      position: relative;
      height: 1.4375em;

      & textarea {
        width: 85%;
        position: absolute;
        top: 0.4em;
        padding: 0.2em 0.5em;
        resize: none;
        line-height: 1.6em;
        font-size: 1em;
        z-index: 1;
      }
    }

    &.inactive, &.inactive i:before {
      color: #89909C;
    }

    button {
      font-size: 0.7em;
      padding: 0.2em 1em;
    }
  }

  // tr:nth-child(odd) {
  //   background-color: #FFFFFF;
  // }

  // tr:nth-child(even) {
  //   background-color: #F0F3F5;
  // }

  // th:first-child, td:first-child {
  //   border-radius: 0.25em 0 0 0.25em;
  // }

  // th:last-child, td:last-child {
  //   border-radius: 0 0.25em 0.25em 0;
  // }
}
