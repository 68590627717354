@import '../../scss/variables/variables';
@import '../../scss/mixins/mixins';

.root {
    margin-top: 0;
    padding: 0.5em 0.6em 1em 0.7em;
    min-width: 0;
    margin: 0 -1.5em 0 -1.4em;
    // max-width: 100%;
    flex-grow: 1;
    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    gap: 1.375em;
    @extend %scrollbar;

    @media (min-width: 768px) {
        margin: 0 0em 0 0;
    }

    @media (max-width: 767px) and (orientation: 'landscape') {
        padding: 0;
    }

    &.all {
        flex-direction: row;
        flex-wrap: wrap;

        [class^='Chart_root'] {
            width: calc(50% - 0.7em);
            height: calc(33.3% - 0.9em);
            padding: 0.8em 0.65em 0.8em 0.7em;
        }

        [class^='Chart_head--'] {
            margin-bottom: 0.3em;
        }

        &.items1 {
            [class^='Chart_root'] {
                width: 100%;
                height: auto;
            }
        }

        &.items2 {
            [class^='Chart_root'] {
                width: 100%;
                height: calc(50% - 0.7em);
            }
        }

        &.items3 {
            [class^='Chart_root'] {
                width: calc(50% - 0.7em);
                height: calc(50% - 0.7em);

                &:first-child {
                    width: 100%;
                }
            }
        }
        &.items4 {
            [class^='Chart_root'] {
                width: calc(50% - 0.7em);
                height: calc(50% - 0.7em);
            }
        }
        &.items5 {
            [class^='Chart_root'] {
                width: calc(50% - 0.7em);
                height: calc(33.3% - 0.9em);
                padding: 0.8em 0.65em 0.8em 0.7em;

                &:first-child {
                    width: 100%;
                }
            }
            [class^='Chart_head'] {
                margin-bottom: 0.3em;
            }
        }
    }

    &.one {
        flex-direction: row;
        flex-wrap: wrap;

        [class^='Chart_root'] {
            width: 100%;
            height: calc(100vh - 10.2em);

            @media (max-width: 767px) and (orientation: 'landscape') {
                height: calc(100vh - 4.375em);
            }
        }
    }

    &.two {
        [class^='Chart_root'] {
            height: calc(50% - 0.8em);
        }
    }

    &.three {
        [class^='Chart_root'] {
            height: calc(33% - 0.8em);
            flex-grow: 1;
        }
    }

    &.four {
        flex-direction: row;
        flex-wrap: wrap;

        [class^='Chart_root'] {
            width: calc(50% - 0.7em);
            height: calc(50% - 0.7em);
            // flex-grow: 1;
        }
    }
}

.wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    // max-width: calc(100vw - 30em);
    // max-width: 100%;

    &Collapsed {
        @media (max-width: 767px) {
            flex: 0;
            width: 0;
            overflow: hidden;
        }
    }
}

.head {
    padding: 0 0.4em 0.25em 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 1.125em;
    font-weight: 600;

    &::first-letter {
        text-transform: uppercase;
    }

    :global(.dark) & {
        color: $color_withe;
    }
}

.settings {
    display: flex;
    align-items: center;
    gap: 0.9375em;

    label {
        font-size: 0.75em;
        color: #7c8195;
        font-weight: 500;

        :global(.dark) & {
            color: #c7c9d1;
        }
    }

    [class^='Dropdown_root'] {
        width: 9.6em;
    }

    i {
        color: #666f7e;
        cursor: pointer;
        font-size: 1.125em;

        :global(.dark) & {
            color: #c7c9d1;
        }
    }
}
