.root {
    gap: 0.9375em;
    padding-top: 1em;
}

.item {
    display: flex;
    flex-direction: column;
    width: 25em;
    border-radius: 0.375em;


    background: #FFFFFF;
    border: 1px solid;
    border-color: #E8E8E8;

    :global(.dark) & {
        background: #363A45;
        border-color: #484B5A;
    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.125em 1em;

    border-top-left-radius: 0.375em;
    border-top-right-radius: 0.375em;
    margin: -1px -1px 0;

    background: #EFF6F9;

    :global(.dark) & {
        background: #16171D;
    }
}

.head_active {
    composes: head;
    background: #00B5FF;
    color: #fff;

    :global(.dark) & {
        background: #00B5FF;
        color: #fff;
    }

    .gear {
        color: #fff;
        &:hover {
            color: #fff;
        }
        :global(.dark) & {
           
            color: #fff;
        }
    }


}

.name {
    font-size: 1.25em;
    font-weight: 500;
}

.gear {
    font-size: 1.25em;
    color: #666F7E;
    cursor: pointer;
    transition: color 0.3s;

    :global(.dark) & {
        color: #d1d1d1;
    }

    &:hover {
      color: #00B5FF;
    }
}

.main {
    padding: 1em;
    flex-grow: 1;
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        display: flex;
        gap: 0.5em;
        margin-top: 0.25em;
        &:first-child {
            margin-top: 0;
        }

        span {
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
                color: #7C8195;
                min-width: 6.5em;

                b {
                    font-weight: 400;
                    color: #393A40;
                }

                :global(.dark) & {
                    color: #A2A2A5;

                    b {
                        color: #fff;
                    }
                }
            }
        }

    }
}

.bottom {
    padding: 1em;

    border-top: 1px solid;
    border-color: #E8E8E8;

    :global(.dark) & {
        border-color: #484B5A;
    }


    &__link {
        color:#00B5FF;
        cursor: pointer;
        &:after {
          content: '>';
          margin-left: 0.125em;
          
        }
    }
}


